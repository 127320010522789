<template>
  <div class="teamPage hyundai">
    <Top />
    <router-view></router-view>
  </div>
</template>

<script>
import Top from '@/components/Team/Top'

export default {
  name: 'TeamView',
  components: {
    Top
  }
}
</script>

