import Index from '@/views/Mypage.vue'
import MyPage from '@/components/Mypage/MyPage.vue'
import Pay from '@/components/Mypage/Pay.vue'
import PayTicket from '@/components/Mypage/PayTicket.vue'
import PaySeason from '@/components/Mypage/PaySeason.vue'
import PayEntrySeason from "@/components/Mypage/PayEntrySeason"
import PayGoods from '@/components/Mypage/PayGoods.vue'
import Activity from '@/components/Mypage/Activity.vue'
import ActivityQna from '@/components/Mypage/ActivityQna.vue'
import ActivityWrite from '@/components/Mypage/ActivityWrite.vue'
import ActivityEvent from '@/components/Mypage/ActivityEvent.vue'
import ActivitySurvey from '@/components/Mypage/ActivitySurvey.vue'
import Point from '@/components/Mypage/Point.vue'
import PointHistory from '@/components/Mypage/PointHistory.vue'
import PointCoupon from '@/components/Mypage/PointCoupon.vue'
import PointReservation from '@/components/Mypage/PointReservation.vue'

const mypage = [
  {
    path: '/mypage',
    name: 'mypage',
    component: Index,
    redirect: {
      name: 'mypageMyPage'
    },
    children: [
      {
        path: 'mypage',
        name: 'mypageMyPage',
        component: MyPage,
        meta: {
          mypage: 'mypage'
        }
      },
      {
        path: 'pay',
        name: 'mypagePay',
        component: Pay,
        redirect: {
          name: 'mypagePayTicket'
        },
        children: [
          {
            path: 'ticket/:page',
            name: 'mypagePayTicket',
            component: PayTicket,
            meta: {
              mypage: 'pay',
              detail: 'ticket',
              scroll: '#scrollAnchor'
            },
            props: true,
          },
          {
            path: 'season/:page',
            name: 'mypagePaySeason',
            component: PaySeason,
            meta: {
              mypage: 'pay',
              detail: 'season',
              scroll: '#scrollAnchor'
            }
          },
          {
            path: 'entrySeason/:page',
            name: 'mypageEntrySeason',
            component: PayEntrySeason,
            meta: {
              mypage: 'pay',
              detail: 'entrySeason',
              scroll: '#scrollAnchor'
            }
          },
          {
            path: 'goods/:page',
            name: 'mypagePayGoods',
            component: PayGoods,
            meta: {
              mypage: 'pay',
              detail: 'goods',
              scroll: '#scrollAnchor'
            }
          }
        ]
      },
      {
        path: 'activity',
        name: 'mypageActivity',
        component: Activity,
        meta: {
          mypage: 'activity'
        },
        redirect: {
          name: 'mypageActivityQna'
        },
        children: [
          {
            path: 'qna/:page',
            name: 'mypageActivityQna',
            component: ActivityQna,
            meta: {
              mypage: 'activity',
              detail: 'qna',
              scroll: '#scrollAnchor'
            }
          },
          {
            path: 'write',
            name: 'mypageActivityWrite',
            component: ActivityWrite,
            meta: {
              mypage: 'activity',
              detail: 'qna'
            }
          },
          {
            path: 'event/:page',
            name: 'mypageActivityEvent',
            component: ActivityEvent,
            meta: {
              mypage: 'activity',
              detail: 'event',
              scroll: '#scrollAnchor'
            }
          },
          {
            path: 'survey/:page',
            name: 'mypageActivitySurvey',
            component: ActivitySurvey,
            meta: {
              mypage: 'activity',
              detail: 'survey',
              scroll: '#scrollAnchor'
            }
          }
        ]
      },
      {
        path: 'point',
        name: 'mypagePoint',
        component: Point,
        meta: {
          mypage: 'point'
        },
        redirect: {
          name: 'mypagePointHistory'
        },
        children: [
          {
            path: 'history/:page',
            name: 'mypagePointHistory',
            component: PointHistory,
            meta: {
              mypage: 'point',
              detail: 'history'
            }
          },
          {
            path: 'coupon/:page',
            name: 'mypagePointCoupon',
            component: PointCoupon,
            meta: {
              mypage: 'point',
              detail: 'coupon'
            }
          },
          {
            path: 'reservation/:page',
            name: 'mypagePointReservation',
            component: PointReservation,
            meta: {
              mypage: 'point',
              detail: 'reservation'
            }
          }
        ]
      }
    ]
  }
]

export default mypage
