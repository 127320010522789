<template>
  <div class="memberBox wide">
    <div class="step">
      <div
        v-for="step in steps"
        :key="step.id"
        class="item"
        :class="{active: step.id === currentStep}">
        <div class="num"><span>{{step.id}}</span></div>
        <div class="label" v-html="step.name"></div>
      </div>
    </div>
    <SignupStep1
      v-if="currentStep === '1'"
      @nextStep="nextStep" />
    <SignupStep2
      v-else-if="currentStep === '2'"
      @nextStep="nextStep" />
    <SignupStep3
      v-else-if="currentStep === '3'"
      @cert="cert"
      @nextStep="nextStep" />
    <SignupStep4
      v-else-if="currentStep === '4'"
      :certType="certType"
      :certInfo="certInfo"
      @confirmMigration="confirmMigration"
      @nextStep="nextStep" />
    <SignupStep5
      v-else-if="currentStep === '5'"
      :certInfo="certInfo"
      :migration="migration"
      @nextStep="nextStep" />
  </div>
</template>

<script>
import SignupStep1 from '@/components/Member/SignupStep1'
import SignupStep2 from '@/components/Member/SignupStep2'
import SignupStep3 from '@/components/Member/SignupStep3'
import SignupStep4 from '@/components/Member/SignupStep4'
import SignupStep5 from '@/components/Member/SignupStep5'

export default {
  name: 'MemberSignup',
  props: [
    'currentStep'
  ],
  components: {
    SignupStep1,
    SignupStep2,
    SignupStep3,
    SignupStep4,
    SignupStep5
  },
  computed: {
    stepInfo () {
      const current = this.currentStep
      return this.steps.find(x => x.id === current)
    }
  },
  mounted () {
  },
  created() {
    const userAgent = navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('naver')> -1) {
      alert('크롬, 사파리 등 기본 브라우저를 사용해주세요')
      return this.$router.go(-1)
    }
  },
  data () {
    return {
      steps: [
        { id: '1', name: '회원가입<br>안내' },
        { id: '2', name: '동의과정' },
        { id: '3', name: '본인인증' },
        { id: '4', name: '회원정보<br>입력' },
        { id: '5', name: '회원정보<br>통합' }
      ],
      certType: null,
      certInfo: null,
      migration: null,
    }
  },
  methods: {
    cert (certType, certInfo) {
      // console.log('CERT >>>>>>');
      this.certType = certType
      this.certInfo = certInfo
    },
    confirmMigration (migration) {
      // console.log('********** migration **********', migration)
      this.migration = migration
    },
    nextStep (step) {
      this.$router.push({
        params: {
          currentStep: step
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  font-size: 14px;
  text-align: center;
  color: #bebebe;
  .item {
    width: 95px;
    position: relative;
    &:first-child .num:before {
      left: 50%;
    }
    &:last-child .num:before {
      right: 50%;
    }
  }
  .num {
    width: 44px;
    margin: 0 auto 20px;
    font-size: 18px;
    font-weight: 700;
    span {
      display: block;
      position: relative;
      height: 44px;
      padding-top: 14px;
      border-radius: 100%;
      background-color: $lightgrey4;
      z-index: 1;
    }
    &:before {
      content: "";
      position: absolute;
      top: 21px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: $lightgrey4;
    }
  }
  .label {
    line-height: 1.3;
  }
  .active {
    .num span {
      color: $white;
      background-color: $primary2;
    }
    .label {
      font-weight: 700;
      color: #000;
    }
  }
}
@media screen and (max-width: 767px) {
  .step {
    margin-bottom: 50px;
    font-size: 12px;
  }
}
</style>
