<template>
  <div class="introSection section8">
    <div class="sectionTitle">영구결번</div>
    <div class="permanentSwiper">
      <swiper class="swiper"
        ref="permanentSwiper"
        :options="swiperOption">
        <swiper-slide
          v-for="(item, index) in permanentVacate"
          :key="index"
          class="swiperItem">
          <div class="slideInner">
            <div class="number">
              <img :src="require(`@/assets/img/history/num_permanent_${item.no}.png`)">
              <div class="text">
                <div class="desc" v-html="item.desc"></div>
                <div class="ess" v-html="item.ess"></div>
              </div>
            </div>
            <div class="img"><img :src="require(`@/assets/img/history/img_permanent_${item.no}.png`)"></div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev permanentSwiperPrev" slot="button-prev"></div>
      <div class="swiper-button-next permanentSwiperNext" slot="button-next"></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'HistoryPermanentVacate',
  props: [
    'active'
  ],
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    isActive () {
      return this.active && this.active.index === 8
    }
  },
  data () {
    return {
      permanentVacate: [
        { no: '12', desc: '전북현대의 첫번째 영구결번으로 지정된 12번은 <span class="pcBreak">서포터즈(매드 그린 보이즈)에게 헌정된 등번호입니다.</span>', ess: '매드 그린 보이즈(Mad Green Boys)는 1997년에 조직이 결성된 <span class="pcBreak">K리그 소속 전북현대 모터스의 서포터즈 연합입니다.</span>' },
        { no: '20', desc: '전북현대의 두번째 영구결번으로 지명된 20번은', ess: '전북현대의 레전드 이동국 선수에게<br>헌정된 등번호입니다.' }
      ],
      swiperOption: {
        loop: false,
        spaceBetween: 10,
        navigation: {
          nextEl: '.permanentSwiperNext',
          prevEl: '.permanentSwiperPrev'
        }
      }
    }
  },
  methods: {
  }
}
</script>
