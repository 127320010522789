import axios from "axios";
import tokenCtrl from '@/library/token';
import router from "@/router"
import store from "@/store"

const baseURL = "https://api.jbfc.kr"; // 라이브
// const baseURL = "https://api-jbhd.thesports.io" // 개발
const instance = axios.create({
    baseURL: baseURL,
    headers: { "content-type": "application/json" },
    timeout: 30000,
    withCredentials: true,
    crossDomain: true,
    credentials: "include"
});

instance.interceptors.request.use(
    async config => {

        // Access token expire check
        return await tokenCtrl.accessTokenChk(config);
    },
    error => {
        console.log('@@@@@ request error @@@@@');
        return Promise.reject(error);
    }
);


instance.interceptors.response.use(
    (res) => {
        return res
    },
    (error) => {
        if (error.response.status === 401) {
            if (error.response.data.message === 'Failed to authenticate token') { // 401 & 토큰 만료시 로그아웃 처리
                store.commit('logout');
                return
            }
            if (router.currentRoute.path.indexOf('/signin') !== -1) {
                alert(error.response.data.message)
                return
            }
            alert('로그인이 필요한 서비스입니다.')
            localStorage.clear()
            return router.push({name: 'signin'})
        }
        if (error.code === 'ERR_NETWORK') {
            return router.push({name: 'serverErrorPage'})
        }
        if (error.response && Number(error.response.status) >= 500) {
            return router.push({name: 'serverErrorPage'})
        }
        return Promise.reject(error);
    }
)


export default instance;
