<template>
  <div class="fanzonePage">
    <!-- 팬존FAQ와 동일한 내용에 탭은 티켓으로 설정 -->
    <Faq :cate="cate" v-if="state"/>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 30px;">
      <div class="container">
        <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
      </div>
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
import Faq from '@/components/Fanzone/Faq'

export default {
  name: 'TicketServiceFaq',
  props: {
    cate: {
      type: String,
      default: 'ticket'
    }
  },
  data () {
    return {
      state: false, // 티켓 임시페이지 사용시 false
    }
  },
  components: {
    Faq
  },
  created () {
    this.init()
  },
  destroyed () {
    this.$router.push({
      params: {
        cate: ''
      }
    })
  },
  watch: {
    $route (to, from) {
      this.init()
    }
  },
  methods: {
    init () {
      if (!this.$route.params.cate) {
        this.setCategory('ticket')
      }
    },
    setCategory (cate) {
      this.$router.push({
        params: {
          cate: cate || 'ticket'
        }
      })
    }
  }
}
</script>
