<template>
  <div :class="this.showPopup <= 1 ? 'popupContainerForOneData' : 'popupContainer'" v-if="popup.length > 0">
    <div class="layerPopup" v-for="(el, i) in popup" :key="i" :id="el.id" v-show="el.display">
      <h5>{{el.title}}</h5>
      <a :href="el.setUrl" :target="el.openWindow" v-if="el.isNewPage === true"><img :src="el.image" alt=""/></a>
      <router-link :to="el.path" v-else><img :src="el.image" alt=""/></router-link>
      <p>
        <a href="javascript:void(0)" @click='closeWin(true, el.id)'>[오늘 하루 열지않기]</a>
        <a class="off" href='javascript:void(0)' :value="el.id" @click='closeWin(false,el.id); return false;'>[닫기]</a></p>
    </div>
  </div>
</template>

<script>
import http from '@/api/index'
import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
dayjs.extend(isSameOrAfter)


export default {
  name: 'layerPopup',
  computed: {},
  created() {
    const today = dayjs()
    http.get('main/popup')
        .then(res => {
              const popup = res.data.data
              let showPopup = 0
              if(popup !== null) {
                popup.map(row => {
                  row.id = `divpop${row.idx}`
                  row.newpage_yn === "N" ? row.openWindow = '_self' : row.openWindow='_blank'
                  if (row.url === null || row.url === '') {
                    row.setUrl = `#`
                    row.isNewPage = false
                  } else {
                    row.setUrl = row.url
                    row.isNewPage = true
                  }
                  if(row.setUrl !== `javascript:${void(0)}`) {
                    const url = row.setUrl
                    row.path = url.substring(url.indexOf("/", url.indexOf("//") + 2))
                  }
                  if (document.cookie.indexOf(`${row.id}=done`) < 0) {
                    row.display = true
                    showPopup += 1
                  } else {
                    row.display = false
                  }
                  if(dayjs(row.endtime).isSameOrAfter(today) === true) {
                    this.popup.push(row)
                  }
                })
              } else {
                return this.popup
              }
              this.showPopup = showPopup
            }
        )
  },
  data() {
    return {
      popup: [],
      showPopup: 0
    }
  },
  methods: {
    closeWin: function (flag, layer) {
      if (flag) {
        this.setCookie( layer, 'done', 1 );
      }
      document.querySelector('#'+layer).style.display = "none";
      this.showPopup -= 1
    },
    setCookie(name, value, expiredays){
      const todayDate = new Date();
      todayDate.setDate(todayDate.getDate() + expiredays);
      document.cookie = name + "=" + escape(value) + "; path=/; expires=" + todayDate.toGMTString() + ";"
    }
  }
}
</script>

