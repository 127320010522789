/* eslint-disable no-unused-vars */
import * as modules from "./index";
import store from "../index"

const nav = {
  namespaced: true,
  state: {
    homeMatch: [],
    ticketNotice: [],
    banner: null,
    bannerTotal: Number,
    prevMatch: [],
    nextMatch: [],
    topGoal: [],
    topAssist: [],
    topWork: [],
    topLossless: [],
    board: [],
    notice: [],
    news: [],
    eventBanner: [],
  },
  mutations: {
  },
  getters: {
  }

}

export default nav;
