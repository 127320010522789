<template>
  <div class="memberBox wide myinfo">
    <div class="tabs" @click="preventNavigation">
      <router-link
        v-for="tab in tabs"
        :key="tab.id"
        :to="tab.link"
        :class="tab.id"
        class="tab"
        :event="setPassword ? 'click' : '' ">
        <span>{{tab.name}}</span>
      </router-link>
    </div>
    <div v-if="index" class="myinfoBox">
      <div class="topTitle">
        <p class="title">비밀번호 확인</p>
        <p class="subTitle">전북현대모터스에프씨 웹사이트 회원제와 관련하여 <span class="pcBreak">신청, 수정하기 위해서는 비밀번호 확인이 필요합니다.</span></p>
      </div>
      <div class="formGroup">
        <label class="label">비밀번호 확인</label>
        <input type="password" class="input" v-model="password" @keyup.enter="confirm"/>
      </div>
      <div class="formBtns">
        <button type="button" class="btn mobileFull large primary1" @click="confirm">확인하기</button>
        <button type="button" class="btn mobileFull large lightgrey4">재입력</button>
      </div>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import http from '@/api/index'
import router from "@/router"
import store from "@/store"
export default {
  name: 'Myinfo',
  computed: {
    index () {
      return this.$route.name === 'myinfo'
    }
  },
  mounted() {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')
  },
  created() {
    const token = JSON.parse(localStorage.getItem('token'))
    if (!token || !token.access) {
      const redirectUrl = router.currentRoute.path
      router.push({name: 'signin', query: {redirectUrl: redirectUrl}});
    }
  },
  data () {
    return {
      tabs: [
        { id: 'sns', name: 'SNS 연동', link: '/member/myinfo/sns' },
        { id: 'info', name: '정보수정', link: '/member/myinfo/info' },
        { id: 'withdraw', name: '탈퇴', link: '/member/myinfo/withdraw' }
      ],
      password: '',
      setPassword : false,

    }
  },
  methods: {
    confirm () {
      let data = { user_pw: this.password };
      http.post('/mypage/my_info', data).then(res => {
        if(res.status === 200) {
          this.setPassword = true
          this.$router.push('/member/myinfo/info')
        }

      }).catch(err => {
        console.log(err);
        alert('비밀번호가 일치하지 않습니다.');
      })

    },
    preventNavigation(e) {
      if (this.setPassword === false) {
        alert('비밀번호를 입력해주세요.')
        return false
      }
    }
  },
  beforeDestroy () {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
  }
}
</script>

<style lang="scss" scoped>
</style>
