<template>
  <div class="historyIntro hyundai">
    <div class="introSection section1" :style="styleHeight">
      <div class="team">
        <div class="videoFrame">
          <video autoplay playsinline muted loop preload>
            <source :src="this.video" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="sectionNav">
        <button type="button" class="arrowUp" @click="goSection('.section9')">우승 사진<br>보러가기</button>
        <button type="button" class="arrowDown" @click="goSection('.section2')">역사관<br>보러가기</button>
      </div>
    </div>
    <Year
      :active="activeSection"
      :style="styleHeight" />
    <Emblem
    :active="activeSection"
      :style="styleHeight" />
    <Coach
      :active="activeSection"
      :style="styleHeight" />
    <Captain
      :active="activeSection"
      :style="styleHeight" />
    <TeamAward
      :active="activeSection"
      :style="styleHeight"
      :height="styleHeight" />
    <ClubAward
      :active="activeSection"
      :style="styleHeight"
      :height="styleHeight" />
    <PermanentVacate
      :active="activeSection"
      :style="styleHeight" />
    <Cup
      :active="activeSection"
      :style="styleHeight" />
  </div>
</template>

<script>
import Year from './HistoryYear'
import Emblem from './HistoryEmblem'
import Coach from './HistoryCoach'
import Captain from './HistoryCaptain'
import TeamAward from './HistoryTeamAward'
import ClubAward from './HistoryClubAward'
import PermanentVacate from './HistoryPermanentVacate'
import Cup from './HistoryCup'
import imgPath from "@/library/imgPath"

export default {
  name: 'HistoryIntro',
  components: {
    Year,
    Emblem,
    Coach,
    Captain,
    TeamAward,
    ClubAward,
    PermanentVacate,
    Cup
  },
  computed: {
    styleHeight () {
      return {
        minHeight: this.sectionHeight
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    this.getWinHeight()
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onScroll()
    this.onResize()
    this.video = imgPath.setVideo('bg_history_intro')
  },
  data () {
    return {
      sectionHeight: '860px',
      activeSection: null,
      parallaxWidth: false,
      video: ''
    }
  },
  methods: {
    getWinHeight () {
      this.sectionHeight = `${window.innerHeight}px`
    },
    onScroll () {
      const sections = document.querySelectorAll('.introSection')
      const position = sections ? [...sections].map((x, i) => {
        return {
          section: i + 1,
          height: x.clientHeight,
          top: x.offsetTop,
          bottom: x.offsetTop + x.clientHeight - 1
        }
      }) : null
      const scrollY = window.scrollY
      const section = document.querySelector('.section1')
      function getRange (scroll, position) {
        const section = position ? position.find(x => {
          return scroll >= x.top && scroll <= x.bottom
        }) : null
        return section ? {
          index: section.section,
          scrollPercent: ((scroll - section.top) / section.height * 100).toFixed(2) // 현재 섹션에서 스크롤 위치가 몇%인지
        } : null
      }
      this.activeSection = getRange(scrollY, position)
      if (section && this.activeSection && this.activeSection.index === 1) {
        const video = section.querySelector('.team')
        const scrollDiff = scrollY
        if (this.parallaxWidth) {
          video.style.transform = `translateY(${scrollDiff}px)`
        } else {
          video.style.transform = ''
        }
      }
    },
    onResize () {
      // resize
      this.parallaxWidth = window.innerWidth >= 1600
    },
    goSection (target) {
      const section = document.querySelector(target)
      if (section) {
        const top = section.offsetTop
        window.scrollTo(0, top)
      }
    }
  }
}
</script>
