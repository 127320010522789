<template>
      <div id="app" :class="{isScroll: isScroll, noSubTop: noSubTop, hiddenGnb: hiddenGnb}" v-if="state">
        <Header ref="header" v-if="!isWebView"/>
        <router-view id="content" />
        <Footer v-if="!isWebView" />
        <VideoViewer v-if="isVideo || isPic" />

        <iframe id="iframe_connect" src="https://shop.hyundai-motorsfc.com/loginBridge.html" frameborder="0" width="1px" height="1px" style="position: absolute; top: -9999px; left: -999px;"></iframe>
        <iframe id="iframe_connect_green" src="https://greenschool.hyundai-motorsfc.com/loginBridge.html" frameborder="0" width="1px" height="1px" style="position: absolute; top: -9999px; left: -999px;"></iframe>

      </div>
      <div v-else>
        <AlertPage @changeState="changeState" :state="state"/>
      </div>
</template>

<script>
import dayjs from 'dayjs'
import Header from '@/views/Header'
import Footer from '@/views/Footer'
import VideoViewer from '@/components/Util/VideoViewer'
import http from "@/api";
import AlertPage from "@/components/Etc/Alert.vue"

export default {
  name: 'App',
  components: {
    AlertPage,
    Header,
    Footer,
    VideoViewer
  },
  computed: {
    isLogin () {
      return this.$store.getters['isLogin']
    },
    isVideo () {
      return this.$store.getters['isVideo']
    },
    isPic () {
      return this.$store.getters['isPic']
    },
    noSubTop () {
      return this.$route.meta && this.$route.meta.noSubTop
    },
    hiddenGnb () {
      return this.$route.meta && this.$route.meta.hiddenGnb
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },
    favorite () {
      return this.$store.getters['favorite']
    }
  },
  watch: {
    isLogin () {
      this.chkFavorite()
    },
    favorite () {
      this.chkFavorite()
    }
  },
  created () {
    const thisYear = dayjs().year()
    this.$store.commit('setThisYear', thisYear)
    this.chkFavorite()
    // localStorage에 저장된 token 있으면 로그인처리
    // 만료된 토큰 -> 로그아웃 처리 해야할듯
    this.$store.dispatch('tokenCheck')

    // 초록이네 로그인처리
    const loginState = this.isLogin
    const _store = this.$store

    window.onmessage = function(e) {
      if( !(e.origin === 'https://shop.hyundai-motorsfc.com' || e.origin === 'https://greenschool.hyundai-motorsfc.com') ) {
        return
      }

      const data = JSON.parse(e.data)
      const payload = JSON.parse(data.data);
      if (loginState || !payload || !payload.access || payload.expired) {
        return false
      } else {

        // 토큰 만료시간 체크
        const exp = dayjs.unix(payload.exp).format('YYYY-MM-DD HH:mm:ss');
        if (dayjs().isAfter(exp)) {
          return false
        }

        // 로그아웃상태, 토큰있음
        const args = { token: payload.access, agent: 'web' };
        http.post('/user/conversion_token', args)
          .then(res => {
            const payloadObj = {
              accessToken: {
                token: payload.access,
                exp: payload.exp,
                user_id: res.data.user_id,
                user_name: res.data.user_name
              },
              refreshToken: {
                token: payload.refresh,
                exp: payload.refreshExp
              },
              state: true
            }
            _store.commit('login', payloadObj);
          })
          .catch(err => {
            console.log('ERROR', err)
          })
      }
    };

  },
  mounted () {
    document.addEventListener('scroll', this.onScroll)

    window.onload = function() {
      document.getElementById( "iframe_connect" ).contentWindow.postMessage( "getAccessToken", "*" )
      document.getElementById( "iframe_connect_green" ).contentWindow.postMessage( "getAccessToken", "*" )
    }

    const userAgent = navigator.userAgent;
    if(userAgent.indexOf('JeonBukWebView') > -1) {
      this.isWebView = true;
    }
  },
  data () {
    return {
      isScroll: false,
      isWebView: false,
      state : true,
      test : false
    }
  },
  methods: {
    onScroll () {
      if (this.openAlertPage !== false ) {
        const header = this.$refs.header.$el.querySelector(window.innerWidth >= 768 ? '.header' : '.gnbBox')
        const height = header.clientHeight
        this.isScroll = header && window.scrollY >= height
      }
    },
    changeState () {
      this.state = true
    },
    chkFavorite () {
      if (this.isLogin){
        if (this.favorite === null) {
          this.state = this.isLogin === false;
        } else {
          this.state = true
        }
      }
    }
  }
}
</script>
