<template>
  <div class="memberBox">
    <div class="topTitle">
      <p class="title">아이디 찾기</p>
      <p class="subTitle" v-html="title"></p>
    </div>
    <div v-if="!result">
      <Cert
        :type="'findId'"
        @complete="setCertResult" />
    </div>
    <div v-else>
      <div class="result">
        <p class="name">{{result.name}}님의 가입하신 아이디는 아래와 같습니다.</p>
        <p class="id">아이디: {{result.id}}</p>
        <p class="date">가입일시: {{result.date}}</p>
      </div>
      <div class="formBtns formBtns2">
        <router-link to="/member/signin" class="btn large full primary1">로그인</router-link>
      </div>
      <div class="formRow">
        <router-link to="/member/findpw" class="btn large full primary2">비밀번호 찾기</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Cert from '@/components/Member/Cert'
import dayjs from "dayjs";
export default {
  name: 'MemberFindId',
  components: {
    Cert
  },
  data () {
    return {
      title: `전북현대모터스에프씨<br>웹사이트 아이디 찾기를 위해 본인인증을 하세요.`,
      result: null
    }
  },
  created() {
    const userAgent = navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('naver')> -1){
      alert('크롬, 사파리 등 기본 브라우저를 사용해주세요')
      return this.$router.go(-1)
    }
  },
  mounted() {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')
  },
  methods: {
    setCertResult (type, info) {
      if(!info.userId || info.userId === 'false'){
        alert('가입한 내역이 없습니다. 가입시 명의가 동일한지 확인하세요.');
        return;
      }

      this.title = `전북현대모터스에프씨<br> 웹사이트 아이디 찾기 결과입니다.`;
      this.result = {};
      this.result.name = info.name;
      this.result.id = info.userId
      this.result.date = dayjs(info.regdate).format('YYYY년 MM월 DD일 HH:mm:ss');
    }
  },
  beforeDestroy () {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
  }
}
</script>

<style lang="scss" scoped>
.result {
  padding: 44px 20px;
  text-align: center;
  font-size: 14px;
  color: $grey3;
  background-color: #f5f5f5;
  .name {
    margin-bottom: 28px;
    color: #000;
  }
  .id {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }
}
</style>
