<template>
  <div class="memberContent hyundai ethic">
    <div class="container">
      <div class="memberBox wide">
        <div class="topTitle">
          <div class="title">선수 인권침해 접수처</div>
          <div class="subTitle">전북현대모터스에프씨의 유소년 윤리지도와 관련하여 불편사항을 경험하셨을 경우 <span class="pcBreak">해당 사항에 대한 개선을 위하여 내용을 구체적으로 작성해주시기 바랍니다.</span></div>
        </div>
        <div class="formGroup">
          <label class="label">대상</label>
          <select class="input" v-model="data.target">
            <option value="">선택하세요</option>
            <option v-for="item in targetList" :value="item">{{ item }}</option>
          </select>
        </div>
        <div class="formGroup">
          <label class="label">이름</label>
          <input type="text" class="input" :value="user.name" disabled />
        </div>
        <div class="formGroup">
          <label class="label">아이디</label>
          <input type="text" class="input" :value="user.id" disabled />
        </div>
        <div class="formGroup">
          <label class="label">연락처</label>
          <input type="text" class="input" v-model="data.contact" @input="numberVali" placeholder="연락처를 입력하세요" />
        </div>
         <div class="formGroup">
          <label class="label">제목</label>
          <input type="text" class="input" v-model="data.title" maxlength="20" placeholder="제목을 입력하세요" />
        </div>
         <div class="formGroup">
          <label class="label">일시</label>
          <input type="text" class="input" v-model="data.date" maxlength="20" placeholder="일시를 입력하세요" />
        </div>
         <div class="formGroup">
          <label class="label">장소</label>
          <input type="text" class="input" v-model="data.place" maxlength="20" placeholder="장소를 입력하세요" />
        </div>
         <div class="formGroup">
          <label class="label">내용</label>
          <textarea class="input textarea height110" v-model="data.content" placeholder="내용을 입력하세요"></textarea>
        </div>
        <div class="formGroup">
          <label class="label">개인정보 수집 및 이용에 관한 동의사항</label>
          <div class="textarea height110">
전북현대모터스에프씨는 아래의 목적으로 개인정보를 수집 및 이용하며, 회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.<br><br>
1. 수집한 개인정보의 이용<br>
- 접수자 식별, 원활한 의사소통<br>
- 접수 내용 정보 전달 및 민원 처리<br><br>
2. 수집하는 개인정보 항목<br>
: 이름, 아이디, 연락처 및 선수 인권침해 내용 내 개인정보<br><br>
3. 개인정보의 보유기간 및 이용기간<br>
: 접수된 정보는 민원 종료 후 지체없이 파기됩니다.<br><br>
4. 동의 거부 권리 및 동의 거부 시 불이익 내용<br>
: 동의 거부 시 선수 인권침해 접수를 하실 수 없습니다.
          </div>
          <div class="checkAgree">
            <div class="checkbox radio">
              <input
                type="radio"
                name="agree_privacy"
                id="agree_privacy_y"
                v-model="agree"
                value="Y">
              <label for="agree_privacy_y">동의</label>
            </div>
            <div class="checkbox radio">
              <input
                type="radio"
                name="agree_privacy"
                id="agree_privacy_n"
                v-model="agree"
                value="N">
              <label for="agree_privacy_n">미동의</label>
            </div>
          </div>
        </div>
        <div class="formBtns">
          <button type="button" class="btn mobileFull large primary1" @click="submit">접수하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api/index'
import regExp from "@/common/regExp";
export default {
  name: 'EthicsView',
  computed: {
    user() {
      return this.$store.getters['user']
    },
    validate () {
      let reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;
      const info = Object.keys(this.data).map(x => {
        return x;
      })
      const emptyValue = info.filter(x => this.isEmpty(this.data[x])).map(x => x)
      info.shift();
      const invalidValue = info.filter(x => reg.test(this.data[x])).map(x => x)

      return {
        emptyValue: emptyValue.length === 0,
        invalidValue: invalidValue.length === 0
      }
    },
  },
  data() {
    return {
      targetList: ['U-18', 'U-15', 'U-12', 'U-10', 'U-8'],
      data: {
        target: '',
        userId: '',
        contact: '',
        title: '',
        date: '',
        place: '',
        content: '',
      },
      agree: 'N',
      loading: false
    };
  },
  created() {
  },
  mounted () {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')
  },
  methods: {
    isEmpty (str) {
      return regExp.replaceAllEmpty(`${str}`).length === 0
    },
    numberVali () {
      if(isNaN(parseFloat(this.data.contact))) {
        alert('숫자만 입력이 가능합니다');
      }
    },
    submit() {
      if (this.loading) return

      this.data.userId = this.user.id;
      if (!this.validate.emptyValue) {
        alert('누락 된 항목이 있습니다. 모든 내용을 입력하셔야만 접수가 가능합니다.')
        return
      }
      if (!this.validate.invalidValue) {
        alert('특수문자는 입력하실 수 없습니다.')
        return
      }
      if (this.agree === 'N') {
        alert('개인정보 수집 및 이용에 관한 동의사항에 동의해주세요.')
        return
      }

      this.loading = true
      this.data.userId = this.user.id;
      http.put('/inconvenience', this.data).then(res=>{
        alert('선수 인권침해 내용이 접수되었습니다.');
      }).catch(err=>{
        console.log(err);
      })
    }
  },
  beforeDestroy () {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
  },
}
</script>
