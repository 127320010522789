var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainSlider hyundai"},[(_vm.slider)?_c('vueper-slides',{attrs:{"autoplay":_vm.autoplay,"duration":5000,"draging-distance":90,"Prevent-y-scroll":true,"touchable":!_vm.autoplay,"infinite":true,"arrows":false,"pause-on-hover":false,"pause-on-touch":false},scopedSlots:_vm._u([{key:"bullets",fn:function({ bulletIndexes, goToSlide, previous, next, currentSlide }){return _vm._l((_vm.sliderBullets),function(bullets,i){return _c('button',{key:i,staticClass:"btnBullet",class:{ 'active': currentSlide === i },attrs:{"role":"tab","aria-label":`Slide ${i + 1}`},on:{"click":function($event){return goToSlide(i)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return previous()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;return next()}]}},[_c('span',{staticClass:"index"},[_vm._v(" "+_vm._s(`${('0' + (i + 1)).slice(-2)}`)+" ")]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"bar"})]),_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(bullets)+" ")])])})}}],null,false,1604143085)},_vm._l((_vm.slider),function(item,index){return _c('vueper-slide',{key:index,style:({
              background: 'rgba(0, 0, 0, 0.5) url(' + item.setImg + ')',
              height: '100%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundBlendMode: 'darken'
            }),scopedSlots:_vm._u([(item.target_game !== '')?{key:"content",fn:function(){return [_c('div',{staticClass:"sliderContent"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"game gmarket"},[_vm._v(" "+_vm._s(item.target_game.home_team_name)+" "),_c('span',{staticClass:"vs"},[_vm._v("VS")]),_vm._v(" "+_vm._s(item.target_game.away_team_name)+" ")]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.target_game.game_date)+" "+_vm._s(item.target_game.game_time))]),_c('Countdown',{attrs:{"end-date":item.target_game.game_date_iso,"label":true,"label-color":'#ffffff'}}),_c('a',{staticClass:"btn yellow large btnReservation",attrs:{"href":item.link,"target":"item.target"}},[_vm._v("예매하기")])],1)])]},proxy:true}:{key:"content",fn:function(){return [_c('a',{staticClass:"sliderContent",staticStyle:{"display":"block"},attrs:{"href":item.link,"target":item.target}},[_c('div',{staticClass:"inner"},[_c('div',[_c('span',{staticClass:"category"},[_vm._v(_vm._s(item.category))])]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),(item.link)?_c('div',{staticClass:"sliderBtn"},[_c('a',{staticClass:"btn green large btnEvent",attrs:{"href":item.link,"target":"_self"}},[_vm._v("기사보기")])]):_vm._e()])])]},proxy:true}],null,true)},[_vm._v(" > ")])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }