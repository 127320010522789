<template>
  <div>
    <div class="sectionTitle line">시즌권 안내</div>
    <div class="" v-if="!state">내용</div>
    <div class="indexBox waitingSeason" style="margin-top: 80px;" v-if="state">
      <div class="container">
        <img src="@/assets/img/img_ticket_season2024_1.jpg" alt="준비중입니다"/>
        <img src="@/assets/img/img_ticket_season2024_2.jpg" alt="준비중입니다"/>
        <img src="@/assets/img/img_ticket_season2024_3.jpg" alt="준비중입니다"/>
        <img src="@/assets/img/img_ticket_season2024_4.jpg" alt="준비중입니다"/>
        <img src="@/assets/img/img_ticket_season2024_5.jpg" alt="준비중입니다"/>
      </div>
    </div>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 30px;" v-else>
      <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
export default {
  name: 'TicketSeasonIntro',
  data () {
    return {
      state: true, // 티켓 임시페이지 사용시 false
    }
  },
}
</script>
