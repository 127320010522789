<template>
  <div class="subTop hyundai">
    <!-- 서브 상단 -->
    <div class="top" :data-dashboard="dashboard">
      <div class="container">
        <div class="breadCrumb">
          홈 <span class="arr">&#8250;</span>
          <router-link :to="depth1.link">{{depth1.name}}</router-link> <span class="arr">&#8250;</span>
          <router-link :to="depth2.link">{{depth2.name}}</router-link> <span v-if="depth2.children && depth3" class="arr">&#8250;</span>
          <router-link v-if="depth2.children && depth3" :to="depth3.link">{{depth3.name}}</router-link>
        </div>

        <div v-if="isLogin" class="topTitle">
          <p class="title"><span class="mobileBreak"><strong>{{ user.name }}</strong>님 </span>안녕하세요!</p>
          <!-- // 시즌권 숨김 처리
          <div v-if="!myInfo.seasonPass">
            <p class="subTitle"><i>ⓘ</i> {{year}}시즌 시즌권 구매전입니다.</p>
            <a href="#" class="btn transparentWhite">시즌권 구매하기</a>
          </div>
          -->
        </div>
        <div v-if="!isLogin" class="topTitle">
          <p class="title"><span class="mobileBreak">로그인 후 </span>사용하세요</p>
          <p class="subTitle"><i>ⓘ</i> 로그인 후 사용하세요</p>
          <router-link to="/member/signin" class="btn transparentWhite">로그인</router-link>
        </div>
        <TopDashboard
          v-if="dashboard"
          :type="dashboard"
          :dashboardData="dashboardData"
        />
      </div>
    </div>
    <!-- //서브 상단 -->
    <!-- 서브 탭메뉴 -->
    <div class="tabMenu">
      <div class="container">
        <router-link
          v-for="menu in tab"
          :key="menu.id"
          :to="menu.link"
          :class="{current: menu.id === depth2.id}">
          <span>{{menu.name}}</span>
        </router-link>
      </div>
    </div>
    <!-- //서브 탭메뉴 -->
    <!-- 상세 탭버튼 -->
    <div v-if="depth2.children && depth3" class="tabBtns mobileDropdown" :class="{open: tabOpen}">
      <div class="container">
        <div class="dropdownBox">
          <button type="button" class="btn btnDropdown" @click="tabOpen = !tabOpen">{{depth3.name}}</button>
          <div class="dropdownGroup">
            <router-link
              v-for="tab in depth2.children"
              :key="tab.id"
              :to="tab.link"
              :class="{white: tab.id !== depth3.id}"
              class="btn">
              {{tab.name}}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- //상세 탭버튼 -->
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import TopDashboard from './TopDashboard'
import dayjs from 'dayjs'

export default {
  name: 'MypageTop',
  props: [
    'isLogin',
    'myInfo',
    'dashboardData'
  ],
  components: {
    TopDashboard
  },
  computed: {
    user () {
      return this.$store.getters['user']
    },
    depth1 () {
      return {
        id: this.page.id,
        name: this.page.name,
        link: this.page.link
      }
    },
    depth2 () {
      const mypage = this.$route.meta.mypage
      const current = this.tab.find(x => x.id === mypage)
      return {
        id: current ? current.id : this.depth1.id,
        name: current ? current.name : this.depth1.name,
        link: current ? current.link : this.depth1.link,
        children: current?.children
      }
    },
    depth3 () {
      const detail = this.$route.meta.detail
      const depth2 = this.depth2
      const current = depth2 && depth2.children ? depth2.children.find(x => x.id === detail) : null
      return current ? {
        id: current.id,
        name: current.name,
        link: current.link
      } : null
    }
  },
  watch: {
    $route (to, from) {
      this.tabOpen = false
      this.setDashboard()
    }
  },
  created () {
    this.tabOpen = false
    this.setDashboard()
    document.addEventListener('click', this.onClick)
  },
  data () {
    return {
      bg: '',
      page: {
        id: 'mypage',
        name: '마이페이지',
        link: '/mypage'
      },
      tab: [
        { id: 'mypage', name : '마이페이지', link: '/mypage/mypage' },
        { id: 'pay', name : '구매내역', link: '/mypage/pay/ticket/1',
          children: [
            { id: 'ticket', name : '티켓 예매내역', link: '/mypage/pay/ticket/8' },
            // { id: 'season', name : '시즌권 구매내역', link: '/mypage/pay/season/1' },
            { id: 'entrySeason', name : '시즌권 입장내역', link: '/mypage/pay/entrySeason/1' },
            { id: 'goods', name : '상품 구매내역', link: '/mypage/pay/goods/1' }
          ]
        },
        { id: 'activity', name : '활동내역', link: '/mypage/activity/qna/1',
          children: [
            { id: 'qna', name : '1:1문의하기', link: '/mypage/activity/qna/1' },
            { id: 'event', name : '이벤트 참여내역', link: '/mypage/activity/event/1' },
            { id: 'survey', name : '설문조사 내역', link: '/mypage/activity/survey/1' }
          ]
        },
        { id: 'point', name : '혜택/포인트', link: '/mypage/point/history/1',
          children: [
            { id: 'history', name : '포인트 내역', link: '/mypage/point/history/1' },
            { id: 'coupon', name : '쿠폰함 내역', link: '/mypage/point/coupon/1' },
            { id: 'reservation', name : '예매권 내역', link: '/mypage/point/reservation/1' }
          ]
        },
        { id: 'myinfo', name : '나의정보 수정', link: '/member/myinfo' },
      ],
      tabOpen: false,
      dashboard: null,
      seasonPass: true,
      year: dayjs().format('YYYY'),
    }
  },
  methods: {
    setDashboard () {
      const mypage = this.$route.meta && this.$route.meta.mypage
      const type = /pay|activity|point/
      this.dashboard = mypage && type.test(mypage) ? mypage : null
    },
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    onClick (e) {
      const target = e.target
      if (!target.closest('.dropdownBox')) {
        this.tabOpen = false
      }
    }
  }
}
</script>
