<template>
  <div class="matchSection">
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatchU18'
}
</script>
