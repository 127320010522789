<template>
  <div>
  </div>
</template>

<script>

export default {
  name: 'MatchK4Ranking',
  created() {
    let router = this.$router
    this.onClickRedirect()
    router.push({name: 'matchRankingKleague'})
  },
  methods: {
    onClickRedirect: function () {
      window.open("https://www.kfa.or.kr/competition/k3_k4.php", "_blank");
    }
  }
}
</script>
