<template>
  <div class="ticketPolicy">
    <table>
      <thead>
        <tr>
          <th colspan="2">구분</th>
          <th>성인</th>
          <th>청소년(중고)</th>
          <th>어린이(초)</th>
          <th>미취학아동</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowspan="2" class="th">센슈어스존</td>
          <td class="th">BIG</td>
          <td>{{comma(45000)}}</td>
          <td>{{comma(45000)}}</td>
          <td>{{comma(35000)}}</td>
          <td>{{comma(35000)}}</td>
        </tr>
        <tr>
          <td class="th">일반</td>
          <td>{{comma(42000)}}</td>
          <td>{{comma(42000)}}</td>
          <td>{{comma(33000)}}</td>
          <td>{{comma(33000)}}</td>
        </tr>
        <tr>
          <td rowspan="2" class="th">W벤치존 H1<br>(1D)</td>
          <td class="th">BIG</td>
          <td>{{comma(33000)}}</td>
          <td>{{comma(33000)}}</td>
          <td>{{comma(23000)}}</td>
          <td>{{comma(23000)}}</td>
        </tr>
        <tr>
          <td class="th">일반</td>
          <td>{{comma(30000)}}</td>
          <td>{{comma(30000)}}</td>
          <td>{{comma(20000)}}</td>
          <td>{{comma(20000)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import regExp from '@/common/regExp'

export default {
  name: 'TicketDiscountPolicy',
  methods: {
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    }
  }
}
</script>
