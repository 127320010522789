/* eslint-disable no-unused-vars */
import * as modules from "./index";
import store from "../index"

const media = {
    namespaced: true,
    state: {
        type: '',
        search: '',
        page: '',
        scrollY: 0,
        year: '',
        sort: 'latest'
    },
    mutations: {
    },
    getters: {
    }

}

export default media;
