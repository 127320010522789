<template>
  <div>
    <div class="sectionTitle line">안내/혜택</div>
    <div class="" v-if="!state">내용</div>
    <div class="indexBox waitingSeason" style="margin-top: 80px;" v-if="state">
      <div class="container">
        <!-- 단체 안내 이미지 S3 루트-->
        <img src="https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/ticket/teamGuide/groupGuideInfo.jpeg" alt=""/>
        <!-- // 단체 안내 이미지 S3 루트-->
      </div>
    </div>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 30px;" v-else>
      <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>
<script>
export default {
  name: 'GroupGuideInfo',
  created () {

  },
  data () {
    return {
      state: true,
    }
  },
  methods : {
  },
}

</script>
