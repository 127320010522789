import { render, staticRenderFns } from "./EventView.vue?vue&type=template&id=c12c38f0"
import script from "./EventView.vue?vue&type=script&lang=js"
export * from "./EventView.vue?vue&type=script&lang=js"
import style0 from "./EventView.vue?vue&type=style&index=0&id=c12c38f0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports