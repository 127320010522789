j<template>
  <div>
    <div class="btnDetail">
      <button
          v-for="(tab, i) in tabs"
          :key="i"
          :class="{lightgrey3: tab.id !== active}"
          :data-tab="tab.id"
          type="button"
          class="btn round"
          @click="setActive(tab.id)">
        <span class="hiddenMobile">{{ tab.name }}</span>
        <span class="displayMobile">{{ tab.name2 || tab.name }}</span><!-- 모바일용 이름 -->
      </button>
    </div>
    <!-- 리스트 정보 -->
    <div class="listInfo">
      <div class="sort">
        <span class="infoItem">
          <select v-model="currentMonth" class="select" @change="setMonthData">
            <option value="">월선택</option>
            <option value="all">전체</option>
            <option
                v-for="(m, i) in 12"
                :key="i"
                :value="m">
              {{ m }}월
            </option>
          </select>
        </span>
        <span class="infoItem">
          <select v-model="currentYear" class="select" @change="init">
            <option value="">년도선택</option>
            <option
                v-for="(y, i) in year"
                :key="i"
                :value="y.id">
              {{ y.name }}
            </option>
          </select>
        </span>
      </div>
    </div>
    <!-- //리스트 정보 -->
    <!-- 리스트 -->
    <div class="matchList">
      <!-- 매치 -->
      <div class="item" v-for="(data, i) in setFilter === false ? matchData : filterData" :key="i">
        <div class="info" :class="data.home_team === 'K29' || data.away_team === 'K29' ? 'mr44' : 'wd15'">
          <div class="league" v-if="data.game_league_logo === 'etc'">{{data.meet_name}}</div>
          <div class="league" :data-league=data.game_league_logo></div>
          <div class="time">{{ data.game_time }}</div>
          <div class="date">{{ data.game_date }} ({{ data.yoil }})</div>
          <div class="stadium">{{data.full_name}} ({{ data.ground }})</div>
        </div>
        <div class="game emblem">
          <div class="team left">
            <img :src="data.homeLogoImg" alt="">
            <span class="name">{{ data.home_team_name }}</span>
          </div>
          <div class="center">
            vs
            <div class="round" v-if="data.league_round"><span>{{ data.league_round }}R</span></div>
          </div>
          <div class="team right">
            <img :src="data.awayLogoImg" class="emblem"
                 alt="">
            <span class="name">{{ data.away_team_name }}</span>
          </div>
        </div>
        <div class="btns mobileFlex">
          <div class="row">
            <a href="#" class="btn" v-if="data.ground === 'H'">티켓예매</a>
          </div>
          <div class="row">
            <a href="#" class="btn primary2" v-if="data.ground === 'H'">프리뷰</a>
          </div>
        </div>
      </div>
      <!-- //매치 -->
    </div>
    <div class="noData" v-if="matchData === undefined || matchData.length === 0">다음 경기 일정이 없습니다</div>
    <div class="noData" v-else-if="setFilter === true && filterData.length === 0">다음 경기 일정이 없습니다</div>
    <!-- //리스트 -->
  </div>
</template>

<script>
import http from '@/api/index'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import imgPath from "@/library/imgPath"
dayjs.extend(isSameOrAfter)

export default {
  name: 'MatchScheduleList',
  created() {
    const paramsYear = this.$store.state.params && this.$store.state.params.year
    const thisYear = this.$store.state.thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    this.currentYear = paramsYear || thisYear
    // this.currentMonth = dayjs().format('M')
    this.currentMonth = 'all' // 전체 디폴트 요청
    this.init()
  },
  data() {
    return {
      active: '',
      tabs: [
        {id: '', name: '전체대회'},
        {id: 'kleague', name: 'K LEAGUE', name2: 'K 리그'},
        {id: 'KOREAN CUP', name: 'KOREAN CUP'},
        {id: 'championsleague', name: 'ACL', name2: 'AFC CL'}
      ],
      currentYear: '',
      currentMonth: '',
      yearStart: 2023, // 필터 시작 년도
      year: [],
      fixData: [],
      matchData: [],
      filterData: [],
      setFilter: false,
      today : dayjs(),
    }
  },
  methods: {
    async init() {
      const params = {
        params: {
          year: this.currentYear,
        }
      }
      const match = await http.get('/match/schedule', params)
      for (let i = 1; i <= 12; i++) {
        if (match.data.data[`${this.currentYear}-0${i}`]) {
          match.data.data[`${this.currentYear}-0${i}`].map(row => {
            this.setData(row)
          })
        }
        if (match.data.data[`${this.currentYear}-${i}`]) {
          match.data.data[`${this.currentYear}-${i}`].map(row => {
            this.setData(row)
          })
        }
      }
      // this.currentMonth = Number(Object.keys(match.data.data)[0].split('-')[1])
      this.fixData = JSON.parse(JSON.stringify(match.data.data));
      // console.log(this.fixData)

      this.setMonthData();

    },
    setMonthData () {
      this.active = '';
      this.setFilter = false;
      const month = this.currentMonth;
      const arr = []
      const _arr =[]
      if (month === '') {
        return this.matchData
      }
      if (month === 'all') {
        for (let i = 1; i < 13; i++) {
          if(i < 10) {
              this.matchData = this.fixData[`${this.currentYear}-0${i}`]
              if (this.matchData !== undefined) {
                arr.push(this.matchData)
              }
          } else {
            this.matchData = this.fixData[`${this.currentYear}-${i}`]
            if (this.matchData !== undefined) {
              arr.push(this.matchData)
            }
          }
        }
        this.matchData = arr.flat(Infinity)
        this.matchData?.map((row) =>{
          if(!row.audience){
            _arr.push(row)
          }
        })
        this.matchData = _arr
        return this.matchData
      } else if (month === 10 || month === 11 || month === 12) {
        this.matchData = this.fixData[`${this.currentYear}-${month}`]
      } else {
        this.matchData = this.fixData[`${this.currentYear}-0${month}`]
      }
      this.matchData?.map((row) =>{
        if(!row.audience){
          arr.push(row)
        }
      })
      this.matchData = arr
    },
    setActive(e) {
      this.active = e
      let arr = []
      switch (e) {
        case 'kleague' :
          this.setFilter = true
          arr = this.matchData.filter(el => el.game_league === 'kleague')
          this.filterData = arr
          return this.filterData
        case 'KOREAN CUP' :
          this.setFilter = true
          arr = this.matchData.filter(el => el.game_league === 'KOREAN CUP')
          this.filterData = arr
          return this.filterData
        case 'championsleague' :
          this.setFilter = true
          arr = this.matchData.filter(el => el.game_league === 'afc')
          this.filterData = arr
          return this.filterData
        case '' :
          this.setFilter = false
      }
    },
    setData(el) {
      el.full_name = el.full_name ? el.full_name.replace(/\s/g, "") : ''
      el.game_time = el.game_time.substring(0, 5)
      el.origin_game_date = el.game_date;
      el.set_origin_game_date = dayjs(`${el.game_date} ${el.game_time}`)
      el.game_date = dayjs(el.game_date).format("YYYY.MM.DD")
      el.home_team_name === '전북' ? el.ground = 'HOME' : el.ground = 'AWAY'
      if(el.away_team_name === '전북'){
        el.away_team_name = el.home_team_name
        el.away_team = el.home_team
        el.home_team_name = '전북'
        el.home_team = 'K05'
      }
      el.homeLogoImg = imgPath.setTeamLogo(el.home_team)
      el.awayLogoImg = imgPath.setTeamLogo(el.away_team)
      switch (el.meet_title) {
        case 'K League 1':
          el.game_league_logo = 'kleague'
          el.game_league = 'kleague'
          el.isKleage = true
          break
        case 'ACL':
          el.game_league_logo = 'afc'
          el.game_league = 'afc'
          break
        case 'KOREAN CUP':
          el.game_league_logo = 'KOREAN CUP'
          el.game_league = 'KOREAN CUP'
          break
        case 'ETC':
          el.game_league_logo = 'etc'
          el.game_league = 'etc'
          break
        case 'ACL2':
          el.game_league_logo = 'acl2'
          el.game_league = 'afc'
          break
      }
      return el
    }
  }
}
</script>
