import Index from '@/views/History.vue'
import Intro from '@/components/History/Intro.vue'

const history = [
  {
    path: '/history',
    name: 'history',
    component: Index,
    meta: {
      detectRollover: true, // GNB 롤오버 표시하지 않고 0번째 children으로 링크
      hiddenGnb: true // 스크롤시 gnb 숨김 (scrollTop > 0)
    }
  },
  {
    path: '/history/intro',
    name: 'historyIntro',
    component: Intro,
    meta: {
      detectRollover: true, // GNB 롤오버 표시하지 않고 0번째 children으로 링크
      hiddenGnb: true // 스크롤시 gnb 숨김 (scrollTop > 0)
    }
  }
]

export default history
