<template>
  <div class="cheersong">
    <div class="container">
      <div class="tiles mediaList">
        <!-- 리스트 정보 -->
        <div class="tileInfo">
          <p class="total">총 <strong>{{ total }}개</strong>의 응원가가 있습니다</p>
          <div class="sort">
            <div
                v-for="item in sort"
                :key="item.id"
                class="_item"
                :class="{active: item.id === currentSort}"
                @click="setSort(item.id)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- //리스트 정보 -->
        <!-- 미디어 타일 리스트 -->
        <div class="tileList">
           <!-- tile item -->
           <div class="tile" v-for="(data, i) in onSort ? sortData : songData" :key="i">
            <router-link :to="{name: 'fanzoneCheersongView', params: {id: data.seq}}">
              <div><img :src="data.img" alt="" /></div>
              <div class="article">
                <div class="subject">{{data.Title}}</div>
                <div class="info">
                  <span class="date">{{data.RegiDate}}</span>
                  <span class="view">{{comma(Number(data.Hit))}}</span>
                </div>
              </div>
            </router-link>
             <a class="downloadBtns" :href="data.AttachFileNM" :download="data.Title" v-if="data.AttachFileNM">
               <button type="button" class="btn auto small lightgrey2">응원가 다운로드</button>
             </a>
          </div>
          <!-- //tile item -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from '@/api/index'
import dayjs from "dayjs"

export default {
  name: 'FanzoneCheersong',
  created() {
    this.init()
  },
  data () {
    return {
      currentSort: 'latest',
      sort: [
        {id: 'latest', name: '최신순'},
        {id: 'views', name: '조회순'}
      ],
      total: 0,
      songData :[],
      sortData : [],
      onSort : false
    }
  },
  methods: {
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    setSort(sort) {
      this.currentSort = sort
      if(sort==='views'){
        this.onSort = true
      } else {
        this.onSort = false
      }
    },
    async init(){
      const songData = await http.get('/fanzone/cheer_song')
      songData.data.data.list.map(row => {
        row.RegiDate = dayjs(row.RegiDate).format('YYYY.MM.DD')
        if(!row.TopImage) {
          row.img = '@/assets/img/bg_media_404.png';
        } else {
          row.img = 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com'+row.TopImage; // 수정필요
        }
        if (row.AttachFileNM.split('/028/')[1] === '') {
          row.AttachFileNM = '';
        }
      })
      this.total = songData.data.data.total
      this.songData = songData.data.data.list
      // 조회순
      const sortData = [...songData.data.data.list]
      sortData.sort((a,b) =>{
        return  b.Hit - a.Hit
      })
      this.sortData = sortData
    },
  }
}
</script>
<style>
._item {
  position: relative;
  cursor: pointer;
}
._item + ._item::before {
  content: "|";
  padding: 0 20px;
  color: #E5E5E5;
 }


</style>
