<template>
  <div>
    <div class="policyMessage">YouTube 서비스 약관</div>
    <div v-if="anchors" class="policyAnchor">
      <div v-for="(a, i) in anchors"
        :key="i"
        class="anchor">
        <a href="javascript:void(0)" @click="goAnchor(a.anchor)">
          <i>{{a.id}}</i>
          {{a.name}}
        </a>
      </div>
    </div>
    <div ref="youtube">
      <p class="policyIndex" data-index="1">서비스 약관</p>
      <div class="policyContent">
        <p class="strong">소개</p>
        YouTube 플랫폼과 플랫폼에서 제공되는 제품, 서비스, 기능(이하 통칭하여 '서비스')을 사용해 주셔서 감사합니다.<br><br>

        <p class="strong">YouTube 서비스</p>
        YouTube 서비스는 사용자가 동영상 및 기타 콘텐츠를 찾아보고, 감상하고, 공유하며, 전 세계 사람들과 소통하고, 정보를 나누며, 아이디어를 주고 받을 수 있는 공간을 제공함은 물론, 규모에 관계없이 모든 독창적인 콘텐츠 크리에이터와 광고주에게 배포 플랫폼의 역할을 합니다. YouTube 고객센터에서 다양한 제품 정보와 사용 방법을 참고할 수 있습니다. 특히 YouTube Kids, YouTube 파트너 프로그램, YouTube 유료 멤버십 및 구매 관련 정보가 제공됩니다. TV, 게임 콘솔 또는 Google Home 등의 다른 기기에서 콘텐츠를 즐기는 방법도 확인할 수 있습니다.<br><br>

        <p class="strong">서비스 제공자</p>
        서비스를 제공하는 법인은 델라웨어 법률에 따라 운영되며, 1600 Amphitheatre Parkway, Mountain View, CA 94043에 소재한 Google LLC입니다(이하 'YouTube' 또는 '당사'). 본 약관에서 YouTube의 '계열사'는 Alphabet Inc. 기업 그룹에 (현재 또는 추후) 소속된 다른 회사를 지칭합니다.<br><br>

        <p class="strong">적용 약관</p>
        귀하는 서비스 사용 시에 수시로 업데이트될 수 있는 본 약관, YouTube 커뮤니티 가이드, 정책, 안전 및 저작권 정책('계약'으로 총칭)을 준수해야 합니다. 본 서비스에 광고 또는 스폰서십을 제공하거나, 콘텐츠에 유료 프로모션을 포함하는 경우 YouTube 광고 정책도 당사와의 계약에 포함됩니다. 본 약관에 제공된 기타 링크 또는 참조는 정보 참고 목적으로만 제공되며, 계약의 일부가 아닙니다.<br><br>

        본 계약을 주의해서 읽고 숙지하시기 바랍니다. 본 계약에 이해할 수 없거나, 동의하지 않는 내용이 있다면 서비스를 사용하지 마시기 바랍니다.
      </div>
      <p class="policyIndex" data-index="2">서비스 이용 대상</p>
      <div class="policyContent">
        <p class="strong">연령 요건</p>
        서비스를 사용하려면 만 14세 이상이어야 합니다. 하지만 부모나 법정대리인이 사용 설정하면 모든 연령대의 아동이 서비스 또는 YouTube Kids를 사용할 수 있습니다(제공 국가에만 해당).<br><br>

        <p class="strong">부모 또는 법정대리인의 허락</p>

        귀하가 거주 국가에서 법률상 미성년자에 해당하는 경우, 귀하는 서비스를 사용할 수 있도록 부모 또는 법정대리인의 허락을 받았음을 진술합니다. 부모 또는 법정대리인과 함께 본 계약을 숙지하시기 바랍니다.<br>
        거주 국가에서 법률상 미성년자로 간주되는 사용자의 부모 또는 법정대리인이 사용자의 서비스 사용을 허락한 경우, 부모 또는 법정대리인은 본 계약 조건의 적용을 받게되며, 미성년 사용자의 서비스 이용 활동에 대한 책임을 집니다. 가족의 YouTube 사용을 관리하는 데 도움이 되는 도구와 자료(만 14세 미만의 아동에게 서비스 및 YouTube Kids를 사용 설정하는 방법 포함)가 YouTube 고객센터와 Google의 Family Link에서 제공됩니다.<br><br>

        <p class="strong">사업자</p>
        회사 또는 기관을 위해 서비스를 사용하는 경우, 귀하는 해당 단체를 대신하여 행위할 권한이 귀하에게 있고, 해당 단체가 본 계약에 동의함을 진술합니다.
      </div>
      <p class="policyIndex" data-index="3">서비스의 이용</p>
      <div class="policyContent">
        <p class="strong">서비스 내 콘텐츠</p>
        서비스 내 콘텐츠에는 귀하, YouTube 또는 제3자가 제공하는 동영상, 오디오(예를 들어, 음악 및 기타 음향), 그래픽, 사진, 텍스트(댓글 및 스크립트), 브랜딩(상표 이름, 상표권, 서비스 표시 또는 로고 등), 인터랙티브 기능, 소프트웨어, 측정항목, 기타 자료가 포함됩니다. (이하 통칭하여 ‘콘텐츠’)<br>
        법률에 따라 YouTube가 책임을 지는 경우를 제외하고, 콘텐츠에 대한 책임은 서비스에 콘텐츠를 제공하는 사용자 또는 단체에게 있습니다. YouTube는 콘텐츠를 호스팅하거나 제공할 의무가 없습니다. 커뮤니티 가이드 또는 법률을 위반하는 등 본 약관을 준수하지 않는 콘텐츠를 발견하면 YouTube에 신고할 수 있습니다.<br><br>

        <p class="strong">Google 계정 및 YouTube 채널</p>
        Google 계정 없이도 콘텐츠 탐색 및 검색 등 일부 서비스를 사용할 수 있습니다. 하지만 Google 계정이 있어야 사용 가능한 기능도 있습니다. Google 계정이 있으면 동영상에 좋아요 표시, 채널 구독, YouTube 채널 만들기 등이 가능합니다. Google 계정 만들기 안내를 따르세요.<br>
        YouTube 채널을 만들면 동영상 업로드, 댓글 작성, 재생목록 만들기 등의 추가 기능을 이용할 수 있습니다. YouTube 채널을 만드는 방법에 대한 자세한 내용을 참조하세요.<br>
        Google 계정을 보호하려면 비밀번호를 공개하지 말아야 합니다. 타사 애플리케이션에서 Google 계정 비밀번호를 재사용하면 안 됩니다. 비밀번호 또는 Google 계정의 무단 사용 사실을 알게 된 경우의 조치 등 Google 계정 보안 유지에 대해 자세히 알아보세요.<br><br>

        <p class="strong">귀하의 정보</p>
        당사의 개인정보처리방침은 서비스를 사용할 때 개인정보가 어떻게 처리되고 보호되는지 설명합니다. YouTube Kids 개인정보처리방침은 YouTube Kids에만 해당하는 개인정보 보호관행에 대한 추가 정보를 제공합니다.<br>
        개인적 용도 또는 가족 활동 과정에서 업로드한 오디오 또는 시청각 콘텐츠인 경우를 제외하고, 귀하가 서비스에 업로드한 오디오 또는 시청각 콘텐츠는 YouTube 정보처리규정에 따라 처리됩니다. 자세히 알아보기<br><br>

        <p class="strong">권한 및 제한사항</p>
        귀하는 본 계약 및 관련 법령을 준수하는 한 귀하에게 제공된 서비스에 액세스하여 이를 사용할 수 있습니다. 귀하는 개인적, 비영리적 용도로 콘텐츠를 보고 들을 수 있습니다. 귀하는 내장가능한 YouTube 플레이어를 통해 YouTube 동영상을 표시할 수도 있습니다.<br><br>
        서비스 사용 시 다음 제한사항이 적용됩니다.<br><br>
        1. (a) 서비스의 명시적 승인, 또는 (b) YouTube 및 해당하는 경우 각 권리 소유자의 사전 서면 승인을 받은 경우를 제외하고는, 서비스나 콘텐츠의 어떤 부분에 대해서도 액세스, 복제, 다운로드, 배포, 전송, 방송, 표시, 판매, 라이선스 부여, 변경, 수정 또는 그 밖의 사용을 금지합니다.<br>
        2. (a) 콘텐츠의 복사 또는 기타 사용을 방지 또는 제한하거나, (b) 서비스 또는 콘텐츠의 사용을 제한하는 기능 또는 보안 관련 기능을 포함하여 서비스의 어떤 부분에 대해서도 우회, 무력화, 기망적인 관여 또는 그 밖의 방해 행위(또는 이러한 행위들에 대한 시도)를 금지합니다.<br>
        3. (a) YouTube의 robots.txt 파일에 따른 공개 검색엔진의 경우, 또는 (b) YouTube의 사전 서면 승인을 받은 경우를 제외하고, 자동화된 수단(로봇, 봇넷 또는 스크래퍼 등)을 사용해 서비스에 액세스하면 안 됩니다.<br>
        4. 당사자의 허가를 받거나, 위의 3항에서 허용하지 않는 한, 개인 식별이 가능한 정보(예: 사용자 이름이나 얼굴 모습)를 수집해서는 안 됩니다.<br>
        5. 서비스를 사용해 원치 않는 홍보성/상업성 콘텐츠나 그 밖의 원치 않는 대량의 구매 권유 자료를 배포하면 안 됩니다.<br>
        6. 동영상 조회수, 좋아요 또는 싫어요 수를 늘리거나, 채널 구독자 수를 늘리기 위해 사람들에게 비용을 지불하거나, 인센티브를 제공하는 등 서비스에 대한 실제 사용자 참여도의 부정확한 측정을 야기 또는 조장하거나, 달리 어떠한 방법으로든 측정항목을 조작하면 안 됩니다.<br>
        7. 근거가 없거나, 권한을 남용하거나 또는 사소한 제출 등으로 보고, 신고, 불만 제기, 이의 제기 또는 항소 절차를 남용하면 안 됩니다.<br>
        8. 본 서비스에서 또는 본 서비스를 통해 YouTube 컨테스트 정책 및 가이드라인을 준수하지 않는 컨테스트를 시행하면 안 됩니다.<br>
        9. 개인적, 비영리적 용도 외의 목적으로 콘텐츠를 보거나 듣기 위해 서비스를 사용하면 안 됩니다(예를 들어, 공개적으로 서비스의 동영상을 상영하거나, 음악을 스트리밍하면 안 됩니다).<br>
        10. (a) YouTube 광고 정책에서 허용하는 경우(정책을 준수하는 간접 광고 등)를 제외하고, 서비스나 콘텐츠에 또는 그 주변이나 내부에 배치된 광고, 후원, 홍보물을 판매하거나, (b) 오로지 서비스의 콘텐츠만 포함되거나, 광고, 후원, 홍보물 판매의 주된 이유가 서비스의 콘텐츠인 웹사이트 페이지나 애플리케이션에서 광고, 후원, 홍보물을 판매(예를 들어, 사용자들이 방문하는 주요 목적이 YouTube 동영상인 웹페이지의 광고 판매)하기 위하여 서비스를 사용하면 안 됩니다.<br><br>

        <p class="strong">유보</p>
        서비스를 사용한다고 해서 본 서비스의 어떠한 요소(예를 들어, 사용자 이름이나 다른 사용자 또는 YouTube가 게시한 다른 콘텐츠 등)에 대해서 소유권이나 권리가 부여되는 것은 아닙니다.<br><br>

        <p class="strong">서비스 개발, 개선 업데이트</p>
        YouTube는 지속적으로 본 서비스를 변경 및 개선하고 있습니다. 지속적인 발전의 일환으로 기능이나 구성을 추가하거나 삭제하고, 새로운 디지털 콘텐츠 또는 서비스를 제공하거나 기존 서비스의 제공을 중단하는 등 본 서비스 전체 또는 일부를 개선하거나 변경할 수 있습니다. 당사는 성능이나 보안을 개선하거나, 법령을 준수하기 위한 변경을 하거나, YouTube 시스템 상의 불법적인 활동이나 시스템을 악용하는 행위를 방지하기 위해 본 서비스나 그 일부를 변경 또는 중단할 수 있습니다. 이러한 변경사항은 사용자 전체, 일부 또는 특정 개인에게 영향을 미칠 수 있습니다. 본 서비스에 다운로드 가능한 소프트웨어(YouTube 스튜디오 애플리케이션 등)가 필요하거나 포함되는 경우, 소프트웨어에 대한 새로운 버전이나 기능이 제공되면 기기 설정에 따라 소프트웨어가 기기에서 자동으로 업데이트될 수 있습니다. 귀하가 본 서비스를 이용하는 데 부정적인 영향을 미치는 중대한 변경사항이 있을 경우, YouTube는 귀하에게 합당한 사전 통지를 전달합니다. 다만, 합리적으로 통지가 불가능한 경우(예: 사용자가 로그인 없이 본 서비스를 이용하는 경우)이거나 긴급한 상황(예: 악용사례를 방지하거나, 법적 요건에 대응하거나, 보안 및 운용가능성 관련 문제를 해결)에서는 예외로 합니다. 또한 YouTube는 Google 테이크아웃을 사용해 Google 계정에서 귀하의 콘텐츠를 내보낼 기회를 제공하며, 여기에는 관련 법규 및 정책이 적용됩니다.
      </div>
      <p class="policyIndex" data-index="4">귀하의 콘텐츠 및 운영</p>
      <div class="policyContent">
        <p class="strong">콘텐츠 업로드</p>
        YouTube 채널을 보유한 경우 귀하는 서비스에 콘텐츠를 업로드할 수 있습니다. 자신의 콘텐츠를 사용해 비즈니스 또는 문화예술 사업을 홍보할 수 있습니다. 콘텐츠를 업로드하기로 했다면, 본 계약 또는 법령을 준수하지 않는 콘텐츠를 서비스에 제출하면 안 됩니다. 예를 들어, 당사자 허가를 받았거나, 법적으로 허용되는 경우가 아니라면 제출한 콘텐츠에 제3자의 지적 재산(예를 들어, 저작권으로 보호되는 자료)이 포함되어서는 안 됩니다. 귀하가 서비스에 제출한 콘텐츠에 대한 법적 책임은 귀하에게 있습니다. 당사는 스팸, 멀웨어, 불법 콘텐츠 등과 같은 침해 및 악용 사례를 감지하기 위해 자동화된 시스템으로 귀하의 콘텐츠를 분석할 수 있습니다.<br><br>

        <p class="strong">귀하가 부여하는 권리</p>
        귀하의 콘텐츠에 대한 소유권은 귀하에게 있습니다. 하지만 아래에 설명된 바와 같이 귀하는 YouTube 및 서비스의 다른 사용자에게 일정한 권리를 부여해야 합니다.<br><br>

        <p class="strong">YouTube에 부여하는 라이선스</p>
        귀하는 본 서비스에 콘텐츠를 제공함으로써 콘텐츠를 사용(복제, 배포, 수정, 표시, 공연 등)할 수 있는 세계적이고, 비독점적이며, 무상으로 제공되고, 양도가능하며, 서브라이선스를 허여할 수 있는 라이선스를 YouTube에 부여합니다. YouTube는 본 서비스를 운영하고, 홍보 및 개선하기 위한 목적으로만 이러한 라이선스를 사용할 수 있습니다. 본 약관의 어떠한 조항도 법령에서 허용되는 범위를 넘어서는 라이선스를 YouTube에 부여하는 것은 아닙니다.<br><br>

        <p class="strong">다른 사용자에게 부여하는 라이선스</p>
        또한 귀하는 서비스의 다른 사용자들에게 서비스를 통해 귀하의 콘텐츠에 액세스할 수 있는 세계적이고, 비독점적이며, 무상으로 제공되는 라이선스를 부여하며, 본 서비스에서 제공하는 기능(예를 들어, 비디오 플레이백이나 퍼가기)을 통해 설정된 바에 따라 해당 콘텐츠를 사용할 권리, 즉, 복제, 배포, 수정, 표시, 공연 등을 할 수 있는 권리를 부여합니다. 보다 명확히 설명하자면, 이 라이선스는 다른 사용자가 본 서비스와는 별개로 귀하의 콘텐츠를 활용할 수 있는 어떠한 권리나 권한도 부여하지 않습니다.<br><br>

        <p class="strong">라이선스 기간</p>
        귀하가 부여한 라이선스는 해당 콘텐츠가 아래에서 설명하는 바에 따라 삭제될 때까지 유지됩니다. 콘텐츠가 삭제되면 라이선스가 종료되지만, 서비스 운영, 삭제 전에 허용한 콘텐츠의 사용, 또는 법령에서 달리 요구하는 경우에는 예외가 인정될 수 있습니다. 예를 들어, 귀하가 콘텐츠를 삭제하더라도 YouTube는 (a) 귀하의 콘텐츠가 포함된 홍보자료를 회수하거나, (b) 서비스의 제한적인 오프라인 시청 기능(예를 들어, 유료가입 서비스에서 이용할 수 있는 기능)에 따라 다른 이용자가 이용하는 콘텐츠를 회수해야 하는 의무가 있는 것은 아니며, (c) YouTube가 법적 필요에 의해 보관할 합리적인 이유가 있는 사본을 삭제해야 하는 것은 아닙니다.<br><br>

        <p class="strong">수익 창출 권리</p>
        귀하는 서비스에 있는 귀하의 콘텐츠에서 수익을 창출할 권리를 YouTube에 부여합니다. 수익 창출에는 콘텐츠에 광고를 게재하거나 사용자에게 이용료를 청구하는 것도 포함될 수 있습니다. 이 계약으로 귀하에게 수익금을 지급받을 자격이 주어지지는 않습니다. 2021년 6월 1일부터 YouTube와 맺은 기타 계약을 통해 YouTube로부터 지급받을 자격이 있는 수익금(YouTube 파트너 프로그램, 채널 멤버십 또는 Super Chat 수익금 포함)은 모두 로열티로 취급됩니다. 법에서 요구되는 경우 Google이 지급액에서 세금을 원천징수합니다.<br><br>

        <p class="strong">하의 콘텐츠 삭제</p>
        귀하는 언제든지 서비스에서 자신의 콘텐츠를 삭제할 수 있습니다. 콘텐츠를 삭제하기 전에 콘텐츠 사본을 만들 수도 있습니다. 만약 귀하가 본 약관에서 요구하는 권한을 더 이상 보유하지 않는 경우 콘텐츠를 삭제해야 합니다.<br><br>

        <p class="strong">YouTube에 의한 콘텐츠 삭제</p>
        귀하의 콘텐츠가 (1) 본 계약을 위반하거나, (2) YouTube, 사용자 또는 제3자에게 위해를 야기한다고 합리적으로 판단되는 경우, 당사는 그러한 콘텐츠를 삭제하거나 차단할 권리를 보유합니다.. YouTube는 통지가 (a) 법적 이유로 금지되거나, (b) 사용자, 기타 제3자, YouTube 또는 그 계열사에게 위해를 야기할 수 있다고 합리적으로 판단되는 경우(예를 들어, 통지하는 것이 법령 또는 규제당국의 명령을 위반하는 경우, 조사를 방해하는 경우, 본 서비스의 보안을 해하는 경우 등)를 제외하고, 귀하에게 해당 조치의 이유를 지체 없이 통지합니다. 항소 방법을 포함한 신고 및 집행에 대한 자세한 내용은 고객센터의 문제해결 페이지에서 확인할 수 있습니다.<br><br>

        <p class="strong">커뮤니티 가이드 위반 경고</p>
        YouTube는 YouTube 커뮤니티 가이드를 위반하는 콘텐츠를 대상으로 한 '경고' 시스템을 운영하고 있습니다. 최초 위반인 경우 YouTube는 일반적으로 주의만 주고, 그 이후의 위반의 경우 귀하의 채널에 대해 경고가 적용됩니다. 경고마다 다양한 제한사항이 수반되며 채널이 YouTube에서 영구적으로 삭제될 수도 있습니다. 경고가 채널에 미치는 영향에 대한 자세한 설명은 커뮤니티 가이드 위반 경고 기본사항 페이지에서 확인할 수 있습니다. 경고가 잘못 주어졌다고 생각되는 경우 여기에서 항소할 수 있습니다.<br><br>

        경고로 인해 채널에 제한이 적용된 경우 다른 채널을 사용해 제한을 회피하면 안 됩니다. 이 금지 조항을 위반하는 것은 본 계약을 중대하게 위반하는 행위로서 Google은 Google 계정을 해지하거나 본 서비스 전체 또는 일부의 액세스 권한을 해지할 권리를 보유합니다.<br><br>

        <p class="strong">저작권 보호</p>
        YouTube는 저작권자가 온라인상에서 자신의 지적 재산을 관리하는 데 도움이 되는 정보를 YouTube 저작권 센터에서 제공하고 있습니다. 본 서비스에서 귀하의 저작권이 침해되었다고 생각하는 경우 YouTube에 신고해 주시기 바랍니다.<br><br>

        YouTube는 YouTube 저작권 센터의 절차에 따라 저작권 침해 신고에 대응합니다. YouTube 저작권 센터에서는 저작권 위반 경고 해결 방법에 대한 정보도 제공하고 있습니다. 반복적으로 저작권을 침해하는 사용자는 YouTube 정책에 따라 일정한 경우 서비스 액세스가 해지될 수 있습니다.
      </div>
      <p class="policyIndex" data-index="5">계정 정지 및 해지</p>
      <div class="policyContent">
        <p class="strong">귀하에 의한 해지</p>
        귀하는 언제든지 서비스 사용을 중지할 수 있습니다. 안내에 따라 자신의 Google 계정에서 본 서비스를 삭제하면 됩니다. 삭제 시 귀하의 YouTube 채널이 폐쇄되고, 데이터가 삭제됩니다. 사용을 중지하기 전에 귀하의 데이터 사본을 다운로드할 수도 있습니다.<br><br>

        <p class="strong">YouTube에 의한 해지 및 정지</p>
        YouTube는 (a) 귀하가 본 계약을 중대하게 또는 반복적으로 위반하거나, (b) 법적 요건이나 법원 명령 등의 준수를 위한 경우, 또는 (c) 사용자, 기타 제3자, YouTube 또는 그 계열사에 책임이나 위해를 야기하는 행위가 있다고 합리적으로 판단되는 경우, 귀하의 Google 계정을 정지 또는 해지하거나 본 서비스 전체 또는 일부에 대한 귀하의 액세스를 해지할 권리를 보유합니다.<br><br>

        <p class="strong">해지 또는 정지에 대한 통지</p>
        YouTube는 통지가 (a) 법적 이유로 금지되거나, (b) 사용자, 다른 제3자, YouTube 또는 그 계열사에게 위해를 야기할 수 있다고 합리적으로 판단되는 경우(예를 들어, 통지하는 것이 법령 또는 규제당국의 명령을 위반하는 경우, 조사를 방해하는 경우, 본 서비스의 보안을 해하는 경우 등)를 제외하고, 귀하에게 YouTube에 의한 해지 또는 정지의 이유를 지체 없이 통지합니다. 서비스 변경으로 인해 YouTube가 귀하의 액세스를 해지하는 경우, 합리적으로 가능하다면 서비스에서 귀하의 콘텐츠를 가져올 충분한 시간을 귀하에게 제공합니다.<br><br>

        <p class="strong">계정 정지 또는 해지의 효과</p>
        귀하의 Google 계정이 해지되거나 본 서비스에 대한 액세스가 제한되어도 서비스의 일부(예를 들어, 단순한 시청)는 계정 없이 계속 사용할 수 있으며, 본 계약은 그러한 사용에 대해서 계속 적용됩니다. Google 계정이 잘못 해지 또는 정지되었다고판단되는 경우 이 양식을 사용하여 항소할 수 있습니다.
      </div>
      <p class="policyIndex" data-index="6">서비스에 포함된 소프트웨어에 대하여</p>
      <div class="policyContent">
        <p class="strong">다운로드 가능한 소프트웨어</p>
        서비스에 다운로드 가능한 소프트웨어(YouTube 스튜디오 애플리케이션 등)가 필요하거나 포함되는 경우, 라이선스를 제공하는 추가 약관의 적용을 받는 소프트웨어가 아니라면, YouTube는 귀하에게 서비스의 일부로 제공한 소프트웨어를 사용할 수 있는 개인적이고, 세계적이며, 무상으로 제공되고, 양도 불가능하며, 비독점적인 라이선스를 제공합니다. 이 라이선스는 귀하가 본 계약에서 허용하는 방법에 따라 YouTube가 제공하는 바대로 본 서비스를 사용하고 혜택을 누릴 수 있도록 하기 위한 목적으로만 제공됩니다. 귀하는 이 소프트웨어의 어느 부분도 복사, 수정, 배포, 판매 또는 대여할 수 없으며, 소프트웨어를 역설계하거나, 소스 코드의 추출을 시도할 수 없습니다. 다만, 법률상 이와 같은 제한이 금지되거나, YouTube의 서면 승인을 받은 경우는 제외합니다.<br><br>

        <p class="strong">오픈소스</p>
        본 서비스에서 사용되는 일부 소프트웨어는 당사가 귀하에게 부여하는 오픈소스 라이선스 하에서 제공될 수 있습니다. 오픈소스 라이선스에는 명시적으로 본 약관의 일부 규정에 우선하는 규정이 있을 수 있으므로, 그러한 라이선스 내용을 숙지하시기 바랍니다.
      </div>
      <p class="policyIndex" data-index="7">기타 법적 조항</p>
      <div class="policyContent">
        <p class="strong">보증의 부인</p>
        본 계약에 명시되거나, 법률에서 요구되지 않는 한, 본 서비스는 '있는 그대로' 제공되며 YouTube는 서비스와 관련한 어떤 구체적인 약정이나 보증도 하지 않습니다. 예를 들어, 법률상 허용되는 한도 내에서, 당사는 (a) 서비스를 통해 제공되는 콘텐츠, (b) 서비스의 특정 기능이나 서비스의 정확성, 안정성, 가용성 또는 귀하의 필요를 충족할 능력, 또는 (c) 귀하가 제출하는 어떤 콘텐츠든 서비스에서 액세스할 수 있음을 보증하지 않습니다.<br><br>

        모바일로 YouTube 광고를 시청하는 경우 데이터 요금이 발생할 수 있습니다.<br><br>

        <p class="strong">책임의 제한</p>
        법률에서 요구되는 경우를 제외하고, YouTube, 그 계열사, 임원, 이사, 직원 및 대리인은 다음 사항을 원인으로 발생한 이익·수입·사업 기회·영업권·예상된 절감 효과의 상실, 데이터의 손실 또는 손상, 간접적 또는 결과적 손실, 징벌적 손해에 대해 책임을 지지 않습니다.<br><br>

        1. 서비스의 오류, 실수 또는 부정확한 내용<br>
        2. 귀하의 서비스 사용으로 인한 개인의 상해나 재산 피해<br>
        3. 서비스의 무단 액세스 또는 사용<br>
        4. 서비스의 중단 또는 중지<br>
        5. 제3자에 의해 서비스에 또는 서비스를 통해 전송된 바이러스 또는 악성 코드<br>
        6. 귀하의 콘텐츠 사용을 포함해 사용자 또는 YouTube가 제출한 콘텐츠, 및/또는<br>
        7. 콘텐츠의 삭제 또는 이용 불가<br>
        본 조항은 청구의 근거가 보증, 계약, 불법 행위 또는 기타 법리 등 무엇이든 상관없이 모든 청구에 적용됩니다.<br><br>

        관련 법률이 허용하는 한도 내에서, 본 서비스와 관련된 또는 그로 인한 청구에 대한 YouTube 및 그 계열사의 총 배상 책임은 (a) 귀하가 청구에 관하여 YouTube에 서면 통지하기 전 12개월 동안 YouTube가 귀하의 서비스 사용과 관련해 귀하에게 지급한 수익 금액과, (b) 미달러 $500 중 높은 금액으로 제한됩니다.<br><br>

        <p class="strong">면책</p>
        관련 법률이 허용하는 한도 내에서, 귀하는 (a) 귀하의 서비스 사용 및 액세스, (b) 귀하의 본 약관 조항 위반, (c) 귀하의 제3자의 저작권, 재산권 또는 프라이버시권 등 권리침해, 또는 (d) 귀하의 콘텐츠로 손해를 입었다는 제3자의 주장으로 발생하는 모든 청구, 손해, 의무, 손실, 책임, 비용 또는 채무, 지출(변호사 비용 등)로부터 YouTube, 그 계열사, 임원, 이사, 직원 및 대리인을 보호하고, 이들의 책임을 면제하며, 이로 인하여 손해를 입지 않도록 하는 데 동의합니다. 이러한 방어 및 면책 의무는 본 계약과 귀하의 본 서비스 사용이 종료된 후에도 유효하게 존속합니다.<br><br>

        <p class="strong">제3자 링크</p>
        서비스에는 YouTube가 소유하거나 관리하지 않는 제3자 웹사이트 및 온라인 서비스의 링크가 포함될 수 있습니다. YouTube는 해당 웹사이트 및 온라인 서비스에 관여할 수 없으며, 관련 법률이 허용하는 한도 내에서, 이에 대한 책임을 지지 않습니다. 본 서비스에서 떠나는 경우 귀하가 방문하는 각 제3자 웹사이트 및 온라인 서비스의 약관과 개인정보처리방침을 검토하시기 바랍니다.
      </div>
      <p class="policyIndex" data-index="8">본 계약에 대하여</p>
      <div class="policyContent">
        <p class="strong">본 계약'의 변경</p>
        당사는 (1) 서비스 수정 사항을 반영하거나, 사업 방식의 변화를 반영하거나(예: 새 제품 또는 기능을 추가하거나 기존의 것들을 삭제하는 경우) (2) 법령, 규제 또는 보안상 등의 이유가 있거나 (3) 악용 또는 위해를 방지하기 위해 본 계약을 변경할 수 있습니다. 본 계약에 중대한 변경사항이 있을 경우, YouTube는 최소 30일 전에 사전 통지와 변경사항 검토 기회를 제공하지만, (1) 사용자에게 유리한 신규 제품 또는 기능을 출시하는 경우 또는 (2) 발생 중인 악용사례를 방지하거나 법적 요건에 대응해야 하는 등 긴급한 상황인 경우는 예외입니다. 새로운 약관에 동의하지 않으면 업로드한 콘텐츠를 삭제하고 본 서비스 이용을 중지해야 합니다.<br><br>

        <p class="strong">본 계약의 지속</p>
        귀하의 서비스 사용이 종료되어도 본 계약의 '기타 법적 조항', '본 계약에 대하여' 부분은 계속 귀하에게 적용되며, 귀하가 부여한 라이선스는 '라이선스 기간'에서 설명된 바에 따라 지속됩니다.

        <p class="strong">분리</p>
        어떤 이유로든 본 계약의 특정 조항이 집행 불가능한 것으로 판명되는 경우, 이는 다른 조항에 영향을 미치지 않습니다.<br><br>

        <p class="strong">권리 포기</p>
        귀하가 본 계약을 준수하지 않았을 때 당사가 즉시 조치를 취하지 않더라도, 이는 당사가 보유하는 권리(향후 조치를 취할 권리 등)를 포기하는 것을 의미하지 않습니다.<br><br>

        <p class="strong">해석</p>
        본 약관에 사용된 '포함' 또는 ‘등'이란 표현은 '포함하되 한정되지 않음'이란 의미이며, 당사가 제시한 예시는 설명을 위한 목적으로 사용된 것입니다.<br><br>

        <p class="strong">준거법</p>
        일부 국가의 법원에서는 분정 유형에 따라 미국 캘리포니아주 법률을 적용하지 않을 수 있습니다. 귀하가 이러한 국가에 거주하고, 미국 캘리포니아주 법률의 적용이 배제되는 경우, 거주 국가의 법률이 본 약관과 관련된 분쟁에 적용됩니다. 그렇지 않은 경우, 귀하는 본 약관 또는 서비스와 관련되거나, 이로부터 야기된 일체의 분쟁에 대해 미국 캘리포니아주 법률이 적용되며, 캘리포니아주 국제사법의 적용은 배제된다는 것에 동의합니다. 마찬가지로, 거주 국가의 법원이 귀하가 미국 캘리포니아주 산타클라라 카운티 법원의 관할에 합의하는 것을 허용하지 않는 경우, 본 약관과 관련된 분쟁에 관하여 귀하의 거주지 재판관할이나 법정지가 적용됩니다. 그렇지 않은 경우, 본 약관 또는 서비스와 관련되거나, 이로부터 야기된 모든 청구는 미국 캘리포니아주 산타클라라 카운티의 연방 또는 주 법원이 전속관할을 가지며, 귀하와 YouTube는 해당 법원이 인적 관할을 갖는 것에 동의합니다.<br><br>

        발효일: 2022년 1월 5일
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PolicyYoutube',
  mounted () {
    this.init()
  },
  data () {
    return {
      anchors: []
    }
  },
  methods: {
    init () {
      const el = this.$refs.youtube
      if (this.$route.hash) {
        this.$router.replace({ hash: '' })
      }
      // data-index 속성 검사하여 북마크 생성
      if (el && el.querySelectorAll('[data-index]') && el.querySelectorAll('[data-index]').length > 0) {
        el.querySelectorAll('[data-index]').forEach((x, i) => {
          this.anchors.push({
            id: x.dataset.index,
            name: x.dataset.label || x.textContent,
            anchor: `anchor_${i}_${x.dataset.index}`
          })
          x.setAttribute('id', `anchor_${i}_${x.dataset.index}`)
        })
      }
    },
    goAnchor (pos) { // 앵커 타겟이 헤더에 묻혀 헤더높이 + 일정수치만큼 스크롤 내림
      // setTimeout(() => {
      //   const scrollY = window.scrollY
      //   const header = document.querySelector(window.innerWidth >= 768 ? '.header' : '.gnbBox')
      //   const height = header.clientHeight + 40
      //   window.scrollTo(0, scrollY - height)
      // }, 700)
      const headerHeight = window.innerWidth >= 768 ? 80 : 60
      const plusHeight = window.innerWidth >= 768 ? 280 : 130
      const position = document.getElementById(pos).offsetTop + plusHeight + headerHeight;
      window.scrollTo(0, position)
    }
  }
}
</script>
