<template>
  <div class="matchPage hyundai">
    <Top />
    <router-view></router-view>
  </div>
</template>

<script>
import Top from '@/components/Match/Top'

export default {
  name: 'MatchView',
  components: {
    Top
  }
}
</script>
