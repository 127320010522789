<template>
  <div class="ticketIndex hyundai" style="margin-bottom:300px">
    <!-- 시즌준비중 서브 상단 -->
    <div class="subTop hyundai noGame">
      <div class="top">
        <div class="container">
          <div class="breadCrumb">
            홈 <span class="arr">&#8250;</span>
            <router-link to="https://shop.hyundai-motorsfc.com/">초록이네</router-link>
          </div>
          <div class="topTitle">
            <p class="cate">초록이네</p>
            <p class="subCate">통합 리뉴얼 안내</p>
          </div>
        </div>
      </div>
    </div>
    <!-- //시즌준비중 서브 상단 -->
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason">
      <div class="container">
        <img src="@/assets/img/bg_waiting_jbfcmall.png" alt="준비중입니다"/>
      </div>
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
import http from '@/api/index'

export default {
  name: 'Jbfcmall',
  data() {
    return {
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>

</style>
