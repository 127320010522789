import axios from "axios";
import store from "@/store";
import dayjs from "dayjs";
import router from "@/router";

/**
 * refresh token 확인/재발급
 * @param string
 * @returns {*}
 */
// "https://api-jbhd.thesports.io"  "http://localhost:3000" "https://api.jbfc.kr";
export default {
    baseURL: "https://api.jbfc.kr",
    authMsg: ['No token provided', 'Failed to authenticate token'],
    accessTokenChk: async function (config) { // Access token expire check
        if(store.state.login && store.state.token.access){
            if(store.state.token.exp < dayjs().unix()){
                // expired token
                let refresh = await this.refreshToken();
                if (refresh){
                    store.commit('login', refresh);
                }
            }

            config.headers['x-access-token'] = store.state.token.access;
        }
        return config;
    },
    refreshToken: async function () { // Refresh token 확인/재발급

        axios.post(this.baseURL+'/user/check_token?agent=web', {
            user_id: store.state.user.id,
            user_name: store.state.user.name
        },{
            headers: {
                'x-refresh-token': store.state.token.refresh
            }
        }).then((res) => {
            return {
                state: true,
                access: res.data.accessToken,
                refresh: res.data.refreshToken
            }

        }).catch(err => {
            console.log(err);
            return false;
        })

    },
    tokenErrChk: function (err) {

        if (err.response.data.message === 'non-existent member') {
            alert('존재하지 않는 회원입니다.');
            router.push('/');
            return false;
        } else if (this.authMsg.indexOf(err.response.data.message) > -1) {
            // 강제 로그아웃
            const redirectUrl = router.currentRoute.path;
            store.commit('logout');
            alert('로그인이 필요한 서비스입니다.');
            router.push({name: 'signin', query: {redirectUrl: redirectUrl}});
            return false;
        }

        return err;

    }

}
