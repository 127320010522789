<template>
  <div class="mediaList wallpaper hyundai">
    <div class="container">
      <!-- 미디어 리스트 -->
      <div class="tiles">
        <!-- 리스트 정보 -->
        <div class="tileInfo">
          <div class="sort">
            <select v-model="currentYear">
              <option
                  v-for="(y, i) in year"
                  :key="i"
                  :value="y.id">
                {{ y.name }}
              </option>
            </select>
          </div>
        </div>
        <!-- //리스트 정보 -->
        <div class="noData" v-if="wallpaperData.length=== 0">게시물이 없습니다.</div>
        <!-- 미디어 타일 리스트 -->
        <div class="tileList wallpaper" v-else>
          <!-- tile item -->
          <div class="tile" v-for="(data,idx) in wallpaperData" :key="idx">
            <div v-if="data.thum_img"><img :src="data.thum_img" @error="replaceImg"/></div>
            <div v-else><img src="../../assets/img/bg_media_404.png"></div>
            <div class="article">
              <p class="subject">{{ data.title }}</p>
              <div class="info">
                <span class="wallPaperdate">{{ data.regdate }}</span>
              </div>
              <div class="downloadBox">
                <div class="download">
                  <a class="downloadBtn" v-for="(el ,key) in data.imgName" :key="key"
                     :href="data.imgData[key] !== 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/wallpaper/' ? data.imgData[key] : undefined"
                     target="_blank"
                     :download="data.imgData[key] !== 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/wallpaper/' ? undefined : el"
                  >{{ data.imgData[key] === 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/wallpaper/' ? '-' : el }}</a>
                  <a class="downloadBtn_black" v-for="(el ,b) in data.imgName_s" :key="`${b}+l`" v-if="data.imgData_s.length!==0" :href="data.imgData_s[b]" target="_blank" download >{{ el }}</a>
                </div>
              </div>
            </div>
          </div>
          <!-- //tile item -->
        </div>

        <!-- //미디어 타일 리스트 -->
      </div>
      <!-- //미디어 리스트 -->
    </div>
  </div>
</template>

<script>
import http from '@/api/index'
import dayjs from 'dayjs'
import bgImg from "@/assets/img/bg_media_404.png"

export default {
  name: 'mediaWallpaper',
  watch: {
    currentYear() {
      this.init(this.currentYear)
    },
  },
  mounted() {
    this.init(this.currentYear)
  },
  created() {
    const thisYear = this.$store.state.thisYear
    this.currentYear = thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
  },
  data() {
    return {
      currentYear: 2023,
      yearStart: 2017, // 필터 시작 년도
      year: [],
      dropdownActive: false,
      wallpaperData: [],
      isData: true,
    }
  },
  methods: {
    async init(type) {
      const params = {
        params: {
          year: this.currentYear,
        }
      }
      if (type === this.currentYear) {
        params.params.year = this.currentYear
      }
      const wallpaperData = await http.get('/media/wallpaper', params)
      wallpaperData.data.body.map(row => {
        row.imgData = {}
        row.imgName = {}
        row.imgData_s ={}
        row.imgName_s = {}
        row.regdate = dayjs(row.regdate).format('YYYY.MM.DD')
        if(row.file_1920 !== 'http://www.hyundai-motorsfc.com/uploadFile/wallpaper/'){
          row.imgData['1920*1080'] = row.file_1920
          row.imgName['1920*1080']='1920*1080'
        }
        if(row.file_1280 !== 'http://www.hyundai-motorsfc.com/uploadFile/wallpaper/'){
          row.imgData['1280*960'] = row.file_1280
          row.imgName['1280*960']='1280*960'
        }
        if(row.file_1152 !== 'http://www.hyundai-motorsfc.com/uploadFile/wallpaper/'){
          row.imgData['1152*864'] = row.file_1152
          row.imgName['1152*864']='1152*864'
        }
        if(row.file_1024 !== 'http://www.hyundai-motorsfc.com/uploadFile/wallpaper/'){
          row.imgData['1024*768'] = row.file_1024
          row.imgName['1024*768']='1024*768'
        }
        if(row.file_mobile !== 'http://www.hyundai-motorsfc.com/uploadFile/wallpaper/'){
          row.imgData['Mobile'] = row.file_mobile
          row.imgName['Mobile']='Mobile'
        }
        if(row.file_1920_s !== 'http://www.hyundai-motorsfc.com/uploadFile/wallpaper/'){
          row.imgData_s['1920*1080'] = row.file_1920_s
          row.imgName_s['1920*1080']='1920*1080'
        }
        if(row.file_1280_s !== 'http://www.hyundai-motorsfc.com/uploadFile/wallpaper/'){
          row.imgData_s['1280*960'] = row.file_1280_s
          row.imgName_s['1280*960']='1280*960'
        }
        if(row.file_1152_s !== 'http://www.hyundai-motorsfc.com/uploadFile/wallpaper/'){
          row.imgData_s['1152*864'] = row.file_1152_s
          row.imgName_s['1152*864']='1152*864'
        }
        if(row.file_1024_s !== 'http://www.hyundai-motorsfc.com/uploadFile/wallpaper/'){
          row.imgData_s['1024*768'] = row.file_1024_s
          row.imgName_s['1024*768']='1024*768'
        }
      })
      this.wallpaperData = wallpaperData.data.body
    },
    replaceImg(e) {
      e.target.src = bgImg
    },
  },
}
</script>
<style>
.wallPaperdate{
  font-size: 13px;
  font-weight: 400;
  color: #999999;
}
.wallPaperdate:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -1px;
  margin-right: 5px;
  vertical-align: middle;
  background: url('@/assets/img/ico_date.png') 0 50% / contain no-repeat;
}
.downloadBox {
  margin-top: 16px;
}

.download {
  /*width: 100%;*/
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 5px;

}

.downloadBtn {
  padding: 8px 3px;
  font-size: 10px;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  text-align: center;
  margin-bottom: 5px;
  color: #444444;
  border-radius: 3px;
}

.downloadBtn_black {
  padding: 8px 0px;
  font-size: 10px;
  box-sizing: border-box;
  border: 1px solid rgb(68, 68, 68, 0.2);
  text-align: center;
  margin-bottom: 5px;
  color: #444444;
  background-color: #DDDDDD;
  border-radius: 3px;
}
</style>

