import axios from "axios";
import lib from "@/library/platform"
let platform = lib.getPlatform()

const baseURL = process.env.VUE_APP_SHOP_BY_API_URL;
const instance = axios.create({
    baseURL: baseURL,
    headers: {
        "content-type": "application/json; charset=utf-8",
        "clientid": process.env.VUE_APP_SHOP_BY_CLIENT_ID,
        "version": "1.0",
        "platform": platform
    },
    timeout: 30000,
    // withCredentials: true,
    crossDomain: true,
    credentials: "include"
});


export default instance;
