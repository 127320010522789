<template>
  <div class="container">
    <div class="sectionTitle">티켓 정책</div>
    <TicketPolicy v-if="state"/>
    <div class="sectionBox" v-if="state">
      <div class="sectionTitle">할인 정책</div>
      <TicketDiscountPolicy />
    </div>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 50px;">
      <div class="container">
        <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
      </div>
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
import TicketPolicy from './TicketPolicy'
import TicketDiscountPolicy from './TicketDiscountPolicy'

export default {
  name: 'TicketReservationPolicy',
  components: {
    TicketPolicy,
    TicketDiscountPolicy
  },
  data () {
    return {
      state: false, // 티켓 임시페이지 사용시 false
    }
  },
}
</script>
