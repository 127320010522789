import Vue from 'vue'
import Vuex from 'vuex'

import * as modules from './modules/'
import router from "@/router";
import http from "@/api";
import dayjs from "dayjs"

Vue.use(Vuex)

const urlParams = function () {
    const queryString = window.location.search

    function paramsObject(str) {
        const urlParams = new URLSearchParams(str)
        const entries = urlParams.entries()
        const result = {}
        for (const [key, value] of entries) {
            result[key] = value
        }
        return result
    }

    return queryString ? paramsObject(queryString) : null
}
const loginChk = function () {
    // token 있으면 true, 없으면 false
    const token = JSON.parse(localStorage.getItem('token'));
    return !!(token && token.access);
}

const store = new Vuex.Store({
  state: {
    login: loginChk(),
    loginProcess: false,
    token: {
        access: null,
        exp: null,
        refresh: null,
        refreshExp: null
    },
    user: {
        idx: null,
        id: null,
        name: null,
        encrypted_id: null,
    },
    lang: 'KOR',
    video: null,
    thisYear: '',
    params: urlParams(),
    matchData : [],
    surveyLogin: '',
    pic: null,
    favPlayer: '',
  },
  mutations: {
      loginCheck(state){
          if(!state.login){
              router.push('/member/signin');
          }
      },
      login(state, payload) {
          // call action getUserDetail (좋아하는 선수 등록 Chk)
          store.dispatch('getUserDetail')
          state.loginProcess = true;
          state.login = payload.state;
          state.token.access = payload.accessToken.token;
          state.token.exp = payload.accessToken.exp;
          state.token.refresh = payload.refreshToken.token;
          state.token.refreshExp = payload.refreshToken.exp;
          state.user.id = payload.accessToken.user_id;
          state.user.name = payload.accessToken.user_name;

          localStorage.setItem('token', JSON.stringify(state.token));
          state.loginProcess = false;
          // call action getUserInfo
          store.dispatch('getUserInfo');

      },
      logout(state) {
          state.login = false;
          state.token.access = null;
          state.token.exp = null;
          state.token.refresh = null;
          state.token.refreshExp = null;
          state.user.id = null;
          state.user.name = null;
          state.user.encrypted_id = null;

          localStorage.setItem('token', JSON.stringify({access: '', refresh: ''}));
          localStorage.setItem('favorite', true)
          localStorage.removeItem('encrypted_id')
      },
      userInfo(state, payload) {
          state.user.idx = payload.idx;
          state.user.id = payload.id;
          state.user.name = payload.name;
          state.user.encrypted_id = payload.encrypted_id;
          localStorage.setItem('encrypted_id', payload.encrypted_id)
          state.user.isAllSet = payload.isAllSet
      },
      isVideo(state, videoId) {
          state.video = videoId
      },
      isPic(state, picId) {
          state.pic = picId
      },
      setThisYear(state, year) {
          state.thisYear = year
      },
      setFavPlayer(state, player) {
          state.favPlayer = player
      }
  },
  actions: {
      tokenCheck({commit}){
          const token = JSON.parse(localStorage.getItem('token'));
          if (token && token.expired) {
              commit('logout')
          } else if(token && token.exp >= dayjs().unix()) {
              commit('login', {
                  state: true,
                  accessToken: {
                      token: token.access,
                      exp: token.exp,
                      user_id: '',
                      user_name: ''
                  },
                  refreshToken: {
                      token: token.refresh,
                      exp: token.refreshExp
                  }
              })

              http.get('/user/info').then(res => {
                  commit('userInfo', {
                      idx: res.data.data.idx,
                      id: res.data.data.user_id,
                      name: res.data.data.user_name,
                      encrypted_id: res.data.data.encrypted_user_id,
                  })
                  // if (res.data.data.isAllSet === false) {
                  //     alert('좋아하는 선수 입력 요망')
                  //     return router.push('/member/myinfo')
                  // }
              })
          } else {
              // 토큰만료시 로그아웃
              commit('logout')
          }
      },
      getUserInfo({commit}){
          http.get('/user/info').then(res => {
              commit('userInfo', {
                  idx: res.data.data.idx,
                  id: res.data.data.user_id,
                  name: res.data.data.user_name,
                  encrypted_id: res.data.data.encrypted_user_id,
              })

          })
      },
      getUserDetail({commit}) {
          http.get('/user/detail').then(res => {
              localStorage.setItem('favorite', res.data.data.user.favorite)
              commit('setFavPlayer', {
                  favPlayer: res.data.data.user.favorite
              })
          })
      },
      logout({commit}, payload){
          if(!payload){
              http.post('/user/logout').then(res => {
                  alert('전북현대에서 지원하는 모든 웹사이트 서비스에서 로그아웃 되었습니다.');
                  commit('logout');
                  router.push('/');
              }).catch(err => {
                  console.log(err);
              });
          } else {
              commit('logout');
              router.push('/');
          }
      },
  },
  getters: {
      isLogin: state => state.login,
      token: state => state.token,
      accessToken: state => state.token.access,
      accessTokenExp: state => state.token.exp,
      refreshToken: state => state.token.refresh,
      refreshTokenExp: state => state.token.refreshExp,
      user: state => state.user,
      isVideo: state => state.video,
      isPic: state => state.pic,
      favorite: state => state.favPlayer.favPlayer
  },
  modules: modules.default
})

export default store
