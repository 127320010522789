<template>
  <div>
    <div class="headCoach">
      <div class="info">
        <div class="img"><img src="@/assets/img/player/headcoach.png" alt=""></div>
        <div class="text">
          <div class="container">
            <div class="position" style="margin-bottom: 10px">HEAD COACH</div>
            <div class="name">김두현<br>KIM DOHEON</div>
          </div>
        </div>
      </div>
<!--      <div class="container">-->
<!--        <div class="intro">-->
<!--          <div class="introTitle">감독 소개</div>-->
<!--          <div class="flexBox">-->
<!--            <div class="flexInner"><img src="@/assets/img/player/headcoach_intro.png" alt=""></div>-->
<!--            <div class="flexInner">소개</div>-->
<!--          </div>-->
<!--&lt;!&ndash;          <img src="@/assets/img/bg_waitingSeason.png" />&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import http from '@/api/index'

export default {
  name: 'headCoach',
  created() {
    this.init()
  },
  data(){
    return {
      state: true,
    }
  },
  methods:{
    async init() {
      const data = await http.get('/player/staff/headcoach')
    }
  }
}
</script>
