<template>
  <div class="myinfoBox">
    <div class="topTitle">
      <div class="title">정보수정</div>
      <div class="subTitle">전북현대모터스에프씨 웹사이트 회원 정보 수정하실 항목을 수정하세요.<span class="pcBreak">필수항목은 모두 기입하셔야 정보수정이 가능합니다.</span></div>
    </div>
    <!-- 아이디 -->
    <div class="formGroup">
      <label class="label">아이디 <span class="required">(필수)</span></label>
      <input type="text" class="input" :value="info.id.value" disabled>
    </div>
    <!-- //아이디 -->
    <!-- 비밀번호 -->
    <div class="formGroup">
      <label class="label">비밀번호 변경</label>
      <input type="password" class="input" placeholder="비밀번호를 입력하세요" v-model="info.pw1.value" @keyup="matchPassword">
      <p class="inputMessage"><span v-if="info.pw1.pass" class="confirmMessage"></span> 영어 대/소문자, 특수문자, 숫자를 조합한 9~20자리로 입력하세요</p>
    </div>
    <!-- //비밀번호 -->
    <!-- 비밀번호 확인 -->
    <div class="formGroup">
      <label class="label">비밀번호 확인</label>
      <input type="password" class="input" placeholder="비밀번호를 다시 입력하세요" v-model="info.pw2.value" @keyup="matchPassword">
      <p v-if="info.pw2.pass" class="confirmMessage">비밀번호가 일치합니다</p>
    </div>
    <!-- //비밀번호 확인 -->
    <!-- 이름 -->
    <div class="formGroup">
      <label class="label">이름 <span class="required">(필수)</span></label>
      <div class="withBtn">
        <input type="text" class="input" :value="info.name.value" disabled>
        <button type="button" class="btn whiteLightgrey" @click="cert('rename')">개명 반영</button>
      </div>
    </div>
    <!-- //이름 -->
    <!-- 성별 -->
<!--    <div class="formGroup">-->
<!--      <label class="label">성별 <span class="required">(필수)</span></label>-->
<!--      <input type="text" class="input" :value="info.sex.value" disabled>-->
<!--    </div>-->
    <!-- //성별 -->
    <!-- 휴대폰번호 -->
    <div class="formGroup" v-if="info.phone.certType === 'phone'">
      <label class="label">휴대폰번호 <span class="required">(필수)</span></label>
      <!-- 휴대폰인증 -->
      <div class="withBtn" v-if="info.phone.certType === 'phone'">
        <input type="text" class="input" disabled v-model="info.phone.value">
        <button type="button" class="btn whiteLightgrey" @click="cert('phone')">휴대폰 변경</button>
      </div>
      <!-- //휴대폰인증 -->
      <!-- 아이핀인증 -->
      <div v-else-if="info.phone.certType === 'ipin'">
        <div class="withBtn">
          <input type="tel" class="input" placeholder="휴대폰번호를 입력하세요" ref="phoneInput" v-model="info.phone.value" @input="phoneFormatter" @keyup.enter.prevent="setPhoneCert">
          <button type="button" class="btn whiteLightgrey" :disabled="!info.phone.change" @click="setPhoneCert">인증코드 요청</button>
        </div>
        <div v-if="info.phone.cert" class="withBtn formRow">
          <input type="text" class="input" placeholder="인증코드를 입력하세요" ref="phoneCertInput" v-model="phoneCert" @keyup.enter.prevent="">
          <button type="button" class="btn whiteLightgrey" @click="checkPhoneCert">인증코드 확인</button>
        </div>
        <p v-if="info.phone.pass" class="confirmMessage">인증코드가 일치합니다</p>
      </div>
      <!-- //아이핀인증 -->
    </div>
    <!-- //휴대폰번호 -->
    <!-- 이메일 -->
    <div class="formGroup">
      <label class="label">이메일 주소 <span class="required">(필수)</span></label>
      <div class="withBtn">
        <input type="email" class="input" v-model="info.email.value" placeholder="이메일 주소를 입력하세요" @keyup="changeEmail">
        <button type="button" class="btn whiteLightgrey" :disabled="!info.email.change" @click="checkDuplicateEmail">이메일 중복체크</button>
      </div>
    </div>
    <!-- //이메일 -->
    <!-- 광고성 수신동의 -->
    <div class="formGroup">
      <label class="label">광고성 수신동의 <span class="required">(선택)</span></label>
      <div class="textarea height110" v-html="terms"></div>
      <div class="checkAgree">
        <div class="checkbox radio">
          <input
            type="radio"
            name="agree_sms"
            id="agree_sms_y"
            v-model="info.sms.value"
            value="Y">
          <label for="agree_sms_y">동의</label>
        </div>
        <div class="checkbox radio">
          <input
            type="radio"
            name="agree_sms"
            id="agree_sms_n"
            v-model="info.sms.value"
            value="N">
          <label for="agree_sms_n">미동의</label>
        </div>
      </div>
    </div>
    <!-- //광고성 수신동의 -->
    <!-- 야간수신동의 -->
    <div class="formGroup" v-if="info.sms.value === 'Y'">
      <label class="label">야간 광고성 수신동의<span class="required">(선택)</span></label>
      <div class="textarea height110" v-html="nightPolicy"></div>
      <div class="checkAgree">
        <div class="checkbox radio">
          <input
              type="radio"
              name="agree_nightAdSms"
              id="agree_nightAdSms_y"
              v-model="info.nightAdSms.value"
              value="Y"
          >
          <label for="agree_nightAdSms_y">동의</label>
        </div>
        <div class="checkbox radio">
          <input
              type="radio"
              name="agree_nightAdSms"
              id="agree_nightAdSms_n"
              v-model="info.nightAdSms.value"
              value="N"
          >
          <label for="agree_nightAdSms_n">미동의</label>
        </div>
      </div>
    </div>
    <!--//야간수신동의 -->
    <!-- 우편번호 -->
    <div class="formGroup">
      <label class="label">우편번호 검색 <span class="required">(필수)</span></label>
      <div class="withBtn">
        <input type="email" class="input" v-model="info.zipcode.value" placeholder="우편번호를 검색하세요" disabled>
        <button type="button" class="btn whiteLightgrey" @click="findZipcode">우편번호 검색</button>
      </div>
      <div class="zipcodeWrap" ref="embed"></div>
    </div>
    <!-- //우편번호 -->
    <!-- 좋아하는 선수 -->
    <div class="formGroup">
      <label class="label">좋아하는 선수 <span class="required">(필수)</span></label>
      <select class="input" v-model="info.favorite.value">
        <option value="">선택하세요</option>
        <option v-for="player in playerList" :value="player.idx">{{ player.name }}</option>
      </select>
    </div>
    <!-- //좋아하는 선수 -->
    <!-- 직업 -->
    <div class="formGroup">
      <label class="label">직업 <span class="required">(선택)</span></label>
      <select class="input" v-model="info.job.value">
        <option value="">선택하세요</option>
        <option v-for="job in jobList" :value="job">{{ job }}</option>
      </select>
    </div>
    <!-- //직업 -->
    <!-- 결혼여부 -->
    <div class="formGroup">
      <label class="label">결혼여부 <span class="required">(선택)</span></label>
      <select class="input" v-model="info.marry.value">
        <option value="">선택하세요</option>
        <option value="y">기혼</option>
        <option value="n">미혼</option>
      </select>
    </div>
    <!-- //결혼여부 -->
    <!-- 현대차 임직원 여부 -->
    <div class="formGroup employee">
      <label class="label">전주공장 사원번호 <span class="required">(선택)</span></label>
      <div class="checkAgree">
        <div class="checkbox radio">
          <input
              type="radio"
              name="employee"
              id="employee_y"
              value="Y"
              v-model="info.employee.value">
          <label for="employee_y">여</label>
        </div>
        <div class="checkbox radio">
          <input
              type="radio"
              name="employee"
              id="employee_n"
              value="N"
              v-model="info.employee.value">
          <label for="employee_n">부</label>
        </div>
      </div>
      <input type="text" class="input employeeVal" placeholder="임직원번호를 입력하세요." v-model="info.employee.number" :disabled="info.employee.value === 'N'">
    </div>
    <!-- //현대차 임직원 여부 -->
    <div class="formBtns">
      <button type="button" class="btn mobileFull large primary1" @click="submit">정보수정</button>
    </div>
  </div>
</template>

<script>
import http from "@/api/index";
import getPlayer from "@/common/getPlayer";
import lib from "@/library/common";
import regExp from "@/common/regExp";
import terms from "@/library/terms";
let popup;
export default {
  name: 'MyinfoInfo',
  computed: {
    user() {
      return this.$store.getters['user']
    },
    validate () {
      const info = Object.keys(this.info).map(x => {
        return this.info[x]
      })
      const invalidValue = info.filter(x => x.required && this.isEmpty(x.value)).map(x => x.name)
      return {
        inValidValue: invalidValue.length === 0 ? null : invalidValue.join(', '),
        result: invalidValue.length === 0
      }
    }
  },
  mounted () {
  },
  data () {
    return {
      terms: terms.policy_4,
      nightPolicy: terms.policy_8,
      userInfo: {},
      info: {
        id: {
          name: '아이디',
          value: '',
          required: false
        },
        pw1: {
          name: '비밀번호',
          value: '',
          pass: false,
          required: false
        },
        pw2: {
          name: '비밀번호 확인',
          value: '',
          pass: false,
          required: false
        },
        name: {
          name: '이름',
          value: '',
          required: false
        },
        sex: {
          name: '성별',
          value: '',
          required: false
        },
        phone: {
          id: 'phone',
          name: '휴대폰번호',
          value: '',
          certType: '', // phone, ipin
          di: '',
          change: false,
          cert: false, //아이핀 인증이고 인증코드 요청한 상태이면 true,
          pass: false, // 아이핀 인증이고 휴대폰인증 완료하면 true
          required: true
        },
        email: {
          id: 'email',
          name: '이메일 주소',
          value: '',
          change: false,
          pass: true, // 변경, 중복체크 완료하면 true
          required: true
        },
        sms: {
          name: '광고성 수신동의',
          value: 'y',
          required: false
        },
        nightAdSms: {
          name: '야간 광고성 수신동의',
          value: 'y',
          required: false
        },
        zipcode: {
          id: 'zipcode',
          name: '우편번호 검색',
          value: '',
          pass: true,
          required: true
        },
        favorite: {
          id: 'favorite',
          name: '좋아하는 선수',
          value: '',
          pass: false,
          required: true
        },
        job: {
          name: '직업',
          value: '',
          required: false
        },
        marry: {
          name: '결혼 여부',
          value: '',
          required: false
        },
        employee: {
          name: '전주공장 임직원 항목',
          value: 'N',
          number: ''
        },
      },
      phoneCert: '',
      playerList: [],
      jobList: lib.jobList,
      loading: false,
    }
  },
  async created() {
    this.playerList = await getPlayer.getPlayer();

    // 성별, 휴대폰번호, 이메일주소, 광고성수신동의, 우편번호, 좋아하는선수, 직업, 결혼여부, 현대차임직원여부
    http.get('/user/detail').then(res=>{
      const data = res.data.data.user;
      this.userInfo = JSON.parse(JSON.stringify(data));
      // console.log('USER INFO >>>>>', this.userInfo);
      this.info.id.value = this.user.id;
      this.info.name.value = this.user.name;
      this.info.sex.value = data.sex === 'M' ? '남' : '여';
      this.info.phone.value = data.phone;
      this.info.phone.certType = data.certType;
      this.info.email.value = data.email;
      this.info.sms.value = data.sms;
      this.info.nightAdSms.value = data.night_ad_receive_sms
      this.info.zipcode.value = data.mb_zipcode;
      if (data.favorite) {
        this.info.favorite.value = data.favorite;
      }
      if (data.mb_job) {
        this.info.job.value = data.mb_job;
      }
      if (data.mb_marriage) {
        this.info.marry.value = data.mb_marriage;
      }
      if (data.employee_number) {
        this.info.employee.value = 'Y';
        this.info.employee.number = data.employee_number;
      }

      //console.log('info >>>>>>>', this.info);

    }).catch(err=>{
      console.log(err);
    })
  },
  methods: {
    isEmpty(str) {
      return regExp.replaceAllEmpty(`${str}`).length === 0
    },
    matchPassword() {
      const pw1 = this.info.pw1.value
      const pw2 = this.info.pw2.value
      if (pw1.length > 0 || pw2.length > 0) {
        this.info.pw1.pass = false
        this.info.pw2.pass = false
      }
      this.info.pw1.pass = !this.isEmpty(pw1) && regExp.expPassword.test(pw1)
      if (!this.isEmpty(pw1) && this.info.pw1.pass) {
        this.info.pw2.pass = pw1 === pw2
      }
    },
    focusRefs(ref) {
      setTimeout(() => {
        if (this.$refs[ref]) {
          this.$refs[ref].focus()
        }
      }, 100)
    },
    setPhoneCert() {
      if (this.loading) {
        return
      }
      if (this.userInfo.phone === this.info.phone.value) {
        alert('변경할 휴대폰번호를 입력해주세요.');
        return
      }
      if (this.isEmpty(this.info.phone.value)) {
        this.focusRefs('phoneInput')
        alert('휴대폰번호를 입력하세요')
      } else if (!regExp.expPhone.test(this.info.phone.value)) {
        alert('올바른 휴대폰번호 형식이 아닙니다')
      } else {
        this.loading = true;
        // 인증코드 요청
        http.post('/message/auth_code', {
          user_name: this.info.name.value,
          user_phone: this.info.phone.value
        }).then(() => {
          alert('인증코드를 요청했습니다\n전송된 인증코드를 입력하세요')
          this.info.phone.cert = true
          this.focusRefs('phoneCertInput')
        }).catch(() => {
          alert('인증코드 요청에 실패했습니다.\n다시 시도해주세요');
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    checkPhoneCert() {
      if (this.loading) {
        return
      }

      this.loading = true;
      if (this.isEmpty(this.phoneCert)) {
        alert('인증코드를 입력하세요')
        this.focusRefs('phoneCertInput')
      } else {
        // 인증코드 확인
        http.post('/message/auth_code_confirm', {
          user_phone: this.info.phone.value,
          auth_code: this.phoneCert
        }).then(res => {
          alert('인증이 완료되었습니다')
          this.info.phone.pass = true
        }).catch(err => {
          this.info.phone.cert = false
          if (err.response.data.message === 'not match') {
            alert('인증번호가 일치하지 않습니다.')
          } else {
            alert('인증코드 유효시간이 만료되었습니다.\n인증코드를 다시 요청해주세요.')
          }
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    changeEmail() {
      let email = this.userInfo.email,
          email_2 = this.info.email.value;
      if (email !== email_2) {
        this.info.email.change = true;
        this.info.email.pass = false;
      } else {
        this.info.email.change = false;
        this.info.email.pass = true;
      }
    },
    checkDuplicateEmail() {
      if (this.loading) {
        return
      }

      this.loading = true;
      if (this.isEmpty(this.info.email.value)) {
        alert('이메일을 입력하세요')
        this.focusRefs('emailInput')
      } else if (!regExp.expEmail.test(this.info.email.value)) {
        alert('올바른 이메일 형식이 아닙니다')
      } else {
        // 이메일 중복검사
        http.get('/user/checkEmail', {
          params: {
            email: this.info.email.value
          }
        }).then(res => {
          if (res.data.success === true) {
            alert('사용 가능한 이메일입니다')
            this.info.email.pass = true
          } else {
            alert('이미 사용중인 이메일입니다')
            this.focusRefs('emailInput')
          }
        }).catch(err => {
          this.info.email.pass = true
          //console.log('checkDuplicateEmail ERROR !!!!!!!!!!!!!!', err)
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    phoneFormatter(e) {
      const phoneNumber = e.target.value
      this.info.phone.value = regExp.phoneFormatter(phoneNumber)
      this.info.phone.change = this.userInfo.phone !== this.info.phone.value;
    },
    findZipcode() {

      new window.daum.Postcode({
        oncomplete: (data) => {
          this.info.zipcode.value = data.zonecode;
        }
      }).embed(this.$refs.embed);

    },
    submit() {
      if (this.loading) {
        return
      }

      const pw1 = this.info.pw1.value
      const pw2 = this.info.pw2.value
      if ((!this.isEmpty(pw1) && !this.info.pw1.pass) || (!this.isEmpty(pw2) && !this.info.pw2.pass)) {
        alert('비밀번호를 확인해주세요')
        this.loading = false;
        return
      }
      if (this.info.phone.certType === 'ipin' && this.info.phone.change && !this.info.phone.pass) {
        alert('휴대폰 인증을 완료해주세요')
        this.loading = false;
        return
      }
      if (this.info.email.change && !this.info.email.pass) {
        alert('이메일 중복검사를 완료해주세요')
        this.loading = false;
        return
      }
      if (!this.info.zipcode.value) {
        alert('우편번호를 입력해주세요')
        this.loading = false;
        return
      }
      if (this.info.employee.value === 'Y' && this.isEmpty(this.info.employee.number)) {
        alert('임직원번호를 입력하세요.');
        this.loading = false;
        return
      }

      if (this.info.sms.value === 'N' && this.info.nightAdSms.value === 'Y'
          || this.info.sms.value === 'N' && this.info.nightAdSms.value === '') {
        this.info.nightAdSms.value = 'N'
      } // 야간 광고성 수신 동의 후 광고성 수신 동의를 미동의 체크시 모두 다 미동의 체크로 바꿔줌

      if (this.info.sms.value === 'y' && this.info.nightAdSms.value === 'y') {
        this.info.sms.value = 'N'
        this.info.nightAdSms.value = 'N'
      }

      //console.log(this.validate);
      if (this.validate.result) {
        this.loading = true;

        let gender = 'F';
        if (this.info.sex.value === '남') {
          gender = 'M';
        }

        if (this.info.pw1.value && this.info.pw2.value === '') {
          alert('비밀번호가 일치하지 않습니다.')
          this.loading = false;
          return
        }


        let data = {
          name: this.info.name.value,
          user_id: this.user.id,
          mobile: this.info.phone.value,
          email: this.info.email.value,
          ad_sms: this.info.sms.value,
          night_ad_receive_sms: this.info.nightAdSms.value,
          user_pw: this.info.pw1.value,
          re_password: this.info.pw2.value,
          zipcode: this.info.zipcode.value,
          favorite_player: this.info.favorite.value,
          job: this.info.job.value,
          marriage: this.info.marry.value,
          di: this.info.phone.di
        }
        if (this.info.employee.value === 'Y') {
          data.employee_number = this.info.employee.number;
        }

        let favorite
        if (this.info.favorite.value) {
          const playerNum = Number(this.info.favorite.value)
          favorite = this.playerList.find(el => el.idx === playerNum).kl_player_id
        }

        let success = false; // 성공 여부를 저장하는 변수
        http.post('/mypage', data)
            .then(() => {
              return http.post(`/mypage/favorite_player?player=${favorite}`);
            })
            .then(() => {
              this.$store.dispatch('getUserDetail');
              success = true;
            })
            .catch(err => {
              console.log('ERROR !!!!!!!!!!!!', err);
              alert(err.response?.data?.message || '오류가 발생했습니다.');
              success = false;
              if (err.config.url.includes('/mypage/favorite_player')) {
                throw err;
              }
            })
            .finally(() => {
              this.loading = false;
              if (success) {
                alert('회원정보가 수정되었습니다.');
              }
            });
      } else {
        if (this.validate.inValidValue) {
          alert(`${this.validate.inValidValue}\n항목을 입력하지 않으셨습니다.`)
        }
      }
    },
    cert(certType) {
      const testUrl = 'https://api-jbhd.thesports.io'
      const liveUrl = 'https://api.jbfc.kr'

      let query = `?type=mypage`;
      if (certType === 'rename') {
        const url = `${liveUrl}/user/checkplus_main` + query
        window.onmessage = (e) => {
          if (e.origin === `${liveUrl}`) { //!Fix
            const data = JSON.parse(e.data.replace(/&#34;/gm, '"'));
            if (data.err !== 'true') {
              this.info.name.value = data.name
              this.info.phone.di = data.dupinfo
            }
          }

        }
        popup = window.open(url, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      }
      if (certType === 'phone') {
        const url = `${liveUrl}/user/checkplus_main` + query
        window.onmessage = (e) => {
          if (e.origin === liveUrl) {
            const data = JSON.parse(e.data.replace(/&#34;/gm, '"'));
            this.info.phone.value = regExp.phoneFormatter(data.mobileno);
            this.info.phone.di = data.dupinfo;
          }
        }
        popup = window.open(url, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.formGroup.employee {
  min-height: 50px;
  padding-top: 5px;

  .label {
    top: 25px;
  }
  .checkAgree {
    margin-top: 0;
    justify-content: left;
  }
  .employeeVal {
    margin-top: 15px;
  }
}
</style>
