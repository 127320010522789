<template>
  <div>
    <div class="policyMessage">전북현대모터스FC(이하 ‘회사’라고 합니다)는 ｢개인정보 보호법｣ 제30조에 따라 고객님의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 
같이 개인정보 처리방침을 수립·공개합니다.</div>
    <div v-if="anchors" class="policyAnchor">
      <div v-for="(a, i) in anchors"
        :key="i"
        class="anchor">
        <a href="javascript:void(0)" @click="goAnchor(a.anchor)">
          <i>{{Number(a.id) + 1}}</i>
          {{a.name}}
        </a>
      </div>
    </div>
    <div v-if="content" v-html="content"></div>
    <div v-if="history && history.length" class="policyHistory">
      <div class="label">이전 개인정보처리방침 보기</div>
      <select class="input auto" v-model="version" @change="setRouter(version)">
        <option
          v-for="(ver, i) in history"
          :key="i"
          :value="ver.version">
          {{ver.name}}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import privacy from './Privacy/index'

export default {
  name: 'PolicyPrivacy',
  watch: {
    $route (to, from) {
      if (to.name === from.name && to.params.version !== from.params.version) {
        const ver = to.params.version
        this.init()
        this.setHistory()
        this.setContent(ver)
      }
    }
  },
  mounted () {
    const ver = this.$route.params.version || 'latest'
    this.init()
    this.$nextTick(() => {
      this.setHistory()
      this.setContent(ver)
    })
  },
  data () {
    return {
      version: 'latest',
      history: null,
      content: null,
      anchors: []
    }
  },
  methods: {
    init () {
      if (this.$route.hash) {
        this.$router.replace({ hash: '' })
      }
      this.history = null
      this.content = null
      this.anchors = []
    },
    setHistory () {
      this.history = Object.keys(privacy).map(x => {
        const data = privacy[x]
        return {
          version: x,
          name: data.name
        }
      }).sort((a, b) => a.version.toLowerCase() < b.version.toLowerCase() ? 1 : -1)
    },
    setContent (ver) {
      const data = privacy[ver]
      const html = data && data.html || null // html 데이터가 있으면 여기서 렌더
      const link = data && data.link || null // link 데이터가 있으면 아웃링크로 이동
      if (!ver) {
        ver = 'latest'
      }
      this.version = ver
      if (!this.version || this.version !== ver) {
        this.setRouter(ver)
      }
      if (html) {
        const el = document.createElement('div')
        el.innerHTML = html
        // data-index 속성 검사하여 북마크 생성
        if (el.querySelectorAll('[data-index]') && el.querySelectorAll('[data-index]').length > 0) {
          el.querySelectorAll('[data-index]').forEach((x, i) => {
            this.anchors.push({
              id: x.dataset.index,
              name: x.dataset.label || x.textContent,
              anchor: `anchor_${i}_${x.dataset.index}`
            })
            x.setAttribute('id', `anchor_${i}_${x.dataset.index}`)
          })
        }
        this.content = el.innerHTML
      } else if (link) {
        window.open(link)
      } else {
        alert('올바른 정보를 불러오지 못했습니다. 메인페이지로 이동합니다.')
        this.$router.push('/')
      }
    },
    setRouter (ver) {
      const data = privacy[ver]
      const html = data && data.html || null // html 데이터가 있으면 여기서 렌더
      const link = data && data.link || null // link 데이터가 있으면 아웃링크로 이동
      if (html) {
        this.$router.push({
          params: {
            version: ver
          }
        })
      } else if (link) {
        window.open(link)
      }
    },
    goAnchor (pos) { // 앵커 타겟이 헤더에 묻혀 헤더높이 + 일정수치만큼 스크롤 내림
      // setTimeout(() => {
      //   const scrollY = window.scrollY
      //   const header = document.querySelector(window.innerWidth >= 768 ? '.header' : '.gnbBox')
      //   const height = header.clientHeight + 40
      //   window.scrollTo(0, scrollY - height)
      // }, 700)
      const headerHeight = window.innerWidth >= 768 ? 80 : 60
      const plusHeight = window.innerWidth >= 768 ? 280 : 130
      const position = document.getElementById(pos).offsetTop + plusHeight + headerHeight;
      window.scrollTo(0, position)
    }
  }
}
</script>
