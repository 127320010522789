<template>
  <div class="mediaView hyundai">
    <!-- 응원가 뷰 타이틀 -->
    <div class="mediaTitle">
      <div class="container">
        <p class="subject">{{scheduleData.Title}}</p>
        <div class="about">
          <div class="info">
            <span class="date">2022.03.01</span>
            <span class="view">{{comma(Number(scheduleData.Hit))}}</span>
          </div>
          <!-- 공유 -->
          <div class="share">
            <div class="shareBtn link" @click="clipboard">
              <span v-if="copyLink" class="message">주소가 복사되었습니다.</span>
            </div>
            <a href="#" class="shareBtn facebook" @click="shareFacebook"></a>
            <a href="#" class="shareBtn kakaotalk" @click="kakaoLink"></a>
          </div>
          <!-- //공유 -->
        </div>
      </div>
    </div>
    <!-- //응원가 뷰 타이틀 -->
    <div class="container">
      <!-- 응원가 본문 -->
      <div class="mediaContent">
        <div class="mediaAudio" v-if="scheduleData.AttachFileNM">
        </div>
        <div class="article" v-html="scheduleData.Content">
        </div>
      </div>
      <div class="mediaBtns">
        <router-link to="/fanzone/playerSchedule" class="btn auto">목록으로</router-link>
      </div>
      <!-- //응원가 본문 -->
      <!-- 이전, 다음글 -->
      <div class="mediaRelated">
        <div class="row noData" v-if="prevData.length === 0">이전 게시물이 없습니다</div>
        <div class="row prev" v-else>
          <p class="subject" @click="setRoute(prevData.seq)">
            <router-link
                :to="{name :'scheduleView', params:{id:prevData.seq}}">
              {{prevData.Title}}
            </router-link>
          </p>
          <span class="date">{{prevData.RegiDate}}</span>
        </div>

        <div class="row noData" v-if="nextData.length === 0">다음 게시물이 없습니다</div>
        <div class="row next" v-else>
          <p class="subject" @click="setRoute(nextData.seq)">
            <router-link
                :to="{name :'scheduleView', params:{id:nextData.seq}}">
              {{nextData.Title}}
            </router-link>
          </p>
          <span class="date">{{nextData.RegiDate}}</span>
        </div>
      </div>
      <!-- //이전, 다음글 -->
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from '@/api/index'
import dayjs from "dayjs"
import {sharePath} from "@/library/sharePath"

export default {
  name: 'ScheduleView',
  created() {
    this.init()
  },
  watch:{
    paramsId(){
      this.init()
    }
  },
  data () {
    return {
      paramsId:this.$route.params.id,
      copyLink: false,
      scheduleData : [],
      nextData:[],
      prevData:[],
    }
  },
  methods: {
    comma(num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    clipboard() {
      const url = window.location.href
      this.copyLink = true
      window.navigator.clipboard.writeText(url)
      setTimeout(() => {
        this.copyLink = false
      }, 1000)
    },
    shareFacebook() {
      window.open(`http://www.facebook.com/sharer.php?u=${sharePath.host}/fanzone/playerSchedule/${this.paramsId}`);
    },
    kakaoLink () {
      const url = `https://hyundai-motorsfc.com/fanzone/playerSchedule/${this.paramsId}`
      window.Kakao.Link.sendDefault({
            objectType: 'feed',
            content: {
              title: this.scheduleData.Title,
              imageUrl: this.scheduleData.Content,
              link: {
                mobileWebUrl: url,
                webUrl: url,
              },
            },
            buttons: [
              {
                title: '웹으로 보기',
                link: {
                  mobileWebUrl: url,
                  webUrl: url,
                },
              },
            ],
            installTalk: true,
          }
      )
    },
    init() {
      http.get(`/fanzone/teamschedule/detail/${this.paramsId}`)
          .then(res => {
            this.scheduleData = res.data.body.current.data[0]
            if(!this.scheduleData) {
              alert('존재하지 않는 게시물입니다.');
              this.$router.push('/fanzone/playerSchedule')
            }
              this.nextData = res.data.body.next.length !== 0 ? res.data.body.next[0] : []
              this.prevData = res.data.body.previous.length !== 0 ? res.data.body.previous[0] : []
          })
    },
    setDate(el){
      if(el!==null){
        el.RegiDate = dayjs(el.RegiDate).format('YYYY.MM.DD')
        return el
      } else {
        return
      }
    },
    setRoute(id) {
      this.$route.params.id = id
      this.paramsId = this.$route.params.id
    },
  }
}
</script>
