<template>
  <div class="mainVideo">
    <div class="container">
      <div class="sectionTitle">
        VIDEO
        <div class="viewmore"><a href="https://www.youtube.com/c/Jeonbuk1994" target="_blank">더보기</a></div>
      </div>
    </div>
    <div class="videoSlider">
      <div class="container frame">
        <div ref="video" class="videoList">
          <div
              v-for="(item, index) in setWidth === false ? video : list"
              :key="index"
              data-width="276"
              class="videoItem">
            <a href="#" @click="videoView($event, item.videoId)">
              <div class="img">
                <img :src="item.set_thumbnail_img" @error="replaceImg($event)" alt="" class="video_img">
              </div>
              <div class="content">
                <div class="time">{{item.duration}}</div>
                <div class="title">{{item.title}}</div>
              </div>
            </a>
          </div>
        </div>
        <div class="displayMobile videoBanner">
          <div>
            <img src="@/assets/img/logo_90.png" alt="">
            전북현대 유튜브채널
          </div>
          <button type="button" class="btn"><a href="https://www.youtube.com/@Jeonbuk1994" target="_blank">바로가기</a></button>
        </div>
      </div>
      <div class="videoArr">
        <button type="button" class="btnArr prev" :disabled="disabledPrev" @click="moveSlider($event, 'prev')"></button>
        <button type="button" class="btnArr next" :disabled="disabledNext" @click="moveSlider($event, 'next')"></button>
      </div>
    </div>
  </div>
</template>

<script>
import ceilFromUnitString from "@/library/ceilFromUnitString";
import http from "@/api/index";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import bgImg from "@/assets/img/bg_feeds_404.png"
import imgPath from "@/library/imgPath"
dayjs.extend(duration);

export default {
  name: 'MainVideo',
  components: {
  },
  created () {
    this.videoInit()
    this.width < 769 ? this.setWidth = true : this.setWidth = false
  },
  watch : {
    width(){
      this.width < 769 ? this.setWidth = true : this.setWidth = false
    }
  },
  computed : {
    list(){
      const list = this.videoList
      if(this.width < 769) {
        if(this.videoList.length > 5){
          return list.slice(0,5)
        }
      }
      return list
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize);
    this.$nextTick(() => {
      this.videoInit()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  data () {
    return {
      video: [],
      videoList : [],
      itemName: '.videoItem', // 비디오 아이템 querySelector
      moveCount: 0,
      itemCount: 0,
      itemLength: 0,
      moveWidth: 0,
      videoItem:{},
      disabledNext: false,
      disabledPrev: false,
      width : window.innerWidth,
      setWidth : false
    }
  },
  methods: {
    async videoInit () {
      const video_res = await http.get('/main/video')
      video_res.data.data.map(row => {
        row.videoId = row.link_url.substr(32)
        row.ctime = dayjs(row.ctime).format("YYYY-MM-DD HH:mm:ss")
        row.duration = dayjs.duration(row.duration).format("mm:ss")
        const pattern = /(?<=vi\/)[^\/]+(?=\/)/;
        const set_thumbnail_img = row.thumbnail_img.match(pattern)
        row.set_thumbnail_img = imgPath.setYoutubeThumbnailImg(set_thumbnail_img)
      })
      const _video =  video_res.data.data
      this.video = _video
      this.videoList = _video
      const itemName = this.itemName
      const video = this.$refs.video
      const videoItems = video.querySelectorAll(itemName)
      const itemLength = videoItems.length // 전체 아이템 수
      const videoItem = videoItems[itemLength - 1] // video item el (last item)
      if(videoItem!==undefined){
        const style = window.getComputedStyle(videoItem)
        const width = ceilFromUnitString(videoItem.dataset.width)
        const margin = ceilFromUnitString(style.marginLeft) || ceilFromUnitString(style.marginRight)
        const itemCount = parseInt(video.clientWidth / width) // 컨테이너 안에 들어갈 아이템 수
        this.moveCount = 0
        this.itemLength = itemLength
        this.itemCount = itemCount
        this.moveWidth = width + margin // 움직일 가로값
      }
      await this.checkDisabled()
    },
    checkDisabled () {
      const disabledPrev = this.moveCount === 0
      const disabledNext = this.itemLength - this.moveCount <= this.itemCount
      this.disabledPrev = disabledPrev
      this.disabledNext = disabledNext
    },
    moveSlider (e, direction) {
      const itemName = this.itemName
      const video = this.$refs.video
      const videoItems = video.querySelectorAll(itemName)
      const videoItem = videoItems[0]
      const style = window.getComputedStyle(videoItem)
      const currentMargin = ceilFromUnitString(style.marginLeft) || 0
      if (e.detail === 1) {
        if (direction === 'prev' && !this.disabledPrev) {
          videoItem.style.marginLeft = `${currentMargin + this.moveWidth}px`
          this.moveCount -= 1
        }
        if (direction === 'next' && !this.disabledNext) {
          videoItem.style.marginLeft = `${currentMargin - this.moveWidth}px`
          this.moveCount += 1
        }
      }
      this.checkDisabled()
    },
    videoView (e, videoId) {
      e.preventDefault()
      this.$store.commit('isVideo', videoId)
    },
    handleResize() {
      this.width = window.innerWidth
    },
    replaceImg(e) {
      e.target.src = bgImg // 임시
    },
  }
}
</script>

<style>
.video_img{
  width: 276px;
  height: 364px;
  object-fit: cover;
}
</style>
