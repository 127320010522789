<template>
  <div v-if="!isEnded" class="countdown" :class="{hasLabel: label, center: center}">
    <!-- countdown: day -->
    <div class="flip" :style="{color: color}">
      <span v-if="label" class="label" :style="{color: labelColor}">일</span>
      <span>{{days}}</span>
    </div>
    <span class="colon">:</span>
    <!-- countdown: hour -->
    <div class="flip" :style="{color: color}">
      <span v-if="label" class="label" :style="{color: labelColor}">시간</span>
      <span>{{hours}}</span>
    </div>
    <span class="colon">:</span>
    <!-- countdown: minute -->
    <div class="flip" :style="{color: color}">
      <span v-if="label" class="label" :style="{color: labelColor}">분</span>
      <span>{{minutes}}</span>
    </div>
    <span class="colon">:</span>
    <!-- countdown: second -->
    <div class="flip" :style="{color: color}">
      <span v-if="label" class="label" :style="{color: labelColor}">초</span>
      <span>{{seconds}}</span>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
export default {
  name: 'CountDown',
  props: [
    'endDate', // isoString
    'label', // display label
    'center', // centered position
    'color', // countdown text color
    'labelColor'
  ],
  data () {
    return {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      isEnded: null
    }
  },
  mounted () {
    this.tick()
    this.timer = setInterval(this.tick.bind(this), 1000)
  },
  destroy () {
    clearInterval(this.timer)
  },
  methods: {
    updateRemaining (distance) {
      function getDigit (num) {
        return `${('0' + num).slice(-2)}`
      }
      this.days = getDigit(Math.floor(distance / (1000 * 60 * 60 * 24)))
      this.hours = getDigit(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
      this.minutes = getDigit(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
      this.seconds = getDigit(Math.floor((distance % (1000 * 60)) / 1000))
    },
    tick () {
      // const currentTime = dayjs(new Date())
      // const distance = Math.max(dayjs(new Date(this.endDate)) - currentTime, 0)

      const currentTime = dayjs().valueOf();
      const endDate = dayjs(this.endDate).valueOf();
      const distance = Math.max(endDate - currentTime, 0)
      this.updateRemaining(distance)
      if (distance === 0) {
        clearInterval(this.timer)
        this.isEnded = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.countdown {
  display: flex;
  margin-bottom: 35px;
  font-size: 60px;
  font-weight: 700;
  color: $white;
  &.hasLabel {
    padding-top: 20px;
  }
  &.center {
    justify-content: center;
  }
}
.colon {
  margin: 0 6px;
  opacity: 0.2;
}
.flip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 70px;
  height: 67px;
  //overflow: hidden; << 여기 주석처리 내가함 ! 나중에 플립 수정사항 생기면 여기도 수정해야함 !
  padding-top: 0.2em;
  color: $yellow;
  font-size: 45px;
  font-weight: 700;
  background-color: #181818;
  border-radius: 6px;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(180deg, #171717 0%, #2B2B2B 73.23%, #202020 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    border-radius: 6px 6px 0 0;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #000;
  }
  & > span {
    position: relative;
    margin-top: -6px;
  }
  .label {
    position: absolute;
    bottom: 100%;
    right: 0;
    font-size: 12px;
    font-weight: 400;
    &:not([style*='color']) {
      opacity: 0.3;
    }
  }
}
@media screen and (max-width: 1199px) {
  .countdown {
    font-size: 48px;
  }
  .flip {
    width: 56px;
    height: 52px;
    font-size: 32px;
  }
  .colon {
    margin: 0 3px;
  }
}
</style>
