<template>
  <div class="confirmLayer">
    <button type="button" class="btnClose" @click="close"></button>
    <div class="confirmBox" :style="{textAlign: textAlign}">
      <div v-if="checkBox">
        <p class="ckb">취소할 티켓을 선택하세요</p>
        <div class="checkbox radio ckb" v-for="(data, i) in seatDetails" :key="i">
        <input
            type="checkbox"
            v-model="ticketData"
            :value="data.ticketNo"
            :id="data.ticketNo">
        <label :for="data.ticketNo">{{data.seatInfo}} {{data.denominationName}} ({{data.ticketNo}})</label>
      </div>
      </div>
      <div class="message" v-html="confirmMessage" v-else></div>
      <div class="btns">
        <button type="button" class="btn whiteLightgrey" @click="cancel">{{ btnCancel }}</button>
        <button type="button" class="btn primary2" @click="confirm">{{ btnConfirm }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'ConfirmLayer',
  props: [
    'align', // 메시지 정렬
    'message', // 컨펌창 내용(html)
    'btn', // 버튼(confirm, cancel)
    'checkBox', // PayTicket.vue tmp 활성화 on/off
    'seatDetails'  // PayTicket.vue 티켓번호 data
  ],
  computed: {},
  mounted() {
    this.$root.$el.append(this.$el)
  },
  data() {
    let textAlign = this.align || 'center'
    let confirmMessage = this.message || '내용을 확인해 주세요.'
    let btnConfirm = (this.btn && this.btn.confirm) || '확인'
    let btnCancel = (this.btn && this.btn.cancel) || '취소'
    return {
      textAlign,
      confirmMessage,
      btnConfirm,
      btnCancel,
      ticketData : [],
    }
  },
  methods: {
    confirm() {
      if(this.checkBox===true){
        if(this.ticketData.length === 0){
        return alert('하나 이상의 티켓을 선택해주세요')
      } else {
        return this.$emit('confirm', this.ticketData)
      }
      }else {
        return this.$emit('confirm')
      }
    },
    cancel() {
      this.checkBox ? (this.ticketData = [], this.$emit('cancel')) : this.$emit('cancel')

    },
    close() {
      if (window.confirm('이 확인창을 닫으시겠습니까?')) {
        this.$emit('close')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.confirmLayer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99;
}

.confirmBox {
  min-width: 600px;
  padding: 45px;
  border-radius: 12px;
  background-color: $white;
}

.message {
  font-size: 20px;
  line-height: 1.5;
  color: #000;
}

.ckb{
  margin-bottom: 5px;
}

.btns {
  margin-top: 30px;
  text-align: center;

  .btn + .btn {
    margin-left: 15px;
  }
}
.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 2px;
    margin-top: -1px;
    margin-left: -25px;
    background: $white;
    transform-origin: 50% 50%;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

@media screen and (max-width: 767px) {
  .confirmLayer {
    padding: 30px 20px;
  }
  .confirmBox {
    width: 100%;
    min-width: 0;
    padding: 40px 20px;
  }
  .message {
    font-size: 16px;
  }
  .btn {
    min-width: 80px;
  }
}
</style>
