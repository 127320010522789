<template>
  <div class="introSection section5">
    <div class="mascotBox box1" :style="{height: `${sectionHeight}px`}">
      <div class="sectionName">JEONBUK HYUNDAI<br>MASCOT</div>
      <div class="sectionTitle">
        <div class="sectionTitle">
          <div v-if="mainTitle[lang]" class="mainTitle" v-html="mainTitle[lang]"></div>
          <div v-if="subTitle[lang]" class="subTitle" v-html="subTitle[lang]"></div>
          <div v-if="desc[lang]" class="desc" v-html="desc[lang]"></div>
        </div>
      </div>
    </div>
    <div class="mascotBlock" :style="{height: `${sectionHeight}px`}">
      <div class="mascotFrame">
        <!-- 나이티 -->
        <div class="mascotBox box2">
          <div class="objs left">
            <div v-for="img in 5" :key="img" class="obj">
              <img :src="require(`@/assets/img/club/obj_mascot_1_${img}.png`)" alt="">
            </div>
          </div>
          <div class="mascotInner">
            <div class="mascotObj">
              <div class="mascotImg mascot1"><img :src="require('@/assets/img/club/img_mascot_1.png')" alt=""></div>
              <div class="mascotInfo">
                <div v-if="nighty.label[lang]" class="label" v-html="nighty.label[lang]"></div>
                <div v-if="nighty.name[lang]" class="name" v-html="nighty.name[lang]"></div>
                <div v-if="nighty.title[lang]" class="title" v-html="nighty.title[lang]"></div>
                <div v-if="nighty.desc[lang]" class="desc" v-html="nighty.desc[lang]"></div>
              </div>
            </div>
          </div>
          <div class="objs right">
            <div v-for="img in 5" :key="img" class="obj">
              <img :src="require(`@/assets/img/club/obj_mascot_1_${img + 5}.png`)" alt="">
            </div>
          </div>
        </div>
        <!-- //나이티 -->
        <!-- 써치 -->
        <div class="mascotBox box3">
          <div class="objs left">
            <div v-for="img in 5" :key="img" class="obj">
              <img :src="require(`@/assets/img/club/obj_mascot_2_${img}.png`)" alt="">
            </div>
          </div>
          <div class="mascotInner">
            <div class="mascotObj">
              <div class="mascotImg mascot1"><img :src="require('@/assets/img/club/img_mascot_2.png')" alt=""></div>
              <div class="mascotInfo">
                <div v-if="search.label[lang]" class="label" v-html="search.label[lang]"></div>
                <div v-if="search.name[lang]" class="name" v-html="search.name[lang]"></div>
                <div v-if="search.title[lang]" class="title" v-html="search.title[lang]"></div>
                <div v-if="search.desc[lang]" class="desc" v-html="search.desc[lang]"></div>
              </div>
            </div>
          </div>
          <div class="objs right">
            <div v-for="img in 5" :key="img" class="obj">
              <img :src="require(`@/assets/img/club/obj_mascot_2_${img + 5}.png`)" alt="">
            </div>
          </div>
        </div>
        <!-- //써치 -->
      </div>
    </div>
    <div class="blank"></div>
  </div>
</template>

<script>
export default {
  name: 'ClubMascot',
  props: [
    'active',
    'height',
    'lang'
  ],
  computed: {
    isActive () {
      return this.active && this.active.index === 5
    },
    sectionHeight () {
      const height = this.height && this.height.minHeight ? parseInt(this.height.minHeight.replace(/[^0-9]/g, '')) : 860
      return height
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onScroll()
    this.onResize()
  },
  data () {
    return {
      parallaxWidth: false,
      mainTitle: {
        ko: '<span>JEONBUK HYUNDAI</span> MASCOT',
        en: '<span>JEONBUK HYUNDAI</span> MASCOT'
      },
      subTitle: {
        ko: '전북현대 마스코트'
      },
      desc: {
        ko: `전북현대는 2021년 새로운 캐릭터 나이티와 써치를 제작 발표했다.<br>
          새롭게 제작된 나이티는 뜨거운 열정을 느끼면 힘이 솟는 스피드레이서로 에너지와 도전, 친화력이 특징이다.<br>
          써치는 귀여우면서 스마트한 캐릭터로 나이티와 다른 매력을 갖고 있다.`
      },
      nighty: {
        label: {
          en: 'JEONBUK HYUNDAI MASCOT'
        },
        name: {
          ko: '나이티<br>(NIGHTY)',
          en: 'NIGHTY'
        },
        title: {
          ko: '뜨거운 열정을 느끼며 힘이 솟는 날쌘돌이'
        },
        desc: {
          ko: `<span class="pcBreak">은하계를 돌며 축구로 도장 깨기를 하던 외계의 생명 에너지가 </span>
              <span class="pcBreak">전주성을 달구는 선수와 서포터즈들의 뜨거운 에너지를 포착하고 </span>
              내려와 마스코트를 넘어선 전북현대모터스FC의 선수를 노리며 전진한다!<br><br>
              <span class="pcBreak">아담한 체구와 다르게 빛나는 재능, 강한 집념으로 </span>
              차원이 다른 공격 축구를 선보인다.<br>
              그는 사실 구단의 전 마스코트였던 모티의 아들.<br>
              <span class="pcBreak">평소 전주성의 이야기를 듣고 항상 동경하던 </span>
              나이티는 비로소 아버지와 자신의 꿈을 위한 발걸음을 내딛게 된다.`
        }
      },
      search: {
        label: {
          en: 'JEONBUK HYUNDAI MASCOT'
        },
        name: {
          ko: '써치<br>(SEARCH)',
          en: 'SEARCH'
        },
        title: {
          ko: '겸둥이 어쌔신'
        },
        desc: {
          ko: `한때 전주성의 그라운드를 비추던 서치라이트였다.<br>
              외계의 생명 에너지가 흡수되어 지능이 생긴 ‘써치’로 진화되었다.<br><br>
              <span class="pcBreak">서치라이트 시절 때부터 축구의 다양한 전술 및 팀플 등을 </span>
              익혔으며 전북현대모터스FC의 역사와 전통 또한 잘 알고 있다.<br>
              <span class="pcBreak">크기를 자유자재로 줄이고 키울 수 있으며 숨긴 재능이 많은 친구 </span>
              ‘나이티’와 전북현대모터스가 우승할 수 있도록 조력자 역할을 자처한다.`
        }
      }
    }
  },
  methods: {
    onScroll () {
      const isActive = this.isActive
      const section = document.querySelector('.section5')
      const scrollY = window.scrollY
      if (section && isActive) {
        const scrollDiff = scrollY - section.offsetTop
        const mascotBlock = section.querySelector('.mascotBlock')
        const mascotFrame = section.querySelector('.mascotFrame')
        if (this.parallaxWidth) {
          if (mascotBlock && mascotFrame) {
            const pos = mascotBlock.offsetTop - scrollDiff > 0 ? 0 : (mascotBlock.offsetTop - scrollDiff) * -1
            const translateY = `${pos}px`
            const translateX = scrollDiff <= mascotFrame.clientHeight + 400 // 400은 스크롤 딜레이
              ? 0
              : parseInt(pos / (section.clientWidth - 400) * 50) > 50
                ? 50
                : parseInt(pos / (section.clientWidth - 400) * 50)
            mascotBlock.style.transform = `translateY(${translateY})`
            mascotFrame.style.transform = `translateX(-${translateX}%)`
          }
        } else {
          mascotBlock.style.transform = ''
          mascotFrame.style.transform = ''
        }
      }
    },
    onResize () {
      // resize
      this.parallaxWidth = window.innerWidth >= 1600
    }
  }
}
</script>
