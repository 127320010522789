<template>
  <div>
    <div class="sectionTitle line">주차장</div>
    <div class="seasonInfoCard" v-if="state">
      <div class="title">
        JEONBUK HYUNDAI
        <p>2024 <span class="mobileBreak">PARKING CARD</span><span class="mobileBreak"></span></p>
      </div>
      <div class="close">2024 W존 전좌석(성인권) 시즌권 소유자 대상<br><strong>1월 25일(목) 12시 ~ 26일(금) 18시</strong></div>
      <Countdown
          :endDate="datetime"
          :center="true"
          :label="true"
          :labelColor="'rgba(255,255,255,0.7)'"/>
      <div class="btns">
        <button type="button" class="btn yellow large" @click="purchase">주차권 구매</button>
      </div>
    </div>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 30px;" v-else>
      <img src="@/assets/img/ticket/parking/2024_parking.jpg" alt="2024 주차장 안내"/>
<!--      <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>-->
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
import Countdown from '@/components/Util/Countdown.vue'
import dayjs from "dayjs"
import { reservePopup } from '@/library/ticketlink'
import router from '@/router'
export default {
  name: 'TicketStadiumParking',
  components: {
    Countdown
  },
  mounted() {
    this.getEncryptedId()
    // setInterval(() => {
    //   this.isOnTime = this.checkIsOnTime()
    // }, 500)
  },
  data () {
    return {
      state: false, // 티켓 임시페이지 사용시 false
      datetime: '2024-01-25T03:00:00.000Z',
      encrypted_id: '',
      // isOnTime: this.checkIsOnTime()
    }
  },
  methods : {
    getEncryptedId() {
      if(this.$store.state.user.encrypted_id !== null) {
        this.encrypted_id = this.$store.state.user.encrypted_id
      } else {
        this.encrypted_id = ''
      }
    },
    // checkIsOnTime() {
    //   const today = dayjs().toISOString();
    //   return dayjs(today).isBefore('2024-01-25T03:00:00.000Z');
    // },
    purchase() {
      this.getEncryptedId()
      if(this.encrypted_id !== '') {
        const url = reservePopup(this.encrypted_id, "pc");
        window.gtag('event','btn_구매하기',{'event_category':'시즌권', 'event_label':this.$store.state.user.id})
        window.open(url,'tl', 'width=1010, height=600, left=100, top=100, scrollbars=yes')
      } else {
        alert("통합회원 로그인 후 이용해주세요.")
        const redirectUrl = router.currentRoute.path;
        router.push({name: 'signin', query: {redirectUrl: redirectUrl}});
      }
    }
  },
}
</script>
