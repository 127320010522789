<template>
  <div class="fanzonePage">

    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 30px;">
      <div class="container">
        <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
      </div>
    </div>
    <!-- //시즌 준비중 -->

    <div class="fanzoneEvent" v-if="state">
      <div class="container">
        <div class="eventList">
          <div class="eventItem">
            <router-link :to="{ name: 'ticketServiceEventView', params: {id: 1} }">
              <div>
                <div class="img"><img src="@/assets/img/bg_media_404_wide.png" alt="" /></div>
                <p class="subject">전북현대 2023시즌 개막전 하이파이브 시즌권 대상 참가자 신청</p>
                <p class="date">2022년 1월 10일 10:00 ~ 2022년 2월 10일 19:00</p>
              </div>
            </router-link>
          </div>
          <div class="eventItem">
            <router-link :to="{ name: 'ticketServiceEventView', params: {id: 1} }">
              <div>
                <div class="img"><img src="@/assets/img/bg_media_404_wide.png" alt="" /></div>
                <p class="subject">전북현대 2023시즌 개막전 하이파이브 시즌권 대상 참가자 신청</p>
                <p class="date">2022년 1월 10일 10:00 ~ 2022년 2월 10일 19:00</p>
              </div>
            </router-link>
          </div>
          <div class="eventItem">
            <router-link :to="{ name: 'ticketServiceEventView', params: {id: 1} }">
              <div>
                <div class="img"><img src="@/assets/img/bg_media_404_wide.png" alt="" /></div>
                <p class="subject">전북현대 2023시즌 개막전 하이파이브 시즌권 대상 참가자 신청</p>
                <p class="date">2022년 1월 10일 10:00 ~ 2022년 2월 10일 19:00</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="noData">등록된 이벤트가 없습니다</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TicketServiceEvent',
  data () {
    return {
      state: false, // 티켓 임시페이지 사용시 false
    }
  }
}
</script>
