<template>
  <div>
    <!-- 리스트 정보 -->
    <div class="listInfo">
      <div class="sort">
        <span class="infoItem">
          <select v-model="currentMonth" class="select" @change="setMonthData(currentMonth)">
            <option value="">월선택</option>
            <option value="all">전체</option>
            <option
                v-for="(m, i) in 12"
                :key="i"
                :value="m">
              {{ m }}월
            </option>
          </select>
        </span>
        <span class="infoItem">
          <select v-model="currentYear" class="select">
            <option value="">년도선택</option>
            <option
                v-for="(y, i) in year"
                :key="i"
                :value="y.id">
              {{ y.name }}
            </option>
          </select>
        </span>
      </div>
    </div>
    <!-- //리스트 정보 -->
    <!-- 리스트 -->
    <div class="matchList">
      <!-- 매치 -->
      <div class="item" v-for="(data, i) in matchData" :key="i">
        <div class="info">
          <div class="league">{{data.league_name}}</div>
          <div class="time">{{data.game_time}}</div>
          <div class="date">{{data.game_date_set}} ({{data.yoil}})</div>
          <div class="stadium">{{data.stadium}}</div>
        </div>
        <div class="game">
          <div class="team left">
            {{data.home_team}}
          </div>
          <div class="center">
            vs
          </div>
          <div class="team right">
            {{data.away_team}}
          </div>
        </div>
      </div>
      <!-- //매치 -->
    </div>
    <!-- //리스트 -->
    <div class="noData" v-if="matchData===undefined || matchData.length === 0">다음 경기 일정이 없습니다</div>
  </div>
</template>

<script>
import http from "@/api/index"
import dayjs from "dayjs"
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrBefore)
import 'dayjs/locale/ko'
dayjs.locale('ko')

export default {
  name: 'MatchU15List',
  created() {
    const paramsYear = this.$store.state.params && this.$store.state.params.year
    const thisYear = this.$store.state.thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    this.currentYear = paramsYear || thisYear
    this.currentMonth = 'all'
  },
  watch: {
    currentYear() {
      this.init()
    }
  },
  data () {
    return {
      currentYear: '',
      currentMonth: '',
      yearStart: 2023, // 필터 시작 년도
      year: [],
      matchData:[],
    }
  },
  methods: {
    async init () {
      const params = {
        params: {
          year: this.currentYear,
        }
      }

      const match = await http.get('/match/schedule/youth/15',params)
      const today = dayjs()
      const arr = []
      const data = match.data.data
      if ( data.length > 0 ) {
        data.map((row,idx) => {
          row.stadium = row.stadium.replace(/\s/g, "")
          row.game_time = row.game_time.substring(0, 5)
          row.game_date = dayjs(row.game_date).format("YYYY-MM-DD")
          row.game_date_set = dayjs(row.game_date).format("YYYY.MM.DD")
          row.date_for_sort = dayjs(`${row.game_date} ${row.game_time}`)
          row.month = Number(dayjs(row.game_date).format('M'))
          row.yoil = dayjs(row.game_date).format('dd')
          if(row.away_team.indexOf('전북현대') !== -1){
            row.set_home_team = row.away_team
            row.away_team = row.home_team
            row.home_team = row.set_home_team
          }
          if(dayjs(row.date_for_sort).isSameOrBefore(today)===false){
            arr.push(match.data.data[idx])
          }
        })
      }
      if(arr.length > 0) {
        arr.sort((a, b) => dayjs(a.date_for_sort) - dayjs(b.date_for_sort))
      }
      this.fixData = arr
      this.setMonthData(this.currentMonth)
    },
    setMonthData (currentMonth) {
      if (currentMonth === '') {
        return this.fixData
      }
      if (currentMonth === 'all') {
        return this.matchData = this.fixData
      } else {
        let arr = this.fixData
        let newArr = arr.filter(item => item.month === currentMonth)
        this.matchData = newArr
      }
    }
  }
}
</script>
