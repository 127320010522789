<template>
  <div class="memberContent hyundai">
    <div class="container">
      <div class="memberBox" style="max-width: 400px; background-color: #FFFFFF">
        <div class="topTitleForErr">
          <div class="title">ERROR 404</div>
          <div class="subTitle" style="font-weight: 400; line-height: 18px"></div>
          존재하지 않는 주소, 페이지를 입력하셨거나,<br>
          현재 페이지가 변경, 삭제되어 찾을 수 없습니다.
          <div class="formBtns formBtns2">
            <button class="btn full primary1" @click="go">메인페이지</button>
          </div>
          <div class="formRow">
            <button class="btn full primary2" @click="goBack">이전으로</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ErrorPage',
  data() {
    return {
      // code: 401, 500, 404, 502
    };
  },
  created() {
  },
  mounted () {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')
  },
  methods: {
    go () {
      this.$router.push('/')
    },
    goBack () {
      this.$router.go(-1)
    }
  },
  beforeDestroy () {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
  },
}
</script>
<style lang="scss" scoped>
</style>
