<template>
  <div>
    <div class="header hyundai">
      <div class="top">
        <div class="familySite">
          <router-link :to="{name: 'main'}" class="link point">전북현대</router-link>
          <a href="https://greenschool.hyundai-motorsfc.com/ " class="link">그린스쿨</a>
          <a href="https://house.hyundai-motorsfc.com/" class="link">후원의집</a>
          <a href="https://shop.hyundai-motorsfc.com/" class="link">초록이네</a>
          <router-link to="/ethics" class="link">인권침해 접수처</router-link>
        </div>
        <div class="logo">
          <router-link :to="{name: 'main'}"><img src="@/assets/img/logo_gnb_hover.png" alt="" /></router-link>
        </div>
        <div class="utils">
          <router-link :to="{name: 'ticket'}" class="link">티켓예매</router-link>
          <router-link v-if="!isLogin" :to="{name: 'signin'}" class="link">로그인</router-link>
          <router-link v-if="!isLogin" :to="{name: 'signup', params: { currentStep: '1' }}" class="link">통합 회원가입</router-link>
          <a href="javascript:void(0)" v-if="isLogin" class="link" @click="logout">로그아웃</a>
          <router-link v-if="isLogin" :to="{name: 'mypage'}" class="link">마이페이지</router-link>
<!--          <div class="langs">-->
<!--            <button type="button" class="current" @click="openLang = !openLang">{{currentLang}}</button>-->
<!--            <div v-if="openLang" class="dropdown">-->
<!--              <button-->
<!--                v-for="lang in filteredLang"-->
<!--                :key="lang"-->
<!--                type="button"-->
<!--                class="lang"-->
<!--                @click="setLang(lang)">-->
<!--                {{lang}}-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <Gnb
        :isLogin="isLogin"
        :current="currentMenu"
        @openMenu="openMenu"
        @closeMenu="init" />
    </div>
    <div class="dimmer"></div>
  </div>
</template>

<script>
import Gnb from '@/components/Gnb'

export default {
  name: 'HeaderView',
  components: {
    Gnb
  },
  computed: {
    isLogin () {
      return this.$store.getters['isLogin']
    },
    filteredLang () {
      const currentLang = this.currentLang
      return this.langs.filter(x => x !== currentLang)
    }
  },
  mounted () {
    document.addEventListener('click', this.closeLang)
    document.addEventListener('mouseover', this.closeMenu)
    if (this.$route.params.lang === 'en') {
      this.currentLang = 'ENG'
    } else {
      this.currentLang = 'KOR'
    }
  },
  data () {
    return {
      currentMenu: null,
      dimmMenu: false,
      langs: [
        'KOR',
        'ENG'
      ],
      openLang: false,
      currentLang: this.$store.state.lang
    }
  },
  methods: {
    init () {
      this.currentMenu = null
      document.body.classList.remove('menuOpen')
      document.body.classList.remove('dimm')
    },
    openMenu (menu) {
      this.currentMenu = menu.name
      this.dimmMenu = menu.intro
      document.body.classList.add('menuOpen')
      document.body.classList[menu.intro ? 'remove' : 'add']('dimm')
    },
    closeMenu (e) {
      const target = e.target
      const isMenuOut = !target.closest('.header') || !(target.closest('.gnbBox') || target.closest('.top') || target.closest('.subMenuBox'))
      if (isMenuOut) {
        this.init()
      }
    },
    closeLang (e) {
      if (!e.target.closest('.langs')) {
        this.openLang = false
      }
    },
    setLang (lang) {
      if(lang === 'ENG') {
        window.open('https://hyundai-motorsfc.com/club/en')
      } else {
        window.open('https://hyundai-motorsfc.com/')
      }
      this.openLang = false
    },
    logout () {
      document.getElementById( "iframe_connect" ).contentWindow.postMessage( "deleteAccessToken", "*" )
      document.getElementById( "iframe_connect_green" ).contentWindow.postMessage( "deleteAccessToken", "*" )

      this.$store.dispatch('logout');
      //this.$store.commit('logout');
      //this.$router.push('/');
    }
  }
}
</script>
