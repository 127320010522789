<template>
  <div class="gamePhoto">
    <div class="innerBox">
      <div class="innerTitle">경기사진</div>
      <div class="noData" v-if="photo.length === 0">데이터가 없습니다</div>
      <div class="photoBox" v-else>
        <div class="viewer">
          <img :src="current" alt="">
        </div>
        <div class="list">
          <vueper-slides
            :slide-ratio="506/384"
            :infinite="false"
            :duration="5000"
            :arrows="false"
            :touchable="false"
            class="no-shadow">
            <vueper-slide
              v-for="(p, i) in photo"
              :key="i">
              <template #content>
                <div
                  v-for="(img, idx) in p"
                  :key="idx" class="img"
                  :class="{current: img === current}"
                  @click="viewImg(img)">
                  <img :src="img" alt="">
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import http from '@/api/index'
import imgPath from '@/library/imgPath'
import dayjs from "dayjs"
// import el from "vue2-datepicker/locale/es/el"
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrBefore)

export default {
  name: 'MatchGamePhoto',
  components: {
    VueperSlides,
    VueperSlide,
  },
  created () {
    const photoes = [
      // 'https://i.imgur.com/hid0xqZ.jpg',
      // 'https://i.imgur.com/KfeA45X.jpg',
      // 'https://i.imgur.com/Qdm5GEk.jpg',
      // 'https://i.imgur.com/7WQw0hh.jpg',
      // 'https://i.imgur.com/ciZ4ewP.jpg',
      // 'https://i.imgur.com/naPm2eS.jpg',
      // 'https://i.imgur.com/rsMWwNz.jpg',
      // 'https://i.imgur.com/CmfBWFn.jpg',
      // 'https://i.imgur.com/JdAFYvy.jpg',
      // 'https://i.imgur.com/Z9TYKsz.jpg',
      // 'https://i.imgur.com/3cTAvrb.jpg'
    ]
    this.init()
    if (photoes.length > 0) {
      const num = 8 // 슬라이드 하나에 들어가는 포토 개수
      let arr = new Array(Math.ceil(photoes.length / num))
      for (let i = 0; i < arr.length; i++) {
        arr[i] = new Array()
        for (let j = 0; j < num; j++) {
          arr[i][j] = ''
        }
      }
      for (let i = 0; i < photoes.length; i++) {
        arr[parseInt(i / num)][i%num] = photoes[i]
      }
      // 8개씩 묶어 리스트 처리
      this.photo = arr.map(x => {
        return x.filter(p => p !== '')
      })
      this.current = arr[0][0]
    }
  },
  data () {
    return {
      photo: [],
      current: ''
    }
  },
  methods: {
    viewImg (img) {
      this.current = img
    },
    init () {
      const date = this.$route.params.id;
      const setDate = dayjs(date).format('YYYY-MM-DD');
      const photoRouteChange = dayjs('2024-03-05').format('YYYY-MM-DD');
      const isPhotoRouteChangeGreaterOrEqual = dayjs(photoRouteChange).isSameOrBefore(setDate);
      http.get(`/photo/match?game_date=${date}`)
          .then(res => {
            const data = res.data.data
            if (data.length !== 0) {
              let photoData
              if (isPhotoRouteChangeGreaterOrEqual !== true) {
                photoData = data.map(el => imgPath.setGamePhoto(el))
              } else {
                photoData = data.map(el => imgPath.setNewGamePhoto(el))
              }
              if (photoData.length > 0) {
                const num = 8 // 슬라이드 하나에 들어가는 포토 개수
                let arr = new Array(Math.ceil(photoData.length / num))
                for (let i = 0; i < arr.length; i++) {
                  arr[i] = new Array()
                  for (let j = 0; j < num; j++) {
                    arr[i][j] = ''
                  }
                }
                for (let i = 0; i < photoData.length; i++) {
                  arr[parseInt(i / num)][i%num] = photoData[i]
                }
                // 8개씩 묶어 리스트 처리
                this.photo = arr.map(x => {
                  return x.filter(p => p !== '')
                })
                this.current = arr[0][0]
              }
            }
          })
    }
  }
}
</script>
