<template>
  <div>
    <!-- 리스트 정보 -->
    <div class="listInfo">
      <div class="title" v-if="rankData.length !== 0">하나원큐 K리그1 {{currentYear}}</div>
      <div class="sort">
        <span class="infoItem">
          <select v-model="currentYear" class="select">
            <option value="">년도선택</option>
            <option
                v-for="(y, i) in year"
                :key="i"
                :value="y.id">
              {{ y.name }}
            </option>
          </select>
        </span>
      </div>
    </div>
    <!-- //리스트 정보 -->
    <div class="rankingTable smallTable" style="overflow: hidden">
      <table style="min-width: 420px;">
        <colgroup>
          <col style="width: 8%;">
          <col style="width: auto;">
          <col style="width: 9%;">
          <col style="width: 9%;">
          <col style="width: 9%;">
          <col style="width: 9%;">
          <col style="width: 9%;">
          <col style="width: 9%;">
          <col style="width: 9%;">
          <col style="width: 9%;">
        </colgroup>
        <thead >
          <tr>
            <th>순위</th>
            <th>팀</th>
            <th>승점</th>
            <th>승</th>
            <th>무</th>
            <th>패</th>
            <th>득점</th>
            <th>실점</th>
            <th>득실</th>
            <th>경기</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, i) in rankData" :key="i" :class="{active: data.team_name_front === '전북'}">
            <td class="bold">{{data.rank}}</td>
            <td class="bold">
              <img :src="data.teamLogoImg">
              <span>{{data.team_name_front}} {{data.team_name_back}}</span>
            </td>
            <td class="bold">{{data.gain_point}}</td>
            <td>{{data.win_cnt}}</td>
            <td>{{data.tie_cnt}}</td>
            <td>{{data.loss_cnt}}</td>
            <td>{{data.gain_goal}}</td>
            <td>{{data.loss_goal}}</td>
            <td>{{data.gap_cnt}}</td>
            <td>{{data.game_count}}</td>
          </tr>
        </tbody>
      </table>
      <p class="updateat" v-if="rankData.length !== 0">{{update_date}}</p>
    </div>
    <div class="noData" v-if="rankData.length === 0">데이터가 없습니다</div>
  </div>
</template>

<script>
import http from '@/api/index'
import imgPath from "@/library/imgPath"
import dayjs from "dayjs"

export default {
  name: 'MatchKleagueRanking',
  created() {
    const paramsYear = this.$store.state.params && this.$store.state.params.year
    const thisYear = this.$store.state.thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    this.currentYear = paramsYear || thisYear
  },
  watch: {
    currentYear() {
      this.init('year')
    }
  },
  data () {
    return {
      currentYear: '',
      yearStart: 2013, // 필터 시작 년도
      year: [],
      rankData : [],
      isActive : null,
      update_date: '' // 업데이트 시간
    }
  },
  methods: {
    async init (type) {
      const params = {
        params: {
          year: ''
        }
      }
      if(type === 'year'){
        params.params.year = this.currentYear
      }
      const rank = await http.get('/match/team_rank?type=all&',params)
      const _rank = rank.data.data
      if (_rank && _rank.length !== 0) {
        _rank.map(row => {
          row.team_name_front = row.team_name.substr(0,2)
          row.team_name_back = row.team_name.substr(2)
          row.teamLogoImg = imgPath.setTeamLogo(row.team_id)
        })
        if (_rank[0] && _rank[0].update_date && _rank[0].update_date !== null) {
          const update_date = {
            year: dayjs(_rank[0].update_date).format('YYYY'),
            month: dayjs(_rank[0].update_date).format('MM'),
            date: dayjs(_rank[0].update_date).format('DD')
          }
          this.update_date = `업데이트 일시 : ${update_date.year}년 ${update_date.month}월 ${update_date.date}일`
        }
        this.rankData = _rank
      }
    },
  }
}
</script>
