<template>
  <div class="introSection section6" :style="{height: `${sectionHeight}px`}">
    <div class="sectionTitle section6Title">경기 관련 수상</div>
    <div class="img section6Img"><div class="dimm"></div><img :src="require('@/assets/img/history/bg_team_award.png')" alt=""></div>
    <ul class="awardList">
      <li>1997 프로축구 올해의 공격팀</li>
      <li>1998 프로축구 올해의 페어플레이</li>
      <li>1999 FA컵 페어플레이상</li>
      <li>2002 아시안컵 위너스컵  페어플레이상</li>
      <li>2003 FA컵 페어플레이상</li>
      <li>2006 AFC 선정 올해의 아시아 최고 클럽</li>
      <li>2009 K리그 올해의 베스트팀</li>
      <li>2011 K리그 올해의 베스트팀 </li>
      <li>2011 AFC 챔피언스리그 페어플레이상</li>
      <li>2021 K리그 페어플레이상</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HistoryTeamAward',
  props: [
    'active',
    'height'
  ],
  computed: {
    isActive () {
      return this.active && this.active.index === 6
    },
    sectionHeight () {
      const height = this.height && this.height.minHeight ? parseInt(this.height.minHeight.replace(/[^0-9]/g, '')) : 3000
      return height * 3
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onScroll()
    this.onResize()
  },
  data () {
    return {
      parallaxWidth: false
    }
  },
  methods: {
    onScroll () {
      const isActive = this.isActive
      const section = document.querySelector('.section6')
      const scrollY = window.scrollY
      if (section) {
        const sectionTitle = section.querySelector('.section6Title')
        const img = section.querySelector('.section6Img')
        const imgObj = img.querySelector('img')
        const award = section.querySelector('.awardList')
        const scrollDiff = section.offsetTop - scrollY
        const scrollPercent = parseInt(this.active && this.active.scrollPercent) >= 33.33 ? 100 : parseInt(this.active && this.active.scrollPercent) * 3
        const pos = scrollDiff > 0 ? 0 : scrollDiff * -1
        const imgOffset = img.offsetTop
        const awardPos = award.getBoundingClientRect().top
        if (isActive && section && this.parallaxWidth) {
          // img position + img
          if (pos >= imgOffset) {
            sectionTitle.style.color = '#fff'
            img.style.transform = `translateY(${pos - imgOffset}px)`
            img.style.width = `${62.5 + ((37.5) * scrollPercent / 100)}%`
            imgObj.style.opacity = scrollPercent > 50 ? 0.5 : 1 - (scrollPercent / 100)
          } else {
            sectionTitle.style.color = ''
            img.style.transform = ''
            img.style.width = ''
            imgObj.style.opacity = ''
          }
          // title
          if (awardPos > sectionTitle.offsetTop * 1.5) {
            sectionTitle.style.transform = `translateY(${pos}px)`
          }
        } else {
          sectionTitle.style.transform = ''
          sectionTitle.style.color = ''
          img.style.transform = ''
          img.style.width = ''
          imgObj.style.opacity = ''
        }
      }
    },
    onResize () {
      // resize
      this.parallaxWidth = window.innerWidth >= 1600
    }
  }
}
</script>
