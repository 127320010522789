<template>
  <div class="subTop hyundai">
    <!-- 서브 상단 -->
    <div class="top">
      <div class="container">
        <div class="breadCrumb">
          홈 <span class="arr">&#8250;</span>
          <router-link :to="depth1.link">{{depth1.name}}</router-link> <span class="arr">&#8250;</span>
          <router-link :to="depth2.link">{{depth2.name}}</router-link>
        </div>
        <div class="topTitle">
          <p class="cate">{{depth1.name}}</p>
          <p class="subCate">{{depth2.name}}</p>
        </div>
      </div>
    </div>
    <!-- //서브 상단 -->
    <!-- 서브 탭메뉴 -->
    <div class="tabMenu">
      <div class="container">
        <router-link
          v-for="menu in tab"
          :key="menu.id"
          :to="menu.link"
          :class="{current: menu.id === depth2.id}">
          <span>{{menu.name}}</span>
        </router-link>
      </div>
    </div>
    <!-- //서브 탭메뉴 -->
  </div>
</template>

<script>
export default {
  name: 'MediaTop',
  computed: {
    depth1 () {
      return {
        id: this.page.id,
        name: this.page.name,
        link: this.page.link
      }
    },
    depth2 () {
      const role = this.$route.meta.role
      const subName = role === 'list'
        ? this.$route.meta.subName
        : role === 'view'
          ? this.$route.params.media
          : null
      const current = this.tab.find(x => x.id === subName)
      return {
        id: current ? current.id : this.depth1.id,
        name: current ? current.name : this.depth1.name,
        link: current ? current.link : this.depth1.link
      }
    }
  },
  data () {
    return {
      bg: '',
      page: {
        id: 'media',
        name: '미디어',
        link: '/media/all'
      },
      tab: [
        { id: 'all', name: '전체', link: '/media/all' },
        { id: 'notice', name: '공지사항', link: '/media/notice' },
        { id: 'news', name: '구단소식', link: '/media/news' },
        { id: 'jbMedia', name: 'JB 미디어', link: '/media/jbMedia' },
        { id: 'photo', name: '경기사진', link: '/media/photo' },
        { id: 'wallpaper', name: '바탕화면', link: '/media/wallpaper' },
        { id: 'video', name: '구단영상', link: '/media/video' }
      ]
    }
  }
}
</script>
