<template>
  <div class="memberContent hyundai">
    <div class="container">
      <div class="memberBox">
        <div class="topTitle">
          <div class="title">회원정보 수정 안내</div>
          <div class="subTitle" v-html="text" />
          <div class="formBtns">
            <button type="button" class="btn mobileFull large primary1" @click="goMyinfo">정보수정</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
export default {
  name: 'AlertPage',
  mounted() {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')
  },
  beforeDestroy () {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
  },
  props: [
      'state'
  ],
  data () {
    return {
      text : `
                  전북현대 방문을 환영합니다!
            <br><br>
            <span class="pcBreak">보다 더 만족하실 수 있는 서비스를</span>
            제공하기 위해 추가 정보를 입력받고 있습니다.<br><br>
            <span class="pcBreak">추가 정보를 입력하시면 보다</span>
              다양한 정보를 받아보실 수 있습니다.
              <br><br>
              <span class="pcBreak">티켓 예매 시 추가 정보를</span>반드시 입력하셔야만 티켓 예매가 가능합니다.`
    }
  },
  methods: {
    cancel () {
      window.alert('필수 추가 정보를 입력하지 않으시면, 티켓 예매 서비스를 이용하실 수 없습니다.')
    },
    goMyinfo () {
      this.$emit('changeState')
      return this.$router.push('/member/myinfo')
    }
  }
}
</script>
<style scoped lang="scss">
.cancelBtn {
  position: fixed;
  right: -20px;
  top: 0;
  .btnClose {
    pointer-events: auto;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 3px $white solid;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.5;
    &:hover{
      opacity: 0.8;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 31%;
      width: 24px;
      height: 2px;
      background: $white;
      transform-origin: 50% 50%;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
@media screen and (max-width: 756px) {
  .cancelBtn {
    right: 70px;
    top : 10px;
    .btnClose {
      width: 50px;
      height: 50px;
      border: 2px black solid;
      opacity: 0.2;
      &:before,
      &:after {
        background: black;
        top: 49%;
        left: 26%;
      }
    }
  }
}
</style>
