import Index from '@/views/Record.vue'

const record = [
  {
    path: '/record',
    name: 'record',
    component: Index,
    meta: {
      detectRollover: true, // GNB 롤오버 표시하지 않고 0번째 children으로 링크
      hiddenGnb: true // 스크롤시 gnb 숨김 (scrollTop > 0)
    }
  }
]

export default record
