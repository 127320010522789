<template>
  <div class="supporters">
    <div class="container">
      <div class="supportersList">
        <div
            v-for="item in supporters"
            :key="item.id"
            class="supportersItem">
          <a :href="item.link" target="_blank">
            <img :src="item.image" alt="">
            <p class="name">{{item.name}}</p>
            <!--<p class="subName">{{item.subName}}</p>-->
            <div class="desc" v-html="item.desc"></div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FanzoneSupporters',
  data () {
    return {
      supporters: [
        {
          id: 1,
          name: 'Mad Green Boys',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/madgreenboys.png',
          desc: '전북 서포터즈 연합으로 각 모임의 통합 활동공간입니다.',
          link: 'https://www.facebook.com/madgreenboys/'
        },
        {
          id: 2,
          name: '신록',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/shinrok.png',
          desc: '전북서포터 M.B.B 수도권 소모임 신록입니다.',
          link: 'https://band.us/band/547304'
        },
        {
          id: 3,
          name: '전북★사랑',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/jeonbuksarang.png',
          desc: '전북FC를 지지하는 독립군들의 모임. 독립군들을 위한 전북사랑★GreenHolic',
          link: 'http://naver.me/GoBfC97n'
        },
        {
          id: 4,
          name: '狂Crew',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/gwangcrew.png',
          desc: '적극적이고 열성적인 참여가 반드시 필요한 서포터 그룹 狂Crew',
          link: 'http://realmadcrew.com/'
        },
        {
          id: 5,
          name: 'ULTRAS CRAZY BOY',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/ULTRASCRAZYBOY.png',
          desc: '언제나 나의 두번째 심장 전북과 함께하는 지지자 그룹! ULTRAS CRAZY BOY',
          link: 'http://cafe.naver.com/jbucb'
        },
        {
          id: 6,
          name: '그린패밀리',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/greenfamily.png',
          desc: '전북FC를 사랑하는 가족이 모였습니다. 가족, 연인, 친구와 함께하는 가족 소모임 ‘그린패밀리’ 입니다!',
          link: 'http://cafe.daum.net/ourjbfc'
        },
        {
          id: 7,
          name: 'Green Admiral',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/greenadmiral.png',
          desc: '군산지역을 중심으로 전북FC를 꾸준히 사랑해온 모임',
          link: 'https://band.us/band/6521230'
        },
        {
          id: 8,
          name: 'Green Monster',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/greenmonster.png',
          desc: '하나된 단결과 강력한 의지! 그리고 새로운 움직임! 전북FC와 함께하는 그린몬스터입니다.',
          link: 'https://www.facebook.com/groups/jbultras/'
        },
        {
          id: 9,
          name: 'INVICTUS',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/invictus.png',
          desc: '전북FC를 지지하며 멋진 깃발 퍼포먼스와 열정의 섭팅을 하는 불멸. 천하무적의 의미 ☆INVICTUS☆',
          link: 'https://www.instagram.com/invictus__official/?utm_source=ig_profile_share&igshid=1v2snd9bf4cxs'
        },
        {
          id: 10,
          name: '12th_열두번째전사',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/12thwarriors.png',
          desc: '울트라스와 캐쥬얼 문화를 표방하는 울트라스 그룹. N석에서 함께 해요.',
          link: 'https://www.instagram.com/12th_warriors/'
        },
        {
          id: 11,
          name: '레알그린',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/realgreen.png',
          desc: '',
          link: 'http://cafe.naver.com/jeonbuk1994'
        },
        {
          id: 12,
          name: 'ALLIANCE★\nJEONBUK',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/alliancejeonbuk.png',
          desc: '',
          link: 'https://www.facebook.com/alliancejeonbuk/'
        },
        {
          id: 13,
          name: 'Greenyouth',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/greenyouth.png',
          desc: '',
          link: 'https://www.facebook.com/전북현대-청소년소모임-그린유스-1434880189944498/'
        },
        {
          id: 14,
          name: 'OH MY GREEN',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/omg_home_logo.png',
          desc: '',
          link: 'https://instagram.com/oh_my_green_?utm_source=ig_profile_share&igshid=i3tky36kwx1g'
        },
        {
          id: 15,
          name: '인연',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/inyeon.png',
          desc: '',
          link: 'https://www.instagram.com/jbfc_supporters/'
        },
        {
          id: 16,
          name: '에떼르노그린',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/eternogreen.png',
          desc: '',
          link: 'https://www.instagram.com/eternogreen_2022/'
        },
        {
          id: 17,
          name: '베르데',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/verde.png',
          desc: 'passion',
          link: 'https://instagram.com/verde._.official?utm_source=qr&igshid=NGExMmI2YTkyZg%3D%3D'
        },
        {
          id: 18,
          name: '낙원 :: ULTRAS PARADISO',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/ultras+paradiso.png',
          desc: '90분, 우리의 함성이 가득한 낙원으로',
          link: 'https://www.instagram.com/ultras.paradiso/'
        },
        {
          id: 19,
          name: '베르데 모멘토',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/verdemomento.png',
          desc: '피치 안과 밖을 녹색의 순간으로 물들이자',
          link: 'https://www.instagram.com/verde.momento?igsh=bDdlcnlpNjQwYTFi&utm_source=qr'
        },
        {
          id: 20,
          name: '그린하트',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/greenheart.png',
          desc: '',
          link: 'https://www.instagram.com/jbuk.always?igsh=ZHl5cGhicWs1dnAy&utm_source=qr'
        },
        {
          id: 21,
          name: '청춘그린',
          image: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/supporters/chungchun.jpg',
          desc: 'N석에서 같이 뛰는 당신도 승리자입니다.',
          link: 'https://www.instagram.com/chungchun.grexn_12?igsh=MWNrZzZ5bTRubWUzZg'
        }
      ]
    }
  }
}
</script>
