<template>
  <div>
    <Intro />
  </div>
</template>

<script>
import Intro from '@/components/Club/Intro'
export default {
  name: 'ClubView',
  components: {
    Intro
  }
}
</script>
