<template>
  <div class="container">
    <div class="accordion" v-if="state">
      <div class="accordionList">
        <div
          v-for="(item, i) in info"
          :key="i"
          class="accordionItem"
          :class="{open: item.open}">
          <div class="accordionHead"
            @click="item.open = !item.open">
            <p class="title">{{item.title}}</p>
            <button type="button" class="btnFold"></button>
          </div>
          <div class="accordionBody" v-html="item.detail"></div>
        </div>
      </div>
    </div>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 30px;" v-if="!state">
        <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
export default {
  name: 'TicketReservationInfo',
  data () {
    return {
      state: true, // 티켓 임시페이지 사용시 false
      info: [
        { title: '예매 오픈 일정', detail: `1. 일반 예매는 경기 5일 전 12시부터 예매 가능합니다.<br>2. 현장 예매는 경기 2시간 전부터 구매 가능합니다.`, open: true },
        { title: '예매 안내', detail: `1. 일반 예매는 경기 전반 45분까지 티켓 예매 가능합니다.<br>2. 현장 판매는 경기 후반 30분까지 티켓 구매 가능합니다.<br>3. 1경기 1인당 최대 10매까지 예매 가능합니다.`, open: false },
        { title: '취소/환불정책', detail: `1. 취소 수수료<br>- 예매 당일은 취소수수료 없습니다.<br>- 예매 익일 이후 취소수수료 10% 부과됩니다.<br>2. 부분 취소 가능합니다.<br>3. 취소는 경기 시작 전까지 가능합니다.<br>4. 좌석 변경은 불가능합니다.`, open: false }
      ]
    }
  }
}
</script>
