<template>
  <div class="policyPage">
    <Top />
    <div class="container pretendard">
      <router-view class="policyBox"></router-view>
    </div>
  </div>
</template>

<script>
import Top from '@/components/Policy/Top'

export default {
  name: 'PolicyView',
  components: {
    Top
  }
}
</script>
