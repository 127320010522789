<template>
  <div>
    <div class="sectionTitle line">경기장 위치</div>
    <div class="" v-if="state">내용</div>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 80px;">
      <div class="container">
        <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
      </div>
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
export default {
  name: 'TicketStadiumLocation',
  data () {
    return {
      state: false, // 티켓 임시페이지 사용시 false
    }
  },
}
</script>
