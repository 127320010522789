<template>
  <div class="introSection section4">
    <div class="sectionTitle">역대감독</div>
    <div class="swiperFrame">
      <div class="swiperContent">
        <swiper class="swiper coachSwiper"
          ref="coachSwiper"
          :options="swiperOption">
          <swiper-slide
            v-for="(item, index) in coach"
            :key="index"
            :data-coach="index"
            class="swiperItem">
            <div class="slideInner">
              <div class="frame">
                <div class="bg"><img :src="require(`@/assets/img/history/${item.bg}.png`)" alt=""></div>
                <div class="coach"><img v-if="item.img !== ''" :src="require(`@/assets/img/history/${item.img}.png`)" alt=""></div>
                <div class="info">
                  {{item.order}}
                  <div class="name" v-html="item.name"></div>
                  <div class="term">
                    {{item.from}}
                    <span v-if="item.to"> ~ </span>
                    <div v-if="item.to">{{item.to}}</div>
                  </div>
                </div>
                <div class="careerList">
                  <div class="label">주요기록</div>
                  <ul>
                    <li
                      v-for="(careerItem, index) in item.career"
                      :key="index">
                      {{careerItem}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="swiperNav">
      <div class="swiper-button-prev coachSwiperPrev" slot="button-prev"></div>
      <div class="swiper-button-next coachSwiperNext" slot="button-next"></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'HistoryCoach',
  props: [
    'active'
  ],
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    isActive () {
      return this.active && this.active.index === 4
    }
  },
  data () {
    return {
      coach: [
        { order: '제1대', name: '차경복', from: '1994. 11', to: '1996. 12', bg: 'bg_coach_1', img: 'img_coach_1', career: [
          '전북현대모터스 초대 감독',
          '1995 아디다스컵 4위',
          '1995 하이트배 코리안리그 전기 7위',
          '1995 하이트배 코리안리그 후기 4위',
          '1996 라피도컵 정규리그 전기 5위',
          '1996 라피도컵 정규리그 후기 7위',
          '1996 아디다스컵 7위'
        ]},
        { order: '제2대', name: '최만희', from: '1996. 12', to: '2001. 07', bg: 'bg_coach_2', img: 'img_coach_2', career: [
          '1997 라피도컵 정규리그 6위',
          '1998 코리아컵 B조 4위',
          '1998 현대컵 K-리그 6위',
          '1998 필립모리스코리아컵 7위',
          '1999 춘계실업축구연맹 준우승',
          '1999 바이코리아컵 K-리그 7위',
          '제5회 FA컵 우승',
          '2000 삼성디지털 K-리그 3위',
          '2000 대한화재컵 A조 3위',
          '2001 수퍼컵 준우승',
          '2001 FA컵 4위',
          '2001 포스코 K-리그 9위'
        ]},
        { order: '대행', name: '남대식', from: '2001. 07', to: '2001. 10', bg: 'bg_coach_3', img: 'img_coach_3', career: [
          '2001 수퍼컵 준우승'
        ]},
        { order: '제3대', name: '조윤환', from: '2001. 10', to: '2005. 06', bg: 'bg_coach_4', img: 'img_coach_4', career: [
          '2001 FA컵 4위',
          '2001 포스코 K-리그 9위',
          '2002 아시안컵 위너스컵 준우승',
          '2002 삼성 퍼브 K-리그 7위',
          '2002 아디다스컵 A조 4위',
          '2002 FA컵 8강',
          '2003 FA컵 우승',
          '2003 정규리그 5위',
          '2004 수퍼컵 우승',
          '2004 AFC 챔피언스리그 4강',
          '2004 하우젠컵 3위',
          '2004 정규리그 통합 5위',
          '2005 FA컵 우승',
          '2005 컵대회 12위',
          '2005 K-리그 통합 12위'
        ]},
        { order: '대행', name: '김형열', from: '2005. 06', to: '2005. 07', bg: 'bg_coach_5', img: 'img_coach_5'},
        { order: '제4대', name: '최강희', from: '2005. 07', to: '2011. 12', bg: 'bg_coach_6', img: 'img_coach_6', career: [
            '2005 FA컵 우승',
            '2005 컵대회 12위',
            '2005 K-리그 통합 12위',
            '2006 AFC 챔피언스리그 우승',
            '2006 슈퍼컵 준우승',
            '2006 FIFA 클럽월드컵 5위',
            '2007 AFC 챔피언스리그 8강',
            '2007 정규리그 8위',
            '2008 태국 빅4컵 우승',
            '2008 FA컵 8강',
            '2008 정규리그 4위',
            '2009 정규리그 1위',
            '2009 FA컵 4강',
            '2009 쏘나타 챔피언십 우승',
            '2010 쏘나타 K-리그 3위',
            '2010 FA컵 8강',
            '2010 포스코컵 준우승',
            '2010 AFC 챔피언스리그 8강',
            '2011 정규리그 1위',
            '2011 AFC 챔피언스리그 준우승',
            '2011 현대오일뱅크 K리그 챔피언십 우승',
        ]},
        { order: '대행', name: '이흥실', from: '2012. 01', to: '2012. 12', bg: 'bg_coach_7', img: 'img_coach_7', career: [
          '2012 현대오일뱅크 K리그 준우승',
          '2012 FA컵 8강'
        ]},
        { order: '대행', name: 'Fabio</br>Lefundes', from: '2012. 12', to: '2013. 06', bg: 'bg_coach_8', img: 'img_coach_8', career: [
          '2013 현대오일뱅크 K리그 3위',
          '2013 하나은행 FA컵 준우승',
          '2013 ACL 챔피언스리그 16강'
        ]},
        { order: '제4대', name: '최강희', from: '2013. 06', to: '2018. 12', bg: 'bg_coach_9', img: 'img_coach_9', career: [
            '2013 현대오일뱅크 K리그 3위',
            '2013 하나은행 FA컵 준우승',
            '2013 ACL 챔피언스리그 16강',
            '2014 현대오일뱅크 K리그 클래식 우승',
            '2014 제19회 하나은행 FA컵 4강',
            '2014 AFC 챔피언스리그 16강',
            '2015 현대오일뱅크 K리그 클래식 우승',
            '2015 제20회 KEB하나은행 FA컵 16강',
            '2015 AFC 챔피언스리그 8강',
            '2016 현대오일뱅크 K리그 클래식 준우승',
            '2016 제21회 KEB하나은행 FA컵 8강',
            '2016 AFC 챔피언스리그 우승',
            '2017 KEB하나은행 K리그 클래식 우승',
            '2017 제22회 KEB하나은행 FA컵 32강',
            '2018 KEB하나은행 K리그1 우승',
            '2018 제23회 KEB하나은행 FA컵 16강',
            '2018 AFC 챔피언스리그 8강'
        ]},
        { order: '제5대', name: 'José<br>Morais', from: '2019. 01', to: '2020. 12', bg: 'bg_coach_10', img: 'img_coach_10', career: [
          '2019 하나원큐 K리그1 우승',
          '2020 하나원큐 K리그1 우승',
          '2020 하나원큐 FA컵 우승'
        ]},
        { order: '제6대', name: '김상식', from: '2021. 01', to: '2023. 05',bg: 'bg_coach_11', img: 'img_coach_11', career: [
          '2021 하나원큐 K리그1 우승',
          '2022 하나원큐 K리그1 준우승',
          '2022 하나은행 FA컵 우승'
        ]},
        { order: '대행', name: '김두현', from: '2023. 05', bg: 'bg_coach_12', img: 'img_coach_12'},
        { order: '제7대', name: '단<br/>페르레스쿠', from: '2023. 06', to: '2024. 04', bg: 'bg_coach_13', img: 'img_coach_13', career: [
            '2023 하나원큐 K리그 4위',
            '2023 하나원큐 FA컵 준우승'
          ]},
        { order: '대행', name: '박원재', from: '2024. 04', to: '2024. 05', bg: 'bg_coach_14', img: 'img_coach_14'},
        { order: '제8대', name: '김두현', from: '2024. 05 ~', bg: 'bg_coach_15', img: 'img_coach_15'},
      ],
      swiperOption: {
        loop: true,
        slidesPerView: 5.4,
        spaceBetween: 0,
        centeredSlides: false,
        navigation: {
          nextEl: '.coachSwiperNext',
          prevEl: '.coachSwiperPrev'
        }
      }
    }
  },
  methods: {
  }
}
</script>
<style>
.nameFont {
  font-size: 28px;
}
</style>
