<template>
  <div class="introSection section9">
    <div class="sectionTitle">우승컵</div>
    <div class="summary">
      <div
        v-for="(item, index) in summary"
        :key="index"
        class="item">
        <div class="name">{{item.name}}</div>
        <div class="count">{{item.count}}</div>
        TROPHIES
      </div>
    </div>
    <div class="swiperNav">
      <div class="swiper-button-prev cupSwiperPrev" slot="button-prev"></div>
      <div class="swiper-button-next cupSwiperNext" slot="button-next"></div>
    </div>
    <div class="cupSwiper pretendard">
      <swiper class="swiper"
        ref="cupSwiper"
        :options="swiperOption">
        <swiper-slide
          v-for="(item, index) in cups"
          :key="index"
          :data-img="item.img"
          class="swiperItem">
          <div class="slideInner">
            <a href="javascript:void(0)">
              <div class="img"><img :src="require(`@/assets/img/history/${item.img}.png`)" alt=""></div></a>
            <div class="name">{{item.name}}</div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: 'HistoryCup',
  props: [
    'active'
  ],
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    const _vm = this
    return {
      summary: [
        { name: 'K LEAGUE', count: '09' },
        { name: 'FA CUP', count: '05' },
        { name: 'AFC 챔피언스리그', count: '02' }
      ],
      cups: [
        { name: '2022 하나원큐 FA컵', img: 'img_cup_17' },
        { name: '2021 K리그1', img: 'img_cup_16' },
        { name: '2020 K리그1', img: 'img_cup_15' },
        { name: '2020 하나은행 FA컵', img: 'img_cup_14' },
        { name: '2019 K리그1', img: 'img_cup_13' },
        { name: '2018 K리그1', img: 'img_cup_12' },
        { name: '2017 K리그 클래식', img: 'img_cup_11' },
        { name: '2016 AFC 챔피언스리그', img: 'img_cup_10' },
        { name: '2015 K리그 클래식', img: 'img_cup_9' },
        { name: '2014 K리그 클래식', img: 'img_cup_8' },
        { name: '2011 K리그', img: 'img_cup_7' },
        { name: '2009 쏘나타 챔피언십', img: 'img_cup_6' },
        { name: '2009 K리그', img: 'img_cup_5' },
        { name: '2006 AFC 챔피언스리그', img: 'img_cup_4' },
        { name: '2005 FA컵', img: 'img_cup_3' },
        { name: '2004 K-리그 수퍼컵', img: 'img_cup_2' },
        { name: '2003년 하나은행 FA컵', img: 'img_cup_1' }
      ],
      swiperOption: {
        loop: true,
        loopAdditionalSlides : 1,
        slidesPerView: window.innerWidth >= 768 ? 3.3 : 1.5,
        spaceBetween: 0,
        centeredSlides: true,
        navigation: {
          nextEl: '.cupSwiperNext',
          prevEl: '.cupSwiperPrev'
        },
        on: {
          click: function (e){
            e.preventDefault()
            _vm.picView();
          }
        }
      }
    }
  },
  created() {
  },
  methods: {
    picView () {
      const activeImg = document.querySelector('.cupSwiper .swiper-slide-active').getAttribute('data-img');
      this.$store.commit('isPic', activeImg)
    }
  }
}
</script>
