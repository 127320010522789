<template>
  <div :class="{ticketReservation: $route.name === 'ticketReservationIntro' || $route.name === 'ticketReservationHistory'}">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'TicketReservation'
}
</script>
