<template>
  <div>
    <div class="detailTitle">득점순위</div>
    <!-- 리스트 정보 -->
    <div class="listInfo">
      <p class="updateat btnFold" style="color: black">하나원큐 K리그1 {{currentYear}}</p>
      <div class="sort">
        <span class="infoItem">
          <select v-model="currentYear" class="select">
            <option value="">년도선택</option>
            <option
                v-for="(y, i) in year"
                :key="i"
                :value="y.id">
              {{ y.name }}
            </option>
          </select>
        </span>
      </div>
    </div>
    <!-- //리스트 정보 -->
    <div class="rankingTable recordTable smallTable">
      <table>
        <colgroup>
          <col style="width: 8%;">
          <col style="width: 30%;">
          <col style="width: 12%;">
          <col style="width: 12%;">
          <col style="width: 12%;">
          <col style="width: 42%;">
        </colgroup>
        <thead>
        <tr>
          <th>순위</th>
          <th>선수이름/배번</th>
          <th>득점</th>
          <th>경기</th>
          <th>경기당 득점</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(data, i) in rankData" :key="i">
          <td class="bold">{{i+1}}</td>
          <td class="player bold center">
            <div class="img">
              <img :src="data.imgPath" @error="nullImage">
            </div>
            <div class="info">
              <div class="name">{{data.name}}</div>
              <div class="no">NO.{{data.uniform_number}}</div>
            </div>
          </td>
          <td class="bold">{{data.qty}}득점</td>
          <td>{{data.work_qty}}경기</td>
          <td>경기당 {{data.qtyperwork}} 득점</td>
          <td class="btns right">
<!--            <a href="#" class="btn primary2">영상</a>-->
            <router-link :to="{path:`/team/player/${data.kl_player_id}`}" class="btn whiteLightgrey">선수별 페이지</router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="noData" v-if="rankData.length === 0">데이터가 없습니다</div>
  </div>
</template>

<script>
import http from '@/api/index'
import imgPath from "@/library/imgPath"


export default {
  name: 'MatchRecordGoal',
  created() {
    const paramsYear = this.$store.state.params && this.$store.state.params.year
    const thisYear = this.$store.state.thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    this.currentYear = paramsYear || thisYear
  },
  watch: {
    currentYear() {
      this.init('year')
    }
  },
  data() {
    return {
      currentYear: '',
      yearStart: 2022, // 필터 시작 년도
      year: [],
      rankData:[],
    }
  },
  methods: {
    async init(type) {
      const params = {
        params: {
          year: ''
        }
      }
      if (type === 'year') {
        params.params.year = this.currentYear
      }
      const rank = await http.get('/match/player_rank/?type=goal', params)
      rank.data.data.map(row => {
        row.imgPath = imgPath.set140px(row.kl_player_id)
      })
      this.rankData = rank.data.data
    },
    nullImage (e) {
      e.target.src = imgPath.imgPath.player_null;
    }
  }
}
</script>
<style>
.center{
  justify-content: center;
}
</style>


