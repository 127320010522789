<template>
  <div class="sectionBox">
    <div class="container">
      <div class="sectionTitle">티켓 예매내역</div>
      <div class="noData" v-if="state">
        마이페이지 > 구매내역 > 티켓 예매내역에서 확인 가능하십니다.
        <div><router-link to="/mypage/pay/ticket/1" class="btn primary2 small">티켓 예매내역</router-link></div>
      </div>
      <!-- 시즌 준비중 -->
      <div class="indexBox waitingSeason" style="margin-top: 50px;" v-if="!state">
        <div class="container">
          <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
        </div>
      </div>
      <!-- //시즌 준비중 -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'TicketReservationHistory',
  data () {
    return {
      state: true, // 티켓 임시페이지 사용시 false
    }
  },
}
</script>
