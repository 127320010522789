<template>
  <div class="main hyundai">
    <!-- 메인슬라이더 -->
    <Slider />
    <!-- //메인슬라이더 -->
    <Match />
    <Video />
    <Feeds />
    <Shop />
    <Application />
    <Sponsor />
    <LayerPopup />
  </div>
</template>

<script>
import Slider from '@/components/Main/Slider'
import Match from '@/components/Main/Match'
import Feeds from '@/components/Main/Feeds'
import Video from '@/components/Main/Video'
import Application from '@/components/Main/Application'
import Sponsor from '@/components/Main/Sponsor'
import LayerPopup from '@/components/Util/layerPopup.vue'
import Shop from '@/components/Main/Shop.vue'

export default {
  name: 'MainView',
  components: {
    LayerPopup,
    Slider,
    Match,
    Feeds,
    Video,
    Application,
    Sponsor,
    Shop
  },
  computed: {
  },
  created () {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
