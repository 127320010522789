import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMasonry from 'vue-masonry-css'
import VueMobileDetection from "vue-mobile-detection";

import './assets/css/reset.css'
import './assets/css/style.scss'

Vue.config.productionTip = false

if (process.env.NODE_ENV === "production") { // production에서만 사용할 수 없도록
  console = window.console || {};
  console.log = function no_console() {}; // console log 막기
  console.warn = function no_console() {}; // console warning 막기
  console.error = function () {}; // console error 막기
}


Vue.use(VueMasonry)
Vue.use(VueMobileDetection);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
