<template>
  <div>
    <div class="listInfo">
      <div class="title hiddenMobile">포인트 내역</div>
      <div class="sort">
        <div class="filter">
          <!-- filter -->
          <div class="filterItem shortcut">
            <button
              v-for="s in shortcuts"
              :key="s.id"
              type="button"
              @click="setTerm(s.id)"
              :class="{current: s.id === shortcut}">
              <span>{{s.name}}</span>
            </button>
          </div>
          <!-- //filter -->
          <!-- datepicker -->
          <div class="filterItem datePicker hiddenMobile">
            <date-picker v-model="term.from" valueType="format"></date-picker> ~
            <date-picker v-model="term.to" valueType="format"></date-picker>
            <button type="button" class="btn whiteLightgrey2 auto btnSearch" @click="search">검색</button>
          </div>
          <!-- //datepicker -->
        </div>
      </div>
    </div>
    <div class="dataTableForPoint">
      <div>
        <table v-if="showData">
          <thead>
            <tr>
              <th>일시</th>
              <th>포인트 구분</th>
              <th>포인트 내용</th>
              <th>증감</th>
              <th>잔액</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in pointList">
              <td class="date">{{ row.date }}</td>
              <td class="no">{{ row.type_ }}</td>
              <td>{{ row.title }}</td>
              <td><span class="no2">{{ row.type_ }}</span>{{row.detail}}{{comma(row.point)}}</td>
              <td class="total">{{row.totalPoint}}</td>
            </tr>
          </tbody>
        </table>
        <div class="noData" v-else>포인트 내역이 없습니다</div>
        <Pagination
          :perPage="6"
          :pages="pages"
          @change="getData"
          v-show="showData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Pagination from '@/components/Util/Pagination'
import regExp from '@/common/regExp'
import http from '@/api/mypageApi'

export default {
  name: 'MypagePointHistory',
  components: {
    DatePicker,
    Pagination
  },
  computed: {
    showData () {
      return Object.keys(this.pointList).length
    }
  },
  created() {
    this.pages = 0;
    const termFrom = dayjs().startOf('year').format()
    const termTo = dayjs().format()
    this.term = {
      from: termFrom,
      to: termTo
    }

    let page = this.$route.params.page || '1';
    this.getData(page);
  },
  data () {
    return {
      shortcut: 'thisYear',
      shortcuts: [
        { id: 'thisMonth', name: '이번달' },
        { id: 'lastMonth', name: '지난달' },
        { id: 'thisYear', name: '올해' },
        { id: 'lastYear', name: '지난해' }
      ],
      term: {
        from: '',
        to: ''
      },
      pointList: [],
      totalPoint: 0
    }
  },
  methods: {
    getData (page) {
      let range = `${dayjs(this.term.from).format('YYYY-MM-DD')},${dayjs(this.term.to).format('YYYY-MM-DD')}`
      let query = `?&page=${page}&range=${range}&limit=6`

      http.get(`/point${query}`).then(res => {
        let data = res.data.data;
        data.list.map(row=>{
          row.date = dayjs(row.cdate).format('YYYY.MM.DD(dd) HH:mm');
          row.type_ = row.type === 'plus' ? '포인트 증가(+)': '포인트 감소(-)';
          row.detail = row.type === 'plus' ? `+`: `-`;
          row.totalPoint = this.comma(row.remainPoint)
        })
        this.totalPoint = data.point
        this.pointList = data.list;
        this.pages = data.total;
      }).catch(err => {
        console.log(err);
      })
    },
    setTerm (shortcut) {
      const term = this.term
      switch (shortcut) {
        case 'thisMonth':
          term.from = dayjs().startOf('month').format()
          term.to = dayjs().format()
          break
        case 'lastMonth':
          term.from = dayjs().add(-1, 'month').startOf('month').format()
          term.to = dayjs().add(-1, 'month').endOf('month').format()
          break
        case 'thisYear':
          term.from = dayjs().startOf('year').format()
          term.to = dayjs().format()
          break
        case 'lastYear':
          term.from = dayjs().add(-1, 'year').startOf('year').format()
          term.to = dayjs().add(-1, 'year').endOf('year').format()
          break
      }
      this.shortcut = shortcut
      this.getData(1);
    },
    search () {
      if(!this.term.from || !this.term.to) {
        alert('날짜를 확인해주세요');
        return false;
      }
      this.getData(1);
    },
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    }
  }
}
</script>

<style lang="scss" scoped>
.no2 {
  display: none;
}
@media screen and (max-width: 767px) {
  tbody {
    .date {
      color: $grey3;
    }
    .no {
      display: none;
    }
    .no2 {
      display: inline-block;
      vertical-align: middle;
      color: $grey3;
      &:after {
        content: "|";
        margin: 0 8px;
        color: $lightgrey1;
      }
    }
    td.total {
      display: block;
      margin-top: 20px;
      padding: 1.1em 1em;
      color: $white;
      border-radius: 4px;
      background-color: $primary2;
      &:after {
        content: " 포인트";
      }
    }
  }
}
</style>

