<template>
  <div class="container" :class="{fieldAll: position === 'all'}">
    <!-- 리스트 정보 -->
    <div v-if="state" class="listInfo">
      <div class="sort">
        <span class="infoItem">
          <select v-model="sort" class="select">
            <option value="uniform_number">번호순</option>
            <option value="name">이름순</option>
          </select>
        </span>
      </div>
    </div>
    <!-- //리스트 정보 -->
    <div v-if="state" class="field">
      <div
        v-for="(group, index) in proTeamList"
        :key="index"
        :data-position="group.position"
        class="teamGroup">
        <div class="posTitle">{{ positionName(group.position) }}</div>
        <div class="teamList">
          <div
            v-for="(item, index) in group.list"
            :key="index"
            class="teamItem"
            @click="detailPlayer(item.kl_player_id)">
            <div class="num">
              <img v-for="(num, idx) in imgToNum(item.uniform_number, 'number_primary')" :key="idx" :src="num" class="imgNum" />
            </div>
            <div class="name" v-html="item.name"></div>
            <div class="nameEn" v-html="item.name_eng "></div>
            <div class="pos"><span class="fieldPos">{{group.position}}</span><span class="gridPos">{{ positionName(group.position) }}</span></div>
            <div v-if="playerImg(item.kl_player_id)" class="img">
              <img :src="playerImg(item.kl_player_id)" :alt="item.name">
            </div>
            <div v-else class="img">
              <img src="@/assets/img/player/player_nonePic.png" :alt="item.name">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="waitingSeason"><img src="@/assets/img/bg_waitingSeason.png" /> </div>
  </div>
</template>

<script>
import http from "@/api/index"
import regExp from '@/common/regExp'
import imgPath from "@/library/imgPath"

export default {
  name: 'proTeam',
  props: [
    'position'
  ],
  computed: {
    proTeamList () {
      const position = this.position.toLowerCase()
      const sort = this.sort
      const filteredList = this.list.filter(x => position === 'all' ? x : x.position.toLowerCase() === position)
      function sortBy (list, sort) {
        return list.map(x => {
          return {
            ...x,
            list: x.list.sort((a, b) => {
              return a[sort] > b[sort] ? 1 : a[sort] < b[sort] ? -1 : 0
            })
          }
        })
      }
      return sortBy(filteredList, sort)
    }
  },
  watch: {
    position (position) {
      // 전체이면 숫자순
      if (position === 'all') {
        this.sort = 'uniform_number'
      }
    }
  },
  created () {
    this.init()
  },
  data () {
    return {
      state : true,
      sort: 'uniform_number',
      list: [],
      pos: {
        'GK': 'GOALKEEPER',
        'DF': 'DEFENDER',
        'MF': 'MIDFIELDER',
        'FW': 'FORWARD'
      }
    }
  },
  methods: {
    positionName (pos) { // GK -> GOALKEEPER
      pos = pos.toUpperCase()
      return this.pos[pos]
    },
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    imgToNum (num, path) { // 등번호 등 숫자를 하나씩 쪼개 이미지로 리턴
      const nums = `${this.comma(num)}`.split('')
      function getImg(n) {
        return require(`@/assets/img/${path || 'number'}/${n === ',' ? 'comma' : n}.png`)
      }
      return nums.map(x => getImg(x))
    },
    playerImg (playerId) {
      // try {
      //   return imgPath.setProteamDetail(playerId)
      // } catch (e) {
      //   return null
      // }
      const image = new Image()
      image.src = imgPath.setProteamDetail(playerId)
      image.onerror = () => {
        return null
      }
      return image.src
    },
    async init () {
      const proTeam = await http.get('/player')
      const list = proTeam && proTeam.data && proTeam.data.data ? proTeam.data.data : null
      if (list) {
        Object.keys(list).forEach((key) => {
          this.list = this.list.concat({position: key, list: list[key]}) // list[key] = 포지션별 선수 리스트(array)
        })
      }

      this.list[3].list.map(row => {
        if(row.idx === 359) {
          row.name = `안드레</br>루이스`
        }})

    },
    detailPlayer (num) {
      this.$router.push({
        name: 'playerDetail',
        params: {
          num: num
        }
      })
    }
  }
}
</script>
