import Index from '@/views/Team.vue'
import Proteam from '@/components/Team/Proteam.vue'
import ProteamDetail from '@/components/Team/ProteamDetail.vue'
import Staff from '@/components/Team/Staff.vue'
import Youth from '@/components/Team/Youth.vue'
import U18 from '@/components/Team/U18.vue'
import U15 from '@/components/Team/U15.vue'
import U12 from '@/components/Team/U12.vue'
import StaffHeadCoach from "@/components/Team/StaffHeadCoach"
import StaffCoaching from "@/components/Team/StaffCoaching"
import StaffCoachingB from "@/components/Team/StaffCoachingB"
import StaffSupport from "@/components/Team/StaffSupport"

const team = [
  {
    path: '/team',
    name: 'team',
    component: Index,
    redirect: {
      name: 'teamPlayer',
      params: {
        position: 'all'
      }
    },
    children : [
      {
        path: 'proteam/:position',
        name: 'teamPlayer',
        component: Proteam,
        params: {
          position: 'all',
        },
        meta: {
          team: 'proteam'
        },
        props: true
      },
      {
        path: 'staff',
        name: 'teamStaff',
        component: Staff,
        redirect: {
          name: 'headcoach',
        },
        children: [
          {
            path: 'headcoach',
            name: 'headcoach',
            component: StaffHeadCoach,
            meta: {
              team: 'staff',
              detail: 'headcoach'
            }
          },
          {
            path: 'coachingstaff',
            name: 'coachingstaff',
            component: StaffCoaching,
            meta: {
              team: 'staff',
              detail: 'coachingstaff'
            }
          },
          {
            path: 'coachingstaffB',
            name: 'coachingstaffB',
            component: StaffCoachingB,
            meta: {
              team: 'staff',
              detail: 'coachingstaffB'
            }
          },
          {
            path: 'supportstaff',
            name: 'supportstaff',
            component: StaffSupport,
            meta: {
              team: 'staff',
              detail: 'supportstaff'
            }
          },
        ]
      },
      {
        path: 'youth',
        name: 'teamYouth',
        component: Youth,
        meta: {
          team: 'youth'
        }
      },
      {
        path: 'u18/:position',
        name: 'teamU18',
        component: U18,
        params: {
          position: 'all',
        },
        meta: {
          team: 'u18'
        },
        props: true
      },
      {
        path: 'u15/:position',
        name: 'teamU15',
        component: U15,
        params: {
          position: 'all',
        },
        meta: {
          team: 'u15'
        },
        props: true
      },
      {
        path: 'u12/:position',
        name: 'teamU12',
        component: U12,
        params: {
          position: 'all',
        },
        meta: {
          team: 'u12'
        },
        props: true
      },
    ]
  },
  {
    path: '/team/player/:num',
    name: 'playerDetail',
    component: ProteamDetail,
    params: {
      num: '1'
    },
    meta: {
      team: 'proteam'
    },
    props: true
  },
]

export default team
