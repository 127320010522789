<template>
  <div style="margin-bottom:300px">

  </div>
</template>

<script>
import http from '@/api/index'

export default {
  name: 'WebviewBridge',
  data() {
    return {
      info: {},
    }
  },
  created() {

    setTimeout(() => {
      this.init()
    }, 200)

  },
  methods: {
    init() {
      this.info.token = this.$route.query.token
      this.info.redirectUrl = this.$route.query.redirectUrl
      if (this.$route.query.game_id  && this.$route.query.id) {
        this.info.redirectUrl = `${this.$route.query.redirectUrl}&game_id=${this.$route.query.game_id}&id=${this.$route.query.id}`
      }


      if(!this.info.redirectUrl) {
        return this.$router.push('/')
      }

      if(this.$store.state.user.encrypted_id || !this.info.token) {
        return this.$router.push(this.info.redirectUrl)
      }

      console.log('webviewBridge init')
      http.post('/user/conversion_token', this.info)
        .then(res => {

          // 로그인처리
          let payload = res.data.data
          payload.state = true
          this.$store.commit('login', payload)

        })
        .catch(err => {
          console.log('ERROR', err)
          //alert('로그인이 필요한 서비스입니다.')
          //return this.$router.push('/member/signin')
        })
        .finally(() => {
          return this.$router.push(this.info.redirectUrl)
        })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
