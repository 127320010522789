<template>
  <div>
    <div class="sectionTitle line">시즌권 구매안내</div>
    <!--      <div class="sectionTitle">티켓 정책</div>-->
    <div class="seasonInfoCard">
      <div class="title">
        JEONBUK HYUNDAI
        <p>2024 <span class="mobileBreak">SEASON CARD</span><span class="mobileBreak"></span></p>
      </div>
      <div class="close">2023년 시즌권자 대상 선 구매 기간<br><strong>1월 11일(목) 정오 12시 ~ 13일(토) 18시</strong></div>
      <div class="close">전체 대상 판매 기간<br><strong>1월 14일(일) 정오 12시 ~ 21일(일) 18시</strong></div>
      <Countdown
          v-if="isOnTime"
          :endDate="time"
          :center="true"
          :label="true"
          :labelColor="'rgba(255,255,255,0.7)'"/>
      <div class="btns">
        <button type="button" class="btn yellow large" @click="purchase" :disabled="!isOnTime" v-if="isOnTime">구매하기</button>
      </div>
    </div>
    <div class="indexBox waitingSeason" style="margin-top: 80px;">
      <div class="container">
        <img src="@/assets/img/img_ticket_season2024_1.jpg" alt="준비중입니다"/>
        <img src="@/assets/img/img_ticket_season2024_2.jpg" alt="준비중입니다"/>
        <img src="@/assets/img/img_ticket_season2024_3.jpg" alt="준비중입니다"/>
        <img src="@/assets/img/img_ticket_season2024_4.jpg" alt="준비중입니다"/>
        <img src="@/assets/img/img_ticket_season2024_5.jpg" alt="준비중입니다"/>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from '@/components/Util/Countdown'
import dayjs from "dayjs"
import { reservePopup } from '@/library/ticketlink'
import router from '@/router'

export default {
  name: 'TicketSeasonInfo',
  components: {
    Countdown
  },
  computed : {
    time(){
      const today = dayjs().toISOString()
      if(dayjs(today).isBefore('2024-01-11T03:00:00.000Z')){
        return '2024-01-11T03:00:00.000Z'
      } else {
        return '2024-01-21T09:00:00.000Z'
      }
    },
  },
  created () {
  },
  mounted() {
    this.getEncryptedId()
    setInterval(() => {
      this.isOnTime = this.checkIsOnTime()
    }, 500)
  },

  data () {
    return {
      datetime: null,
      encrypted_id: '',
      isOnTime: this.checkIsOnTime()
    }
  },
  methods : {
    getEncryptedId() {
      if(this.$store.state.user.encrypted_id !== null) {
        this.encrypted_id = this.$store.state.user.encrypted_id
      } else {
        this.encrypted_id = ''
      }
    },
    checkIsOnTime() {
      const today = dayjs().toISOString();
      return dayjs(today).isBefore('2024-01-21T09:00:00.000Z');
    },
    purchase() {
      this.getEncryptedId()
      if(this.encrypted_id !== '') {
        const url = reservePopup(this.encrypted_id, "pc");
        window.gtag('event','btn_구매하기',{'event_category':'시즌권', 'event_label':this.$store.state.user.id})
        window.open(url,'tl', 'width=1010, height=600, left=100, top=100, scrollbars=yes')
      } else {
        alert("통합회원 로그인 후 이용해주세요.")
        const redirectUrl = router.currentRoute.path;
        router.push({name: 'signin', query: {redirectUrl: redirectUrl}});
      }
    }
  },
}
</script>
