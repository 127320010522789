export default {
    name: '2017년 12월 18일',
    html: `
<div>
  <p class="policyIndex" data-index="0" data-label="제1조 총칙">제1조 총칙</p>
  <div class="policyContent">'전북현대모터스FC (주)'(이하 '회사'라 함)은 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 이용자의 개인정보 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 개인정보 처리방침을 두고 있습니다.</div>
  
  <p class="policyIndex" data-index="1" data-label="개인정보의 처리 목적, 항목 및 보유기간">개인정보의 수집 및 이용목적</p>
  <div>
  1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 컨텐츠 제공<br>
2) 회원 관리<br>
 회원제 서비스 이용에 따른 본인확인 , 개인 식별 , 불량회원의 부정 이용 방지와 비인가 사용 방지 , 가입 의사 확인 , 연령확인 , 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인 , 불만처리 등 민원처리 , 고지사항 전달 <br>
3) 마케팅 및 광고에 활용 <Br>
 이벤트 등 광고성 정보 전달 , 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계, 타겟 마케팅 및 개인 맞춤 서비스 제공<br><br>
</div>
  
    <p class="policyIndex" data-index="2" data-label="수집하는 개인정보의 항목 및 수집방법">수집하는 개인정보의 항목 및 수집방법</p>
    <div class="policyContent">
1) 수집항목<br>

- 필수항목 : 이름, 로그인ID, 비밀번호, 연락처, 휴대전화번호, 이메일, 주소, 생년월일, SMS 수신여부, 메일 수신여부, 가입동기 , 본인인증결과값(DI) <br>
- 선택항목 : 직업, 프로필사진 , 좋아하는 선수, 나도한마디(전북현대모터스 축구단에 바라는 점)<br>

※ 개인정보의 필수/선택적 수집 항목에 대한 구분은 개인정보를 수집하는 동의 양식(홈페이지, 서면, 전화, 팩스 등)에 고객님이 확인하기 쉬운 방법으로 표기하겠습니다.<br>

※이벤트 및 추가적인 개인정보를 수집시, 수집항목을 필수/선택으로 구분하며 , 해당페이지 및 서면 등에서 개인정보의 수집 목적 보유 기간 등을 고객님에게 고지하고 추가적인 동의를 받겠습니다.<br>

2) 개인정보 수집방법 : 서면/전화/팩스를 통한 회원가입, 이벤트 응모, 제휴사로부터의 제공<Br>

※ 위 개인정보는 수집 당시 개인정보의 내용은 물론 사후 변경된 개인정보의 내용을 보유하는 경우도 포함합니다. <Br><Br>
  </div>
  
      <p class="policyIndex" data-index="3" data-label="개인정보의 보유 및 이용기간">개인정보의 보유 및 이용기간</p>
    <div class="policyContent">
1) 회사는 원칙적으로 고객님의 개인정보를 수집한 때로부터 개인정보 수집 및 이용에 관한 동의 목적을 달성할 때까지(회원탈퇴시까지) 기간 동안에 한하여 개인정보룰 보유 및 이용하며, 개인정보 수집 및 이용목적이 달성된 경우에는 해당 정보를 지체없이 파기합니다.<br>
2) 단, 상법 등 관계법령의 규정에 의하여 보존할 의무가 있는 경우 회사는 고객님의 개인정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 다음과 같습니다.<Br>
  ① 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)<br>
  ② 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)<br>
  ③ 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)<br>
  ④ 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의 이용 및 보호에 관한 법률)<br>
  ⑤ 서비스이용기록, 접속로그, 접속IP정보 : 3개월 (통신비밀보호법)<br>

※ '개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.
  </div>
  
        <p class="policyIndex" data-index="4" data-label="개인정보의 제3자 제공">개인정보의 제3자 제공</p>
    <div class="policyContent">
1) 회사는 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<Br>
  ① 고객님의 사전동의가 있는 경우<br>
  ② 법령의 특별한 규정에 의한 경우<Br>
  ③ 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br><Br>
  </div>
  <div class="policyTable">
    <table>
      <thead>
        <tr>
          <th>위탁받는 자(수탁자)</th>
          <th>위탁하는 업무의 내용</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>스포탈코리아</td>
          <td>홈페이지 관리 및 시스템 관리</td>
        </tr>
        <tr>
            <td>SIL</td>
            <td>오프라인 시즌권 판매 및 발급 업무</td>
        </tr>
      </tbody>
    </table>
  </div>
  
          <p class="policyIndex" data-index="5" data-label="개인정보의 파기절차 및 방법">개인정보의 파기절차 및 방법</p>
  <div class="policyContent">
회사는 다른 법률에 따라 개인정보를 보존하여야 하는 경우가 아닌 한, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기하는 것을 원칙으로 합니다.<br>
파기절차 및 방법은 다음과 같습니다.<Br>

1) 파기절차 <br>
  ① 고객님이 회사에 제공한 개인정보는 수집 · 이용 목적이 달성된 후에는 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.<br>
  ② 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는 목적 이외의 다른 목적으로 이용되지 않습니다.<Br>
2) 파기방법 <Br>
  ① 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br>
  ② 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br><br>

<b>고객 및 법정대리인의 권리와 그 행사방법</b><br>

1) 고객님 또는 법정대리인(만 14세 미만의 아동인 경우)은 회사에 대하여 언제든지 개인정보 수집 · 이용 · 제공등의 동의를 철회(가입해지) 할 수 있습니다.<Br>
2) 회사에 대하여, 고객님은 자신의 개인정보를 법정대리인은 만 14세 미만의 아동의 개인정보를 각 열람 · 제공 및 오류를 정정하도록 요구할 수 있습니다.<Br>
3) 고객님은 위와 같은 권리를 온라인에서는 홈페이지에 접속하여 본인 확인 절차를 거친 후 개인정보관리 메뉴에서 행사 가능하며 오프라인에서는 서면, 전화 또는 이메일을 통해 고객센터 또는 회사 개인정보 보호책임자에게 연락하는 방법으로 행사할 수 있습니다.<Br>
4) 고객님이 개인정보 오류정정을 요구하신 경우, 회사는 오류정정을 완료하기 전까지 당해 개인정보를 이용 · 제공하지 않으며, 이미 제3자에게 당해 개인정보를 제공한 경우에는 제3자에게 지체없이 통지하여 오류정정이 이루어지도록 하고 있습니다.<Br>
5) 고객님 또는 법정대리인이 동의철회(가입해지)한 경우, 회사는 지체없이 파기하는 것을 원칙으로 하나 관계법령에서 의무적으로 보유하도록 한 경우에는 개인정보처리방침 ‘개인정보의 보유 및 이용기간’에 따라 처리하고, 반드시 필요한 경우에만 열람 또는 이용이 가능하도록 조치하고 있습니다.<Br><Br>
  </div>

  <p class="policyIndex" data-index="6" data-label="개인정보의 안전성 확보 조치에 관한 사항">개인정보의 안전성 확보 조치에 관한 사항</p>
  <div class="policyContent">
※ 개인정보의 기술적/관리적 보호 대책<br>
회사는 고객의 개인정보를 처리함에 있어 개인정보의 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 아래와 같은 기술적/관리적 대책을 적용하고 있습니다.<br>

1) 기술적 조치<br>
  ① 고객정보의 암호화<br>
 소중한 고객의 개인정보는 DB내에 암호화되어 저장되어 외부 침입에 의해 유출되더라도 고객의 개인정보를 활용할수 없도록 하고 있습니다.<br>

  ② 통신 구간 암호화<br>
 고객이 홈페이지를 통한 회원가입 및 로그인시 고객정보를 입력하여 전달하는 구간에 대해 SSL을 통해 고객의 정보가 안전하게 전송되도록 조치하고 있습니다.<br>
  ③ 보안솔루션의 설치<br>
 서비스 제공 및 고객의 정보를 안전하게 관리하기 위하여 개인정보처리시스템에 대해 백신프로그램의 설치, 주기적인 업데이트 및 정기점검을 수행하고 있으며, DB암호화 솔루션 및 화면캡처방지 솔루션을 적용하고 있습니다. 또한 해킹 등의 외부침입을 대비하여 침입차단/탐지시스템을 설치하고 통합보안관재센터를 통해 해킹 및 외부침입에 대비하여 계속적으로 모니터링을 수행하고 있습니다.<br>

2) 관리적 조치<br>
  ① 개인정보관리체계 수립<br>
 개인정보를 안전하게 관리하기위하여 회사 내부적으로 개인정보 관리체계를 수립하여 운영하고 있습니다.<br>
  ② 개인정보보호 위원회 운영<br>
 회사의 개인정보보호를 위한 위원회를 구성하여 연2회 이상 위원회 회의를 개최하고 개인정보관리체계의 운영 및 개인정보보호 이슈 등의 사항에 대하여 개선하고 바로잡기 위한 노력을 기울이고 있습니다.<br>
  ③ 개인정보 취급자 관리<br>
 고객의 개인정보를 처리하는 개인정보처리자를 대상으로 개인정보보호 서약서를 제출받고, 연2회 이상의 개인정보보호 교육을 수행하여 고객정보의 중요성과 안전하게 관리하도록 하고 있습니다. 또한 개인정보처리자의 권한 관리를 통하여 불필요한 고객의 개인정보에 대한 접근과 노출을 최소화하고 있습니다.<br><br>
  </div>

  <p class="policyIndex" data-index="7" data-label="개인정보의 자동 수집 장치의 설치 · 운영 및 그 거부에 관한 사항">개인정보의 자동 수집 장치의 설치 · 운영 및 그 거부에 관한 사항</p>
  <div class="policyContent">
회사는 고객님의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.<br>
1) 쿠키등 사용 목적 <br>
  ① 홈페이지 접속빈도 및 방문시간 분석, 고객의 관심분야 파악 및 분석, 각종 이벤트 참여 및 방문횟수 파악등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공을 위해 쿠키등을 사용합니다.<br>
  ② 고객님은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 고객님은 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br>
2) 쿠키 설정 거부 방법<br>
 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br>
※ 설정방법 예(인터넷 익스플로어의 경우) <br>
웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 > 고급 > 설정방법 선택<br>
  </div>

  <p class="policyIndex" data-index="8" data-label="개인정보 보호책임자 및 개인정보관련 불만처리에 관한 사항">개인정보 보호책임자 및 개인정보관련 불만처리에 관한 사항</p>
  <div class="policyContent">
1) 회사는 고객님의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 담당부서 및 관련 부서 및 개인정보 보호책임자를 지정하고 있습니다.<br>

개인정보 보호책임자<br><br>

성명 : 김동탁<br>
소속 : 전북현대모터스FC<br>
직위 : 부단장<br>
전화번호 : 063-273-1763<br>

개인정보 보호담당자<br><br>

성명 : 장은서<br>
소속 : 전북현대모터스FC<br>
직위 : 대리<br>
전화번호 : 063-210-5810<br><br>

2) 고객님께서 위 고객서비스 담당부서 또는 개인정보 보호책임자에게 회사의 개인정보 처리 사항에 관한 의견 또는 불만을 제기하실 경우, 회사는 신속하고 성실하게 조치하여 문제가 해결될 수 있도록 노력하겠습니다.<br>
3) 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다. <br>
＊개인정보 침해신고센터 (한국인터넷진흥원 운영) <br>
  - 홈페이지 : privacy.kisa.or.kr<br>
  - 전화 : (국번없이) 118<br>
＊대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)<br>
＊경찰청 사이버테러대응센터 : 1566-0112 (www.netan.go.kr)<br><br>

<b>개인정보 처리방침의 변경에 관한 사항</b><br>

회사는 본 개인정보처리방침을 변경하는 경우에는 그 이유 및 변경내용을 인터넷 홈페이지 첫 화면의 공지사항란 또는 별도의 창을 통하는 등의 방법으로 최소 7일 이전에 공지할 것입니다. <br>
 
  본 방침은 2017년 12월 18일 부터 시행됩니다.<br><Br>
  </div>
`
}
