export default {
    name: '2018년 04월 20일 이전',
    html: `
<div>
  <p class="policyIndex" data-index="0" data-label="전북현대모터스에프씨 주식회사 개인정보 처리방침">전북현대모터스에프씨 주식회사 개인정보 처리방침</p>
  <div class="policyContent">전북현대모터스에프씨 주식회사(이하 ‘회사’라 한다)는 개인정보 보호법 제30조 및 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령에 따라 정보주체의 
  개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립•․공개합니다.</div>
  
  <p class="policyIndex" data-index="1" data-label="제1조(개인정보의 처리목적)">제1조(개인정보의 처리목적)</p>
  <div class="policyContent">
  회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조 등 관련 법령에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. <br>
  <br>
<b>1. 공식 홈페이지 운영</b><br>
- 홈페이지 회원 관리 및 서비스 제공: 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 확인, 회원자격 유지•관리, 컨텐츠 등 이용약관에 따른 서비스 제공, 서비스 부정이용 방지, 공지 및 고지사항 전달<br>
- 구단 소식 및 경기 정보 안내, 이벤트, 홍보, 프로모션 정보 등 전자적 전송매체(SMS, 이메일 등 포함)를 이용한 광고성 정보 전송<br>

<br>
<b>2. 문자 안내 서비스 제공 </b><br>
- 구단 소식 및 경기 정보, 이벤트 정보 등 문자(SMS/LMS) 안내 서비스 제공<br>

<br>
<b>3. 그린스쿨 홈페이지 및 유소년 축구 교실 운영 </b><br>
- 홈페이지 회원 관리 및 서비스 제공: 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 확인, 회원자격 유지•관리, 컨텐츠 등 이용약관에 따른 서비스 제공, 서비스 부정이용 방지, 그린스쿨 유소년 축구교실 운영, 교육비 납부 및 환불절차 진행, 복수 자녀 수강시 가입비 면제 혜택 제공, 공지 및 고지사항 전달<br>
- 구단 소식 및 경기 정보 안내, 이벤트, 홍보, 프로모션 정보 등 전자적 전송매체(SMS, 이메일 등 포함)를 이용한 광고성 정보 전송<br>

<br>
<b>4. 시즌 회원제도 운영 </b><br>
- 시즌권 현장 판매 및 온라인 판매 진행, 회원 관리, 시즌권 이용 및 회원 좌석 배정, 경기 정보 안내 등 회원제 서비스 제공 <br>

<br>
<b>5. 민원 처리 및 분쟁 대응</b><br>
- 민원인 신원 확인 및 민원 사항 확인, 사실조사를 위한 연락•통지, 처리결과 통보, 분쟁 발생시 대응<br>
  </div>
  
    <p class="policyIndex" data-index="2" data-label="제2조(개인정보의 처리 및 보유기간)">제2조(개인정보의 처리 및 보유기간)</p>
  <div class="policyContent">
① 회사는 법령에 따른 개인정보 보유•․이용기간 또는 정보주체로부터 동의받은 개인정보 보유•․이용기간  내에서 개인정보를 처리•․보유하며, 개인정보의 처리목적이 달성된 경우 해당 정보를 지체없이 파기합니다.<br>
② 각 처리목적에 따른 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
1. 공식 홈페이지 운영: 홈페이지 회원 탈퇴시까지<br>
2. 문자 안내 서비스 제공 : 문자 안내 서비스 신청 철회시까지 (문자 안내 서비스 신청은 문자 안내 서비스 신청 페이지 하단 및 회사가 전송한 SMS, LMS 등에 기재된 수신 거부 연락처, 회사 개인정보보호 담당 부서 및 담당자 등을 통하여 언제든지 철회하실 수 있습니다)<br>
3. 그린스쿨 홈페이지 및 유소년 축구 교실 운영<br>
- 회원 탈퇴시까지 <br>
- 단, 아동(자녀)이 유소년 축구교실에 참여하여 교육비 수납이 완료된 경우, 해당 클래스 수강이 완료된 날로부터 5년  <br>
4. 시즌 회원제도 운영: 해당 시즌 종료시까지 <br>
※ 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지 <br>
- 관계 법령 위반에 따른 수사•조사 등이 진행중인 경우에는 해당 수사•조사 종료시까지 <br>
- 홈페이지 등 서비스 이용에 따른 채권•채무관계 잔존시에는 해당 채권•채무관계 정산시까지 <br>
- 전자상거래 등에서의 소비자 보호에 관한 법률에 따른 표시․광고, 계약내용 및 이행 등 거래에 관한 기록<br>
&nbsp;&nbsp;• 표시•광고에 관한 기록 : 6월 <br>
&nbsp;&nbsp;• 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년 <br>
&nbsp;&nbsp;• 소비자 불만 또는 분쟁처리에 관한 기록 : 3년 <br>
- 「통신비밀보호법」제41조에 따른 통신사실확인자료 보관<br>
&nbsp;&nbsp;• 가입자 전기통신일시, 개시․종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년 <br>
&nbsp;&nbsp;• 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월<br>
- 기타 상법 등 관계 법령의 규정에 의하여 보존할 의무가 있거나, 민원 처리 및 분쟁 대응 등의 사유로 개인정보 처리목적이 달성되지 않은 경우에는 해당 처리목적 달성 시점까지 <br>

※ 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라 1년간 홈페이지 서비스를 이용하지 않은 정보주체의 개인정보는 다른 정보주체의 개인정보와 분리하여 별도로 저장•관리 합니다. 단, 아동(자녀)이 유소년 축구교실에 참여 중인 그린스쿨 홈페이지 회원 및 해당 아동(자녀)의 개인정보는 예외로 합니다. <br>
  </div>
  
    <p class="policyIndex" data-index="3" data-label="제3조(개인정보의 제3자 제공)">제3조(개인정보의 제3자 제공)</p>
  <div class="policyContent">
회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의가 있거나, 다른 법률에  특별한 규정이 있는 경우 등 관련 법령에서 허용되는 경우에만 개인정보를 제3자에게 제공합니다.
  </div>
      <p class="policyIndex" data-index="4" data-label="제4조(개인정보처리의 위탁)">제4조(개인정보처리의 위탁)</p>
        <div class="policyContent">
        ① 회사는 원활한 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
  </div>
  <div class="policyTable">
    <table>
      <thead>
        <tr>
          <th>위탁받는 자(수탁자)/th>
          <th>위탁하는 업무의 내용</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>스포탈코리아(주)</td>
          <td>홈페이지 및 시스템 관리, 유지보수</td>
        </tr>
        <tr>
          <td>에스아이엘</td>
          <td>시즌권 현장판매 및 배송 대행</td>
        </tr>
      </tbody>
    </table>
  </div>
          <div class="policyContent">
② 회사는 위탁계약 체결시 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제26조 및 개인정보 보호법 제256조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적•․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리•․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. <br>
③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다. <br>
  </div>
  
      <p class="policyIndex" data-index="5" data-label="제5조(정보주체와 법정대리인의 권리•․의무 및 행사방법)">제5조(정보주체와 법정대리인의 권리•․의무 및 행사방법)</p>
  <div class="policyContent">① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.  <br>
1. 개인정보 열람요구<br>
2. 오류 등이 있을 경우 정정 요구<br>
3. 삭제요구 <br>
4. 처리정지 요구 <br>
② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다. <br>
③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다. <br>
④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. <br>
⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다. <br>
  </div>

  <p class="policyIndex" data-index="6" data-label="제6조(처리하는 개인정보 항목 및 수집방법)">제6조(처리하는 개인정보 항목 및 수집방법)</p>
  <div class="policyContent">
① 회사는 다음의 개인정보 항목을 처리하고 있습니다. <br>
1. 공식 홈페이지 운영<br>
- (필수) 회원명, 아이디, 비밀번호, 연락처, 휴대전화번호, 이메일, 생년월일, 본인인증결과값(DI) <br>
2. 문자 안내 서비스 제공 <br>
- (필수) 이름, 휴대전화번호 <br>
3. 그린스쿨 홈페이지 및 유소년 축구 교실 운영  <br>
- (필수) 이름, 아이디, 비밀번호, 연락처, 이메일,  교육비 입금자명, 생년월일, 본인인증결과값(DI)<br>
- (선택) 자녀정보(자녀명, 연락처, 생년월일, 학교, 학년, 나이, 신장, 체중, 사이즈)<br>
4. 시즌 회원제도 운영<br>
- (필수) 성명, 생년월일, 주소, 휴대폰 번호, 성별<br>
5. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다. <br>
-․IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등 <br>
② 회사가 개인정보를 수집하는 방법은 다음과 같습니다.<br>
- 서면/전화/팩스/인터넷 홈페이지를 통한 회원가입, 이벤트 응모, 제휴사로부터의 제공 <br>
  </div>

  <p class="policyIndex" data-index="7" data-label="제7조(개인정보의 파기)">제7조(개인정보의 파기)</p>
  <div class="policyContent">
① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. <br>
② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. <br>
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다. <br>
1. 파기절차 <br>
회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. <br>
2. 파기방법 <br>
회사는 전자적 파일 형태로 기록•․저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. <br>
  </div>

  <p class="policyIndex" data-index="8" data-label="제8조(개인정보의 안전성 확보조치)">제8조(개인정보의 안전성 확보조치)</p>
  <div class="policyContent">
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. <br>
1. 관리적 조치 <br>
- 개인정보관리체계 수립: 개인정보를 안전하게 관리하기위하여 회사 내부적으로 개인정보 관리체계를 수립하여 운영하고 있습니다.<br>
- 개인정보 취급자 관리: 고객의 개인정보를 처리하는 개인정보처리자를 대상으로 개인정보보호 서약서를 제출받고, 연2회 이상의 개인정보보호 교육을 수행하여 고객정보의 중요성과 안전하게 관리하도록 하고 있습니다. 또한 개인정보처리자의 권한 관리를 통하여 불필요한 고객의 개인정보에 대한 접근과 노출을 최소화하고 있습니다.<br>
2. 기술적 조치 <br>
- 고객정보의 암호화: 소중한 고객의 개인정보는 DB내에 암호화되어 저장되어 외부 침입에 의해 유출되더라도 고객의 개인정보를 활용할수 없도록 하고 있습니다.<br>
- 통신 구간 암호화: 고객이 홈페이지를 통한 회원가입 및 로그인시 고객정보를 입력하여 전달하는 구간에 대해 SSL을 통해 고객의 정보가 안전하게 전송되도록 조치하고 있습니다.<br>
- 보안솔루션의 설치: 서비스 제공 및 고객의 정보를 안전하게 관리하기 위하여 개인정보처리시스템에 대해 백신프로그램의 설치, 주기적인 업데이트 및 정기점검을 수행하고 있으며, DB암호화 솔루션 및 화면캡처방지 솔루션을 적용하고 있습니다. 또한 해킹 등의 외부침입을 대비하여 침입차단/탐지시스템을 설치하고 통합보안관재센터를 통해 해킹 및 외부침입에 대비하여 계속적으로 모니터링을 수행하고 있습니다.<br>
3. 물리적 조치 <br>
- 고객의 개인정보가 보유, 관리되는 전산실, 자료보관실 등의은 적법한 접근권한을 가진 자만이 출입할 수 있도록 접근을 통제하고 있습니다.
  </div>

  <p class="policyIndex" data-index="9" data-label="제9조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)">제9조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)</p>
  <div class="policyContent">
    회사는 정보주체의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.<br>
1. 쿠키등 사용 목적 <br>
- 홈페이지 로그인 시 재로그인을 위하여 쿠키를 사용합니다.<br>
- 정보주체는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 정보주체는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br>
2. 쿠키 설정 거부 방법<br>
쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br>
※ 설정방법 예(인터넷 익스플로어의 경우) <br>
웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 > 고급 > 설정방법 선택<br>
단, 귀하께서 쿠키 설치를 거부하였을 경우 맞춤형 서비스 제공에 어려움이 있을 수 있습니다. <br>
  </div>

  <p class="policyIndex" data-index="10" data-label="제10조(개인정보 보호책임자)">제10조(개인정보 보호책임자)</p>
  <div class="policyContent">
① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. <br><br>
▶ 개인정보 보호책임자 <br>
성명 : 김동탁 <br>
직위책 : 부단장 <br>
연락처 : 063-273-1763 <br><br>

▶ 개인정보 보호 담당부서 <br>
부서명 : 경영지원팀 <br>
담당자 : 장은서 대리 <br>
연락처 : 063-210-5810 <br><br>
② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다. <br>
  </div>

  <p class="policyIndex" data-index="11" data-label="제11조(개인정보 열람•정정•삭제•처리정지 등 청구)">제11조(개인정보 열람•정정•삭제•처리정지 등 청구) </p>
  <div class="policyContent">
정보주체는 개인정보 보호법 제35조 등 관련법령에 따른 개인정보의 열람•정정•삭제•처리정지 등 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다. <br>
▶ 개인정보 열람•정정•삭제•처리정지 등 청구 접수•․처리 부서 <br>
부서명 : 경영지원팀 <br>
담당자 : 장은서 대리 <br>
연락처 : 063-210-5810 <br><br>
  </div>

  <p class="policyIndex" data-index="12" data-label="제12조(권익침해 구제방법)">제12조(권익침해 구제방법)</p>
  <div class="policyContent">
정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. <br>

<br>
정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. <br>
<br>
▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영) <br>
- 소관업무 : 개인정보 침해사실 신고, 상담 신청 <br>
- 홈페이지 : privacy.kisa.or.kr <br>
- 전화 : (국번없이) 118 <br>
- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터<br>

<br>
▶ 개인정보 분쟁조정위원회 <br>
- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결) <br>
- 홈페이지 : www.kopico.go.kr <br>
- 전화 : (국번없이) 1833-6972 <br>
- 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층 <br>

<br>
▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)<br>

<br>
▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)<br>
  </div>
    <p class="policyIndex" data-index="13" data-label="제14조(개인정보 처리방침 변경)">제14조(개인정보 처리방침 변경)</p>
      <div class="policyContent">
      ① 이 개인정보 처리방침은 2018. 4. 21부터 적용됩니다.<br>
② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다. 
</div>
</div>
`
}
