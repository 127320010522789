<template>
  <div class="mediaView hyundai">
    <!-- 미디어 뷰 타이틀 -->
    <div class="mediaTitle">
      <div class="container">
        <p class="subject">{{ currentData.Title }}</p>
        <p class="summary" v-html="currentData.TopContent"></p>
        <div class="about">
          <div class="info">
            <span class="date">{{ currentData.RegiDate }}</span>
            <span class="view">{{ comma(Number(currentData.Hit)) }}</span>
          </div>
          <!-- 공유 -->
          <div class="share">
            <div class="shareBtn link" @click="clipboard">
              <span v-if="copyLink" class="message">주소가 복사되었습니다.</span>
            </div>
            <a href="#" class="shareBtn facebook" @click="shareFacebook"></a>
            <span class="shareBtn kakaotalk" @click="shareKakao"></span>
          </div>
          <!-- //공유 -->
        </div>
      </div>
    </div>
    <!-- //미디어 뷰 타이틀 -->
    <div class="container">
      <!-- 미디어 본문 -->
      <div class="mediaContent" :class="isOver100000">
        <img v-if="setImg(currentData.img) === false" style="display: none"/>
        <div class="img" v-else-if="paramsMedia === 'photo'"><img :src="currentData.img" alt="" @error="replaceImg"></div>
        <div class="img" v-else-if="currentData.img === ''"><img src="../../assets/img/bg_media_404.png"></div>
        <div class="article" v-html="currentData.Content"></div>
        <!-- 태그 -->
        <div class="tags pretendard">
          <a :href="data.url" class="tag" v-for="(data, idx) in tag" :key="idx">{{ data.name }} </a>
        </div>
        <!-- //태그 -->
        <!-- 첨부파일 -->
       <a v-if="fileName!==''"
          :href='currentData.AttachFileNM' download="fileName">첨부파일 : {{fileName}}</a>
      </div>
      <!-- //첨부파일 -->
      <div class="mediaBtns">
        <router-link :to="{path: `/media/${$route.params.media}`}" class="btn auto">목록으로</router-link>
      </div>
      <!-- //미디어 본문 -->
      <!-- 이전, 다음글 -->
      <div class="mediaRelated">
        <div class="row prev" v-if="previousData.length!==0">
          <p class="subject" @click="setRoute(previousData.category, previousData.seq)">
            <router-link v-if="previousData.category"
                         :to="{name: 'mediaView', params: { media:previousData.category,id: previousData.seq}}">
              {{ previousData.Title }}
            </router-link>
          </p>
          <span class="date">{{ previousData.RegiDate }}</span>
        </div>
        <div class="row noData_ prev_" v-else>이전 게시물이 없습니다</div>
        <div class="row next" v-if="nextData.length!==0">
          <p class="subject" @click="setRoute(nextData.category, nextData.seq)">
            <router-link v-if="nextData.category"
                         :to="{name: 'mediaView', params: {media: nextData.category,id: nextData.seq}}">
              {{ nextData.Title }}
            </router-link>
          </p>
          <span class="date">{{ nextData.RegiDate ? nextData.RegiDate : null }}</span>
        </div>
        <div class="row noData_ next_" v-else><p>다음 게시물이 없습니다</p></div>
      </div>
      <!-- //이전, 다음글 -->
      <!-- 최근 게시글 -->
      <div class="mediaLatest mediaList">
        <p class="latestTitle">최신 게시물</p>
        <div class="tileList">
          <!-- tile item -->
          <div class="tile" v-for="(data, i) in latestData" :key="i" @click="setRoute(data.category, data.seq)">
            <router-link :to="{name: 'mediaView', params: {id: data.seq, media: data.category}}">
              <div class="img" v-if="data.img"><img :src="data.img" alt="" @error="replaceImg"></div>
              <div class="img" v-else><img src="../../assets/img/bg_media_404.png"></div>
              <div class="article">
                <div class="category"><span>{{ data.Status }}</span></div>
                <p class="subject">{{ data.Title }}</p>
                <div class="info">
                  <span class="date">{{ data.RegiDate }}</span>
                  <span class="view">{{ comma(Number(data.Hit)) }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- //tile item -->
        </div>
      </div>
      <!-- //최근 게시글 -->
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from '@/api/index'
import dayjs from 'dayjs'
import bgImg from "@/assets/img/bg_media_404.png"
import { sharePath } from '@/library/sharePath'

export default {
  name: 'mediaView',
  props: [
    'id',
    'media'
  ],
  created() {
    this.init()
  },
  watch: {
    paramsMedia() {
      this.init()
    },
    paramsId() {
      this.init()
    },
  },
  data() {
    return {
      copyLink: false,
      paramsMedia: this.$route.params.media,
      paramsId: this.$route.params.id,
      tag: [],
      currentData: [],
      previousData: [],
      nextData: [],
      latestData: [],
      file:'',
      fileName:'',
      isImg : true,
      isOver100000: ''
    }
  },
  methods: {
    comma(num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    clipboard() {
      const url = window.location.href
      this.copyLink = true
      window.navigator.clipboard.writeText(url)
      setTimeout(() => {
        this.copyLink = false
      }, 1000)
    },
    async init() {
      const setStatus = (el, type) => {
        if(type=== 'currentData'){
          if (el.seq >= 100000) {
            this.isOver100000 = 'over100000'
            if (el.AttachFileNM || el.AttachFileNM === 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/newBoard/') {
              this.fileName = el.AttachFileNM.substr(77)
              this.file = el.AttachFileNM
            }
          } else {
            if(el.AttachFileNM || el.AttachFileNM === "https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/SoccerUpload/news/"){
              this.fileName = el.AttachFileNM.substr(86)
              this.file = el.AttachFileNM
            }
          }
        }
        if (el.RegiDate) {
          el.RegiDate = dayjs(el.RegiDate).format('YYYY.MM.DD')
        }
        if (!el.TopImage||el.TopImage === "" || el.TopImage === "http://www.hyundai-motorsfc.com/uploadFile/data/") {
          el.img = el.PhotoFileNM
        }
        if (el.PhotoFileNM === "" || el.PhotoFileNM === "http://www.hyundai-motorsfc.com/uploadFile/data/") {
          el.img = el.TopImage
        }
        switch (el.status){
          case '1':
            el.status = '공지사항'
            el.category = 'notice'
            break
          case '2':
            el.status = '구단소식'
            el.category = 'news'
            break
          case '3':
            el.status = '경기사진'
            el.category = 'photo'
            break
          case '4':
            el.status = '구단영상'
            el.category = 'video'
            break
          case '6':
            el.status = 'JB 미디어'
            el.category = 'jbmedia'
            break
        }
        return el
      }
      const data = await http.get(`/media/detail/${this.paramsMedia}/${this.paramsId}`)
      this.currentData = data.data.body.current.data[0]
      if(!this.currentData) {
        alert('존재하지 않는 게시물입니다.');
        this.$router.push('/media/all')
      }
      if(this.paramsMedia === 'photo'){
        this.currentData.img = this.currentData.PhotoFileNM
      }
      setStatus(this.currentData, 'currentData')
      //! tag !//
      this.tag = data.data.body.current.tag
      if (this.tag) {
        await this.getTagData()
      }

      data.data.body.previous.length < 1 ? this.previousData = data.data.body.previous : this.previousData = data.data.body.previous[0]
      if (data.data.body.previous.length > 0) {
        setStatus(this.previousData)
      }
      data.data.body.next.length < 1 ? this.nextData = data.data.body.next : this.nextData = data.data.body.next[0]
      if (data.data.body.next.length > 0) {
        setStatus(this.nextData)
      }
      this.latestData = data.data.body.latest
      data.data.body.latest.map(row => {
        row.RegiDate = dayjs(row.RegiDate).format('YYYY.MM.DD')
        if (row.seq >= 100000) {
          const regex = /https:\/\/jbhd-upload-file\.s3\.ap-northeast-2\.amazonaws\.com\/uploadFile\/newBoard\//
          if (regex.test(row.PhotoFileNM)) {
            row.img = row.PhotoFileNM
          }
          if (regex.test(row.TopImage)) {
            row.img = row.TopImage
          }
        } else {
          if (row.TopImage === "" ||
              row.TopImage === null ||
              row.TopImage === "https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/data/" ||
              row.TopImage === "https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/SoccerUpload/news/"
          ) {
            row.img = row.PhotoFileNM
          }
          if (row.PhotoFileNM === "" ||
              row.PhotoFileNM === "https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/data/" ||
              row.PhotoFileNM === "https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/SoccerUpload/news/"
          ) {
            row.img = row.TopImage
        }
        }
        switch (row.Status){
          case '1':
            row.Status = '공지사항'
            row.category = 'notice'
            break
          case '2':
            row.Status = '구단소식'
            row.category = 'news'
            break
          case '3':
            row.Status = '경기사진'
            row.category = 'photo'
            break
          case '4':
            row.Status = '구단영상'
            row.category = 'video'
            break
          case '6':
            row.Status = 'JB 미디어'
            row.category = 'jbmedia'
            break
        }
      })
    },
    async getTagData() {
      for (const row of this.tag) {
        switch(row.tag_type) {
          case 1:
            if (row.kl_player_id === 'headcoach') {
              row.url = 'https://hyundai-motorsfc.com/team/staff/headcoach'
            }
            else {
              try {
                const playerResponse = await http.get(`/photo/player?pid=${row.kl_player_id}`)
                row.url = playerResponse.data.data
              } catch (err) {
                if (err.response && err.response.status === 400) {
                  row.url = `javascript:${void(0)}`
                } else {
                  throw err
                }
              }
            }
            break
          case 2:
            try {
              const gameResponse = await http.get(`/photo/game?season=${row.season}&gameid=${row.game_id}`)
              row.url = gameResponse.data.data
            } catch (err) {
              if (err.response && err.response.status === 400) {
                row.url = `javascript:${void(0)}`
              } else {
                throw err
              }
            }
            break
        }
      }
    },
    setRoute(params, id) {
      this.$route.params.media = params
      this.$route.params.id = id
      this.paramsMedia = this.$route.params.media
      this.paramsId = this.$route.params.id
    },
    replaceImg(e) {
      e.target.src = bgImg
    },
    setImg(el){
      if(
          el === 'http://www.hyundai-motorsfc.com:80/uploadFile/Board/' ||
        el === 'http://www.hyundai-motorsfc.com/SoccerUpload/news/' ||
      el === 'http://www.hyundai-motorsfc.com/uploadFile/data/'
      ){
        return this.isImg = false
      } else {
        return this.isImg =true
      }

    },
    shareFacebook() {
      window.open(`http://www.facebook.com/sharer.php?u=${sharePath.host}/media/${this.paramsMedia}/${this.paramsId}`);
    },
    shareKakao () {
      const url = `${sharePath.host}/media/${this.paramsMedia}/${this.paramsId}`
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: this.currentData.Title,
          description: this.currentData.TopContent,
          imageUrl: this.currentData.TopImage,
          link: {
            mobileWebUrl: url,
            webUrl: url,
          },
        },
        buttons: [
          {
            title: '웹으로 보기',
            link: {
              mobileWebUrl: url,
              webUrl: url,
            },
          },
        ],
        installTalk: true,
          }
      )
    }
  }
}
</script>
<style>
.noData_ {
  justify-content: center;
}
.over100000 p {
  margin-bottom: 1em;
}
</style>
