<template>
  <div class="gameHighlight">
    <div class="innerBox">
      <div class="innerTitle">하이라이트</div>
      <div class="noData" v-if="highlight==='' || highlight=== null">데이터가 없습니다</div>
      <div class="videoFrame" v-else>
        <iframe
          width="100%"
          height="100%"
          :src="`https://www.youtube.com/embed/${highlight}`"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MatchGameHighlight',
  created () {
    // this.video = '-j9o_W40yPM'
    this.highlight = this.$store.state.matchData.content.highlight
  },
  watch:{
  },
  data () {
    return {
      video: '',
      highlight : ''
    }
  },
}
</script>
