<template>
  <div class="gameSummary">
    <!-- 기록요약 -->
    <div class="innerBox">
      <div class="innerTitle">기록요약</div>
      <div class="summaryData pretendard" v-if="summaryData">
        <div class="team home"><img :src="matchData.homeLogoImg" alt=""><span>{{ matchData.home_team_name }}<i
            class="hiddenMobile"></i></span></div>
        <div class="team away"><img :src="matchData.awayLogoImg" alt=""><span>{{ matchData.away_team_name }}<i
            class="hiddenMobile"></i></span></div>
        <div class="graph">
          <span class="label">점유율</span>
          <div class="bar"
               :style="{width: barWidth(Number(summaryData.home_Team_Time_share), Number(summaryData.away_Team_Time_share))}"></div>
          <span class="digit home">{{ summaryData.home_Team_Time_share }}</span>
          <div class="digit away">{{ summaryData.away_Team_Time_share }}</div>
        </div>
        <div class="graph">
          <span class="label">슈팅</span>
          <div class="bar"
               :style="{width: barWidth(Number(summaryData.home_st_qty), Number(summaryData.away_st_qty))}"></div>
          <span class="digit home">{{ summaryData.home_st_qty }}</span> <!-- 5:1 -->
          <div class="digit away">{{ summaryData.away_st_qty }}</div>
        </div>
        <div class="graph">
          <span class="label">유효슈팅</span>
          <div class="bar"
               :style="{width: barWidth(Number(summaryData.home_st_valid_qty), Number(summaryData.away_st_valid_qty))}"></div>
          <span class="digit home">{{ summaryData.home_st_valid_qty }}</span>
          <div class="digit away">{{ summaryData.away_st_valid_qty }}</div>
        </div>
        <div class="graph">
          <span class="label">파울</span>
          <div class="bar"
               :style="{width: barWidth(Number(summaryData.home_fo_qty), Number(summaryData.away_fo_qty))}"></div>
          <span class="digit home">{{ summaryData.home_fo_qty }}</span>
          <div class="digit away">{{ summaryData.away_fo_qty }}</div>
        </div>
        <div class="graph">
          <span class="label">경고</span>
          <div class="bar"
               :style="{width: barWidth(Number(summaryData.home_warn_qty), Number(summaryData.away_warn_qty))}"></div>
          <span class="digit home">{{ summaryData.home_warn_qty }}</span>
          <div class="digit away">{{ summaryData.away_warn_qty }}</div>
        </div>
        <div class="graph">
          <span class="label">코너킥</span>
          <div class="bar"
               :style="{width: barWidth(Number(summaryData.home_CK_Qty), Number(summaryData.away_CK_Qty))}"></div>
          <span class="digit home">{{ summaryData.home_CK_Qty }}</span>
          <div class="digit away">{{ summaryData.away_CK_Qty }}</div>
        </div>
        <div class="graph">
          <span class="label">프리킥</span>
          <div class="bar"
               :style="{width: barWidth(Number(summaryData.home_FK_St_Qty), Number(summaryData.away_FK_St_Qty))}"></div>
          <span class="digit home">{{ summaryData.home_FK_St_Qty }}</span>
          <div class="digit away">{{ summaryData.away_FK_St_Qty }}</div>
        </div>
        <div class="graph">
          <span class="label">오프사이드</span>
          <div class="bar"
               :style="{width: barWidth(Number(summaryData.home_os_qty), Number(summaryData.away_os_qty))}"></div>
          <span class="digit home">{{ summaryData.home_os_qty }}</span>
          <div class="digit away">{{ summaryData.away_os_qty }}</div>
        </div>
      </div>
      <div class="noData" v-else>데이터가 없습니다</div>
    </div>
    <!-- //기록요약 -->
    <div class="flexBox">
      <!-- 타임라인 -->
      <div class="flexInner innerBox">
        <div class="innerTitle semi">타임라인</div>
        <div class="timeline pretendard"
             v-if="matchData.match_title === 'K LEAGUE' && Object.keys(timelineData.first).length || Object.keys(timelineData.second).length">
          <div class="game">
            <div class="team home">
              <img :src="matchData.homeLogoImg" alt="">
              <div class="displayMobile">{{ matchData.home_team_name }}</div>
            </div>
            VS
            <div class="team away">
              <img :src="matchData.awayLogoImg" alt="">
              <div class="displayMobile">{{ matchData.away_team_name }}</div>
            </div>
          </div>
          <div class="timelineBox">
            <!-- 타임라인 이벤트 -->
            <template v-for="row in timelineData.first">
              <div class="event" :data-event="data.division" :data-status="data.status" v-for="(data,num) in row"
                   :key="data.play_time+'-'+num">
                <span class="time" v-if="num === 0">{{ data.play_time }}'</span>
                <div class="status">{{ data.play_status }}</div>
                <div class="detail">
                  <span class="exp">({{ data.play_status }})</span>
                  <span class="name">{{ data.player_info }}</span>
                </div>
              </div>
            </template>
            <!-- 타임라인 맵핑할 때 중간에 하프타임 추가 해주세요 -->
            <div class="event halftime"></div>
            <!-- //타임라인 맵핑할 때 중간에 하프타임 추가 해주세요 -->
            <template v-for="row in timelineData.second">
              <div class="event" :data-event="data.division" :data-status="data.status" v-for="(data,n) in row"
                   :key="data.play_time+'to'+n">
                <span class="time" v-if="n === 0">{{ data.play_time }}'</span>
                <div class="status">{{ data.play_status }}</div>
                <div class="detail">
                  <span class="exp">({{ data.play_status }})</span>
                    <span class="name">{{ data.player_info }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="noData" v-else>데이터가 없습니다</div>
      </div>
      <!-- //타임라인 -->
      <!-- 출전명단 -->
      <div class="flexInner innerBox">
        <div class="innerTitle semi">출전명단</div>
        <div class="lineup pretendard" v-if="changePlayerData.length > 0 && playerData.length > 0">
          <div class="listBox">
            <div class="listTitle">선발</div>
            <ul class="list">
              <li v-for="(data, i) in playerData" :key="i+'n'">
                <div style="display: contents">
                  <span class="no">{{ data.uniform_number }}</span>
                  <img :src="data.imgPath" alt="" @error="nullImage">
                  <div class="player">
                    {{ data.name }}
                    <span class="pos">{{ data.position }}</span>
                  </div>
                </div>
                <div style="display: flex; flex-wrap: wrap; gap: 3px; justify-content: right; align-items: center; margin-left: auto;">
                  <div class="record">
                    <span v-if="data.Assist_Qty"
                          class="green"><i>{{ data.Assist_Qty_set }}</i></span>
                  </div>
                  <div class="record" v-for="(el, idx) in data.play_time" :key="'new'+idx">
                    <span :class="data.statusClass[idx]"><i>{{ data.play_time[idx] }}{{ data.statusForKorean[idx] }}</i></span>
                  </div>
                  <div class="record">
                    <span v-if="data.class_Change_o"
                          :class="data.class_Change_o"><i>{{ data.Change_o_Des_set }}</i></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="listBox">
            <div class="listTitle">교체</div>
            <ul class="list" v-for="(data, idx) in changePlayerData" :key="idx+'s'">
              <li>
                <div style="display: contents">
                  <span class="no">{{ data.uniform_number }}</span>
                  <img :src="data.imgPath" alt="" @error="nullImage">
                  <div class="player">
                    {{ data.name }}
                    <span class="pos">{{ data.position }}</span>
                  </div>
                </div>
                <div style="display: flex; flex-wrap: wrap; gap: 3px; justify-content: right; align-items: center; margin-left: auto;">
                  <div class="record">
                    <span v-if="data.Assist_Qty"
                          class="green"><i>{{ data.Assist_Qty_set }}</i></span>
                  </div>
                  <div class="record" v-for="(el, idx) in data.play_time" :key="'new'+idx">
                    <span :class="data.statusClass[idx]"><i>{{ data.play_time[idx] }}{{ data.statusForKorean[idx] }}</i></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="noData" v-else>데이터가 없습니다</div>
      </div>
      <!-- //출전명단 -->
    </div>
  </div>
</template>

<script>
import imgPath from "@/library/imgPath"


export default {
  name: 'MatchGameSummary',
  created() {
    this.init()
  },
  data() {
    return {
      summaryData: [],
      matchData: [],
      playerData: [],
      changePlayerData: [],
      firstHalfData: [],
      secondHalfData: [],
      timelineData: {},
    }
  },
  methods: {
    barWidth(x, y) {
      const a = x + y
      return `${parseFloat(x / a) * 100}%`
    },
    init() {
      const match = JSON.parse(JSON.stringify(this.$store.state.matchData)) // 깊은 복사
      this.summaryData = match.record.stat
      this.matchData = match.match
      const player = match.record.player
      const timeline = match.record.timeline
      const playerData = []
      const changePlayerData = []

      //출전명단 데이터
      player.map(row => {
        row.imgPath = imgPath.set140px(row.Player_id)
        switch (row.Work_Des) {
          case '출장':
            this.setPlayerDataClass(row)
            break
          case '교체출장':
            this.setPlayerDataClass(row)
            break
          case '대기':
            this.setPlayerDataClass(row)
        }
      })
      const HometeamTimeline = match.record.timeline.filter(el => el.division !== 'away')

      //타임라인 데이터
      timeline.map(row => {
        row.player_info = row.player_info.replace(/[0-9]/g, "")
        switch (row.play_half) {
          case '전반':
            switch (row.division) {
              case 'H':
                this.setTimelineDataStatus(row)
                row.division = 'home'
                break
              case  'A':
                this.setTimelineDataStatus(row)
                row.division = 'away'
                break
            }
            break
          case '후반':
            switch (row.division) {
              case 'H':
                this.setTimelineDataStatus(row)
                row.division = 'home'
                break
              case  'A':
                this.setTimelineDataStatus(row)
                row.division = 'away'
                break
            }
            break
        }
      })

      this.setTimelineData(match.record.timeline)
      const setMatchingPlayersAndTimelines = this.getMatchingPlayers(HometeamTimeline, player) // 타임라인과 출전선수 데이터 합침

      player.forEach(player => { // 출전명단에 합친 데이터 추가
        setMatchingPlayersAndTimelines.forEach(el => {
          if (player.uniform_number === el.uniform_number) {
            player.play_time = el.play_time
            player.status = el.status
          }
        })
        return player
      })
      player.map(el => { // 출전명단 데이터 수정
        if (el.Change_o_Des) {
          el.Change_o_Des_set = "OUT"
        }
        if (el.Assist_Qty) {
          el.Assist_Qty_set = "도움"
        }
        if (Array.isArray(el.statusForKorean) && el.statusForKorean.length > 0 && el.statusForKorean[0].includes('누적퇴장')) {
          el.play_time = el.play_time.slice(-1)
          el.status = el.status.slice(-1)
          el.statusClass = el.statusClass.slice(-1)
          el.statusForKorean = el.statusForKorean.slice(-1)
        }
        el.imgPath = imgPath.set140px(el.Player_id)
        switch (el.Work_Des) {
          case '출장':
            playerData.push(el)
            break
          case '교체출장':
            changePlayerData.push(el)
            break
          case '대기':
            changePlayerData.push(el)
        }
        if (Array.isArray(el.play_time)) {
          el.play_time.map((row, idx) => {
            if (row[idx] && !el.statusClass[idx]) { // 출전명단에 들어가지 않는 타임라인요소 제거
              el.play_time.splice(idx, 1)
            }
          })
        }
      })

      this.playerData = playerData
      this.changePlayerData = changePlayerData
    },
    setPlayerDataClass(row) {
      if (row.Assist_Qty > 0) {
        row.class_Assist = 'green'
      }
      if (row.Goal_Qty > 0) {
        row.class_Goal = 'green2 goal'
      }
      if (row.Change_i_Des !== '') {
        row.class_Change_i = 'grey'
      }
      if (row.Change_o_Des !== '') {
        row.class_Change_o = 'grey'
      }
      if (row.Warn_Qty === 1) {
        row.class_Warn1 = 'red'
      }
      if (row.Warn_Qty === 2) {
        row.class_Warn2 = 'red2'
      }
      if (row.Exit_Qty === 1) {
        row.class_Exit = 'red2'
      }
      return row
    },
    setTimelineDataStatus(row) {
      switch (row.play_status) {
        case '득점':
          row.status = 'goal'
          break
        case  '교체':
          row.status = 'change'
          break
        case  '경고':
          row.status = 'yellowcard'
          break
        case  '퇴장':
          row.status = 'redcard'
          break
        case '경고 누적 퇴장':
          row.status = 'yellowcard'
          break
      }
      return row
    },
    setTimelineData(data) {
      const result = {
        first: {},
        second: {}
      }
      for (const row of data) {
        if (row['play_half'] === '전반') {
          if (result['first'][parseInt(row['play_time'])] === undefined) {
            result['first'][parseInt(row['play_time'])] = [];
          }
          result['first'][parseInt(row['play_time'])].push(row)
        } else {
          if (result['second'][parseInt(row['play_time']) + 45] === undefined) {
            result['second'][parseInt(row['play_time']) + 45] = []
          }
          result['second'][parseInt(row['play_time']) + 45].push(row)
        }
      }

      this.timelineData = result;
    },
    nullImage(e) {
      e.target.src = imgPath.imgPath.player_null;
    },
    getMatchingPlayers(matchList, playerList) {
      const result = []

      // 초기화 코드
      playerList.forEach(player => {
        player.play_time = []
        player.status = []
        player.statusForKorean = []
        player.statusClass = []
        player.play_half = []
      })

      matchList.forEach(match => {
        playerList.forEach(player => {
          if (match.player_info === player.name) {
            player.play_time = player.play_time || []
            player.status = player.status || []
            player.statusForKorean = player.statusForKorean || []
            player.statusClass = player.statusClass || []
            player.play_half = player.play_half || []

            if (match.status) {
              switch (match.status) {
                case 'goal':
                  player.statusForKorean.push('’ 득점')
                  player.statusClass.push('green2 goal')
                  break
                case 'change':
                  player.statusForKorean.push('’ IN')
                  player.statusClass.push('grey')
                  break
                case 'yellowcard':
                  player.statusForKorean.push(player.Warn_Qty === 1 ? '’ 경고' : '’ 누적퇴장')
                  player.statusClass.push(player.Warn_Qty === 1 ? 'red' : 'red2')
                  break
                case 'redcard':
                  player.statusForKorean.push('’ 퇴장')
                  player.statusClass.push('red2')
                  break
              }
            }

            const playTime = match.play_half === '후반' ? Number(match.play_time) + 45 : match.play_time
            player.play_time.push(playTime)
            player.play_half.push(match.play_half)
            player.status.push(match.status)
            result.push(player)
          }
        })
      })

      return result
    },
  }
}
</script>
