<template>
  <div class="gameCharacterContainer">
    <div class="characterBox">
      <img src="@/assets/img/etc/game_character.png" alt="">
    </div>
    <div class="box">
      <div class="top">
        <p class="title pb25" v-html="data.title"/>
        <p class="pb5"><strong>{{data.subTitle}}</strong></p>
        <p>(참여기간 : ~ {{data.deadline}})</p>
      </div>
      <div class="bottom">
        <p class="pb5">{{name}}님, {{ isQuiz ? '참여' : '응모' }} 기회가 있습니다.</p>
        <p v-if="isQuiz">지금 퀴즈에 참여해보세요!</p>
        <p v-else>지금 룰렛을 돌려보세요!</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CharacterBox',
  props: [
    'isQuiz',
    'data',
    'name'
  ],
  data() {
    return {};
  },
  created() {
  },
  methods: {},
}
</script>
