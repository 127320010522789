<template>
  <div class="subTop hyundai">
    <!-- 서브 상단 -->
    <div class="top">
      <div class="container">
        <div class="breadCrumb">
          홈 <span class="arr">&#8250;</span>
          <router-link :to="depth1.link">{{depth1.name}}</router-link> <span class="arr">&#8250;</span>
          <router-link :to="depth2.link">{{depth2.breadCrumb}}</router-link> <span class="arr">&#8250;</span>
          <router-link :to="depth2.link">{{depth3.breadCrumb}}</router-link>
        </div>
        <div class="topTitle">
          <p class="cate">{{depth1.name}}</p>
          <p class="subCate">{{depth2.name}}</p>
        </div>
      </div>
    </div>
    <!-- //서브 상단 -->
    <!-- 서브 탭메뉴 -->
    <div class="tabMenu">
      <div class="container">
        <router-link
          v-for="menu in tab"
          :key="menu.id"
          :to="menu.link"
          :class="{current: menu.id === depth2.id}">
          <span>{{menu.name}}</span>
        </router-link>
      </div>
    </div>
    <!-- //서브 탭메뉴 -->
    <!-- 상세 탭버튼 -->
    <div class="tabBtns">
      <router-link
        v-for="tab in depth2.children"
        :key="tab.id"
        :to="tab.link"
        :class="{white: tab.id !== depth3.id}"
        class="btn">
        {{tab.name}}
      </router-link>
    </div>
    <!-- //상세 탭버튼 -->
  </div>
</template>

<script>
export default {
  name: 'MatchTop',
  computed: {
    depth1 () {
      return {
        id: this.page.id,
        name: this.page.name,
        link: this.page.link
      }
    },
    depth2 () {
      const match = this.$route.meta.match
      const current = this.tab.find(x => x.id === match)
      return {
        id: current ? current.id : this.depth1.id,
        name: current ? current.name : this.depth1.name,
        breadCrumb: current ? current.breadCrumb || current.name : this.depth1.name,
        link: current ? current.link : this.depth1.link,
        children: current.children
      }
    },
    depth3 () {
      const detail = this.$route.meta.detail
      const depth2 = this.depth2
      const current = depth2.children.find(x => x.id === detail)
      return {
        id: current ? current.id : depth2.id,
        name: current ? current.name : depth2.name,
        breadCrumb: current ? current.breadCrumb || current.name : depth2.name,
        link: current ? current.link : this.depth2.link
      }
    }
  },
  data () {
    return {
      page: {
        id: 'match',
        name: 'MATCH',
        link: '/match'
      },
      tab: [
        { id: 'schedule', name: 'A팀 일정/결과', breadCrumb: 'A팀', link: '/match/schedule', // breadCrumb: 브레드크럼용 짧은 이름, 없으면 name
          children: [
            { id: 'list', name: '경기일정', link: '/match/schedule/list' },
            { id: 'result', name: '경기결과', link: '/match/schedule/result' }
          ]
        },
        { id: 'k4', name: 'B팀(K4) 일정/결과', breadCrumb: 'B팀(K4)', link: '/match/k4',
          children: [
            { id: 'list', name: '경기일정', link: '/match/k4/list' },
            { id: 'result', name: '경기결과', link: '/match/k4/result' }
          ]
        },
        { id: 'u18', name: 'U18', link: '/match/U18',
          children: [
            { id: 'list', name: '경기일정', link: '/match/u18/list' },
            { id: 'result', name: '경기결과', link: '/match/u18/result' }
          ]
        },
        { id: 'u15', name: 'U15', link: '/match/U15',
          children: [
            { id: 'list', name: '경기일정', link: '/match/u15/list' },
            { id: 'result', name: '경기결과', link: '/match/u15/result' }
          ]
        },
        { id: 'ranking', name: '리그 순위', link: '/match/ranking',
          children: [
            { id: 'kleague', name: 'K리그', link: '/match/ranking/kleague' },
            { id: 'k4', name: 'K4', link: '/match/ranking/k4' },
            { id: 'acl', name: 'ACL', link: '/match/ranking/acl' },
          ]
        },
        { id: 'record', name: '개인 순위', link: '/match/record',
          children: [
            { id: 'goal', name: '득점순위', link: '/match/record/goal' },
            { id: 'assist', name: '도움순위', link: '/match/record/assist' },
            { id: 'game', name: '선수별 출전기록', link: '/match/record/game' }
          ]
        }
      ]
    }
  }
}
</script>
