<template>
  <div>
    <Intro />
  </div>
</template>

<script>
import Intro from '@/components/History/Intro.vue'
export default {
  name: 'HistoryView',
  components: {
    Intro
  }
}
</script>
