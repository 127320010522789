<template>
  <div class="gnb">
    <div class="gnbMenu">
      <!-- gnb bar -->
      <div class="gnbBox">
        <div class="container">
          <!-- mobile only -->
          <router-link :to="{name: 'main'}" class="displayMobile mobileLogo"></router-link>
          <!-- router meta data 있는 경우(media router 참고) -->
          <router-link v-if="historyBack" :to="historyBack" class="mobileBack"></router-link>
          <!-- 없는 경우 mypage 아이콘 -->
          <router-link v-else-if="$route.name !== 'main'" :to="{name: isLogin ? 'mypage' : 'signin'}" class="displayMobile mobileMypage"></router-link>
          <!-- //mobile only -->
          <button type="button" class="btnOpen" @click="toggleMegaMenu">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
          </button>
          <ul class="mainMenuBox">
            <li v-for="main in mainMenu" :key="main.name" class="mainMenu" :class="{ active: main.name === current }"
              @click="goMenu(main)" @mouseenter="openMenu(main)">
              {{ main.title }}
            </li>
          </ul>
        </div>
      </div>
      <!-- //gnb bar -->
      <!-- gnb hover:: submenu -->
      <div v-if="current && subMenu" class="container">
        <div class="subMenuBox">
          <ul class="subMenus">
            <li v-for="sub in subMenu" :key="sub.name" class="subMenu">
              <!-- 외부링크 연결 -->
              <a v-if="sub.link" :href="sub.link">{{ sub.title }}</a>
              <!-- //외부링크 연결 -->
              <!-- 추후 주차장 안내 우선으로 들어가기 해지시 삭제 요망 -->
<!--              <router-link v-else-if="sub.title === '경기장안내'" :to="{name : 'ticketStadiumParking'}">경기장 안내</router-link>-->
              <!--// 추후 주차장 안내 우선으로 들어가기 해지시 삭제 요망 -->
              <router-link :to="{ name: sub.name, ...sub.params && { params: sub.params } }">
                {{ sub.title }}
              </router-link>
            </li>
          </ul>
          <!-- submenu content -->
          <SubContent :subContent="current" />
          <!-- //submenu content -->
        </div>
      </div>
      <!-- //gnb hover:: submenu -->
    </div>
    <!-- 메가메뉴 -->
    <MegaMenu :class="{active: megaMenu}" :menu="menu" @close="toggleMegaMenu" />
    <!-- //메가메뉴 -->
  </div>
</template>

<script>
import MegaMenu from './MegaMenu'
import SubContent from './SubContent'
import MENU from '@/common/menu'
import http from '@/api/index'
import dayjs from 'dayjs'
import lib from '@/library/common'
import imgPath from "@/library/imgPath"

export default {
  name: 'GnbView',
  props: [
    'current',
    'isLogin'
  ],
  components: {
    MegaMenu,
    SubContent
  },
  computed: {
    mainMenu() {
      return this.menu.filter(x => x.name && x.menuBar !== false)
    },
    subMenu() {
      const current = this.current
      const main = this.menu.find(x => x.name === current)
      const sub = main && main.children
      const intro = main && main.intro
      return !intro && sub
    },
    historyBack () {
      return this.$route.meta && this.$route.meta.historyBack
    },
    detectRollover () {
      return this.$route.meta && this.$route.meta.detectRollover
    }
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.init()
        this.$emit('closeMenu')
      }
    }
  },
  created() {
    this.menu = MENU
    let webView = false;
    if (['quiz', 'roulette'].includes(this.$route.name)) {
      webView = true;
    }

    if (!webView) {
      this.api();
    }
  },
  data() {
    return {
      megaMenu: false,
      menu: [],
      introMenu: [ // 마우스오버시 서브메뉴가 노출되는 타입이 아닌 클릭하여 인트로 페이지로 바로 이동
        'club',
        'history',
        'record'
      ]
    }
  },
  methods: {
    init() {
      this.megaMenu = false
      document.body.classList.remove('overflowBody')
    },
    toggleMegaMenu() {
      this.megaMenu = !this.megaMenu
      document.body.classList.toggle('overflowBody')
    },
    isIntroMenu(menuName) {
      return this.introMenu.includes(menuName)
    },
    openMenu(menu) {
      const detectRollover = this.detectRollover
      if (!detectRollover) {
        this.$emit('openMenu', menu)
      }
    },
    goMenu(menu) {
      const menuName = menu.name || null
      // const detectRollover = this.detectRollover
      if (menuName && this.isIntroMenu(menuName)) {
        return this.$router.push({ name: menu.intro })
      }
      if (menu.children && menu.children[0].name === 'fanzoneFaq') {
        return this.$router.push('/fanzone/faq/all')
      }
      if (menu.children && menu.children[0].name === 'mediaNotice') {
        return this.$router.push('/media/all')
      }
      if (menu.children && menu.children[0].name === 'teamPlayer') {
        return this.$router.push('/team/proteam/all')
      }
      return this.$router.push({ name: menu.children? menu.children[0].name : menu.name })
      // if (detectRollover) { // 롤오버 하지않고 첫번째 하위메뉴 링크
      // this.$router.push({ name: menu.children? menu.children[0].name : menu.name })
      // }
    },
    async api() {
      const eventList_res = await http.get('/nav/event')// 이벤트
      this.$store.state.nav.eventList = eventList_res.data.data.list
      const topGoal_res = await http.get('/nav/top_goal')// 최다득점 선수
      const topAssist_res = await http.get('/nav/top_assist')// 최다도움 선수
      const topWork_res = await http.get('/nav/top_work')// 최다출장 선수
      const topLossless_res = await http.get('/nav/top_lossless')// 최다무실점 선수
      const lastMatch_res = await http.get('/nav/last_match')// 지난경기
      const nextMatch_res = await http.get('/nav/next_match')// 다음경기
      const homeMatch_res = await http.get('/nav/next_home_match')// 다음 홈 경기
      const ticketBanner_res = await http.get('/nav/ticket_banner')// 티켓 배너
      const ticketNotice_res = await http.get('/nav/ticket_notice')// 티켓 공지사항 : 현재 데이터 없음
      const board_res = await http.get('/nav/media')// 최근게시물
      const notice_res = await http.get('/nav/notice')// 공지사항
      const news_res = await http.get('/nav/news')// 뉴스
      const eventBanner_res = await http.get('/nav/event_banner')// 이벤트 배너

      lastMatch_res.data.data.map(row => {
        // row.leagueName = lib.getLeagueName(row.meet_name);
            row.isKleage = false
            switch (row.meet_name) {
              case 'K League 1':
                row.game_league = 'kleague'
                row.game_league_set = 'kleague'
                row.isKleage = true
                break
              case 'ACL':
                row.game_league = 'afc'
                row.game_league_set = 'afc'
                break
              case 'koreancup':
                row.game_league = 'KOREAN CUP'
                if (this.currentYear < 2024) {
                  row.game_league_set = 'facup'
                } else {
                  row.game_league_set = 'koreancup'
                }
                break
              case 'ETC':
                row.game_league = 'etc'
                row.game_league_set = 'etc'
                break
            }

        row.game_date_set = dayjs(row.game_date).format('YYYY.MM.DD')

        if (row.home_team === 'K05') {
          row.front_home_team_name = row.home_team_name;
          row.front_home_team = row.home_team;
          row.front_home_team_goal = row.home_team_goal;
          row.back_away_team_name = row.away_team_name;
          row.back_away_team = row.away_team;
          row.back_home_team_goal = row.away_team_goal;
          row.home = true
        } else {
          row.front_home_team_name = row.away_team_name;
          row.front_home_team = row.away_team;
          row.front_home_team_goal = row.away_team_goal;
          row.back_away_team_name = row.home_team_name;
          row.back_away_team = row.home_team;
          row.back_home_team_goal = row.home_team_goal;
          row.home = false
        }
            row.homeLogoImg = imgPath.setTeamLogo(row.front_home_team)
            row.awayLogoImg = imgPath.setTeamLogo(row.back_away_team)
      }
      )

      nextMatch_res.data.data.map(row => {

        row.game_date_set = dayjs(row.game_date).format('YYYY.MM.DD')
        row.game_time_set = row?.game_time.slice(0, 5)

        if (row.home_team === 'K05') {
          row.home = true
        } else {
          row.home = false
        }
        row.homeLogoImg = imgPath.setTeamLogo(row.home_team)
        row.awayLogoImg = imgPath.setTeamLogo(row.away_team)
      })

      homeMatch_res.data.data.map(row => {
        row.game_date_set = dayjs(row.game_date).format('YYYY.MM.DD')
        row.game_time_set = row?.game_time.slice(0, 5)
        row.homeLogoImg = imgPath.setTeamLogo(row.home_team)
        row.awayLogoImg = imgPath.setTeamLogo(row.away_team)
      })

      ticketBanner_res.data.data.list.map(row => {
        if (row.seq === 0) {
          row.newWindow = true
        }
      })

      board_res.data.data.board.map(row => {
        if (row.type === 'news') {
          row.category = '구단뉴스'
        }
        if (row.type === 'notice') {
          row.category = '공지사항'
        }
      })

      const nowTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
      eventBanner_res.data.data.list.map(row => {
        row.reserve_start_date = dayjs(row.reserve_start_date).format('YYYY-MM-DD HH:mm:ss')
        row.reserve_end_date = dayjs(row.reserve_end_date).format('YYYY-MM-DD HH:mm:ss')
        if (nowTime < row.reserve_start_date) {
          row.eventState = '예정'
        }
        if (nowTime > row.reserve_end_date) {
          row.eventState = '종료'
        }
        if (row.reserve_start_date <= nowTime || nowTime <= row.reserve_end_date) {
          row.eventState = "진행중"
        }
      })

      eventList_res.data.data.list.map(row => {
        row.start_apply = dayjs(row.start_apply).format('YYYY-MM-DD HH:mm:ss')
        row.end_apply = dayjs(row.end_apply).format('YYYY-MM-DD HH:mm:ss')
        if (nowTime < row.start_apply) {
          row.eventState = '예정'
        } else if (nowTime > row.end_apply) {
          row.eventState = '종료'
        } else{
          row.eventState = "진행중"
        }

        if(row.event_kinds === '신청'){
          row.event_kind = '이벤트';
        } else if(row.event_kinds === '투표'){
          row.event_kind = '투표';
        }
      })

      this.$store.state.nav.topGoal = topGoal_res.data.data
      this.$store.state.nav.topAssist = topAssist_res.data.data
      this.$store.state.nav.topWork = topWork_res.data.data
      this.$store.state.nav.topLossless = topLossless_res.data.data

      this.$store.state.nav.prevMatch = lastMatch_res.data.data
      this.$store.state.nav.nextMatch = nextMatch_res.data.data
      this.$store.state.nav.homeMatch = homeMatch_res.data.data

      this.$store.state.nav.banner = ticketBanner_res.data.data.list
      this.$store.state.nav.bannerTotal = ticketBanner_res.data.data.total
      this.$store.state.nav.ticketNotice = ticketNotice_res.data.data

      this.$store.state.nav.board = board_res.data.data
      this.$store.state.nav.notice = notice_res.data.data
      this.$store.state.nav.news = news_res.data.data.list

      this.$store.state.nav.eventBanner = eventBanner_res.data.data.list
      this.$store.state.nav.eventList = eventList_res.data.data.list

    }
  }
}
</script>
