import Index from '@/views/Policy.vue'
import Privacy from '@/components/Policy/Privacy.vue'
import Service from '@/components/Policy/Service.vue'
import Youtube from '@/components/Policy/Youtube.vue'

const policy = [
  {
    path: '/policy',
    name: 'policy',
    component: Index,
    redirect: {
      name: 'policyPrivacy'
    },
    children: [
      {
        path: 'privacy',
        redirect: {
          name: 'policyPrivacy',
          params: {
            version: 'latest'
          }
        }
      },
      {
        path: 'privacy/:version',
        name: 'policyPrivacy',
        component: Privacy,
        meta: {
          policy: 'privacy'
        }
      },
      {
        path: 'service',
        name: 'policyService',
        component: Service,
        meta: {
          policy: 'service'
        }
      },
      {
        path: 'youtube',
        name: 'policyYoutube',
        component: Youtube,
        meta: {
          policy: 'youtube'
        }
      }
    ]
  }
]

export default policy
