<template>
  <div>
    <div class="listInfo">
      <div class="title hiddenMobile">시즌권 구매내역</div>
      <div class="sort">
        <div class="filter">
          <!-- filter -->
          <div class="filterItem shortcut">
            <button
              v-for="s in shortcuts"
              :key="s.id"
              type="button"
              @click="setTerm(s.id)"
              :class="{current: s.id === shortcut}">
              <span>{{s.name}}</span>
            </button>
          </div>
          <!-- //filter -->
          <!-- datepicker -->
          <div class="filterItem datePicker hiddenMobile">
            <date-picker v-model="term.from" valueType="format"></date-picker> ~
            <date-picker v-model="term.to" valueType="format"></date-picker>
            <button type="button" class="btn whiteLightgrey2 auto btnSearch" @click="search">검색</button>
          </div>
          <!-- //datepicker -->
        </div>
      </div>
    </div>
    <div class="dataTable">
      <div>
        <table>
          <thead>
            <tr>
              <th>예매번호</th>
              <th>구매상품</th>
              <th>구매일시</th>
              <th>구매매수</th>
              <th>예매상태</th>
              <th>기능</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="no">1427810500</td>
              <td class="left">
                <div class="league">전북현대 2023시즌 시즌권</div>
                <div class="bold">일반석 성인권 외 1매</div>
              </td>
              <td class="date">2022.03.12(일) 14:00</td>
              <td class="qty"><span class="no2">1427810500</span>2매</td> <!-- 디자인상 모바일용 데이터로 번호 한번 더 넣어야함 -->
              <td class="status">정상구매</td>
              <td class="btns">
                <button type="button" class="btn w100">구매취소</button>
              </td>
            </tr>
            <tr>
              <td class="no">1427810500</td>
              <td class="left">
                <div class="league">전북현대 2023시즌 시즌권</div>
                <div class="bold">일반석 성인권 외 1매</div>
              </td>
              <td class="date">2022.03.12(일) 14:00</td>
              <td class="qty"><span class="no2">1427810500</span>2매</td> <!-- 디자인상 모바일용 데이터로 번호 한번 더 넣어야함 -->
              <td class="status">전체취소</td>
              <td class="btns">
                <button type="button" class="btn w100" disabled>취소완료</button>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :perPage="6"
          :pages="90" />
      </div>
      <div class="noData">
        시즌권 구매내역이 없습니다
        <div><a href="#" class="btn primary2 w100">시즌권 구매</a></div>
      </div>
    </div>
    
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Pagination from '@/components/Util/Pagination'

export default {
  name: 'MypagePaySeason',
  components: {
    DatePicker,
    Pagination
  },
  computed: {
    list () {
      /**
       * api가 기간내 모든 데이터를 반환하는듯 하여
       * 이중배열로 페이징에 따라 filter 하면 될듯합니다
       */
      return true
    }
  },
  created() {
    const termFrom = dayjs().startOf('year').format()
    const termTo = dayjs().format()
    this.term = {
      from: termFrom,
      to: termTo
    }
    this.init()
  },
  data () {
    return {
      shortcut: 'thisYear',
      shortcuts: [
        { id: 'thisMonth', name: '이번달' },
        { id: 'lastMonth', name: '지난달' },
        { id: 'thisYear', name: '올해' },
        { id: 'lastYear', name: '지난해' }
      ],
      term: {
        from: '',
        to: ''
      }
    }
  },
  methods: {
    init () {
    },
    setTerm (shortcut) {
      const term = this.term
      switch (shortcut) {
        case 'thisMonth':
          term.from = dayjs().startOf('month').format()
          term.to = dayjs().format()
          break
        case 'lastMonth':
          term.from = dayjs().add(-1, 'month').startOf('month').format()
          term.to = dayjs().add(-1, 'month').endOf('month').format()
          break
        case 'thisYear':
          term.from = dayjs().startOf('year').format()
          term.to = dayjs().format()
          break
        case 'lastYear':
          term.from = dayjs().add(-1, 'year').startOf('year').format()
          term.to = dayjs().add(-1, 'year').endOf('year').format()
          break
      }
      this.shortcut = shortcut
    },
    search () {
      const from = dayjs(this.term.from).toISOString()
      const to = dayjs(this.term.to).toISOString()
      // datepicker에서 isostring 자정 계산이 이상해서 dayjs.format(ISO 8601) 표기한 후 api 호출시에는 toISOString() 사용
      // console.log(this.term.from)
      // console.log(from)
      // console.log(this.term.to)
      // console.log(to)
    }
  }
}
</script>

<style lang="scss" scoped>
.no2 {
  display: none;
}
@media screen and (max-width: 767px) {
  tbody {
    .no {
      display: none;
    }
    .league {
      margin-bottom: 10px;
    }
    .date {
      margin-top: 20px;
      color: $grey3;
    }
    .qty,
    .status {
      display: inline-block;
      vertical-align: middle;
    }
    .no2 {
      display: inline-block;
      vertical-align: middle;
    }
    .no2:after,
    .status:before {
      content: "|";
      margin: 0 8px;
      color: $lightgrey1;
    }
  }
}
</style>
