import store from "@/store";
import axios from "axios";
import tokenCtrl from '@/library/token';

const baseURL = "https://api.jbfc.kr/mypage"; // 라이브
// const baseURL = "https://api-jbhd.thesports.io/mypage" // 개발
const instance = axios.create({
    baseURL: baseURL,
    headers: { "content-type": "application/json",},
    timeout: 30000,
    withCredentials: true,
    crossDomain: true,
    credentials: "include"
});

instance.interceptors.request.use((config) => {
    config.headers['x-access-token'] = store.state.token.access
    return config
})


instance.interceptors.response.use(
    res => {
        return res;
    }, async (err) => {

        // Token error check
        if (err.response.status === 401) {
            const chkToken = tokenCtrl.tokenErrChk(err);
            if(!chkToken){
                return false;
            }
        }

        return Promise.reject(err);

    }
)

export default instance;
