<template>
    <div class="introSection section1_1"  :style="{height: `${sectionHeight}px`}">
    <div class="section1_1Title">대표이사 인사말</div>
    <div class="section1_1Img"><img style="display: none" :src="require('@/assets/img/history/greeting.jpg')" alt=""></div>
    <div class="greeting" style="font-weight: 400">
      <div class="container" style="max-width: 1300px" v-html="greeting[lang]"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClubCeo',
  props: [
    'active',
    'height',
    'lang'
  ],
  computed: {
    isActive () {
      return this.active && this.active.index === 2
    },
    sectionHeight () {
      const height = this.height && this.height.minHeight ? parseInt(this.height.minHeight.replace(/[^0-9]/g, '')) : 4000
      // height * 4 추후 인물사진 기입 시 원복
      return height * 1.7
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onScroll()
    this.onResize()
  },
  data () {
    return {
      parallaxWidth: false,
      greeting: {
        ko: `
전북현대를 사랑해주시는 팬 여러분<br>
항상 큰 관심과 사랑으로 아껴 주셔서 감사드립니다.<br>
<br>
올해는 전북현대가 창단 30주년을 맞이하는 해입니다.<br>
지난 30여 년간 많은 성원을 보내주신 팬 여러분께 진심으로 감사드립니다.<br>
<br>
서른 살 청년이 된 전북현대는 올 시즌 더욱 많은 도전을 해 나가야 합니다.<br>
새롭고 힘차게 나아갈 이 여정에 팬 여러분께서 동행해 주실 것을 부탁드립니다.<br>
<br>
전북현대의 명성에 걸맞은 최고의 퍼포먼스로 팬들에게 보답하고<br>
우리 사회에 축구로 더 많은 가치를 실현하는 구단이 되도록 최선의 노력을 다하겠습니다.<br>
<br>
팬 여러분,<br>
전북현대가 창단 30주년을 맞아 열어갈 역사의 발자취에 아낌없는 성원을 보내 주십시오.<br>
여러분의 관심과 사랑이 전북현대를 한 걸음 더 나아가게 하는 큰 힘이 될 것입니다.<br>
감사합니다.<br>
        `,
        en: `
                       전북현대를 사랑해주시는 팬 여러분
항상 큰 관심과 사랑으로 아껴 주셔서 감사드립니다.

올해는 전북현대가 창단 30주년을 맞이하는 해입니다.
지난 30여 년간 많은 성원을 보내주신 팬 여러분께 진심으로 감사드립니다.

서른 살 청년이 된 전북현대는 올 시즌 더욱 많은 도전을 해 나가야 합니다.
새롭고 힘차게 나아갈 이 여정에 팬 여러분께서 동행해 주실 것을 부탁드립니다.

전북현대의 명성에 걸맞은 최고의 퍼포먼스로 팬들에게 보답하고
우리 사회에 축구로 더 많은 가치를 실현하는 구단이 되도록 최선의 노력을 다하겠습니다.

팬 여러분,
전북현대가 창단 30주년을 맞아 열어갈 역사의 발자취에 아낌없는 성원을 보내 주십시오.
여러분의 관심과 사랑이 전북현대를 한 걸음 더 나아가게 하는 큰 힘이 될 것입니다.
감사합니다.
        `
      }
    }
  },
  methods: {
    onScroll () {
      const isActive = this.isActive
      const section = document.querySelector('.section1_1')
      const scrollY = window.scrollY
      if (section) {
        const sectionTitle = section.querySelector('.section1_1Title')
        const img = section.querySelector('.section1_1Img')
        const imgObj = img.querySelector('img')
        const greeting = section.querySelector('.greeting')
        const scrollDiff = section.offsetTop - scrollY
        const scrollPercent = parseInt(this.active && this.active.scrollPercent) >= 33.33 ? 100 : parseInt(this.active && this.active.scrollPercent) * 3
        const pos = scrollDiff > 0 ? 0 : scrollDiff * -1
        const imgOffset = img.offsetTop
        const greetingPos = greeting.getBoundingClientRect().top
        if (isActive && section && this.parallaxWidth) {
          if (pos >= imgOffset) {
            sectionTitle.style.color = '#fff'
            img.style.transform = `translateY(${pos + 50 - imgOffset}px)`
            img.style.width = `${62.5 + ((37.5) * scrollPercent / 100)}%`
            img.style.width = '100%'
            imgObj.style.opacity = scrollPercent > 50 ? 0.5 : 1 - (scrollPercent / 100)
          } else {
            sectionTitle.style.color = ''
            img.style.transform = ''
            img.style.width = ''
            imgObj.style.opacity = ''
          }
          // title
          if (greetingPos > sectionTitle.offsetTop * 1.5) {
            sectionTitle.style.transform = `translateY(${pos}px)`
          }
        } else {
          sectionTitle.style.transform = ''
          sectionTitle.style.color = ''
          img.style.transform = ''
          img.style.width = ''
          imgObj.style.opacity = ''
        }
      }
    },
    onResize () {
      // resize
      this.parallaxWidth = window.innerWidth >= 1600
    }
  }
}
</script>
