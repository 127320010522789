<template>
  <component v-if="component"
    :is="component" />
</template>

<script>
export default {
  name: 'SubContent',
  props: [
    'subContent'
  ],
  computed: {
    loader () {
      const subContent = capitalizeFirstLetter(this.subContent)
      function capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
      if (!this.subContent) {
        return null
      }
      return () => import(`./${subContent}Content.vue`)
    }
  },
  watch: {
    subContent () {
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      component: null
    }
  },
  methods: {
    init () {
      this.loader()
        .then(() => {
          this.component = () => this.loader()
        })
        .catch(() => {
          this.component = null
        })
    }
  }
}
</script>
