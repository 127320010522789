<template>
  <div>
    <div class="listInfo">
      <div class="title hiddenMobile">티켓 예매내역</div>
      <div class="sort">
        <div class="filter">
          <!-- filter -->
          <div class="filterItem shortcut">
            <button
                v-for="s in shortcuts"
                :key="s.id"
                type="button"
                @click="setTerm(s.id)"
                :class="{current: s.id === shortcut}">
              <span>{{ s.name }}</span>
            </button>
            <button
                type="button"
                @click="setTerm('hidden')"
                :class="{current: 'hidden' === shortcut}"
            >
              <span>숨긴내역 <img
                  class="hiddenBtn"
                  :class="{current: 'hidden' === shortcut}"
                  src="~@/assets/img/ico_hidden.png"/></span>
            </button>
          </div>
          <!-- //filter -->
          <!-- datepicker -->
          <div class="filterItem datePicker hiddenMobile">
            <date-picker v-model="term.from" valueType="format"></date-picker>
            ~
            <date-picker v-model="term.to" valueType="format"></date-picker>
            <button type="button" class="btn whiteLightgrey2 auto btnSearch" @click="reserveList('search')">검색</button>
          </div>
          <!-- //datepicker -->
        </div>
      </div>
    </div>
    <div class="dataTable">
      <div>
        <table>
          <thead>
          <tr>
            <th>예매번호</th>
            <th>예매경기</th>
            <th>예매일시</th>
            <th>예매매수</th>
            <th>예매상태</th>
            <th>기능</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(data, i) in list" :key="i">
            <td class="no"><span>{{ data.reserveNo }}</span></td>
            <td class="left" @click="reserveDetailPopup(data.reserveNo)" style="cursor: pointer">
              <div class="league">[{{ data.leagueName }}]</div>
              <div class="bold" style="margin-bottom: 10px;">{{ data.scheduleName }}</div>
              <div v-for="(ticket, index) in data.seatDetails" :key="ticket.seatNo" style="margin-bottom: 2px;">
                <div class="seat">{{ ticket.productGradeName }} - {{ ticket.seatInfo }}({{ ticket.denominationName }}/{{ ticket.ticketNo }})</div>
              </div>
            </td>
            <td class="date">{{ data.reserveDate }}({{ data.yoil }}) {{ data.reserveTime }}</td>
            <td class="qty"><span class="no2"><span>{{data.reserveNo}}</span></span>{{ data._reserveCount }}매
            </td> <!-- 디자인상 모바일용 데이터로 예매내역 한번 더 넣어야함 -->
            <td class="status" :class="data.cashReceiptStateName === 'POSSIBLE' ? 'pb63' : ''">
              {{ data.reserveState }}({{data.paymentName}})
                <button v-if="data.cashReceiptStateName === 'POSSIBLE'" class="cashReceipt no3" @click="cashReceipt(data.reserveNo)">
                  <img class="hiddenBtn"
                      src="~@/assets/img/ico_cashReceipt.png" alt=""/>
                  현금영수증 신청</button></td>
            <td class="btns">
                <button type="button" class="btn w100" v-if="data.reserveStateCode === 'ALL_CANCEL'" disabled style="cursor: no-drop">취소완료</button>
                <button type="button" class="btn w100" v-else-if="data.cancelState === true" disabled  style="cursor: no-drop">취소불가</button>
                <button type="button" class="btn w100" v-else @click="setCancel(data.reserveNo, i)">예매취소</button>
                <button
                    @click="hideTicket(data.reserveNo)"
                    class="hiddenText">{{hidden ? '숨김 해제' : '내역 숨기기'}}
                  <img class="hiddenBtn" src="~@/assets/img/ico_hidden.png"/>
                </button>
            </td>
          </tr>
          </tbody>
        </table>
        <Pagination
            v-if="listData!==null && list.length !== 0"
            :perPage="6"
            :pages="listData.length"
            @change="change"
        />
      </div>
      <div class="noData" v-if="listData===null || list.length === 0">
        {{hidden ? '숨긴 예매내역이 없습니다' : '예매내역이 없습니다'}}
        <div><router-link to="/ticket/reservation/intro" class="btn primary2 w100">티켓예매</router-link></div>
      </div>
    </div>
    <ConfirmLayer
        v-if="confirmLayer"
        @close="closeConfirmLayer"
        @cancel="closeConfirmLayer"
        :checkBox="this.checkBox"
        :seatDetails="this.seatDetails"
        @confirm="confirm"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Pagination from '@/components/Util/Pagination'
import ConfirmLayer from "@/components/Util/ConfirmLayer"
import { beforeCancelCheck, cancelReserve, mypagePopup, reserveDetailPopup, reserveListCheck, reservePopup, getReceipt } from '@/library/ticketlink'
import http from '@/api/index'
import 'dayjs/locale/ko'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import router from '@/router'
dayjs.extend(isSameOrBefore)
dayjs.locale('ko')

export default {
  name: 'MypagePayTicket',
  components: {
    DatePicker,
    Pagination,
    ConfirmLayer
  },
  computed: {
    list() {
      let listData = this.listData === null ? this.listData : [...this.listData].reverse()
      const pageNum = this.pageNum
      const start = pageNum * 6
      const end = start + 6
      return listData === null ? false : listData.slice(start, end) //추후 api사용시 수정해줘야함
    }
  },
  created() {
    this.pages = 0
    const termFrom = dayjs().startOf('year').format()
    const termTo = dayjs().format()
    this.term = {
      from: termFrom,
      to: termTo
    }
    this.setEncryptedId()
  },
  data() {
    return {
      hidden: false,
      shortcut: 'thisYear',
      shortcuts: [
        {id: 'thisMonth', name: '이번달'},
        {id: 'lastMonth', name: '지난달'},
        {id: 'thisYear', name: '올해'},
        {id: 'lastYear', name: '지난해'}
      ],
      term: {
        from: '',
        to: ''
      },
      encryptedId: '',
      seatDetails: [],
      reserveNo: '',
      pageNum: 0,
      listData: [],
      ticketNum: [],
      confirmOption: {
        message: ''
      },
      confirmLayer: false,
      checkBox: false
    }
  },
  methods: {
    async cashReceipt(reserveNo){
      const data = {
        "encryptedId": this.encryptedId,
        "reserveNo": reserveNo,
      }
      const res = await getReceipt(data)
      await window.open(res.data,'tl', 'width=1010, height=600, left=100, top=100, scrollbars=yes')
    },
    hideTicket (reserveNum) {
      const data = {
        "encryptedId": this.encryptedId,
        "reserveNo": reserveNum,
      }
      http.post('/ticketlink/ticketviewstatus',data)
          .then(res => {
            alert(res.data.message)
            this.shortcut = 'thisYear'
            return this.setTerm(this.shortcut)
          })
    },
    async setRefund(reserveNo, num) {
      this.reserveNo = reserveNo
      this.confirmOption.message = '환불계좌를 등록하시겠습니까?'
      this.confirmLayer = true
      this.checkBox = true
      this.seatDetails = this.listData[num].seatDetails
    },
    getEncryptedId() {
      if(this.$store.state.user.encrypted_id !== null) {
        this.encryptedId = this.$store.state.user.encrypted_id
      } else {
        this.encryptedId = ''
      }
    },
    async setEncryptedId() {
      const data = await http.get('/app/myInfo')
      const encryptedId = data.data.body.ticketlink_encryptId
      this.encryptedId = encryptedId
      await this.reserveList()
    },
    async reserveList(type) {
      const data = {
        'encryptedId': this.encryptedId,
        'startDate': this.term.from,
        'endDate': this.term.to
      }

      if (type === 'search') {
        data.startDate = dayjs(this.term.from).subtract(1, 'day').format('YYYY-MM-DD')
        data.endDate = dayjs(this.term.to).format('YYYY-MM-DD')
      }
      const listData = await reserveListCheck(data)
      const nowDate = dayjs().format('YYYY-MM-DD HH:mm')
      listData.data?.map((row, i) => {
        row.num = i
        row.yoil = dayjs(row.reserveDate).format('dd')
        row.reserveDate = dayjs(row.reserveDate).format('YYYY.MM.DD')
        row.cancelState = false
        if(dayjs(row.cancelableDatetime).isSameOrBefore(nowDate)){
          row.cancelState = true
        }
        row.reserveTime = row.reserveTime.replace(/(.{2})/, "$1:")
        row.nonCancleTieckNum = 0
        row._reserveCount = row.reserveCount
        row.seatDetails.map(el =>{
          if(row.cancelState === false && el.cancelYn === 'N'){
            row.nonCancleTieckNum = row.nonCancleTieckNum + 1
            row._reserveCount = row.nonCancleTieckNum
          }
        })
      })
      let obj = []
      let obj_ = []
      if (listData.data !== null ) {
        listData.data.filter( el => {
          if (type === 'hidden') {
            if (el.hideYn === true) {
              obj.push(el)
            }
          } else {
            if (el.hideYn !== true) {
              obj.push(el)
            }
          }
        })
        obj.reduce(function(acc, current) { // 중복되는 예매번호 없애기
          if (acc.findIndex(({ reserveNo }) => reserveNo === current.reserveNo) === -1) {
            acc.push(current);
          }
          return obj_ = acc
        }, []);
      }
      this.listData = obj_
    },
    reserveDetailPopup(reserveNo) {
      this.getEncryptedId()
      if(this.encryptedId !== '') {
        window.open(reserveDetailPopup(this.encryptedId, reserveNo),'tl', 'width=1010, height=600, left=100, top=100, scrollbars=yes')
      } else {
        alert("통합회원 로그인 후 이용해주세요.")
        const redirectUrl = router.currentRoute.path;
        router.push({name: 'signin', query: {redirectUrl: redirectUrl}});
      }
    },
    async setCancel(num, i) { // 티켓 취소

      // 무통장입금일 때 티켓링크 팝업으로 연결
      if (this.list[i].paymentName === "무통장입금") {
        const reserveNo = this.listData[i].reserveNo
        this.reserveDetailPopup(reserveNo)
        return
      }

      // 취소 가능한 티켓 목록 구성
      this.list[i].seatDetails.forEach(x => {
        if (x.cancelYn !== 'Y') {
          this.ticketNum.push(x.ticketNo)
          this.seatDetails.push({
            'ticketNo': x.ticketNo,
            'denominationName': x.denominationName,
            'seatInfo': x.seatInfo
          })
        }
      })

      let setCheck = false
      let partialCancelable = false
      this.reserveNo = num
      const data = {
        'encryptedId': this.encryptedId,
        'reserveNo': num,
        'refundTicketNos': this.ticketNum
      }

      // 취소 전 정보 조회
      try {
        const res = await beforeCancelCheck(data)
        let confirmTmp = ''

        if (this.list[i].paymentType === '01' || this.list[i].paymentType === '31') {
          if (res.data.partialCancelable === true) {
            confirmTmp = `예매를 취소하시겠습니까?\n다음 단계에서 취소하실 티켓을 선택하세요.`
          } else {
            confirmTmp = `해당 예매내역은 전체 취소로 진행됩니다.\n정말 예매 취소를 진행하시겠습니까?`
          }
        } else {
          confirmTmp = `해당 예매내역은 전체 취소로 진행됩니다.\n정말 예매 취소를 진행하시겠습니까?`
        }

        if (window.confirm(confirmTmp)) {
          if (res.success === false) {
            alert(res.originMessage)
            return
          }

          res.data.cancelFlag !== 'Y' ? setCheck = false : setCheck = true
          res.data.partialCancelable === true ? partialCancelable = true : partialCancelable = false

          if (setCheck === false) {
            this.seatDetails = []
            this.ticketNum = []
            return alert('취소처리가 불가능합니다.')
          }

          if (setCheck === true && partialCancelable === true) {
            this.confirmLayer = true
            this.checkBox = true
            return
          }

          // 티켓 취소
          const cancelRes = await cancelReserve(data)
          if (cancelRes.success === false) {
            this.seatDetails = []
            this.ticketNum = []
            return alert('다시 시도해주세요.')
          }

          if (cancelRes.code !== 0) {
            this.seatDetails = []
            this.ticketNum = []
            alert('정상적으로 처리되었습니다.')
            return this.reserveList()
          } else {
            this.seatDetails = []
            this.ticketNum = []
          }
        }
      } catch (error) {
        console.error(error)
        alert('오류가 발생했습니다. 다시 시도해주세요.')
      }
    },
    setTerm(shortcut) {
      const term = this.term
      switch (shortcut) {
        case 'thisMonth':
          term.from = dayjs().startOf('month').format()
          term.to = dayjs().format()
          break
        case 'lastMonth':
          term.from = dayjs().add(-1, 'month').startOf('month').format()
          term.to = dayjs().add(-1, 'month').endOf('month').format()
          break
        case 'thisYear':
          term.from = dayjs().startOf('year').format()
          term.to = dayjs().format()
          break
        case 'lastYear':
          term.from = dayjs().add(-1, 'year').startOf('year').format()
          term.to = dayjs().add(-1, 'year').endOf('year').format()
          break
        case 'hidden':
          term.from = ''
          term.to = ''
          break
      }
      this.shortcut = shortcut
      if (this.shortcut === 'hidden') {
        this.hidden = true
        this.reserveList('hidden')
      } else {
        this.hidden = false
        this.reserveList()
      }

    },
    change(el) {
      //페이징네이션
      this.pageNum = el - 1
      return this.pageNum
    },
    closeConfirmLayer() {
      this.confirmLayer = false
      this.checkBox = false
      return (this.seatDetails = [], this.reserveNo = '')
    },
    confirm(ticketData) {
      this.confirmLayer = false
      this.seatDetails = []
      const data = {
        'encryptedId': this.encryptedId,
        'reserveNo': this.reserveNo,
        'refundTicketNos': ticketData
      }
      cancelReserve(data) // 부분취소를 위한 함수
          .then(res => {
            if (res.success === false) {
              this.seatDetails = []
              this.ticketNum = []
              return alert('다시 시도해주세요.')
            }
            if (res.code !== 0) {
              this.seatDetails = []
              this.ticketNum = []
              alert('정상적으로 처리되었습니다.')
              return this.reserveList()
            }
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.pb63 {
  padding-bottom: 63px;
}
.cashReceipt{
  color: #999999;
  padding-top: 15px;
  padding-left: 31px;
  display: block;
  margin: auto;
  position: absolute;
}
.no2 {
  display: none;
}
.hiddenBtn {
  margin-bottom: 2px;
}

.mobileHidden {
  display: block;
}

.hiddenBtn.current  {
  background-image: url("~@/assets/img/ico_hidden_green.png");
}
.hiddenText {
  color: #999999;
  padding-top: 10px;
  display: block;
  margin: auto;
}
.hiddenText:hover {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .cashReceipt{
    color: #999999;
    padding-top: 10px;
    display: block;
    margin: auto;
    position: inherit;
    padding-left: 0px;
  }
  .pb63 {
    padding-bottom: 0px;
  }
  tbody {
    .no {
      display: none;
    }

    .league {
      margin-bottom: 10px;
    }

    .date {
      margin-top: 20px;
      color: $grey3;
    }

    .qty,
    .status {
      display: inline-block;
      vertical-align: middle;
    }

    .no2 {
      display: inline-block;
      vertical-align: middle;
    }

    .no3 {
      display: inline-block;
      vertical-align: middle;
      padding-top: 0px;
    }

    .no3:before {
      content: "|";
      margin: 0 8px;
      color: $lightgrey1;
    }

    .no2:after,
    .status:before {
      content: "|";
      margin: 0 8px;
      color: $lightgrey1;
    }
  }
}

@media screen and (max-width: 457px) {
  .listInfo .filter .shortcut {
    font-size: 11px;
  }
}
</style>
