<template>
  <div class="ticketPolicy">
    <table>
      <thead>
      <tr>
        <th colspan="2" style="width: 25%">구분</th>
        <th>성인</th>
        <th>청소년</th>
        <th>어린이</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td rowspan="2" class="th">E/N/S존</td>
        <td class="th">현장구매</td>
        <td>{{ comma(16000) }}</td>
        <td>{{ comma(10000) }}</td>
        <td>{{ comma(8000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td>{{ comma(14000) }}</td>
        <td>{{ comma(8000) }}</td>
        <td>{{ comma(6000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">E존 센터석<br>(E1- D~E)</td>
        <td class="th">현장구매</td>
        <td colspan="2">{{ comma(18000) }}</td>
        <td>{{ comma(11000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="2">{{ comma(16000) }}</td>
        <td>{{ comma(9000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">W존 엣지</td>
        <td class="th">현장구매</td>
        <td>{{ comma(21000) }}</td>
        <td>{{ comma(13000) }}</td>
        <td>{{ comma(11000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td>{{ comma(19000) }}</td>
        <td>{{ comma(11000) }}</td>
        <td>{{ comma(9000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">W존 벨류</td>
        <td class="th">현장구매</td>
        <td>{{comma(23000)}}</td>
        <td>{{comma(16000)}}</td>
        <td>{{comma(13000)}}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td>{{comma(21000)}}</td>
        <td>{{comma(14000)}}</td>
        <td>{{comma(11000)}}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">벤치존 H(1D)</td>
        <td class="th">현장구매</td>
        <td colspan="2">{{ comma(33000) }}</td>
        <td>{{ comma(23000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="2">{{ comma(29000) }}</td>
        <td>{{ comma(19000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">벤치존 H(1C)</td>
        <td class="th">현장구매</td>
        <td colspan="2">{{ comma(30000) }}</td>
        <td>{{ comma(21000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="2">{{ comma(26000) }}</td>
        <td>{{ comma(17000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">벤치존 A(1M)</td>
        <td class="th">현장구매</td>
        <td colspan="2">{{ comma(27000) }}</td>
        <td>{{ comma(19000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="2">{{ comma(23000) }}</td>
        <td>{{ comma(17000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">벤치존 A(1N)</td>
        <td class="th">현장구매</td>
        <td colspan="2">{{ comma(25000) }}</td>
        <td>{{ comma(18000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="2">{{ comma(21000) }}</td>
        <td>{{ comma(15000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">W존 센터석</td>
        <td class="th">현장구매</td>
        <td colspan="2">{{ comma(40000) }}</td>
        <td>{{ comma(35000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="2">{{ comma(36000) }}</td>
        <td>{{ comma(31000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">테이블석 H <br>(1인 가격)</td>
        <td class="th">현장구매</td>
        <td colspan="2">{{ comma(40000) }}</td>
        <td>{{ comma(35000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="2">{{ comma(36000) }}</td>
        <td>{{ comma(31000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">커플석 H <br>(1인 가격)</td>
        <td class="th">현장구매</td>
        <td colspan="3">{{ comma(40000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="3">{{ comma(36000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">테이블석 A <br>(1인 가격)</td>
        <td class="th">현장구매</td>
        <td colspan="2">{{ comma(35000) }}</td>
        <td>{{ comma(30000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="2">{{ comma(31000) }}</td>
        <td>{{ comma(26000) }}</td>
      <tr>
        <td rowspan="2" class="th">커플석 A <br>(1인 가격)</td>
        <td class="th">현장구매</td>
        <td colspan="3">{{ comma(35000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="3">{{ comma(31000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">패밀리석 A <br>(1인 가격)</td>
        <td class="th">현장구매</td>
        <td colspan="2">{{ comma(29000) }}</td>
        <td>{{ comma(24000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="2">{{ comma(25000) }}</td>
        <td>{{ comma(20000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">센트럴석</td>
        <td class="th">현장구매</td>
        <td colspan="2">{{ comma(30000) }}</td>
        <td>{{ comma(25000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="2">{{ comma(26000) }}</td>
        <td>{{ comma(21000) }}</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">사이드 테이블석</td>
        <td class="th">현장구매</td>
        <td colspan="2">{{ comma(23000) }}</td>
        <td>{{ comma(13000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td colspan="2">{{ comma(21000) }}</td>
        <td>{{ comma(11000) }}</td>
      </tr>
      <tr>
        <td class="th" style="border-left: none">스카이박스</td>
        <td class="th">일반</td>
        <td colspan="3">{{ comma(1200000) }}(최대 12인)</td>
      </tr>
      <tr>
        <td rowspan="2" class="th">S존(원정석)</td>
        <td class="th">현장구매</td>
        <td>{{ comma(16000) }}</td>
        <td>{{ comma(10000) }}</td>
        <td>{{ comma(8000) }}</td>
      </tr>
      <tr>
        <td class="th">예매</td>
        <td>{{ comma(14000) }}</td>
        <td>{{ comma(8000) }}</td>
        <td>{{ comma(6000) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import regExp from '@/common/regExp'

export default {
  name: 'TicketPolicy',
  methods: {
    comma(num) {
      return regExp.comma(regExp.numberOnly(num))
    }
  }
}
</script>
