<template>
  <div>
    <div class="sectionTitle line">좌석 안내</div>
    <div class="" v-if="state">내용</div>
    <!-- 좌석 배치도 -->
    <div class="indexBox stadiumSeat" v-if="!state">
      <div class="container">
        <div class="sectionTitle">좌석 배치도</div>
        <Seats />
      </div>
    </div>
    <!-- //좌석 배치도 -->
    <!-- 좌석별 소개 -->
    <div class="indexBox" v-if="state">
      <div class="container">
        <div class="sectionTitle">좌석별 소개</div>
        <div class="seatIntro">
          <div
              v-for="seat in seatIntro"
              :key="seat.id"
              class="seat">
            <div class="img"><img :src="require(`@/assets/img/img_seat_${seat.id}.png`)" alt=""></img></div>
            <p class="name">{{seat.name}}</p>
            <div class="desc">{{seat.desc}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- //좌석별 소개 -->
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 80px;" v-if="state">
      <div class="container">
        <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
      </div>
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
import TicketPolicy from "@/components/Ticket/TicketPolicy.vue"
import Seats from "@/components/Util/Seats.vue"

export default {
  name: 'TicketStadiumIntro',
  components: {Seats, TicketPolicy},
  data () {
    return {
      state: false, // 티켓 임시페이지 사용시 false
      seatIntro: [
        { id: 'wvalue', name: 'W존 벨류', desc: '다수의 팬들과 함께 관전이 가능한 서측 좌석, 선수들 및 코칭스텝을 관찰할 수 있는 좌석, 햇빛 탈피 그늘 좌석' },
        { id: 'wbench', name: 'W벤치존(H)', desc: '가장 지근거리에서 선수들을 관찰할 수 있는 서측 좌석, 지정 좌석제, 햇빛 탈피 그늘 좌석' },
        { id: 'wedge', name: 'W존 엣지', desc: '가장 지근거리에서 선수들을 관찰할 수 있는 서측 좌석, 지정 좌석제, 햇빛 탈피 그늘 좌석' },
        { id: 'sensuous', name: '센슈어스존', desc: '경기장 최상의 위치에서 홈경기 관전이 가능한 단상석 하단 좌석, 구단 우승시 선수들을 관전할 수 있는 좌석, 편안한 지정 좌석제' }
      ],
    }
  },
}
</script>
