<template>
  <div class="mediaList hyundai">
    <div class="container">
      <!-- 미디어 리스트 -->
      <div class="tiles">
        <!-- 리스트 정보 -->
        <template v-if="appendVideoData.length > 0">
        <div class="tileInfo">
          <p class="total">총 <strong>{{allVideoLength}}개</strong>의 구단영상이 있습니다</p>
        </div>
        <!-- //리스트 정보 -->
        <!-- 미디어 타일 리스트 -->

        <div class="tileList">
          <!-- tile item -->
          <div class="tile" v-for="(data,idx) in appendVideoData" :key="idx">
            <router-link :to="{name: 'mediaView', params: {id: data.seq, media: 'video'}}">
            <div v-if="data.PhotoFileNM"><img :src="data.PhotoFileNM" @error="replaceImg"/></div>
            <div v-else><img src="../../assets/img/bg_media_404.png"></div>
            <div class="article">
              <p class="subject">{{ data.Title }}</p>
              <div class="info">
                <span class="date">{{ data.RegiDate }}</span>
                <span class="view">{{ comma(Number(data.Hit)) }}</span>
              </div>
            </div>
            </router-link>
          </div>
          <!-- //tile item -->
        </div>
        </template>
        <template v-else >
        <div class="noData">게시물이 없습니다.</div>
        </template>
        <!-- //미디어 타일 리스트 -->
        <div class="viewMore">
          <button v-if="dataFull === true" :style="{display: display}"></button>
          <button v-else type="button" class="btn whiteLightgrey btnViewmore" @click="appendList">더보기</button>
      </div>
      </div>
      <!-- //미디어 리스트 -->
    </div>
  </div>
</template>

<script>
import http from '@/api/index'
import dayjs from 'dayjs'
import bgImg from "@/assets/img/bg_media_404.png"
import regExp from "@/common/regExp"

export default {
  name: 'mediaVideo',
  created() {
    this.init()
  },
  data() {
    return {
      allVideoData: [],
      appendVideoData : [],
      allVideoLength:0,
      appendVideoLength:9,
      videoCount : 0,
      display:'none',
      dataFull:false,
      postInfo: true,
      isData: true,
    }
  },
  methods: {
    comma(num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    async init() {
      const appendVideoData = []
      const videoData = await http.get('/media/video')
      videoData.data.map(row => {
        row.RegiDate = dayjs(row.RegiDate).format('YYYY.MM.DD')
      })
      this.allVideoData = videoData.data
      for(let i =0; i < this.appendVideoLength; i++){
        appendVideoData.push(this.allVideoData[i])
      }
      this.appendVideoData = appendVideoData
      this.allVideoLength = this.allVideoData.length
    },
    replaceImg(e) {
      e.target.src = bgImg
    },
    appendList() {
      if(this.appendVideoLength < this.allVideoLength){
        this.appendVideoLength += 9
        const appendVideoData = []
        for(let i = 0; i < this.appendVideoLength ; i++){
          appendVideoData.push(this.allVideoData[i])
        }
        this.appendVideoData = appendVideoData
      } else {
        alert('마지막 게시물입니다.')
        this.dataFull = true
      }
    }
  },
}
</script>


