<template>
  <div class="footer">
    <div class="siteMap">
      <div class="container">
        <ul class="tree">
          <li
            v-for="main in menu"
            :key="main.name"
            class="treeItem">
            <div class="main">
              {{main.title}}
            </div>
            <ul v-if="main.children">
              <li
                v-for="sub in main.children"
                :key="sub.name">
                <div class="sub">
                  <!-- 추후 주차장 안내 우선으로 들어가기 해지시 삭제 요망 -->
<!--                  <router-link v-if="sub.title === '경기장안내'" :to="{name : 'ticketStadiumParking'}">경기장 안내</router-link>-->
                  <!--// 추후 주차장 안내 우선으로 들어가기 해지시 삭제 요망 -->
                  <router-link :to="{name: sub.name, ...sub.params && { params: sub.params }}">{{sub.title}}</router-link>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="corp">
      <div class="container">
        <div class="links">
          <div class="terms">
            <span><router-link to="/policy/privacy/latest" class="point">개인정보처리방침</router-link></span>
            <span><router-link to="/policy/service">이용약관</router-link></span>
            <span><router-link to="/ticket">티켓예매</router-link></span>
            <span><a href="https://shop.hyundai-motorsfc.com/">초록이네</a></span>
            <span><router-link to="/mypage">마이페이지</router-link></span>
          </div>
          <div class="sns">
            <a href="https://www.facebook.com/jeonbuk1994" target="_blank"><img src="@/assets/img/ico_facebook_2_wh.png" alt=""></a>
            <a href="https://www.youtube.com/user/JEONBUKHYUNDAI" target="_blank"><img src="@/assets/img/ico_youtube_2_wh.png" alt=""></a>
            <a href="https://www.instagram.com/jeonbuk1994/" target="_blank"><img src="@/assets/img/ico_instagram_2_wh.png" alt=""></a>
            <a href="https://apple.co/3HVkjc3" target="_blank"><img src="@/assets/img/ico_apple_2_wh.png" alt=""></a>
            <a href="https://bit.ly/3nQtcvC" target="_blank"><img src="@/assets/img/ico_google_2_wh.png" alt=""></a>
          </div>
        </div>
        <div class="info">
          <p class="company">전북현대모터스에프씨 주식회사</p>
          <p>대표이사 : 정유석 / 사업자등록번호 : 418-81-27460 <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=4188127460" target="_blank">[사업자정보확인]</a> / 통신판매신고 : 2016-전주덕진-0152</p>
          <p>전화번호 : (063)273-1763 / 팩스 : (063)273-1762</p>
          <p>주소 : 전라북도 전주시 덕진구 기린대로 1055 전주월드컵경기장 2층</p>
          <p>호스팅사업자 : AWS(Amazon Web Service), 더스포츠커뮤니케이션</p>
          <p class="copyright">Copyright ⓒ HYUNDAI MOTORS FOOTBALL CLUB All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MENU from '@/common/menu'

export default {
  name: 'FooterView',
  created () {
    this.menu = MENU.filter(x => x.footer !== false)
  },
  data () {
    return {
      menu: []
    }
  }
}
</script>
