<template>
  <div class="subTop hyundai">
    <!-- 서브 상단 -->
    <div class="top">
      <div class="container">
        <div class="breadCrumb">
          홈 <span class="arr">&#8250;</span>
          <router-link :to="depth1.link">{{depth1.name}}</router-link> <span class="arr">&#8250;</span>
          <router-link :to="depth2.link">{{depth2.name}}</router-link>
        </div>
        <div class="topTitle">
          <p class="cate">{{depth1.name}}</p>
          <p class="subCate">{{depth2.name}}</p>
        </div>
      </div>
    </div>
    <!-- //서브 상단 -->
    <!-- 서브 탭메뉴 -->
    <div class="tabMenu">
      <div class="container">
        <router-link
          v-for="menu in tab"
          :key="menu.id"
          :to="menu.link"
          :class="{current: menu.id === depth2.id}">
          <span>{{menu.name}}</span>
        </router-link>
      </div>
    </div>
    <!-- //서브 탭메뉴 -->
  </div>
</template>

<script>
export default {
  name: 'PolicyTop',
  computed: {
    depth1 () {
      return {
        id: this.page.id,
        name: this.page.name,
        link: this.page.link
      }
    },
    depth2 () {
      const policy = this.$route.meta.policy
      const current = this.tab.find(x => x.id === policy)
      return {
        id: current ? current.id : this.depth1.id,
        name: current ? current.name : this.depth1.name,
        link: current ? current.link : this.depth1.link
      }
    }
  },
  data () {
    return {
      bg: '',
      page: {
        id: 'policy',
        name: '서비스 안내',
        link: '/policy/privacy'
      },
      tab: [
        { id: 'privacy', name: '개인정보처리방침', link: '/policy/privacy' },
        { id: 'service', name: '이용약관', link: '/policy/service' },
        { id: 'youtube', name: '유튜브 서비스약관', link: '/policy/youtube' }
      ]
    }
  }
}
</script>
