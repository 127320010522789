<template>
  <div class="mediaPage">
    <Top />
    <router-view></router-view>
  </div>
</template>

<script>
import Top from '@/components/Media/Top'

export default {
  name: 'MediaView',
  components: {
    Top
  }
}
</script>
