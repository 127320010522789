<template>
  <div class="memberContentForErr hyundai" style="height: 100%">
    <div class="container">
      <div class="memberBox pt15" style="max-width: 400px; background-color: #FFFFFF">
        <div class="topTitleForErr">
          <div class="title">ERROR</div>
          <div class="subTitle" style="font-weight: 400; line-height: 18px"></div>
          지금 전북현대모터스 웹사이트<br>
          이용이 어려운 상태입니다.
          <div class="formBtns formBtns2">
            <button class="btn full primary1" @click="go">메인페이지</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ServerErrorPage',
  data() {
    return {
      // code: 401, 500, 404, 502
    };
  },
  created() {
    // console.log(document.getElementsByClassName('header')[0].classList.add('none'))
  },
  mounted () {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')
    document.getElementsByClassName('header')[0].classList.add('none')
    document.getElementsByClassName('footer')[0].classList.add('none')
  },
  methods: {
    go () {
      this.$router.push('/')
    }
  },
  beforeDestroy () {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
    document.getElementsByClassName('header')[0].classList.remove('none')
    document.getElementsByClassName('footer')[0].classList.remove('none')
  },
}
</script>
<style lang="scss">
.none {
  display: none;
}
.pt15 {
  padding-top: 16%;
}
</style>
