import Index from '@/views/Media.vue'
import Notice from '@/components/Media/Notice.vue'
import News from '@/components/Media/News.vue'
import Photo from '@/components/Media/Photo.vue'
import Wallpaper from '@/components/Media/Wallpaper.vue'
import Video from '@/components/Media/Video.vue'
import List from '@/components/Media/List.vue'
import View from '@/components/Media/View.vue'
import JbMedia from "@/components/Media/JbMedia.vue"

const media = [
  {
    path: '/media',
    name: 'media',
    component: Index,
    redirect: {
      name: 'mediaAll'
    },
    children: [
      {
        path: 'all',
        name: 'mediaAll',
        meta: {
          role: 'list',
          subName: 'all'
        },
        component: List
      },
      {
        path: 'notice',
        name: 'mediaNotice',
        meta: {
          role: 'list',
          subName: 'notice'
        },
        component: Notice
      },
      {
        path: 'news',
        name: 'mediaNews',
        meta: {
          role: 'list',
          subName: 'news'
        },
        component: News
      },
      {
        path: 'jbMedia',
        name: 'jbMedia',
        meta: {
          role: 'list',
          subName: 'jbMedia'
        },
        component: JbMedia
      },
      {
        path: 'photo',
        name: 'mediaPhoto',
        meta: {
          role: 'list',
          subName: 'photo'
        },
        component: Photo
      },
      {
        path: 'wallpaper',
        name: 'mediaWallpaper',
        meta: {
          role: 'list',
          subName: 'wallpaper'
        },
        component: Wallpaper
      },
      {
        path: 'video',
        name: 'mediaVideo',
        meta: {
          role: 'list',
          subName: 'video'
        },
        component: Video
      },
      {
        path: ':media/:id',
        name: 'mediaView',
        meta: {
          role: 'view',
          noSubTop: true // 서브상단 없는 경우
        },
        component: View,
        props: true,
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = `/media/${to.params.media}` // GNB 마이페이지 아이콘 위치에 back 버튼이 들어오는 경우 metaData로 path 지정
          }
          next()
        }
      }
    ]
  },
  
]

export default media
