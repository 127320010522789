<template>
  <div style="display: flex; justify-content: center;">
    <!--휴대폰 번호 인증영역-->
    <div class="mt130mb130" v-if="auth === false">
      <div class="authBox">
        <div class="titleBox">
          <span>스마트티켓<br>선물 확인</span></div>
        <div class="subTitleBox">
<!--          <p class="pb10">전북현대모터스에프씨의</p>-->
<!--          <p class="pb10">홈경기 스마트티켓 선물받으셨습니다.</p>-->
<!--          <p>문자메시지를 받으신 고객님의 휴대폰번호를 입력하세요.</p>-->
<!--          <p class="mt20 pb10">서비스 이용을 위해 개인정보 수집 및 이용과</p>-->
<!--          <p>마케팅 활용 및 광고성 메세지 수신동의를 선택해주세요.</p>-->
          <div>
            <span v-html="subTitleTmp"/>
          </div>
        </div>
        <div class="formGroup">
          <label class="label">휴대폰번호</label>
          <div class="withBtn">
            <input type="tel" class="_input" placeholder="휴대폰번호를 입력하세요" v-model="mobile"/>
            <button type="button" class="_btn _whiteLightgrey" @click="chkPersonalInformationConsent">스마트티켓 체크</button>
          </div>
          <div class="complete" v-if="chkTicket !== null">
            <div class="icoComplete"></div>
            <span>{{ warnMsg }}</span>
          </div>
        </div>
        <div v-if="chkTicket">
        <div v-if="isChkPrivacy" class="formGroup">
          <div class="agreeContent">
            <label class="label">개인정보 수집 및 이용에 대한 안내(필수)</label>
            <div class="textarea" style="height: 110px" v-html="policy_1"></div>
          </div>
          <div class="checkAgree">
            <div class="checkbox radio">
              <input
                  type="radio"
                  name="agree_1"
                  id="agree_1_y"
                  value="Y"
                  v-model="agree_1"
              >
              <label for="agree_1_y">동의</label>
            </div>
            <div class="checkbox radio">
              <input
                  type="radio"
                  name="agree_1"
                  id="agree_1_n"
                  value="N"
                  v-model="agree_1"
              >
              <label for="agree_1_n">미동의</label>
            </div>
          </div>
        </div>
        <div v-if="isChkPrivacy" class="formGroup">
          <div class="agreeContent">
            <label class="label">개인정보의 마케팅 활용 및 광고성 메시지 수신동의 (선택)</label>
            <div class="textarea" style="height: 110px" v-html="policy_2"></div>
          </div>
          <div class="checkAgree">
            <div class="checkbox radio">
              <input
                  type="radio"
                  name="agree_2"
                  id="agree_2_y"
                  value="Y"
                  v-model="agree_2"
              >
              <label for="agree_2_y">동의</label>
            </div>
            <div class="checkbox radio">
              <input
                  type="radio"
                  name="agree_2"
                  id="agree_2_n"
                  value="N"
                  v-model="agree_2"
              >
              <label for="agree_2_n">미동의</label>
            </div>
          </div>
        </div>
        <button type="button"
                :class="btnClass"
                @click="chkAgree">{{btnTmp}}</button>
        </div>
      </div>
    </div>
    <!--//휴대폰 번호 인증영역-->
    <!--스마트티켓-->
    <div class="mt70mb70" style="max-width: 380px" v-if="auth === true">
      <div class="authBox_bottom">
        <select class="input select" v-model="chkMatchData" @change="changeArrow">
          <option value="">경기선택</option>
          <option
              v-for="(row, i) in matchData"
              :key="i" :value="row.game_date">
            {{row.label}}
          </option>
        </select>
      </div>
      <div>
    <vueper-slides
        ref="vueperSlides"
        :slide-ratio="slideRatio"
        :infinite="false"
        :arrows="false"
        :bullets="isBullet"
        :gap="4"
        :slideMultiple="false"
        :touchable="isBullet"
        :draggingDistance="100"
        style="max-width: 410px"
        class="no-shadow">
      <vueper-slide
          v-for="(data, i) in qrData"
          :key="i"
      >
        <template #content>
            <div class="innerTest">
              <div class="tkImgBox">
                <!--추후 api로 받아오는 이미지로 변경할 것-->
                <img class="imgBorder" v-if="data.tkBg !== ''" :src="data.tkBg" @error="replaceImg" alt="">
                <img v-else src="../../assets/img/ticket_base.png">
                <!--//추후 api로 받아오는 이미지로 변경할 것-->
                <div class="imgItem">
                  <div class="dateDiv"><span>{{ data.tkDate.date }}({{ data.tkDate.yoil }}) {{ data.tkDate.time }}</span></div>
                  <div class="matchDiv pt20">
                    <span>{{ data.home_team_name }}</span><br><span>VS</span><br><span>{{ data.away_team_name }}</span></div>
                  <div class="tkNumDiv pt10">
                    <span v-for="(el, i) in data.seat_detail" :key="i">{{ el !== "" ? el : null }} </span>
                    <br>
                    <span>{{ data.product_denomination_name }}</span>
                    <br>
                    <span class="tkNum">{{ data.ticket_no }}</span>
                  </div>
                  <div class="dateDiv addBorder mg10"><span>사용가능</span></div>
                  <div><img class="logoImg" :src="data.homeLogo" alt=""/>
                    <img class="logoImg vsLogo" src="../../assets/img/vsLogo.png" alt=""/>
                    <img class="logoImg" :src="data.awayLogo" alt=""/></div>
                </div>
              </div>
              <div class="tkImgBox">
                <img class="lineBg" src="../../assets/img/plz_02.png" alt="">
                <img class="lineImg" src="../../assets/img/Line4.png" alt="">
              </div>
              <div class="tkImgBox">
                <img src="../../assets/img/plz_03.png" alt="">
                <div class="qrItem">
                  <div class="qrTimeDiv">
                    <div :class="`greencc ${twkHidden}`"></div>
                    <span>{{ nowTime }}</span>
                  </div>
                  <div class="qrImg mg10">
                    <qr-code v-if="!data.isUsed" class="qrCode" :value="data.ticket_issue_no" :size="qrSize"/>
                    <img v-else src="../../assets/img/qr_none.png">
                  </div>
                </div>
              </div>
            </div>
            <!--//스마트티켓-->
        </template>
      </vueper-slide>
    </vueper-slides>
        </div>
      <div class="authBox_bottom">
        <div class="formGroup"
        style="padding-left: 0">
          <div class="agreeContent">
            <label>개인정보의 마케팅 활용 및 광고성 메시지 수신동의 (선택)</label>
            <div class="textarea mt10" style="height: 110px" v-html="policy_2"></div>
          </div>
          <div class="checkAgree">
            <div class="checkbox radio">
              <input
                  type="radio"
                  name="agree_2"
                  id="agree_2_y"
                  value="Y"
                  v-model="agree_2"
              >
              <label for="agree_2_y">동의</label>
            </div>
            <div class="checkbox radio">
              <input
                  type="radio"
                  name="agree_2"
                  id="agree_2_n"
                  value="N"
                  v-model="agree_2"
              >
              <label for="agree_2_n">미동의</label>
            </div>
          </div>
        </div>
        <button type="button" class="btn large full primary1" @click="changeSmsPolicy">동의 변경하기</button>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/index'
import dayjs from "dayjs"
import imgPath from "@/library/imgPath"
import QrCode from "qrcode.vue"
import bgImg from "@/assets/img/ticket_base.png"
import terms from "@/library/terms"
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'


export default {
  name: 'PresentOfSmartTk',
  components: {
    QrCode,
    VueperSlides,
    VueperSlide,
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowSize)
  },
  created() {
    if (window.innerWidth <= 280) {
      this.qrSize = 110
      this.btnClass ='btn medium full primary1'
      this.slideRatio = 880/480
    } else {
      this.qrSize = 200
      this.btnClass = 'btn large full primary1'
    }
  },
  watch : {
    chkMatchData () {
      this.qrData = []
      if (this.chkMatchData === '') {
        this.qrData = this.data
        this.isBullet = this.qrData.length !== 1
        return
      }
      this.qrData = this.data.filter(el => el.game_date[0] === this.chkMatchData[0])
      this.isBullet = this.qrData.length !== 1
    },
    windowSize () {
      if (this.windowSize <= 280) {
        this.qrSize = 110
        this.btnClass ='btn medium full primary1'
        this.slideRatio = 740/480
      } else {
        this.qrSize = 200
        this.btnClass = 'btn large full primary1'
        this.slideRatio = 920/480
      }
    }
  },
  data() {
    return {
      auth: false,
      chkTicket: null,
      isChkPrivacy: false,
      data: [],
      warnMsg: '',
      mobile: '',
      twk: true,
      twkHidden: '',
      nowTime: '',
      qrSize: 200,
      policy_1: terms.policyOfGiftTk_1,
      policy_2: terms.policyOfGiftTk_2,
      isBullet: true,
      agree_1: '',
      agree_2: '',
      matchData: [],
      chkMatchData : '',
      qrData: [],
      btnClass: '',
      windowSize: window.innerWidth,
      slideRatio: 920/480,
      btnTmp: '동의하기 및 사용하기',
      subTitleTmp: `전북현대모터스에프씨의 홈경기<br>스마트티켓 선물받으셨습니다.<br>
            문자메시지를 받으신 고객님의 휴대폰번호를 입력하세요.<br><br>
            서비스 이용을 위해 개인정보 수집 및 이용과 마케팅 활용 및 광고성 메세지 수신동의를 선택해주세요.`
    }
  },
  methods: {
    chkPersonalInformationConsent () { // 휴대폰 번호 확인
      api.get(`/smartticket/checkgift?phone=${this.mobile}`)
          .then(res => {
            this.chkTicket = res.data.success
            if (res.data.data === null) {
              this.agree_1 = ''
              this.agree_2 = ''
            } else {
              this.agree_1 = res.data.data.privacy
              this.agree_2 = res.data.data.sms
            }
            this.warnMsg = res.data.message
            this.isChkPrivacy = this.agree_1 !== 'Y'
            if (this.agree_1 === 'Y') {
              this.btnTmp = '사용하기'
            } else {
              this.btnTmp = '동의하기 및 사용하기'
            }
          })
          .catch(err => {
            this.chkTicket = err.response.data.success
            this.warnMsg = err.response.data.message
          })
    },
    chkAgree () { // 개인정보 & 마케팅 동의 확인 & QR데이터
      if (this.agree_1 === '') {
        return alert('개인정보수집 및 이용 동의는 필수 선택 항목입니다.')
      } else if (this.agree_1 === 'N') {
        return alert('개인정보수집 및 이용 동의를 미동의할 경우 스마트티켓 사용에 제한됩니다.')
      } else if (this.agree_2 === '') {
        return alert('개인정보의 마케팅 활용 및 광고성 메시지 수신여부를 선택해 주세요.')
      } else {
        const data = {
          mobile: this.mobile,
          privacy: this.agree_1,
          sms: this.agree_2
        }
        api.post('/smartticket/checkgift', data)
            .then(res => {
              const _data = res.data.data
              if (_data) {
                let mergedArray = [];
                for (let date in _data) {
                  if (_data.hasOwnProperty(date)) {
                    _data[date].forEach(ticket => {
                      mergedArray.push(ticket)
                    })
                  }
                }
                const matchData = mergedArray.filter((item, index, self) =>
                        index === self.findIndex((t) => (
                            t.away_team_name === item.away_team_name && t.game_date.join() === item.game_date.join()
                        ))
                ).map(item => ({
                  away_team_name: item.away_team_name,
                  game_date: item.game_date,
                  away_team: item.away_team
                }))

                this.matchData = matchData.map(el => {
                  el.set_game_date = dayjs(el.game_date[0]).format('YYYY.MM.DD')
                  el.yoil = el.game_date[1]
                  el.label = `${el.set_game_date} (${el.yoil}) VS ${el.away_team_name}`
                  return el
                })
                this.data = mergedArray.map(ticket => {
                  const tkDate = {
                    date: dayjs(ticket.game_date[0]).format('YYYY.MM.DD'),
                    yoil: ticket.game_date[1],
                    time: ticket.game_date[2].slice(0, 5),
                  }

                  const tkBg = ticket.ticket_bg_img !== '' ? imgPath.setJbhd_S3(ticket.ticket_bg_img) : ""
                  const homeLogo = imgPath.setTeamLogo(ticket.home_team)
                  const awayLogo = imgPath.setTeamLogo(ticket.away_team)

                  return {
                    ...ticket,
                    tkDate,
                    tkBg,
                    homeLogo,
                    awayLogo
                  }
                })
                console.log(this.matchData)
                if (this.matchData && this.matchData.length > 1) {
                  this.matchData = this.matchData.sort((a, b) => { // 경기 오름차순
                    const dateA = new Date(a.game_date[0])
                    const dateB = new Date(b.game_date[0])
                    return dateA - dateB
                  })
                  const today = new Date()
                  const pastMatches = this.matchData.filter(match => new Date(match.game_date[0]) < today)
                  const futureMatches = this.matchData.filter(match => new Date(match.game_date[0]) >= today)

                  this.matchData = [...futureMatches, ...pastMatches]
                }

                this.chkMatchData = this.matchData[0].game_date
                  window.scrollTo(0, 0)
                }
                setInterval(this.setTwk, 1000)
                if (this.data.length === 1) { // Bullet 표시 유/무
                  this.isBullet = false
                }
                this.auth = true
            })
            .catch(err => {
              console.log('@@@@@@ err', err)
              alert(err.response.data.message)
            })
      }
    },
    setTwk() {
      this.nowTime = dayjs().format('HH:mm:ss')
      if (this.twk === true) {
        this.twkHidden = 'twkHidden'
        this.twk = false
      } else {
        this.twkHidden = ''
        this.twk = true
      }
    },
    replaceImg(e) {
      e.target.src = bgImg
    },
    changeSmsPolicy () { // QR코드 아래 마케팅 정보 동의 확인
      const data = {
        mobile: this.mobile,
        privacy: this.agree_1,
        sms: this.agree_2
      }
      api.put('/smartticket/sms', data)
          .then(() => {
            alert('개인정보의 마케팅 활용 및 광고성 메시지 수신동의 정보를 업데이트 하였습니다.')
          })
          .catch(err => {
            alert('문제가 발생하였습니다. 잠시 후 다시 시도해주세요.')
            console.log('마케팅 동의 에러: ', err)
          })
    },
    updateWindowSize() {
      this.windowSize = window.innerWidth
    },
    changeArrow () {
      document.activeElement.blur() // 셀렉트 화살표 움직임
      this.$refs.vueperSlides.goToSlide(0) // 항상 첫번째 슬라이드로 이동
    }
  }
}
</script>
<style scoped lang="scss">
  .textarea::-webkit-scrollbar {
    width: 4px;
}
  .textarea::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 20px;
  }
  .textarea::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .mt10 {
    margin-top: 10px;
  }
</style>
