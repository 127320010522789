<template>
  <div>
    <div class="listInfo">
      <div class="title hiddenMobile">상품 구매내역</div>
      <div class="sort">
        <div class="filter">
          <!-- filter -->
          <div class="filterItem shortcut">
            <button
              v-for="s in shortcuts"
              :key="s.id"
              type="button"
              @click="setTerm(s.id)"
              :class="{current: s.id === shortcut}">
              <span>{{s.name}}</span>
            </button>
            <button
                type="button"
                @click="setTerm('hidden')"
                :class="{current: 'hidden' === shortcut}"
            >
              <span>숨긴내역 <img
                  class="hiddenBtn"
                  :class="{current: 'hidden' === shortcut}"
                  src="~@/assets/img/ico_hidden.png"/></span>
            </button>
          </div>
          <!-- //filter -->
          <!-- datepicker -->
          <div class="filterItem datePicker hiddenMobile">
            <date-picker v-model="term.from" valueType="format"></date-picker> ~
            <date-picker v-model="term.to" valueType="format"></date-picker>
            <button type="button" class="btn whiteLightgrey2 auto btnSearch" @click="search">검색</button>
          </div>
          <!-- //datepicker -->
        </div>
      </div>
    </div>
    <div class="dataTable">
      <div>
        <table v-if="showData">
          <thead>
            <tr>
              <th>구매번호</th>
              <th>주문상품명</th>
              <th>개수</th>
              <th>구매일시</th>
              <th>결제금액</th>
              <th>주문상태</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="row in goodsList">
              <tr v-for="(el, idx) in row">
                <td v-if="idx === 0" :rowspan="row.length" class="rowSpan alignTop no">{{ el.order_no }}
                  <button
                      @click="hideTicket(el.order_no)"
                      class="hiddenText">{{hidden ? '숨김 해제' : '내역 숨기기'}}
                    <img class="hiddenBtn" src="~@/assets/img/ico_hidden.png"/>
                  </button>
                </td>
                <td class="left">{{ el.product_name }}</td>
                <td>{{ el.order_qty }}</td>
                <td class="date">{{ el.order_date }}</td>
                <td class="price">{{ comma(el.product_price) }}</td> <!-- 디자인에 금액 없던데 맞는지 확인 -->
                <td><span class="no2">{{ el.order_no }}</span>{{ el.order_status }}
                  <button
                      @click="hideTicket(el.order_no)"
                      class="hiddenTextMobile">내역 숨기기
                    <img class="hiddenBtn" src="~@/assets/img/ico_hidden.png"/>
                  </button>
                </td> <!-- 디자인상 모바일용 데이터로 번호 한번 더 넣어야함 -->
              </tr>
            </template>
          </tbody>
        </table>
        <div v-else class="noData">{{hidden ? '숨긴 구매내역이 없습니다' : '구매내역이 없습니다'}}</div>
        <Pagination
            :perPage="6"
            :pages="pages"
            @change="getData"
            v-show="showData"
        />
      </div>
    </div>
    <div class="greenStoreBanner">
      <a href="https://shop.hyundai-motorsfc.com/">
        <div class="title">
          전북현대 MD샵
          <strong>초록이네</strong>
          <span class="btn large yellow">방문하기</span>
        </div>
        <div class="infoBox">
          <p class="label">구매취소 안내</p>
          <p class="info">초록이네 상품 주건에 대해서 취소를 원하실 경우 ‘초록이네' 사이트를 통해 취소가 가능하십니다.</p>
        </div>
        <div class="infoBox">
          <p class="label">초록이네 문의처</p>
          <p class="info">전화 : (063)211-4815<br>팩스 : (063)211-4815<br>이메일 : sil_HELP@NAVER.COM</p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Pagination from '@/components/Util/Pagination'
import regExp from '@/common/regExp'
import http from '@/api/mypageApi'

export default {
  name: 'MypagePayGoods',
  components: {
    DatePicker,
    Pagination
  },
  computed: {
    showData () {
      return Object.keys(this.goodsList).length
    }
  },
  created() {
    this.pages = 0;
    const termFrom = dayjs().startOf('year').format()
    const termTo = dayjs().format()
    this.term = {
      from: termFrom,
      to: termTo
    }

    let page = this.$route.params.page || '1';
    this.getData(page)
  },
  data () {
    return {
      hidden: false,
      shortcut: 'thisYear',
      shortcuts: [
        { id: 'thisMonth', name: '이번달' },
        { id: 'lastMonth', name: '지난달' },
        { id: 'thisYear', name: '올해' },
        { id: 'lastYear', name: '지난해' }
      ],
      term: {
        from: '',
        to: ''
      },
      goodsList: [],
    }
  },
  methods: {
    hideTicket (orderNo) {
      const data = {
        "orderNo": orderNo
      }
      http.post('/goods/changeviewstatus',data)
          .then(res => {
            alert(res.data.message)
            this.shortcut = 'thisYear'
            return this.setTerm(this.shortcut)
          })
    },
    getData (page, type) {
      let range = `${dayjs(this.term.from).format('YYYY-MM-DD')},${dayjs(this.term.to).format('YYYY-MM-DD')}`
      let query = `?hide=N&page=${page}&range=${range}&limit=6`
      if (type === 'hidden') {
        query = `?hide=Y&page=${page}&limit=6`
      }
      http.get(`/goods${query}`).then(res => {
        let data = res.data.data;
        Object.keys(data.list).map(row => {
          data.list[row].map(item => {
            item.order_date = dayjs(item.order_date).format('YYYY.MM.DD(ddd) HH:mm')
          })
        })
        this.goodsList = data.list;
        this.pages = data.total;
      }).catch(err => {
        console.log(err);
      })

    },
    setTerm (shortcut) {
      const term = this.term
      switch (shortcut) {
        case 'thisMonth':
          term.from = dayjs().startOf('month').format()
          term.to = dayjs().format()
          break
        case 'lastMonth':
          term.from = dayjs().add(-1, 'month').startOf('month').format()
          term.to = dayjs().add(-1, 'month').endOf('month').format()
          break
        case 'thisYear':
          term.from = dayjs().startOf('year').format()
          term.to = dayjs().format()
          break
        case 'lastYear':
          term.from = dayjs().add(-1, 'year').startOf('year').format()
          term.to = dayjs().add(-1, 'year').endOf('year').format()
          break
        case 'hidden':
          term.from = ''
          term.to = ''
          break
      }
      this.shortcut = shortcut
      if (this.shortcut === 'hidden') {
        this.hidden = true
        this.getData(1,'hidden')
      } else {
        this.hidden = false
        this.getData(1)
      }
    },
    search () {
      if(!this.term.from || !this.term.to) {
        alert('날짜를 확인해주세요');
        return false;
      }
      this.getData(1);
    },
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    }
  }
}
</script>

<style lang="scss" scoped>
.no2 {
  display: none;
}
.hiddenBtn {
  margin-bottom: 2px;
}

.hiddenBtn.current  {
  background-image: url("~@/assets/img/ico_hidden_green.png");
}
.hiddenText {
  color: #999999;
  padding-top: 10px;
  display: block;
  margin: auto;
}

.hiddenTextMobile {
  display: none;
}

.hiddenText:hover {
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  tbody {
    .no {
      display: none;
    }
    .hiddenTextMobile {
      color: #999999;
      padding-top: 10px;
      display: block;
      margin: auto;
    }
    .date {
      margin-top: 20px;
      color: $grey3;
    }
    .status {
      display: inline-block;
      vertical-align: middle;
    }
    .no2 {
      display: inline-block;
      vertical-align: middle;
    }
    .no2:after,
    .status:before {
      content: "|";
      margin: 0 8px;
      color: $lightgrey1;
    }
  }
}
</style>
