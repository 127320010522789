<template>
  <div>
    <div class="sectionTitle line">경기장 비상 대피 안내</div>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 80px;">
      <div class="videoBox">
        <div class="frame">
          <iframe src="https://www.youtube.com/embed/Hx0mk2DOWow?si=HQ2RA7Kdo3LEW-Mi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    <!-- //시즌 준비중 -->
  </div>
  </div>
</template>

<script>
export default {
  name: 'TicketStadiumEmergencyGuidelines',
  data () {
    return {
      state: true, // 티켓 임시페이지 사용시 false
    }
  },
}
</script>
<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 800px;
}
@media screen and (max-width: 767px) {
  iframe {
    width: 100%;
    height: 400px;
  }
}
@media screen and (max-width: 457px) {
  iframe {
    width: 100%;
    height: 270px;
  }
}
</style>
