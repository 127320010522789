<template>
  <div>
    <!-- 리스트 정보 -->
<!--    <div class="listInfo">-->
<!--      <div class="sort">-->
<!--        <span class="infoItem">-->
<!--          <select v-model="currentYear" class="select">-->
<!--            <option value="">년도선택</option>-->
<!--            <option-->
<!--                v-for="(y, i) in year"-->
<!--                :key="i"-->
<!--                :value="y.id">-->
<!--              {{ y.name }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </span>-->
<!--      </div>-->
<!--    </div>-->
    <!-- //리스트 정보 -->
    <!-- 리스트 -->
    <div class="container">
<!--      <div class="waitingSeason">-->
<!--</div>-->
      <div class="youthIntro"><img src="@/assets/img/youth_intro_2024.jpg"/></div>
    </div>

    <!-- //리스트 -->
  </div>
</template>

<script>

export default {
  name: 'teamYouth',
  created() {
    const paramsYear = this.$store.state.params && this.$store.state.params.year
    const thisYear = this.$store.state.thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    this.currentYear = paramsYear || thisYear
  },
  data() {
    return {
      active: '',
      currentYear: '',
      currentMonth: '',
      yearStart: 2017, // 필터 시작 년도
      year: [],
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.youthIntro {
  padding-top: 130px;
  text-align: center;
  color: #000;
}
</style>
