import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import clubRouter from './club'
import teamRouter from './team'
import matchRouter from './match'
import ticketRouter from './ticket'
import historyRouter from './history'
import recordRouter from './record'
import mediaRouter from './media'
import fanzoneRouter from './fanzone'
import policyRouter from './policy'
import memberRouter from './member'
import mypageRouter from './mypage'
import etcRouter from './etc'
import store from "@/store";
import ga from '@/library/ga4'
import Error from "@/components/Etc/Error.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main
  },
  ...clubRouter,
  ...teamRouter,
  ...matchRouter,
  ...ticketRouter,
  ...historyRouter,
  ...recordRouter,
  ...mediaRouter,
  ...fanzoneRouter,
  ...policyRouter,
  ...memberRouter,
  ...mypageRouter,
  ...etcRouter,
  {
    path: '*',
    component: Error
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (to.fullPath !== '/media/notice') {
      if (to.name === from.name && to.params.page !== from.params.page) {
        const anchor = document.querySelector(to.meta.scroll);
        if (to.meta && to.meta.scroll && anchor) {
          return { x: 0, y: anchor.offsetTop };
        } else {
          return { x: 0, y: 0 };
        }
      } else {
        return { x: 0, y: 0 };
      }
    }
  },
  routes: routes
});

const needLogin = ['/ethics', '/mypage'];
router.beforeEach((to, from, next) => {
  // if(to.name === 'mediaVideo') {
  //   return next(false);
  // }
  const token = JSON.parse(localStorage.getItem('token'));
  if(!store.state.loginProcess && token && token.expired) {
    if(token.expiredType === 'greenschool') {
      store.dispatch('logout', 'simple');
    } else {
      store.dispatch('logout');
    }
  }

  if((needLogin.includes(to.path) || !to.path.indexOf('/mypage')) && !store.state.login){
    alert('로그인이 필요한 서비스입니다.');
    next('/member/signin');
  } else {
    next();
  }
})

router.afterEach((to) => {

  if(to.path.split('/')[1] === 'ticket') {
    ga.event('TicketView');
  }

  if(to.name === 'signin'){
    if(store.state.login){
      if(to.query.redirectUrl){
        router.push(to.query.redirectUrl);
      }
    }
  }

})
export default router
