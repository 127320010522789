<template>
  <div class="introSection section6">
    <div class="video" :style="{height: `${sectionHeight}px`}">
      <video autoplay playsinline muted loop preload>
        <source :src="this.setVideo('bg_club_clubhouse')" type="video/mp4" />
      </video>
    </div>
    <div class="clubhouseBox box1" :style="{height: `${sectionHeight}px`}">
      <div class="sectionName">JEONBUK HYUNDAI<br>CLUB HOUSE</div>
      <div class="sectionTitle">
        <div v-if="mainTitle[lang]" class="mainTitle" v-html="mainTitle[lang]"></div>
        <div v-if="subTitle[lang]" class="subTitle" v-html="subTitle[lang]"></div>
        <div v-if="desc[lang]" class="desc" v-html="desc[lang]"></div>
      </div>
    </div>
    <div class="clubhouseBox box2">
      <div class="galleryFrame">
        <div class="imgBox">
          <div class="imgList" :style="{maxWidth: `${imgWidth}px`}">
            <div
              v-for="img in imgLength"
              :key="img"
              class="img">
              <img :src="require(`@/assets/img/club/img_clubhouse_${img}.png`)" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="clubhouseInfo">
        <div class="label">
          <div class="mainTitle" v-html="detailMainTitle[lang]"></div>
          <div class="subTitle" v-html="detailSubTitle[lang]"></div>
          <div class="swiperNav">
            <div class="swiper-button-prev clubhouseSwiperPrev" slot="button-prev"></div>
            <div class="swiper-button-next clubhouseSwiperNext" slot="button-next"></div>
          </div>
        </div>
        <swiper class="swiper clubhouseSwiper"
          ref="clubhouseSwiper"
          :options="swiperOption">
          <swiper-slide
            v-for="(item, index) in info"
            :key="index"
            class="swiperItem">
            <div class="itemInner" @click="setClubhouseDetail(item)">
              <div class="img"><img :src="require(`@/assets/img/club/clubhouse_info_${item.name}.png`)" /></div>
              <div class="name" v-html="item[lang]"></div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div v-if="clubhouseDetail" class="clubhouseDetail">
      <div class="detailBox">
        <button type="button" class="btnClose" @click="setClubhouseDetail(null)"></button>
        <div class="clubhouse">JEONBUK HYUNDAI <strong>CLUB HOUSE</strong></div>
        <div class="name">
          <div class="ko">
            <span v-html="clubhouseDetail.ko"></span>
            <p v-if="clubhouseDetail.ko2" v-html="clubhouseDetail.ko2"></p>
          </div>
          <div class="en" v-html="clubhouseDetail.en2"></div>
          <div class="detailVideo">
            <video autoplay playsinline muted loop preload>
              <source :src="this.setVideo(`video_info_${clubhouseDetail.name}`)" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import imgPath from "@/library/imgPath"

export default {
  name: 'ClubClubhouse',
  props: [
    'active',
    'height',
    'lang'
  ],
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    isActive () {
      return this.active && this.active.index === 6
    },
    sectionHeight () {
      const height = this.height && this.height.minHeight ? parseInt(this.height.minHeight.replace(/[^0-9]/g, '')) : 860
      return height
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onScroll()
    this.onResize()
  },
  data () {
    return {
      parallaxWidth: false,
      imgWidth: 905,
      imgLength: 9,
      info: [
        { name: 'lobby', ko: '로비', en: 'LOBBY', en2: 'LOBBY' },
        { name: 'locker', ko: '락커', en: 'LOCKER', en2: 'LOCKER' },
        { name: 'treatment', ko: '수중치료', en: 'UNDERWATER TREATMENT', en2: 'UNDERWATER<br>TREATMENT' },
        { name: 'weightroom', ko: '체력단련실', ko2: '물리치료실', en: 'WEIGHT ROOM', en2: 'WEIGHT ROOM' },
        { name: 'halfdome', ko: '실내연습장', en: 'HALF DOME', en2: 'HALF DOME' }
      ],
      swiperOption: {
        loop: false,
        preventClicks: false,
        slidesPerView: window.innerWidth >= 768 ? 2.6 : 1.2,
        spaceBetween: 0,
        navigation: {
          nextEl: '.clubhouseSwiperNext',
          prevEl: '.clubhouseSwiperPrev'
        }
      },
      clubhouseDetail: null,
      mainTitle: {
        ko: '<span>JEONBUK HYUNDAI</span> CLUB HOUSE',
        en: '<span>JEONBUK HYUNDAI</span> CLUB HOUSE'
      },
      subTitle: {
        ko: '전북현대 클럽하우스'
      },
      desc: {
        ko: `2013년 준공된 전북현대 클럽하우스는 전라북도립 완주군 봉동읍에 연면적 2,414평 규모로 건립되었습니다.<br>
            클럽하우스는 지하 1층, 지상 2층 규모로 건립되었으며, 천연잔디 3면, 인조잔디 1면, 실내 하프 돔구장 1면, 총 4.5면으로 조성되어 있습니다.<br>
            수중치료실을 비롯하여 다양한 웨이트/검사 시설을 갖추고 있어 선수들에게 높은 인프라를 제공합니다.`
      },
      detailMainTitle: {
        ko: '<span class="pcBreak">클럽하우스 </span>세부 소개',
        en: '<span class="pcBreak">CLUB </span>HOUSE'
      },
      detailSubTitle: {
        ko: '전북현대모터스 클럽하우스의 세부 시설을 영상으로 확인하세요.',
        en: 'FACILITIES INFORMATION'
      },
      video :''
    }
  },
  methods: {
    setVideo (name) {
      return imgPath.setVideo(name)
    },
    onScroll () {
      const isActive = this.isActive
      const section = document.querySelector('.section6')
      const scrollY = window.scrollY - section.offsetTop
      const video = section.querySelector('.video')
      if (section && isActive) {
        const sectionTitle = section.querySelector('.sectionTitle')
        const box1 = section.querySelector('.box1')
        const box2 = section.querySelector('.box2')
        const scrollDiff = scrollY
        const imgBox = section.querySelector('.imgBox')
        const imgList = imgBox ? imgBox.querySelector('.imgList') : null
        const imgItem = imgList ? imgList.querySelector('.img') : null
        const titlePos = sectionTitle.offsetTop + box1.offsetTop - scrollDiff > 0 ? 0 : (sectionTitle.offsetTop + box1.offsetTop - scrollDiff) * -1 // box1이 감싸고 있어서 높이 추가해줌
        const imgPos = imgBox ? imgBox.offsetTop + box2.offsetTop : 0 // box2 가 감싸고 있어서 높이 추가해줌
        if (this.parallaxWidth) {
          const translateY = `${imgPos - sectionTitle.clientHeight > scrollDiff ? 0 : (imgPos - sectionTitle.clientHeight - scrollDiff) * -1}px`
          const translateX = `${imgPos - sectionTitle.clientHeight > scrollDiff ? 0 : imgPos - sectionTitle.clientHeight - scrollDiff}px`
          if (titlePos <= sectionTitle.clientHeight + imgBox.clientHeight && (imgPos - sectionTitle.clientHeight - scrollDiff) * -1 <= imgItem.offsetWidth * (this.imgLength - 1)) {
            sectionTitle.style.transform = `translateY(${titlePos}px)`
          }
          if ((imgPos - sectionTitle.clientHeight - scrollDiff) * -1 <= imgItem.offsetWidth * (this.imgLength - 1)) {
            imgBox.style.transform = `translateY(${translateY})`
            imgList.style.transform = `translateX(${translateX})`
          }
        } else {
          sectionTitle.style.transform = ''
          imgBox.style.transform = ''
          imgList.style.transform = ''
        }
      }
      if (section && video) {
        video.style.transform = `translateY(${scrollY < 0 ? 0 : scrollY}px)`
      }
    },
    onResize () {
      // resize
      const section = document.querySelector('.section6')
      const imgBox = section ? section.querySelector('.imgBox') : null
      if (section && imgBox) {
        imgBox.style.height = `${this.imgWidth * (this.imgLength)}px`
      }
      this.parallaxWidth = window.innerWidth >= 1600
    },
    setClubhouseDetail (info) {
      const section = document.querySelector('.introSection.section6')
      if (section) {
        const top = section.offsetTop + section.clientHeight - window.innerHeight
        window.scrollTo(0, top)
      }
      this.clubhouseDetail = info || null
    }
  }
}
</script>
