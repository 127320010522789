import axios from 'axios';

/**
 * 개발서버 기준 환경변수
 */
const baseURL = process.env.VUE_APP_TICKETLINK_BASEURL;
const facilityPcBaseURL = process.env.VUE_APP_TICKETLINK_FACILITY_PC;
const facilityMobileBaseURL = process.env.VUE_APP_TICKETLINK_FACILITY_MOBILE;
const certKey = process.env.VUE_APP_CERTKEY;
const partnerNo = process.env.VUE_APP_PARTNER_NO;
const urlEncodedPartnerNo = process.env.VUE_APP_ENCODEDURI;

const jbhd_thesports_api = process.env.VUE_APP_THESPORTS_API;


/**
 * 예매팝업연동 - URL 생성
 * @param {String} encryptedId
 * @param {String} device
 *
 * device 파라미터 mobile이 아니면 pc버전 URL로 설정
 */
export function reservePopup(encryptedId, device) {

    if(!encryptedId){
        ret.success = false;
        ret.message = '필수값 누락';
        return ret
    }

    // 8월 9일 19시까지 노출
    // if(new Date() < new Date('2023-08-09 19:00:00')) {
    //     alert("8월 9일 (수) FA컵 시스템 점검으로 티켓팅 일시 중단");
    // }
    if(device === 'mobile'){
        return `${facilityMobileBaseURL}/facility/direct/member/reserve?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}`;
    } else {
        return `${facilityPcBaseURL}/facility/direct/member/reserve?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}`;
    }
}

export function mypagePopup(encryptedId, device) {

    if(!encryptedId){
        ret.success = false;
        ret.message = '필수값 누락';
        return ret
    }

    if(device == 'mobile'){
        return `${facilityMobileBaseURL}/facility/direct/member/mypage?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}`;
    }else{
        return `${facilityPcBaseURL}/facility/direct/member/mypage?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}`;
    }
}


export function reserveDetailPopup(encryptedId, reserveNo, device) {

    if(!encryptedId){
        ret.success = false;
        ret.message = '필수값 누락';
        return ret
    }

    if(!reserveNo){
        ret.success = false;
        ret.message = '필수값 누락';
        return ret
    }

    if(device == 'mobile'){
        return `${facilityMobileBaseURL}/facility/direct/member/detail?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}&reserveNo=${reserveNo}`;
    }else{
        return `${facilityPcBaseURL}/facility/direct/member/detail?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}&reserveNo=${reserveNo}`;
    }
}

/**
 * 예매내역조회
 * @param {String} encryptedId 필수
 * @param {Int} reserveNo
 * @param {List} productIds
 * @param {String} cancelData
 * @param {String} startDate (YYYY-MM-DD)
 * @param {String} endDate (YYYY-MM-DD)
 * @return {List}
 */
export async function reserveListCheck(data){

    const ret = {
        success: true,
        message: null,
        data: null
    };

    const _url = `${jbhd_thesports_api}/ticketlink/reserveList`;

    try{
        const res = await axios({
            method: 'POST',
            headers:{
                'Content-type': 'Application/json',
                'Accept-Encoding': 'Application/json'
            },
            url: _url,
            data
        });

        return res.data;
    }catch(err){
        ret.success = false;
        ret.message = 'error'

        return ret;
    };
}

/**
 * 취소전정보조회
 * @param {String} reserveNo 필수
 * @param {String} refundTicketNos
 */
export async function beforeCancelCheck(data){

    const ret = {
        success: true,
        message: null,
        data: null
    };

    if(!data.reserveNo){
        ret.success = false;
        ret.message = '필수값 누락';

        return ret;
    }

    const _url = `${jbhd_thesports_api}/ticketlink/beforeCancel`

    try{
        const res = await axios({
            method:'POST',
            headers:{
                'Content-type': 'Application/json',
                'Accept-Encoding': 'Application/json'
            },
            url: _url,
            data
        });

        return res.data;
    }catch(err){
        console.log(err);
        ret.success = false;
        ret.message = 'error'
        ret.originMessage = err.response.data.message
        return ret;
    };

}

/**
 * 예매취소
 * @param {String} encryptedId 필수
 * @param {String} reserveNo 필수
 * @param {List} refundTicketNos
 */
export async function cancelReserve(data){

    const ret = {
        success: true,
        message: null,
        data: null
    };

    const _url = `${jbhd_thesports_api}/ticketlink/cancelReserve`

    try{
        const res = await axios({
            method: 'POST',
            headers:{
                'Content-type': 'Application/json',
                'Accept-Encoding': 'Application/json'
            },
            url: _url,
            data
        });

        return res.data
    }catch(err){
        console.log(err);
        ret.success = false;
        ret.message = 'error'
        ret.originMessage = err.response.data.message
        return ret;
    };
}

/**
 * 환불수수료조회
 * @param {String} reserveNo 필수
 * @param {List} refundTicketNos 필수
 */
export async function cancelFeeCheck(data){

    const ret = {
        success: true,
        message: null,
        data: null
    };

    if(!data.reserveNo && !data.refundTicketNos){
        ret.success = false;
        ret.message = '필수값 누락';

        return ret;
    }

    const _url = `${jbhd_thesports_api}/ticketlink/cancelFee`

    try{
        const res = await axios({
            method: 'POST',
            headers:{
                'Content-type': 'Application/json',
                'Accept-Encoding': 'Application/json'
            },
            url: _url,
            data
        });

        return res.data;
    }catch(err){
        ret.success = false;
        ret.message = 'error'

        return ret;
    };
}

/**
 * 증빙
 * 사용자의 데이터와 디바이스 정보를 받아 디바이스에 맞는 URL 혹은 팝업 영수증 URL 생성
 * device 파라미터 mobile이 아니면 pc버전 URL로 설정
 * @param {String} encryptedId 필수
 * @param {String} reserveNo
 * @param {String} device
 */
export async function getReceipt(data, device){

    const ret = {
        success: true,
        message: null,
        data: null
    };

    data.device = device;

    const _url = `${jbhd_thesports_api}/ticketlink/receipt`;


    try{
        const res = await axios({
            method: 'POST',
            headers:{
                'Content-type': 'Application/json',
                'Accept-Encoding': 'Application/json'
            },
            url: _url,
            data
        });

        return res.data;
    }catch(err){
        ret.success = false;
        ret.message = 'error'

        return ret;
    };
}
