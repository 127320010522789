import Index from '@/views/Club.vue'
import Intro from '@/components/Club/Intro.vue'

const club = [
  {
    path: '/club',
    name: 'club',
    component: Index,
    meta: {
      detectRollover: true, // GNB 롤오버 표시하지 않고 0번째 children으로 링크
      hiddenGnb: true // 스크롤시 gnb 숨김 (scrollTop > 0)
    },
    redirect: {
      name: 'clubIntro',
      params: {
        lang: 'ko'
      }
    }
  },
  {
    path: '/club/:lang',
    name: 'clubIntro',
    component: Index,
    meta: {
      detectRollover: true, // GNB 롤오버 표시하지 않고 0번째 children으로 링크
      hiddenGnb: true // 스크롤시 gnb 숨김 (scrollTop > 0)
    }
  }
]

export default club
