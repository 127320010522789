<template>
  <div>
    <div class="detailTitle">선수별 출전기록</div>
    <div class="rankingTable recordTable smallTable">
      <table>
        <thead>
          <tr>
            <th>이름</th>
            <th>경기수</th>
            <th>교체인</th>
            <th>교체아웃</th>
            <th>득점</th>
            <th>도움</th>
            <th>슈팅<br>(S/T)</th>
            <th>파울<br>(C/S)</th>
            <th>OS</th>
            <th>PK<br>(S/F)</th>
            <th>경고</th>
            <th>퇴장</th>
            <th>실점</th>
            <th>자책</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, i) in rankData" :key="i">
            <td class="player bold center">
              <div class="img"><img :src="data.imgPath" @error="nullImage"></div>
              <div class="info">
                <div class="name">{{data.name}}</div>
              </div>
            </td>
            <td>{{data.work_qty}}</td>
            <td>{{data.change_i_qty}}</td>
            <td>{{data.change_o_qty}}</td>
            <td>{{data.goal_qty}}</td>
            <td>{{data.assist_qty}}</td>
            <td>{{data.st_qty}}/{{data.st_valid_qty}}</td>
            <td>{{data.fo_qty}}/{{data.fs_qty}}</td>
            <td>{{data.os_qty}}</td>
            <td>{{data.pk_y_qty}}/{{data.pk_n_qty}}</td>
            <td>{{data.warn_qty}}</td>
            <td>{{data.exit_qty}}</td>
            <td>{{data.loss_goal}}</td>
            <td>{{data.self_goal}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="noData" v-if="rankData.length === 0">데이터가 없습니다</div>
  </div>
</template>

<script>
import http from "@/api/index"
import imgPath from "@/library/imgPath"

export default {
  name: 'MatchRecordGame',
  created() {
    const paramsYear = this.$store.state.params && this.$store.state.params.year
    const thisYear = this.$store.state.thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    this.currentYear = paramsYear || thisYear
  },
  watch: {
    currentYear() {
      this.init('year')
    }
  },
  data() {
    return {
      currentYear: '',
      yearStart: 2022, // 필터 시작 년도
      year: [],
      rankData:[],
    }
  },
  methods: {
    async init(type) {
      const params = {
        params: {
          year: ''
        }
      }
      if (type === 'year') {
        params.params.year = this.currentYear
      }
      const rank = await http.get('/match/player_rank/?type=record', params)
      rank.data.data.map(row => {
        row.imgPath = imgPath.set140px(row.player_id)
      })
      this.rankData = rank.data.data
    },
    nullImage (e) {
      e.target.src = imgPath.imgPath.player_null;
    }
  }
}
</script>
