/**
 * sortGamesByDate : Match 경기결과 내림차순
 * */
export default {
    jobList: ['회사원(사무직/연구직)', '회사원(제조/건설/기술직)', '학생(초/중/고)', '대학생(대학원생 포함)', '전업주부', '자영업자', '공무원', '교사', '군인(소방/경찰/교정직 포함)', '보건 및 의료종사자', '법률 종사자', '금융 및 보험업 종사자', '서비스업 종사자', '방송 및 언론 종사자', '예술 및 디자인 종사자', '농업 종사자(축산/수산/임업 포함)', '프리랜서', '무직', '기타'],
    getLeagueName: function (league) {
      let leagueName = '';
      switch (league) {
        case 'K League 1':
            leagueName = 'kleague'
            break
        case 'ACL':
            leagueName = 'afc'
            break
        case 'FA CUP':
            leagueName = 'facup'
            break
      }
      return leagueName
    },
    sortGamesByDate(arr) {
        if (arr) {
            return arr.sort((a, b) => {
                let dateA = new Date(a.game_date.replace(/\./g, '-'))
                let dateB = new Date(b.game_date.replace(/\./g, '-'))
                return dateB - dateA
            })
        } else {
            return []
        }
    }
}
