<template>
  <div class="mediaList hyundai">
    <div class="container">

      <!-- 시즌 준비중 -->
      <div class="indexBox waitingSeason" style="margin-top: 30px;">
        <div class="container">
          <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
        </div>
      </div>
      <!-- //시즌 준비중 -->

      <!-- 미디어 리스트 -->
      <div class="tiles" v-if="state">
        <!-- 리스트 정보 -->
        <div class="tileInfo">
          <p class="total">총 <strong>123개</strong>의 공지사항이 있습니다</p>
          <div class="sort">
            <div
                v-for="item in sort"
                :key="item.id"
                class="item"
                :class="{active: item.id === currentSort}"
                @click="setSort(item.id)">
              {{ item.name }}
            </div>
            <select v-model="currentYear">
              <option value="">년도선택</option>
              <option
                  v-for="(y, i) in year"
                  :key="i"
                  :value="y.id">
                {{ y.name }}
              </option>
            </select>
          </div>
        </div>
        <!-- //리스트 정보 -->
        <!-- 미디어 타일 리스트 -->
        <div class="tileList">
          <!-- tile item -->
          <div class="tile">
            <router-link :to="{name: 'ticketServiceNoticeView', params: {id: 1}}">
              <div><img src="../../assets/img/bg_media_404.png"></div>
              <div class="article">
                <p class="subject">제목</p>
                <div class="info">
                  <span class="date">2022.07.09</span>
                  <span class="view">{{ comma(Number(10415)) }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- //tile item -->
        </div>
        <div class="noData">게시물이 없습니다.</div>
        <!-- //미디어 타일 리스트 -->
        <div class="viewMore">
          <button type="button" class="btn whiteLightgrey btnViewmore">더보기</button>
        </div>
      </div>
      <!-- //미디어 리스트 -->
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from '@/api/index'
import dayjs from 'dayjs'
import bgImg from "@/assets/img/bg_media_404.png"

export default {
  name: 'TicketServiceNotice',
  watch: {
    currentYear() {
      this.init(this.currentYear)
    },
    currentSort() {
      this.init(this.currentSort)
    }
  },
  created() {
    const thisYear = this.$store.state.thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    this.init()
    this.initFix()
  },
  data() {
    return {
      state: false, // 티켓 임시페이지 사용시 false
      currentSort: 'latest',
      currentYear: '',
      sort: [
        {id: 'latest', name: '최신순'},
        {id: 'views', name: '조회순'}
      ],
      yearStart: 2017, // 필터 시작 년도
      year: [
        {id: '', name: '전체'}
      ],
      dropdownActive: false,
      noticeData: [],
      noticeCount:0,
      addPageData:[],
      fixData: [],
      page: 1,
      postInfo:true,
      isData:true,
    }
  },
  methods: {
    comma(num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    setSort(sort) {
      this.currentSort = sort
    }
  }
}
</script>
