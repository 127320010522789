<template>
  <div class="mediaView hyundai">
     <!-- 응원가 뷰 타이틀 -->
     <div class="mediaTitle">
      <div class="container">
        <p class="subject">{{songData.Title}}</p>
        <p class="summary" v-if="topContent.length === undefined">{{topContent.lyricist}}/ {{topContent.composition}}<br>{{topContent.arrangement}}</p>
        <div class="about">
          <div class="info">
            <span class="date">{{songData.set_date}}</span>
            <span class="view">{{comma(Number(songData.Hit))}}</span>
          </div>
          <!-- 공유 -->
          <div class="share">
            <div class="shareBtn link" @click="clipboard">
              <span v-if="copyLink" class="message">주소가 복사되었습니다.</span>
            </div>
            <a href="#" class="shareBtn facebook" @click="shareFacebook"></a>
            <a href="#" class="shareBtn kakaotalk" @click="kakaoLink"></a>
          </div>
          <!-- //공유 -->
        </div>
      </div>
    </div>
    <!-- //응원가 뷰 타이틀 -->
    <div class="container">
      <!-- 응원가 본문 -->
      <div class="mediaContent">
        <div class="mediaAudio" v-if="songData.AttachFileNM">
          <audio controls :src="`${songData.AttachFileNM}`" :type="`audio/${audioType}`" />
        </div>
        <div class="article" v-html="songData.Content">
        </div>
        <div class="download" v-if="songData.AttachFileNM">
          <a :href="songData.AttachFileNM" :download="songData.Title"><span class="btn auto small lightgrey2">응원가 다운로드</span> {{songData.Title}}.mp3</a>
        </div>
      </div>
      <div class="mediaBtns">
        <router-link to="/fanzone/cheersong" class="btn auto">목록으로</router-link>
      </div>
      <!-- //응원가 본문 -->
      <!-- 이전, 다음글 -->
      <div class="mediaRelated">
        <div class="row noData" v-if="prevData===null">이전 게시물이 없습니다</div>
        <div class="row prev" v-else>
          <p class="subject" @click="setRoute(prevData.seq)">
            <router-link
              :to="{name :'fanzoneCheersongView', params:{id:prevData.seq}}">
              {{prevData.Title}}
            </router-link>
          </p>
          <span class="date">{{prevData.RegiDate}}</span>
        </div>

        <div class="row noData" v-if="nextData===null">다음 게시물이 없습니다</div>
        <div class="row next" v-else>
          <p class="subject" @click="setRoute(nextData.seq)">
            <router-link
                :to="{name :'fanzoneCheersongView', params:{id:nextData.seq}}">
              {{nextData.Title}}
            </router-link>
          </p>
          <span class="date">{{nextData.RegiDate}}</span>
        </div>

      </div>
      <!-- //이전, 다음글 -->
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from '@/api/index'
import dayjs from "dayjs"

export default {
  name: 'FanzoneCheersongView',
  created() {
    this.init()
  },
  watch:{
    paramsId(){
      this.init()
    }
  },
  data () {
    return {
      paramsId:this.$route.params.id,
      copyLink: false,
      audioType: 'mp3',
      songData : [],
      nextData:[],
      prevData:[],
      topContent:[],
    }
  },
  methods: {
    comma(num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    clipboard() {
      const url = window.location.href
      this.copyLink = true
      window.navigator.clipboard.writeText(url)
      setTimeout(() => {
        this.copyLink = false
      }, 1000)
    },
    shareFacebook() {
      window.open(`http://www.facebook.com/sharer.php?u=${sharePath.host}/fanzone/cheersong/${this.paramsId}`);
    },
    kakaoLink () {
      const url = `https://hyundai-motorsfc.com/fanzone/cheersong/${this.paramsId}`
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: this.currentData.Title,
          description: this.currentData.Content,
          imageUrl: this.currentData.img,
          link: {
            mobileWebUrl: url,
            webUrl: url,
          },
        },
        buttons: [
          {
            title: '웹으로 보기',
            link: {
              mobileWebUrl: url,
              webUrl: url,
            },
          },
        ],
        installTalk: true,
          }
      )
    },
    async init(){
      const songData = await http.get(`/fanzone/cheer_song/${this.paramsId}`)
      const obj = {}
      if(songData.data.data.current.TopContent !== null){
        const topContent = songData.data.data.current.TopContent.split('/')
        topContent.forEach(el => {
          if(el.includes('작사')){
            obj['lyricist'] = el
          }
          if(el.includes('작곡')){
            obj['composition'] = el
          }
          if(el.includes('편곡')){
            obj['arrangement'] = el
          }
        })
        this.topContent = obj
      } else {
        this.topContent = []
      }
      songData.data.data.current.set_date = dayjs(songData.data.data.current.RegiDate).format('YYYY.MM.DD')
      this.setDate(songData.data.data.current)
      this.setDate(songData.data.data.next)
      this.setDate(songData.data.data.prev)
      this.songData = songData.data.data.current
      this.nextData = songData.data.data.next
      this.prevData = songData.data.data.prev

      if (this.songData.AttachFileNM.split('/028/')[1] === '') {
        this.songData.AttachFileNM = '';
      }
    },
    setDate(el){
      if(el!==null){
        el.RegiDate = dayjs(el.RegiDate).format('YYYY.MM.DD')
        return el
      } else {
        return
      }
    },
    setRoute(id) {
      this.$route.params.id = id
      this.paramsId = this.$route.params.id
    },
  }
}
</script>
