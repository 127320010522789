import { render, staticRenderFns } from "./Quiz.vue?vue&type=template&id=750c8d44&scoped=true"
import script from "./Quiz.vue?vue&type=script&lang=js"
export * from "./Quiz.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "750c8d44",
  null
  
)

export default component.exports