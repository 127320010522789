<template>
  <div :class="{ticketReservation: $route.name === 'ticketServiceNoticeView'}">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'ticketService'
}
</script>
