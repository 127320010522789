<template>
  <div class="videoViewer">
    <button type="button" class="btnClose" @click="close"></button>
    <div class="videoBox" v-if="src">
      <div class="frame">
        <iframe v-if="src" frameborder="0" :src="src" title=""></iframe>
      </div>
    </div>
    <div class="picBox" v-if="pic">
      <div class="img">
        <img v-if="url === true" :src="require(`@/assets/img/record/${pic}.png`)" alt="">
        <img v-else :src="require(`@/assets/img/history/${pic}.png`)" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoViewer',
  computed: {
    video () {
      return this.$store.getters['isVideo']
    },
    pic () {
      return this.$store.getters['isPic']
    }
  },
  mounted () {
    if (this.video) {
      const videoId = this.video
      this.src = `https://www.youtube.com/embed/${videoId}`
    }
  },
  created() {
    if (this.pic) {
      const picId = this.pic
      if (picId.includes('record') === true) {
        this.url = true
      } else {
        this.url = false
      }
    }
  },
  data () {
    return {
      src: null,
      url: ''
    }
  },
  methods: {
    close () {
      this.$store.commit('isVideo', null)
      this.$store.commit('isPic', null)
    }
  }
}
</script>

<style lang="scss" scoped>
.videoViewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.75);
  z-index: 99;
}
.videoBox {
  position: relative;
  width: 85%;
  // width: 100% (처음에 설정해두신 값)
  max-width: 1400px;
  .frame {
    position: relative;
    padding-bottom: 56.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.picBox {
  position: relative;
   width: 100%;
  max-width: 1400px;
  .img {
    position: relative;
    padding-bottom: 56.25%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.btnClose {
  pointer-events: auto;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: $white;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 31%;
    width: 24px;
    height: 2px;
    background: #000;
    transform-origin: 50% 50%;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
</style>
