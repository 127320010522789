<template>
  <div class="gameAlertContainer">
    <div class="container">
      <div class="iconBox" v-if="tmp.icon">
        <img v-if="tmp.icon" :src="getImagePath(tmp.icon)" alt="">
      </div>
      <div class="box">
        <p class="title"
           v-if="tmp.title"
           :class="tmp.type === 'hint' ? 'pb20' : 'pt20'"
        >{{tmp.type === 'hint' ? '힌트' : tmp.title}}</p>
        <div v-if="tmp.type === 'hint'" class="pb10">
          <img v-if="hint.img" :src="hint.img" alt="">
        </div>
        <div v-else>
          <img v-if="tmp.img" :src="tmp.img" alt="">
        </div>
        <p :class="descClass">
          {{tmp.type === 'hint' ? hint.tmp : tmp.desc}}
          </p>
        <div class="btnBox gr cursorPointer" v-if="this.tmp.url" @click="goHint">힌트보러가기</div>
        <div class="btnBox cursorPointer" @click="close">
            닫기
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// ----- flutter event
let isAppReady = false;
window.addEventListener("flutterInAppWebViewPlatformReady", () => {
  isAppReady = true;
});
function externalLink(link) {
  if (isAppReady) {
    window.flutter_inappwebview.callHandler('externalLink', link )
  }
}
// ----- flutter event
export default {
  name: 'GameAlertView',
  props: [
      'tmp',
      'hint'
  ],
  data() {
    return {
      descClass : ''
    };
  },
  created() {
    this.setDescClass()
  },
  methods: {
    setDescClass() { // 힌트내용, 당첨내용 class 설정
      const type = this.tmp.type
      if (type === 'hint') {
        if (this.hint.img) {
          return this.descClass = 'pb25 fw500'
        } else {
          return this.descClass = 'desc fw500'
        }
      } else {
        return this.descClass = 'desc fw500'
      }
    },
    close () {
      if (this.tmp.type !== 'hint') {
        this.$emit('offCooldown')
      }
      this.$emit('close');
    },
    goHint () {
      const link = this.tmp.url;
      externalLink(link);
    },
    getImagePath(icon) {
      return require(`@/assets/img/etc/${icon}.png`);
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
