<template>
  <div>
    <div class="listInfo">
      <div class="title hiddenMobile">이벤트 참여내역</div>
      <div class="sort">
        <div class="filter">
          <!-- filter -->
          <div class="filterItem shortcut">
            <button
              v-for="s in shortcuts"
              :key="s.id"
              type="button"
              @click="setTerm(s.id)"
              :class="{current: s.id === shortcut}">
              <span>{{s.name}}</span>
            </button>
          </div>
          <!-- //filter -->
          <!-- datepicker -->
          <div class="filterItem datePicker hiddenMobile">
            <date-picker v-model="term.from" valueType="format"></date-picker> ~
            <date-picker v-model="term.to" valueType="format"></date-picker>
            <button type="button" class="btn whiteLightgrey2 auto btnSearch" @click="search">검색</button>
          </div>
          <!-- //datepicker -->
        </div>
      </div>
    </div>
    <div class="dataTable">
      <div>
        <table v-if="showData">
          <thead>
            <tr>
              <th>이벤트 제목</th>
              <th>참여신청 일시</th>
              <th class="test">상태</th>
              <th>기능</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(el, i) in eventList" :key="i">
            <td>{{el.event_name}}</td>
            <td class="date">{{el.date}}({{el.yoil}}) {{el.time}}</td>
            <td class="status">{{el.stateName}}</td>
            <td class="btns">
              <button type="button" class="btn w100" :disabled="el.btnState" @click="cancel(el.idx)">{{el.btnName}}</button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="noData" v-else>이벤트 참여내역이 없습니다</div>
        <Pagination
          :perPage="6"
          :pages="pages"
          @change="getData"
          v-show="showData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Pagination from '@/components/Util/Pagination'
import http from '@/api/mypageApi'

export default {
  name: 'MypageActivityEvent',
  components: {
    DatePicker,
    Pagination
  },
  computed: {
    showData () {
      return Object.keys(this.eventList).length
    },
  },
  created() {
    this.pages = 0;
    const termFrom = dayjs().startOf('year').format()
    const termTo = dayjs().format()
    this.term = {
      from: termFrom,
      to: termTo
    }

    let page = this.$route.params.page || '1';
    this.getData(page);
  },
  data () {
    return {
      state: false,
      shortcut: 'thisYear',
      shortcuts: [
        { id: 'thisMonth', name: '이번달' },
        { id: 'lastMonth', name: '지난달' },
        { id: 'thisYear', name: '올해' },
        { id: 'lastYear', name: '지난해' }
      ],
      term: {
        from: '',
        to: ''
      },
      eventList: []
    }
  },
  methods: {
    getData (page) {
      let range = `${dayjs(this.term.from).format('YYYY-MM-DD')},${dayjs(this.term.to).format('YYYY-MM-DD')}`
      let query = `?&page=${page}&range=${range}&limit=6`
      http.get(`/event${query}`)
          .then(res =>{
          const data = res.data.data.list
            if (data.length !== 0) {
              data.map(row => { //Y:선정 N:미선정 C:신청 취소 D: 취소 완료
                row.yoil = dayjs(row.regdate).format('dd')
                row.date = dayjs(row.regdate).format('YYYY.MM.DD')
                row.time = dayjs(row.regdate).format('HH:mm')
                switch (row.status) {
                  case 'c':
                    row.btnName = '신청 취소'
                    row.btnState = false
                    row.stateName = '신청'
                    break
                  case 'y':
                    row.btnName = '취소 불가'
                    row.btnState = true
                    row.stateName = '선정'
                    break
                  case 'n':
                    row.btnName = '취소 불가'
                    row.btnState = true
                    row.stateName = '미선정'
                    break
                  case 'd':
                    row.btnName = '취소 완료'
                    row.btnState = false
                    row.stateName = '취소 완료'
                    break
                }
              })
            }
            this.eventList = data
            this.pages = res.data.data.total
          })
    },
    setTerm (shortcut) {
      const term = this.term
      switch (shortcut) {
        case 'thisMonth':
          term.from = dayjs().startOf('month').format()
          term.to = dayjs().format()
          break
        case 'lastMonth':
          term.from = dayjs().add(-1, 'month').startOf('month').format()
          term.to = dayjs().add(-1, 'month').endOf('month').format()
          break
        case 'thisYear':
          term.from = dayjs().startOf('year').format()
          term.to = dayjs().format()
          break
        case 'lastYear':
          term.from = dayjs().add(-1, 'year').startOf('year').format()
          term.to = dayjs().add(-1, 'year').endOf('year').format()
          break
      }
      this.shortcut = shortcut
      if (this.$route.params.page !== '1') {
        this.$router.push({params: {page: `1`}})
      }
      this.getData(1)
    },
    search () {
      if(!this.term.from || !this.term.to) {
        alert('날짜를 확인해주세요');
        return false;
      }
      this.getData(1);
    },
    cancel (idx) {
      if(confirm('이벤트 신청을 취소하시겠습니까 ?')){
        http.post(`/event/${idx}`)
            .then(res => {
              if (res.data.success === true) {
                alert('취소가 완료되었습니다.')
                this.getData(1)
              } else {
                alert('문제가 발생하였습니다.')
              }
            })
            .catch((err) => {
              console.log('err', err );
              alert('문제가 발생하였습니다.')
            })
      }
      }
  }
}
</script>

<style lang="scss" scoped>
.test {
  padding: 0 40px;
}
@media screen and (max-width: 767px) {
  tbody {
    .date {
      display: inline-block;
      margin-top: 20px;
      vertical-align: middle;
      color: $grey3;
    }
    .status {
      display: inline-block;
      margin-top: 20px;
      vertical-align: middle;
    }
    .status:before {
      content: "|";
      margin: 0 8px;
      color: $lightgrey1;
    }
  }
}
</style>
