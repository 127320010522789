import http from "@/api";

export default {
  getPlayer: async () => {
    const players = await http.get( '/player' )
    const res = players.data
    // console.log(res);
    const list = res.data.GK.concat(res.data.DF, res.data.MF, res.data.FW);
    return list.map( (player) => {
      const _tmp = {
        idx: player.idx, kl_player_id: player.kl_player_id, name: player.name,
      }

      return _tmp;
    } );
  }
}
