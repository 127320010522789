<template>
  <div>
    <div class="listInfo">
      <div class="title hiddenMobile">쿠폰함 내역</div>
      <div class="sort">
        <div class="filter">
          <!-- filter -->
          <div class="filterItem shortcut">
            <button
                v-for="s in shortcuts"
                :key="s.id"
                type="button"
                @click="setTerm(s.id)"
                :class="{current: s.id === shortcut}">
              <span>{{s.name}}</span>
            </button>
          </div>
          <!-- //filter -->
          <!-- datepicker -->
          <div class="filterItem datePicker hiddenMobile">
            <date-picker v-model="term.from" valueType="format"></date-picker> ~
            <date-picker v-model="term.to" valueType="format"></date-picker>
            <button type="button" class="btn whiteLightgrey2 auto btnSearch" @click="search">검색</button>
          </div>
          <!-- //datepicker -->
        </div>
      </div>
    </div>
    <div class="dataTable">
      <div>
        <table v-if="showData">
          <thead>
          <tr>
            <th style="width: 25%">일시</th>
            <th style="width: 25%">쿠폰 명</th>
            <th style="width: 30%">쿠폰 번호</th>
            <th>상태</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, idx) in data" :key="idx">
            <td class="date">{{ row.date }}</td>
            <td class="no">{{ row.coupon_title }}</td>
            <td v-if="row.show === false"><button @click="showCouponNum(idx)">쿠폰 확인</button></td>
            <td v-else @click="clipboard(row.coupon_num)" class="couponNum">{{ row.coupon_num }}</td>
            <td><span class="no2">{{ row.coupon_state }}</span></td>
          </tr>
          </tbody>
        </table>
        <div class="noData" v-else>쿠폰함 내역이 없습니다</div>
        <Pagination
            :perPage="6"
            :pages="pages"
            @change="getData"
            v-show="showData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Pagination from '@/components/Util/Pagination'
import http from '@/api/mypageApi'

export default {
  name: 'MypagePointCoupon',
  components: {
    DatePicker,
    Pagination
  },
  computed: {
    showData () {
      return Object.keys(this.couponList).length
    },
    data () {
      return this.couponList
    }
  },
  watch: {
    couponList () {
      return this.data
    }
  },
  created() {
    this.pages = 0;
    const termFrom = dayjs().startOf('year').format()
    const termTo = dayjs().format()
    this.term = {
      from: termFrom,
      to: termTo
    }

    let page = this.$route.params.page || '1';
    this.getData(page);
  },
  data () {
    return {
      shortcut: 'thisYear',
      shortcuts: [
        { id: 'thisMonth', name: '이번달' },
        { id: 'lastMonth', name: '지난달' },
        { id: 'thisYear', name: '올해' },
        { id: 'lastYear', name: '지난해' }
      ],
      term: {
        from: '',
        to: ''
      },
      couponList: [],
      test: false
    }
  },
  methods: {
    getData (page) {
      let range = `${dayjs(this.term.from).format('YYYY-MM-DD')},${dayjs(this.term.to).format('YYYY-MM-DD')}`
      let query = `?&page=${page}&range=${range}&limit=6`
      http.get(`/coupon${query}`)
          .then(res => {
            const data = res.data.data.list
            data.map(row => {
              row.date = dayjs(row.reg_date).format('YYYY.MM.DD(dd) HH:mm')
              row.coupon_state = row.status === '3' ? '사용완료' : '사용 전'
              row.show = false
            })
            this.couponList = data
            this.pages = res.data.data.total
          })
          .catch(err => console.log(err))
    },
    setTerm (shortcut) {
      const term = this.term
      switch (shortcut) {
        case 'thisMonth':
          term.from = dayjs().startOf('month').format()
          term.to = dayjs().format()
          break
        case 'lastMonth':
          term.from = dayjs().add(-1, 'month').startOf('month').format()
          term.to = dayjs().add(-1, 'month').endOf('month').format()
          break
        case 'thisYear':
          term.from = dayjs().startOf('year').format()
          term.to = dayjs().format()
          break
        case 'lastYear':
          term.from = dayjs().add(-1, 'year').startOf('year').format()
          term.to = dayjs().add(-1, 'year').endOf('year').format()
          break
      }
      this.shortcut = shortcut
      this.getData(1);
    },
    search () {
      if(!this.term.from || !this.term.to) {
        alert('날짜를 확인해주세요');
        return false;
      }
      this.getData(1);
    },
    showCouponNum(idx) {
      this.couponList[idx].show = true
    },
    clipboard(num) {
      const couponNum = num
      window.navigator.clipboard.writeText(couponNum)
          .then(() => alert('쿠폰번호가 복사되었습니다.'))
    },
  }
}
</script>

