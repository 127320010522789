import { render, staticRenderFns } from "./PointReservation.vue?vue&type=template&id=6b8d2a0d"
import script from "./PointReservation.vue?vue&type=script&lang=js"
export * from "./PointReservation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports