<template>
  <div class="dataTable activityQna">
    <div class="accordion" v-if="qnaData.length !== 0">
      <div class="listInfo">
        <div class="sort">
          <router-link to="/mypage/activity/write" class="btn w190 primary2">새 1:1 문의하기</router-link>
        </div>
      </div>
      <div class="accordionList">
          <div class="accordionItem" v-for="(data, i) in this.qnaData" :key="i" :class="{open: data.open}">
          <div class="accordionHead">
            <p class="title">{{data.title}}</p>
            <span class="status" v-if="data.answer_content">답변완료</span>
            <span class="status" v-else>접수</span>
            <span class="date">{{ data.reg_date }}</span>
            <button type="button" class="btnDel" @click="removeQna(data.id)">삭제</button>
            <button type="button" class="btnFold" @click="data.open = !data.open"></button>
          </div>
          <div class="accordionBody">
            <div class="question" v-html="data.content">
              <!--{{data.content}}-->
            </div>
            <div class="answer" v-if="data.answer_content">
              <div v-html="data.answer_content">
                <!--{{data.answer_content}}-->
              </div>
              <div class="date">답변일시 : {{data.answer_reg_date}}</div>
            </div>
            <div class="answer" v-else>
              <div>답변이 등록전입니다.</div>
            </div>
          </div>
        </div>
      </div>

      <Pagination :perPage="10" :pages="totalQnaData" @change="change" />
    </div>

    <div v-else>
      <div class="listInfo">
        <div class="title">1:1 문의하기</div>
      </div>
      <div class="noData">
        1:1 문의내용이 없습니다
        <div><router-link to="/mypage/activity/write" class="btn primary2 w100">문의하기</router-link></div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Util/Pagination'
import http from '@/api/mypageApi'
import dayjs from 'dayjs'

export default {
  name: 'MypageActivityQna',
  components: {
    Pagination
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      qnaData:[],
      pageNum: 0,
      totalQnaData: 0
    }
  },
  methods: {
    async loadData() {
      const event = await http.get(`/inquiry?page=${this.pageNum + 1}`);

      this.totalQnaData = event.data.data.total

      event.data.data.list.map(row => {
        row.open = false
        if(row.answer_reg_date){
          row.answer_reg_date = dayjs(row.answer_reg_date).format('YYYY년 MM월 DD일 HH:mm');
        }
        row.reg_date = dayjs(row.reg_date).format('YYYY년 MM월 DD일 HH:mm');
      })
      this.qnaData = event.data.data.list;
    },

    async removeQna(index){

      if(confirm('삭제하시겠습니까?') == true){
        const req = await http.delete(`/inquiry/${index}`);
        if(req.data.success){
          this.loadData();
        }
      }else{
        return;
      }

    },

    change(el) {
      //페이징네이션
      this.pageNum = el - 1
      this.loadData();
      return this.pageNum
    }
    
  }
}
</script>
