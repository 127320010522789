<template>
  <div class="topDashboard">
    <div class="dashboard">
      <div class="dashboardItem">
        <div class="label">포인트</div>
        <div class="value"><strong>{{comma(dashboardData.point)}}</strong>P</div>
      </div>
      <div class="dashboardItem">
        <div class="label">예매경기</div>
        <div class="value"><strong>{{comma(dashboardData.reserveCnt)}}</strong>건</div>
      </div>
      <div class="dashboardItem">
        <div class="label">쿠폰함</div>
        <div class="value"><strong>{{comma(dashboardData.coupon)}}</strong>개</div>
      </div>
      <div class="dashboardItem">
        <div class="label">예매권</div>
        <div class="value"><strong>{{comma(dashboardData.advance_ticket)}}</strong>개</div>
      </div>
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'

export default {
  name: 'MypageTopDashboard',
  props: [
    'type',
    'myInfo',
    'dashboardData'
  ],
  computed: {
    user() {
      return this.$store.getters['user']
    },
  },
  data () {
    return {
      info: {
        point: 0,
        coupon: 0,
        advance_ticket: 0
      },
    }
  },
  created() {
  },
  methods: {
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    }
  }
}
</script>

<style lang="scss" scoped>
.topDashboard {
  margin-top: 57px;
  .dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
    margin: 0 auto;
    padding: 24px 0;
    text-align: center;
    border-radius: 6px;
    background-color: rgba(0,0,0,0.2);
    .dashboardItem {
      flex: 1;
      color: rgba($white, 0.7);
      & + .dashboardItem {
        border-left: 1px solid rgba($lightgrey1, 0.15)
      }
    }
    .label {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 700;
      color: rgba($white, 0.5);
    }
    .value {
      strong {
        font-size: 24px;
        font-weight: 700;
        color: $white;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .topDashboard {
    margin-top: 30px;
    font-size: 14px;
    .dashboard {
      width: auto;
      padding: 10px 0;
      .label {
        margin-bottom: 10px;
        font-size: 16px;
      }
      .value {
        strong {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
