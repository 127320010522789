<template>
  <div class="mainFeeds">
    <div class="sectionTitle">FEEDS
      <div v-if="current !== 'all'" class="viewmore_feeds">
        <a :href="viewmore" target="_blank">더보기</a>
      </div></div>
    <!-- 탭 -->
    <div class="tabs pretendard">
      <div class="container">
        <button v-for="tab in tabs" :key="tab.id" type="button" class="btnTab" :class="{ active: tab.id === current }"
          :data-tab="tab.id" @click="feedTab(tab.id)">
          {{ tab.name }}
        </button>
        <div v-if="current !== 'all'" class="viewmore">
          <a :href="viewmore" :target="current === 'notice' || current === 'news' ? '_self' : '_blank'">더보기</a>
        </div>
      </div>
    </div>
    <!-- //탭 -->
    <div class="container">
      <!-- 피드 리스트 -->
      <masonry :cols="{ default: 4, 1199: 3, 767: 2 }" :gutter="{ default: '32px', 1199: '24px', 767: '18px' }">
        <div v-for="(item, index) in feedsList.slice(0, 20)" :key="index" class="feedItem"
          :class="{ hasImg: item.thumbnail_img }">
          <a :href="item.link_url" :target="item.type === 'notice' || item.type === 'news' ? '_self' : '_blank'">
            <div v-if="item.thumbnail_img" class="img">
              <img :src="item.thumbnail_img" alt="" @error="replaceImg" />
            </div>
            <div class="content">

              <div v-if="item.type" class="ico" :class="item.type">
                <img :src="require(`@/assets/img/ico_${item.type}_2${item.thumbnail_img ? '_wh' : ''}.png`)" />
              </div>

<!--              <div v-if="item.type === 'notice'">-->
<!--                <img class="bgImg" src="../../assets/img/bg_feeds.png" alt="" />-->
<!--              </div>-->

              <div class="title">{{ item.title }}</div>
              <div v-if="item.description && item.type !== 'news'" class="desc">{{ item.description }}</div>
              <div class="date">{{ item.ctime }}</div>
            </div>
          </a>
        </div>
      </masonry>
      <!-- //피드 리스트 -->
    </div>
  </div>
</template>

<script>
import http from "@/api/index";
import dayjs from "dayjs";
import bgImg from '@/assets/img/bg_feeds_404.png'
import {unescape} from 'html-escaper'

export default {
  name: 'MainFeeds',
  computed: {
    feedsList() {
      const tab = this.current
      const validTab = this.tabs.map(x => x.id)
      const feeds = this.feeds
      const list = feeds.filter(x => {
        return validTab.includes(x.type) // 탭에 포함된 타입인지? (기획문서에 유튜브가 있었다가 없어져서 피드리스트에 섞여올까봐 걸러냄)
      }).filter(x => {
        if(tab === 'all') {
          if(x.type !== "facebook") {
            return x
          }
        } else {
          if(x.type === tab) {
            return x;
          }
        }
      })
      return list
    },
    viewmore() {
      const tab = this.current
      const current = tab !== 'all'
        ? this.tabs.find(x => x.id === tab)
        : null
      return current && current.link
    }
  },
  created() {
    this.init()
  },
  data() {
    return {
      tabs: [
        { id: 'all', name: 'ALL', link: '/', active: true },
        { id: 'notice', name: 'NOTICE', link: 'https://hyundai-motorsfc.com/media/notice', active: false },
        { id: 'news', name: 'NEWS', link: 'https://hyundai-motorsfc.com/media/news', active: false },
        { id: 'instagram', name: 'INSTAGRAM', link: 'https://www.instagram.com/jeonbuk1994/', active: false },
        { id: 'facebook', name: 'FACEBOOK', link: 'https://www.facebook.com/jeonbuk1994', active: false },
        // { id: 'youtube', name: 'YOUTUBE', link: '#', active: false }
      ],
      current: 'all',
      feeds: [],
      hidden: ''
    }
  },
  methods: {
    async init() {
      const feeds_res = await http.get('/main/feeds')
      feeds_res.data.data.map(row => {
        if (row.ctime) {
          row.ctime = dayjs(row.ctime).format("YYYY-MM-DD")
          row.imgStatus = true
        }
        row.description = row.description.trim()
        if (row.type === 'notice') {
          row.thumbnail_img = require('@/assets/img/feeds_img.png')
        }
        row.title = unescape(row.title).replaceAll("&#039;", '\'')

      })
      this.feeds = feeds_res.data.data

    },
    feedTab(type) {
      this.current = type
    },
    replaceImg(e) {
      e.target.src = bgImg
    }
  }
}
</script>
