<template>
  <div>
<!-- dummy -->
<!--//dummy  -->
    <!-- 우승기록 슬라이더 -->
    <div class="introSection section2" :style="styleHeight">
      <div class="sectionTitle">우승기록</div>
      <div class="recordSlide">
        <swiper class="swiper yearSwiper"
                ref="yearSwiper"
                :options="yearsOption"
                @slideChange="slideYearChanged">
          <swiper-slide
              v-for="(year, index) in years"
              :key="index">
            <div class="yearSlide">
              {{year}}
            </div>
          </swiper-slide>
        </swiper>
        <swiper class="swiper recordSwiper"
                ref="recordSwiper"
                :options="swiperOption"
                @slideChange="slideChanged">
          <swiper-slide
              v-for="(item, index) in slide"
              :key="index">
            <div class="slideInner">
              <a href="#" @click="picView($event, item.img)">
                <img :src="require(`@/assets/img/record/${item.img}.png`)" alt="">
              </a>
            </div>
          </swiper-slide>
        </swiper>
        <div class="slideDesc">
          <div class="title">{{activeSlide.title}}</div>
          <div class="desc">{{activeSlide.record}}</div>
        </div>
        <div class="swiper-pagination" ref="pagination" slot="pagination"></div>
        <div class="swiper-button-prev historyPrev" slot="button-prev"></div>
        <div class="swiper-button-next historyNext" slot="button-next"></div>
      </div>
    </div>
    <!-- //우승기록 슬라이더 -->
    <!-- 선수기록 -->
    <div class="introSection section3" :style="styleHeight">
      <div class="sectionTitle">선수기록</div>
      <div class="recordFrame">
        <div class="playerRecord">
          <div
              v-for="(item, index) in player"
              :key="index"
              class="player"
              @click="setPlayerDetail(item)">
            <div class="playerInfo">
              <span class="record">{{item.record}}</span>
              <div class="name"><em>{{item.name}}</em></div>
              <div class="en">{{item.name_en}}</div>
            </div>
            <img :src="require(`@/assets/img/record/${item.img}.png`)" alt="">
          </div>
        </div>
      </div>
      <!-- 선수기록 상세 레이어 -->
      <div v-if="playerDetail" class="playerDetail">
        <div class="detailBox">
          <button type="button" class="btnClose" @click="setPlayerDetail(null)"></button>
          <div class="position">{{playerDetail.position}}</div>
          <div class="name">{{playerDetail.name}}</div>
          <div class="en">{{playerDetail.name_en}}</div>
          <div class="ranking">
            <span class="record">{{playerDetail.record}}</span>
            <div class="count">
              <img v-for="(num, index) in imgToNum(playerDetail.count, 'number_primary')" :key="index" :src="num" class="imgNum" />
            </div>
            <div class="list">
              <div
                  v-for="(item, index) in playerDetail.allTime"
                  :key="index"
                  class="item">
                <span class="rank">{{item.ranking}}</span>
                {{item.name}}({{comma(item.count)}})
              </div>
            </div>
            <div class="etc" style="font-size: 13px" >* K리그 기록실 {{lastGameDate}} 기준</div>
          </div>
          <img :src="require(`@/assets/img/record/${playerDetail.imgDetail}.png`)" alt="" class="detailImg">
        </div>
      </div>
      <!-- //선수기록 상세 레이어 -->
    </div>
    <!-- //선수기록 -->
    <!-- 클럽기록 -->
    <div class="introSection section4" :style="styleHeight">
      <div class="sectionTitle">클럽기록</div>
      <div class="recordFrame">
        <div class="clubRecord">
          <div
              v-for="(item, index) in data"
              :key="index"
              class="item">
            <div class="title">{{item.name}}</div>
            <div class="count">
              <img v-for="(num, index) in imgToNum(item.count, 'number_black')" :key="index" :src="num" class="imgNum" />
            </div>
            <div class="detail">{{item.detail}}</div>
          </div>
        </div>
      </div>
      <p class="latest">*K리그 기록실 {{lastGameDate}} 기준</p>
    </div>
    <!-- //클럽기록 -->
    <!-- 최다관중 -->
    <div class="introSection section5" :style="styleHeight">
      <div class="sectionTitle">최다관중</div>
      <div class="audienceRecord">
        <div
            v-for="(item, index) in audience"
            :key="index"
            class="item">
          <div class="img"><img :src="require(`@/assets/img/record/${item.img}.png`)" alt=""></div>
          <div class="info">
            <div class="count"><strong>{{comma(item.count)}}</strong>명</div>
            <div class="detail"><span class="label">경기명</span> {{item.game}}</div>
            <div class="detail"><span class="label">일시</span> {{item.date}}</div>
            <div class="detail"><span class="label">상대</span> {{item.versus}}</div>
            <div class="detail"><span class="label">결과</span> {{item.result}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- //최다관중 -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import regExp from '@/common/regExp'

export default {
  name: 'RecordIntro',
  props: [
      'record',
      'date'
  ],
  components: {
    Swiper,
    SwiperSlide,
  },
  watch: {
    record () {
      this.data = [
        { name: '승점', count: this.record.total_win_point, detail:`${this.record.win}승 ${this.record.draw}무 ${this.record.lose}패` },
        { name: '득점', count: this.record.total_goal, detail: 'GOALS' },
        { name: '실점', count: this.record.total_loss, detail: 'GOALS CONCEDED' },
        { name: '도움', count: this.record.total_assist, detail: 'ASSISTS' },
        { name: '슈팅', count: this.record.total_shoot, detail: 'SHOTS' }
      ]
    },
    date () {
      this.lastGameDate = this.date
    }
  },
  computed: {
    years () {
      return this.slide.map(x => x.year)
    },
    activeSlide () {
      const index = this.swiperIndex
      const info = this.slide[index]
      return {
        title: info.title,
        record: info.record
      }
    },
    styleHeight () {
      return {
        minHeight: this.sectionHeight
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    this.getWinHeight()
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onScroll()
    this.onResize()
  },
  data () {
    return {
      lastGameDate: '',
      data : [],
      parallaxWidth: false,
      sectionHeight: '860px', // min-height
      yearsOption: {
        loop: true,
        slidesPerView: 4.8,
        centeredSlides: true,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.historyNext',
          prevEl: '.historyPrev'
        }
      },
      swiperOption: {
        loop: true,
        slidesPerView: 3.5,
        centeredSlides: true,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.historyNext',
          prevEl: '.historyPrev'
        }
      },
      swiperIndex: 0,
      slide:[
        { year: '2022', title: '하나원큐 FA CUP', record: '(1차전 2022-10-27 / vs FC서울 / 2:2, 2차전 2022-10-30 / vs FC서울 / 3:1, 통합 5:3)', img: 'img_record_16' },
        { year: '2021', title: 'K리그1', record: '(22승 10무 6패 / 76점)', img: 'img_record_15' },
        { year: '2020', title: '하나은행 FA CUP', record: '(1차전 2020-11-4 / vs 울산현대 / 1:1, 2차전 2020-11-8 / vs 울산현대 / 2:1, 통합 3:2)', img: 'img_record_14' },
        { year: '2020', title: 'K리그1', record: '(19승 3무 5패 / 60점) *2020-11-1 시즌 종료', img: 'img_record_13' },
        { year: '2019', title: 'K리그1', record: '(22승 13무 3패 / 79점)', img: 'img_record_12' },
        { year: '2018', title: 'K리그1', record: '(26승 8무 4패 / 86점)', img: 'img_record_11' },
        { year: '2017', title: 'K리그 클래식', record: '(22승 9무 7패 / 승점 75점)', img: 'img_record_10' },
        { year: '2016', title: 'AFC 챔피언스리그', record: '(1차전 2016-11-19 / vs 알아인 / 2:1, 2차전 2016-11-26 / vs 알아인 / 1:1, 통합 3:2)', img: 'img_record_9' },
        { year: '2015', title: 'K리그 클래식', record: '(22승 7무 9패 / 73점)', img: 'img_record_8' }, // 없음
        { year: '2014', title: 'K리그 클래식', record: '(24승 9무 5패 / 81점)', img: 'img_record_7' },
        { year: '2011', title: 'K리그', record: '(20승 9무 3패 / 승점 63점)', img: 'img_record_6' },
        { year: '2009', title: 'K리그', record: '(18승 7무 5패 / 승점 57점)', img: 'img_record_5' },
        { year: '2006', title: '2006 AFC 챔피언스리그', record: '(1차전 2006-11-1 / vs 알카리마 / 2:0, 2차전 2006-11-8 / vs 알카리마 / 1:2, 통합 3:2)', img: 'img_record_4' },
        { year: '2005', title: '하나은행 FA CUP 전국축구선수권대회', record: '(2005-12-17 / vs 울산현대미포조선 / 1:0)', img: 'img_record_3' },
        { year: '2003', title: '하나은행 FA CUP 전국축구선수권대회', record: '(2003-11-30 / vs 전남드래곤즈 / 2:2 / 4 PK 2 )', img: 'img_record_2' },
        { year: '2000', title: '2000 서울은행 FA컵 축구대회', record: '(2000-12-5 / vs 성남 일화 / 2:0)', img: 'img_record_1' }, // 없음
      ],
      player: [
        { record: '최다 출전', name: '최철순', name_en: 'CHOI CHUL SOON', img: 'record_player_time', imgDetail: 'record_player_time_detail', no: '23', position: 'DEFENDER', count: 398,
          allTime: [
            { ranking: 2, name: '이동국', count: 361 },
            { ranking: 3, name: '최진철', count: 312 },
            { ranking: 4, name: '권순태', count: 268 },
            { ranking: 5, name: '김경량', count: 261 }
          ]},
        { record: '최다 득점', name: '이동국', name_en: 'LEE DONG GOOK', img: 'record_player_goal', imgDetail: 'record_player_goal_detail', no: '23', position: 'FORWARD', count: 164,
          allTime: [
            { ranking: 2, name: '김도훈', count: 63 },
            { ranking: 3, name: '에닝요', count: 58 },
            { ranking: 4, name: '한교원', count: 54 },
            { ranking: 5, name: '박성배', count: 41 }
          ]},
        { record: '최다 도움', name: '에닝요', name_en: 'ENINHO', img: 'record_player_assist', imgDetail: 'record_player_assist_detail', no: '23', position: 'FORWARD', count: 48,
          allTime: [
            { ranking: 2, name: '이동국', count: 48 },
            { ranking: 3, name: '이승기', count: 35 },
            { ranking: 4, name: '레오나르도', count: 34 },
            { ranking: 5, name: '이재성', count: 32 }
          ]},
        { record: '최다 슈팅', name: '이동국', name_en: 'LEE DONG GOOK', img: 'record_player_shoot', imgDetail: 'record_player_shoot_detail', position: 'FORWARD', no: '23', count: 1034,
          allTime: [
            { ranking: 2, name: '김도훈', count: 432 },
            { ranking: 3, name: '에딩요', count: 430 },
            { ranking: 4, name: '레오나르도', count: 384 },
            { ranking: 5, name: '로페즈', count: 347 }
          ]},
        { record: '최다 무실점', name: '권순태', name_en: 'KWOUN SUN TAE', img: 'record_player_defense', imgDetail: 'record_player_defense_detail', position: 'GOAL KEEPER', no: '23', count: 83,
          allTime: [
            { ranking: 2, name: '송범근', count: 70 },
            { ranking: 3, name: '이용발', count: 24 },
            { ranking: 4, name: '최은성', count: 23 },
            { ranking: 5, name: '이광석', count: 20 }
          ]}
      ],
      playerDetail: null,
      club: [
        { name: '승점', count: 1624, detail: '468승 270무 299패' },
        { name: '득점', count: 1596, detail: 'GOALS' },
        { name: '실점', count: 1226, detail: 'GOALS CONCEDED' },
        { name: '도움', count: 1048, detail: 'ASSISTS' },
        { name: '슈팅', count: 13439, detail: 'SHOTS' }
      ],
      audience: [
        { count: 41805, game: '2011 AFC 챔피언스리그 결승전', date: '2011년 11월 05일', versus: 'VS 알 사드 SC', result: '2-2(2PK4) 패', img: 'img_audience_1' },
        { count: 36246, game: 'K리그 챔피언십 결승 2차전', date: '2009년 12월 06일', versus: 'VS 성남FC', result: '3-1 승', img: 'img_audience_2' },
        { count: 36158, game: '2016 AFC 챔피언스리그 결승 1차전', date: '2016년 11월 19일', versus: 'VS 알 아인FC', result: '2-1 승', img: 'img_audience_3' },
        { count: 33706, game: 'K리그 클래식 38R', date: '2016년 11월 06일', versus: 'VS FC서울', result: '0-1 패', img: 'img_audience_4' },
        { count: 33554, game: 'K리그 챔피언십 결승 2차전', date: '2011년 12월 04일', versus: 'VS 울산현대', result: '2-1 승', img: 'img_audience_5' }
      ]
    }
  },
  methods: {
    goNext () {
      const target = document.querySelector('.section2')
      if (target) {
        const top = target.offsetTop
        window.scrollTo(0, top)
      }
    },
    getWinHeight () {
      this.sectionHeight = `${window.innerHeight}px`
    },
    slideChanged () {
      const swiper = this.$refs.recordSwiper
      const index = swiper ? swiper.swiperInstance.realIndex : 0
      this.swiperIndex = index
    },
    slideYearChanged () {
      const pagination = this.$refs.pagination
      const year = this.$refs.yearSwiper
      const index = year ? year.swiperInstance.realIndex : 0
      pagination.querySelectorAll('.swiper-pagination-bullet')[index].click()
      this.swiperIndex = index
    },
    setPlayerDetail (info) {
      const top = document.querySelector('.introSection.section3').offsetTop + 60
      window.scrollTo(0, top)
      this.playerDetail = info || null
    },
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    imgToNum (num, path) { // 등번호 등 숫자를 하나씩 쪼개 이미지로 리턴
      const nums = `${this.comma(num)}`.split('')
      function getImg(n) {
        return require(`@/assets/img/${path || 'number'}/${n === ',' ? 'comma' : n}.png`)
      }
      return nums.map(x => getImg(x))
    },
    // 패럴럭스를 위한 스크롤이벤트
    onScroll () {
      const sections = document.querySelectorAll('.introSection')
      const position = sections ? [...sections].map((x, i) => {
        return {
          section: i + 1,
          height: x.clientHeight,
          top: x.offsetTop,
          bottom: x.offsetTop + x.clientHeight - 1
        }
      }) : null
      const scrollY = window.scrollY
      function getRange (scroll, position) {
        const section = position ? position.find(x => {
          return scroll >= x.top && scroll <= x.bottom
        }) : null
        return section ? {
          index: section.section,
          scrollPercent: ((scroll - section.top) / section.height * 100).toFixed(2) // 현재 섹션에서 스크롤 위치가 몇%인지
        } : null
      }
      this.parallax(getRange(scrollY, position))
    },
    parallax (data) {
      const index = data && data.index
      if (index) {
        const sections = document.querySelectorAll('.introSection')
        if (sections) {
          this[`parallax${index}`](data.scrollPercent)
        }
      }
    },
    // parallax - section1
    parallax1 (percent) {
      const section = document.querySelectorAll('.introSection')[0]
      const txt = section ? section.querySelector('.slogan .txt') : null
      const pic = section ? section.querySelector('.slogan .pic') : null
      const team = section ? section.querySelector('.slogan .team') : null
      if (txt) {
        txt.style.transform = this.parallaxWidth ? `translate3d(0, ${-100 / 100 * percent}px, 0) scale(${0.1 / 100 * percent + 1})` : ''
      }
      if (pic) {
        pic.style.transform = this.parallaxWidth ? `translate3d(0, ${-320 / 100 * percent}px, 0) scale(${0.2 / 100 * percent + 1})` : ''
        pic.style.opacity = this.parallaxWidth ? 1 - (percent / 100) : ''
      }
      if (team) {
        team.style.fontSize = this.parallaxWidth ? `${0.8 / 100 * percent + 1}em` : ''
        team.style.opacity = this.parallaxWidth ? 1 - (percent / 100) + 0.2 : ''
      }
    },
    // parallax - section2
    parallax2 (percent) {
      const section = document.querySelectorAll('.introSection')[1]
      const recordSwiper = section ? section.querySelector('.recordSwiper') : null
      if (section && recordSwiper) {
        recordSwiper.style.transform = this.parallaxWidth ? `translate3d(0, ${-160 / 100 * percent}px, 0) scale(${0.2 / 100 * percent + 1})` : ''
      }
    },
    // parallax - section3
    parallax3 (percent) {
      const section = document.querySelectorAll('.introSection')[2]
      if (section) {
        const player1 = section.querySelectorAll('.player')[0]
        const player2 = section.querySelectorAll('.player')[1]
        const player3 = section.querySelectorAll('.player')[2]
        const player4 = section.querySelectorAll('.player')[3]
        const player5 = section.querySelectorAll('.player')[4]
        if (player1) {
          player1.querySelector('img').style.transform = this.parallaxWidth ? `translate3d(0, ${80 / 100 * percent}px, 0) scale(${0.15 / 100 * percent + 1})` : ''
          player1.querySelector('.playerInfo').style.transform = this.parallaxWidth ? `translate3d(0, ${-200 / 100 * percent}px, 0)` : ''
        }
        if (player2) {
          player2.querySelector('img').style.transform = this.parallaxWidth ? `translate3d(0, ${-60 / 100 * percent}px, 0)` : ''
          player2.querySelector('.playerInfo').style.transform = this.parallaxWidth ? `translate3d(0, ${180 / 100 * percent}px, 0)` : ''
        }
        if (player3) {
          player3.querySelector('img').style.transform = this.parallaxWidth ? `translate3d(0, ${40 / 100 * percent}px, 0)` : ''
          player3.querySelector('.playerInfo').style.transform = this.parallaxWidth ? `translate3d(0, ${-280 / 100 * percent}px, 0)` : ''
        }
        if (player4) {
          player4.querySelector('img').style.transform = this.parallaxWidth ? `translate3d(0, ${-80 / 100 * percent}px, 0) scale(${0.1 / 100 * percent + 1})` : ''
          player4.querySelector('.playerInfo').style.transform = this.parallaxWidth ? `translate3d(0, ${240 / 100 * percent}px, 0)` : ''
        }
        if (player5) {
          player5.querySelector('img').style.transform = this.parallaxWidth ? `translate3d(0, ${90 / 100 * percent}px, 0) scale(${0.1 / 100 * percent + 1})` : ''
          player5.querySelector('.playerInfo').style.transform = this.parallaxWidth ? `translate3d(0, ${-200 / 100 * percent}px, 0)` : ''
        }
      }
    },
    // parallax - section4
    parallax4 (percent) {
      const section = document.querySelectorAll('.introSection')[3]
      if (section) {
        const card1 = section.querySelectorAll('.clubRecord .item')[0]
        const card2 = section.querySelectorAll('.clubRecord .item')[1]
        const card3 = section.querySelectorAll('.clubRecord .item')[2]
        const card4 = section.querySelectorAll('.clubRecord .item')[3]
        const card5 = section.querySelectorAll('.clubRecord .item')[4]
        const latest = section.querySelector('.latest')
        if (card1) {
          card1.style.transform = this.parallaxWidth ? `translate3d(0, ${300 / 100 * percent}px, 0)` : ''
        }
        if (card2) {
          card2.style.transform = this.parallaxWidth ? `translate3d(0, ${-100 / 100 * percent}px, 0)` : ''
        }
        if (card3) {
          card3.style.transform = this.parallaxWidth ? `translate3d(0, ${150 / 100 * percent}px, 0)` : ''
        }
        if (card4) {
          card4.style.transform = this.parallaxWidth ? `translate3d(0, ${-200 / 100 * percent}px, 0)` : ''
        }
        if (card5) {
          card5.style.transform = this.parallaxWidth ? `translate3d(0, ${100 / 100 * percent}px, 0)` : ''
        }
        if (latest) {
          latest.style.opacity = 0.5 - (percent / 100)
        }
      }

    },
    // parallax - section5
    parallax5 (percent) {
      const section = document.querySelectorAll('.introSection')[4]
      const audience1 = section.querySelectorAll('.audienceRecord .item')[0]
      const audience2 = section.querySelectorAll('.audienceRecord .item')[1]
      const audience3 = section.querySelectorAll('.audienceRecord .item')[2]
      const audience4 = section.querySelectorAll('.audienceRecord .item')[3]
      const audience5 = section.querySelectorAll('.audienceRecord .item')[4]
      percent = parseInt(percent)
      if (audience1) {
        audience1.querySelector('.img').style.transform = this.parallaxWidth ? `translate3d(0, ${-300 / 100 * percent * 2}px, 0) scale(${0.2 / 100 * percent + 1})` : ''
      }
      if (audience2 && percent > 5) {
        audience2.querySelector('.img').style.transform = this.parallaxWidth ? `translate3d(0, ${-340 / 100 * percent * 2}px, 0) scale(${0.15 / 100 * percent + 1})` : ''
      }
      if (audience3 && percent > 8) {
        audience3.style.transform = this.parallaxWidth ? `translate3d(0, ${-100 / 100 * percent * 2}px, 0)` : ''
      }
      if (audience4 && percent > 40) {
        audience4.querySelector('.img').style.transform = this.parallaxWidth ? `translate3d(0, ${-100 / 100 * (percent - 30) * 2}px, 0)` : ''
        audience4.querySelector('.info').style.transform = this.parallaxWidth ? `translate3d(0, ${-300 / 100 * (percent - 30) * 2}px, 0)` : ''
      }
      if (audience5 && percent > 50) {
        audience5.querySelector('.img').style.transform = this.parallaxWidth ? `translate3d(0, ${-100 / 100 * (percent - 40) * 2}px, 0) scale(${0.2 / 100 * percent + 1})` : ''
        audience5.querySelector('.info').style.transform = this.parallaxWidth ? `translate3d(0, ${100 / 100 * (percent - 40) * 2}px, 0)` : ''
      }
    },
    onResize () {
      this.parallaxWidth = window.innerWidth >= 1200
    },
    picView (e, picId) {
      e.preventDefault()
      this.$store.commit('isPic', picId)
    }
  }
}
</script>
