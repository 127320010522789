<template>
  <div class="ticketPage">
    <Index v-if="isIndex" />
    <div v-else>
      <Top />
      <router-view class="ticketBox"></router-view>
    </div>
  </div>
</template>

<script>
import Index from '@/components/Ticket/Index'
import Top from '@/components/Ticket/Top'

export default {
  name: 'TicketView',
  components: {
    Index,
    Top
  },
  computed: {
    isIndex () {
      return this.$route.name === 'ticket'
    }
  }
}
</script>
