<template>
  <div class="mediaView fanzoneEventView">
    <div class="mediaTitle eventTitle">
      <div v-if="surveyInfo" class="container">
        <div v-if="!available" class="mt50"></div>
        <span class="status" :data-status="getStatus.status" v-if="available">{{getStatus.label}}</span>
        <p class="subject">{{surveyInfo.subject}}</p>
        <div class="date displayMobileSub">
        <p class="mt36">전북현대모터스에프씨 팬 여러분들의<br>
          의견을 듣고자 설문조사를 진행중입니다.</p></div>
        <div class="date displayMobileDate">
          <p class="mt36">{{dateFormat(surveyInfo.reserve_date)}} ~ {{dateFormat(surveyInfo.end_date)}}</p>
        </div><!-- start_date가 없는듯? -->
      </div>
    </div>
    <div class="container">
      <!-- 설문조사 본문 -->
      <div class="mediaContent eventContent surveyContent">
        <div v-if="!available">
          <CheckSurvey
            v-if="getStatus.status === '01'"
            @setAvailable="setAvailable"
            @setMobile="setMobile" />
          <div v-else class="noData">
            {{getStatus.status === '02' ? '설문조사 기간이 아닙니다' : '종료된 설문조사입니다'}}
          </div>
        </div>
        <div v-else>
          <div v-if="description" class="article">
            <div class="context" v-html="description"></div>
          </div>
          <!-- 설문폼 -->
          <div class="form">
            <div class="formBox">
              <div class="formTitle">설문조사 참여</div>
              <!-- 설문항목 -->
              <div
                v-for="item in surveyItem"
                :key="item.seq"
                class="formGroup noTable">
                <label class="label">
                  {{item.seq}}. {{item.subject}}
                  <span class="required">({{item.required ? '필수' : '선택'}})</span>
                </label>
                <component
                  :is="item.type.replace(/\b\w/g, l => l.toUpperCase())"
                  :seq="item.seq"
                  :required="item.required"
                  :passed="item.passed"
                  :type="item.type"
                  :value="item.value"
                  :valueType="item.valueType"
                  :item="item.item"
                  @updateValue="updateValue" />
              </div>
              <!-- //설문항목 -->
              <!-- 개인정보 동의 -->
              <div class="formGroup noTable">
                <label class="label">개인정보 수집 및 이용에 관한 동의사항</label>
                <div class="context" v-html="policy"></div>
                <div class="checkAgree">
                  <div class="checkbox radio">
                    <input type="radio" name="agree_sms" id="agree_sms_y" value="y" v-model="agree">
                    <label for="agree_sms_y">동의</label>
                  </div>
                  <div class="checkbox radio">
                    <input type="radio" name="agree_sms" id="agree_sms_n" value="n" v-model="agree">
                    <label for="agree_sms_n">미동의</label>
                  </div>
                </div>
              </div>
              <!-- //개인정보 동의 -->
              <div class="formBtns">
                <button type="button" class="btn mobileFull large primary1" @click="submit">설문조사 응답하기</button>
              </div>
            </div>
          </div>
          <!-- //설문폼 -->
        </div>
      </div>
      <!-- //설문조사 본문 -->
    </div>
  </div>
</template>

<script>
import http from '@/api/index'
import CheckSurvey from './Check.vue'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import terms from "@/library/terms"
dayjs.locale('ko')

export default {
  name: 'SurveyView',
  components: {
    CheckSurvey,
    Checkbox: () => import('./Checkbox.vue'),
    Radio: () => import('./Radio.vue'),
    Input: () => import('./Input.vue'),
  },
  computed: {
    getStatus () {
      const today = dayjs()
      const start = this.surveyInfo && this.surveyInfo.reserve_date
      let end = this.surveyInfo && this.surveyInfo.end_date
      if (end === null) {
        end = "2099-12-31 00:00:00"
      }
      if (end === '0000-00-00 00:00:00') {
        end = "2099-12-31 00:00:00"
      }
      const status = today.isBefore(start)
        ? '02'
        : today.isSameOrBefore(end)
          ? '01'
          : '03'
      return {
        status: status,
        label: status === '01'
          ? '진행중'
          : status === '02'
            ? '예정'
            : '종료'
      }
    },
    description () {
      return this.surveyInfo ? this.surveyInfo.description.replace(/(?:\r\n|\r|\n)/g, '<br />') : null
    },
    submitData () {
      const arr = []
      const item = this.surveyItem.filter(x => {
        return (x.required && x.passed) || (x.value && x.value.length > 0) || (x.value && x.value > 0)
      })
      item.forEach(function (x) {
        const inputValue = getInputValue(x.inputValue)
        let value = x.valueType === 'array'
          ? x.value.map(v => v === '기타' ? inputValue : v)
          : x.value + inputValue
        function getInputValue (inputValue) {
          return inputValue ? inputValue : ''
        }
        const num = []
        switch (x.type) {
          case 'checkbox':
              x.value.map(row => {
                row === '기타' ?
                    num.push(x.item.indexOf('기타')) :
                    num.push(x.item.indexOf(row))
              })
                break
          case 'radio':
            if (typeof x.value === 'number') {
                  num.push(value - 1)
                  x.radio_answer = x.item[value - 1]
              break
            } else {
              if (x.item.indexOf(value) === -1) {
                num.push(x.item.indexOf('기타'))
                // value = value.replace('기타', '')
              } else {
                num.push(x.item.indexOf(value))
              }
              break
            }
          case 'input':
            num.push(0)
                break
        }

        if (value) {
          const obj = {
            survey_item_idx: '',
            answer_index: num,
            answer_content: '',
            radio_answer: ''
          }
          obj.answer_content = value
          obj.survey_item_idx = x.idx
          obj.radio_answer = x.radio_answer

          if (typeof obj.answer_content === 'object') {
            const newObj = obj.answer_content.map((content, index) => ({
              survey_item_idx: obj.survey_item_idx,
              answer_index: obj.answer_index[index],
              answer_content: content.trim()
            }));
            arr.push(...newObj)
          } else {
            const _newObj = {
              survey_item_idx: obj.survey_item_idx,
              answer_index: obj.answer_index[0],
              answer_content: obj.radio_answer === undefined ? obj.answer_content : obj.radio_answer
            }
            arr.push(_newObj)
          }
        }
      })
      return arr
    },
    user() {
      return this.$store.getters['user']
    },
  },
  created() {
    this.init()
  },
  data() {
    return {
      available: false,
      surveyInfo: null,
      surveyItem: null,
      component: null,
      agree: '',
      mobile: '',
      policy: terms.policy_7
    }
  },
  methods: {
    async init() {
      const data = await http.get(`/fanzone/survey/${this.$route.params.id}`)
      // 설문조사 내용
      this.surveyInfo = data.data.data.info
      this.$store.state.surveyLogin = this.surveyInfo.login_yn === 'Y';
      this.surveyItem = data.data.data.item.map(x => {
        return {
          seq: x.order_seq,
          idx: x.idx,
          required: x.required_yn === 'Y',
          subject: x.subject,
          ...this.getType(x.item_type), // C: checkbox, M: radio, T: input, N: radio(number)
          item: this.getItem(x.item_text, x.item_type),
          passed: x.required_yn !== 'Y'
          // item_number
        }
      })
      return this.surveyItem.sort((a, b) => a.seq - b.seq)
    },
    setAvailable (isAvailable) {
      this.available = isAvailable
    },
    setMobile (num) {
      this.mobile = num
    },
    dateFormat (isoString) {
      if (isoString === '2099-12-31 00:00:00') {
        return ''
      } else {
        return dayjs(isoString).format("YYYY년 MM월 DD일 HH:mm:ss")
      }
    },
    getType (type) {
      switch (type) {
        case 'C':
          return {
            type: 'checkbox',
            value: [],
            valueType: 'array',
            setType:'C'
          }
        case 'M':
          return {
            type: 'radio',
            value: '',
            valueType: 'string',
            setType:'M'
          }
        case 'T':
          return {
            type: 'input',
            value: '',
            valueType: 'string',
            setType:'T'
          }
        case 'N':
          return {
            type: 'radio',
            value: null,
            valueType: 'number',
            setType:'N'
          }
        default:
          return {
            type: 'input',
            value: '',
            valueType: 'string',
            setType:'input'
          }
      }
    },
    getItem (string, type) {
      // return string !== null && string.indexOf('#') !== -1
      //     ? string.split('#').slice(1).map(str => str.trim())
      //     : string
      if(string !== null && string.indexOf('#') !== -1) {
        if (type === 'N') {
          let count = 6
          let obj = string.split('#').map(str => str.trim())
          obj.map((el, i) => {
            count--
            obj[i] = `${el} (${count}점)`
          })
          return obj
        } else {
          return string.split('#').map(str => str.trim())
        }
      } else {
        return string
      }
    },
    updateValue (data) { // seq, value, passed
      const item = this.surveyItem.find(x => x.seq === data.seq)
      if (item) {
        item.value = data.value
        item.passed = data.passed
        if (data.inputValue) {
          item.inputValue = data.inputValue
        } else {
          delete item.inputValue
        }
      }
    },
    validate () {
      const invalidItem = this.surveyItem.filter(x => !x.passed)
      const invalidMessage = invalidItem.map(x => {
        return `${x.seq}`
      }).join(', ')
      if (invalidItem.length) {
        alert(`필수항목(${invalidMessage})을 선택/입력 해야합니다`)
        return false
      } else if (this.agree !== 'y') {
        alert('개인정보 수집 및 이용에 관한 동의사항에 동의해야합니다')
      } else {
        return true
      }
    },
    submit () {
      const isMember = this.surveyInfo.login_yn === 'Y';
      if (this.validate()) {
        const data = {
          target: { name: this.user.name, number: this.mobile},
          list: this.submitData
        }
        // 비회원인경우 이름제거
        http.post(`/fanzone/survey/${this.$route.params.id}/send`, data)
            .then(() => {
              if (isMember) {
                alert('설문조사 참여가 완료되었습니다.\n설문조사 참여 내역은 마이페이지에서 확인할 수 있습니다.')
                return this.$router.push('/mypage/activity/survey/1')
              } else {
                alert('설문조사 참여가 완료되었습니다.')
                return this.$router.push('/')
              }
            })
            .catch(err => console.log(err))
      }
    }
  },
}
</script>
<style>
.mt50 {
  margin-top: 50px;
}
</style>
