<template>
  <div>
    <div class="indexBox waitingSeason" style="margin-top: 80px;">
      <div class="container">
        <img src="@/assets/img/fanzone/ticket_point_2024.jpg" alt="ticket_point_info"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ticketPoint',
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
</style>
