<template>
  <div class="myinfoBox">
    <div class="topTitle">
      <div class="title">회원탈퇴</div>
      <div class="subTitle">전북현대모터스에프씨 웹사이트 회원제 탈퇴를 위해서 <span class="pcBreak">유의사항 동의와 비밀번호 확인이 필요합니다.</span></div>
    </div>
    <!-- 유의사항 -->
    <div class="formGroup">
      <label class="label">회원탈퇴 유의사항</label>
      <div class="textarea height110" v-html="content"></div>
      <div class="checkAgree">
        <div class="checkbox radio">
          <input
            v-model="agree"
            type="radio"
            name="agree_sms"
            id="agree_sms_y"
            value="Y">
          <label for="agree_sms_y">동의</label>
        </div>
        <div class="checkbox radio">
          <input
            v-model="agree"
            type="radio"
            name="agree_sms"
            id="agree_sms_n"
            value="N">
          <label for="agree_sms_n">미동의</label>
        </div>
      </div>
    </div>
    <!-- //유의사항 -->
    <!-- 비밀번호 -->
    <div class="formGroup">
      <label class="label">비밀번호 입력 <span class="required">(필수)</span></label>
      <input type="password" class="input" placeholder="비밀번호를 입력하세요" v-model="pw1" @keyup="matchPassword">
      <p class="inputMessage"><span v-if="pw1_pass" class="confirmMessage"></span> 영어 대/소문자, 특수문자, 숫자를 조합한 9~20자리로 입력하세요</p>
    </div>
    <!-- //비밀번호 -->
    <!-- 비밀번호 확인 -->
    <div class="formGroup">
      <label class="label">비밀번호 확인 <span class="required">(필수)</span></label>
      <input type="password" class="input" placeholder="비밀번호를 다시 입력하세요" v-model="pw2" @keyup="matchPassword">
      <p v-if="pw2_pass" class="confirmMessage">비밀번호가 일치합니다</p>
    </div>
    <!-- //비밀번호 확인 -->
    <div class="formBtns">
      <button type="button" class="btn mobileFull large primary1" @click="withdraw">탈퇴</button>
      <!--<button type="button" class="btn mobileFull large lightgrey4">취소하기</button>-->
    </div>
  </div>
</template>

<script>
import regExp from "@/common/regExp";
import http from "@/api/index";
import terms from '@/library/terms'

export default {
  name: 'MyinfoWithdraw',
  computed: {
  },
  mounted () {
  },
  data () {
    return {
      agree: '',
      pw1: '',
      pw2: '',
      pw1_pass: false,
      pw2_pass: false,
      loading: false,
      content : terms.draw
    }
  },
  methods: {
    isEmpty (str) {
      return regExp.replaceAllEmpty(`${str}`).length === 0
    },
    matchPassword () {
      const pw1 = this.pw1
      const pw2 = this.pw2
      this.pw1_pass = !this.isEmpty(pw1) && regExp.expPassword.test(pw1)
      if (!this.isEmpty(pw1) && this.pw1_pass) {
        this.pw2_pass = pw1 === pw2
      }
    },
    withdraw () {
      if (this.loading) return

      if (this.agree !== 'Y') {
        alert('회원탈퇴 동의항목은 필수입니다.')
        return
      } else if (!this.pw1_pass) {
        alert('비밀번호를 영어 대/소문자, 특수문자, 숫자를 조합한 9~20자리로 입력하세요')
        return
      } else if (!this.pw2_pass) {
        alert('비밀번호를 확인해주세요')
        return
      }

      if(confirm('전북현대모터스에프씨 웹사이트를 탈퇴하시면 포인트, 쿠폰 등이 소멸됩니다. 탈퇴하시겠습니까?')) {
        this.loading = true;
        http.post('/user/withdraw').then(res => {
          if (res.data.success) {
            alert('탈퇴되었습니다.')
            this.$store.commit('logout')
            this.$router.push("/")
          } else {
            alert(res.data.message)
            this.$router.push("/")
          }
        }).catch(() => {
          alert('탈퇴 실패하였습니다. 관리자에게 문의하세요.')
        }).finally(() => {
          this.loading = false;
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.confirmMessage {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #046A38;
}
.confirmMessage:before {
  content: "L";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 3px;
  text-align: center;
  vertical-align: middle;
  line-height: 16px;
  font-family: "arial";
  font-size: 10px;
  font-weight: 0;
  color: #FFFFFF;
  border-radius: 100%;
  background-color: #046A38;
  transform: scaleX(-1) rotate(-35deg);
}
</style>
