<template>
  <div class="memberBox">
    <div class="topTitle">
      <p class="title">로그인</p>
      <p class="subTitle">전북현대모터스에프씨<br>웹사이트 방문을 환영합니다.</p>
    </div>
    <div>
      <input type="text" class="input" placeholder="아이디를 입력하세요" v-model="userId">
    </div>
    <div class="formRow">
      <input type="password" class="input" placeholder="비밀번호를 입력하세요" v-model="userPwd" @keyup.enter="signin">
    </div>
    <div class="formBtns formBtns2">
      <button type="button" class="btn large full primary1" @click="signin">로그인</button>
    </div>
    <div class="formBtns formBtns2 memberLinks">
      <router-link to="/member/signup/1" class="signup">회원가입</router-link>
      <router-link to="/member/findid" class="findid">아이디찾기</router-link>
      <router-link to="/member/findpw" class="findpw">비밀번호찾기</router-link>
    </div>
    <div class="snsLogin">
      <!--
      <a href="javascript:alert('SNS로그인 기능은 1월 2주차부터 사용이 가능합니다')" class="kakao">카카오<br>로그인</a>
      <a href="javascript:alert('SNS로그인 기능은 1월 2주차부터 사용이 가능합니다')" class="naver">네이버<br>로그인</a>
      <a href="javascript:alert('SNS로그인 기능은 1월 2주차부터 사용이 가능합니다')" class="facebook">페이스북<br>로그인</a>
      <a href="javascript:alert('SNS로그인 기능은 1월 2주차부터 사용이 가능합니다')" class="apple">애플<br>로그인</a>
      -->
      <a href="javascript:void(0)" class="kakao" @click="kakaoLogin">카카오<br>로그인</a>
      <a href="javascript:void(0)" class="naver" @click="naverLogin">네이버<br>로그인</a>
<!--      <a href="javascript:void(0)" class="facebook" @click="facebookLogin">페이스북<br>로그인</a>-->
      <a href="javascript:void(0)" class="apple" @click="appleLogin">애플<br>로그인</a>
    </div>
    <div id="naver_id_login" style="display:none;"></div>
  </div>
</template>

<script>
import http from '@/api/index'
import regExp from '@/common/regExp'

export default {
  name: 'MemberSignin',
  data() {
    return {
      loading: false,
      userId: '',
      userPwd: '',
      redirectUrl: '/',
    }
  },
  created() {

    if(this.$route.query.redirectUrl) {
      this.redirectUrl = this.$route.query.redirectUrl
    }
  },
  mounted() {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')

    this.naver_id_login = new window.naver_id_login(process.env.VUE_APP_NAVER_CLIENT_ID, `${window.location.origin}/member/signin`);
    let state = this.naver_id_login.getUniqState();
    this.naver_id_login.setButton("white", 1,40);
    //this.naver_id_login.setDomain("10.177.254.20:8080");
    this.naver_id_login.setState(state);
    this.naver_id_login.init_naver_id_login();
    this.naverTokenCheck();

  },
  methods: {
    snsLogin(type) {
      if(this.loading) return;
      this.loading = true;

      if (type === "kakao") {
        this.kakaoLogin();
      } else if (type === "naver") {
        this.naverLogin();
      } else if (type === "facebook") {
        this.facebookLogin();
      } else if (type === "apple") {
        this.appleLogin();
      } else if (type === "google") {
        this.googleLogin();
      }
    },
    appleLogin() {
      window.onmessage = (e) => {
        if(e.origin === process.env.VUE_APP_API_SERVER_URI) {
          if (e.data.hasOwnProperty('id_token')) {
            let body = {
              code: e.data.id_token,
              agent: 'web'
            }
            http.post('/user/apple/callback', body).then(res=>{
              let payload = res.data.data;
              payload.state = true;
              this.$store.commit('login', payload);

              alert('전북현대모터스에프씨 웹사이트에 로그인하셨습니다.');
              this.$router.push(this.redirectUrl);
            }).catch(err=>{
              alert('통합회원가입 후 마이페이지에서 소셜 연동을 해주세요.');
            }).finally(()=>{
              this.loading = false;
            })
          } else {
            this.loading = false;
            alert('애플 로그인 실패하였습니다.');
          }
        } else {
          this.loading = false;
          alert('애플 로그인 실패하였습니다.');
        }
      };

      window.open(process.env.VUE_APP_APPLE_LOGIN_URI, "apple", "width=500, height=500");

    },
    naverTokenCheck() {
      if(this.naver_id_login.getAccessToken() !== undefined) {
        //const accessToken = this.naver_id_login.getAccessToken();
        let body = {
          code: this.naver_id_login.getAccessToken(),
          agent: 'web'
        };
        http.post(`/user/naver/callback`, body).then((res) => {
          let payload = res.data.data;
          // console.log('333--->> ', res.data);
          payload.state = true;
          this.$store.commit('login', payload);
          let query = Object.assign({}, this.$route.query);
          delete query.access_token;
          this.$router.replace({ query });
          alert('전북현대모터스에프씨 웹사이트에 로그인하셨습니다.');
          this.$router.push(this.redirectUrl);
        }).catch((err) => {
          let query = Object.assign({}, this.$route.query);
          delete query.access_token;
          this.$router.replace({ query });
          alert('통합회원가입 후 마이페이지에서 소셜 연동을 해주세요.');
        }).finally(()=>{
          this.loading = false;
        })
      } else {
        this.loading = false;
      }
    },
    naverLogin() {
      document.querySelector("#naver_id_login>a").click();
    },
    facebookLogin() {
      alert("페이스북 로그인은 기본 브라우저에서만 가능합니다.");
      window.FB.login((response) => {
        if (response.status === 'connected') {
          let body = {
            code: response.authResponse.accessToken,
            agent: 'web'
          };
          http.post(`/user/facebook/callback`, body).then((res) => {
            let payload = res.data.data;
            payload.state = true;
            this.$store.commit('login', payload);
            alert('전북현대모터스에프씨 웹사이트에 로그인하셨습니다.');
            this.$router.push(this.redirectUrl);
          }).catch((err) => {
            alert('통합회원가입 후 마이페이지에서 소셜 연동을 해주세요.');
          }).finally(()=>{
            this.loading = false;
          })

        } else {
          this.loading = false;
          alert('페이스북 로그인에 실패하였습니다.');
        }
      }, {scope: 'email'});
    },
    kakaoLogin() {
      window.Kakao.Auth.login({
        success: (authObj) => {
          let body = {
            code: authObj.access_token,
            agent: 'web'
          };
          http.post(`/user/kakao/callback`, body).then((res) => {
            // 로그인처리
            let payload = res.data.data;
            payload.state = true;
            this.$store.commit('login', payload);

            alert('전북현대모터스에프씨 웹사이트에 로그인하셨습니다.');
            this.$router.push(this.redirectUrl);
          }).catch((err) => {
            alert('통합회원가입 후 마이페이지에서 소셜 연동을 해주세요.');
          }).finally(()=>{
            this.loading = false;
          })
        },
        fail: (err) => {
          this.loading = false;
          alert('카카오 로그인 실패하였습니다.');
        }
      });
    },
    signin(){
      if(this.loading){
        return;
      }
      this.loading = true;

      if(regExp.replaceAllEmpty(this.userId) === '' || regExp.replaceAllEmpty(this.userPwd) === ''){
        this.loading = false;
        alert('아이디 또는 비밀번호를 입력해주세요.');
        return;
      }

      http.post('/user/login?agent=web', {
        user_id: this.userId,
        user_pw: this.userPwd
      }).then(res => {

        // 로그인처리
        let payload = res.data.data;
        payload.state = true;
        this.$store.commit('login', payload);
        alert('전북현대모터스에프씨 웹사이트에 로그인하셨습니다.');
        this.$router.push(this.redirectUrl);

      }).catch(err => {
        alert(err.response.data.message);
      }).finally(()=>{
        this.loading = false;
      });
    },
  },
  beforeDestroy () {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
  },
}
</script>

<style lang="scss" scoped>
.memberLinks {
  display: flex;
  margin-bottom: 90px;
  color: #000;
  a {
    position: relative;
    padding-right: 12px;
    &:after {
      content: "\203A";
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -0.55em;
      font-size: 1.2em;
      font-weight: bold;
      color: $grey3;
    }
  }
  .findid {
    margin-left: auto;
    margin-right: 20px;
  }
}
@media screen and (max-width: 767px) {
  .memberLinks {
    font-size: 14px;
  }
}
</style>
