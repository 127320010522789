import Index from '@/views/Member.vue'
import Signin from '@/components/Member/Signin.vue'
import FindId from '@/components/Member/FindId.vue'
import FindPw from '@/components/Member/FindPw.vue'
import Signup from '@/components/Member/Signup.vue'
import Myinfo from '@/components/Member/Myinfo.vue'
import MyinfoInfo from '@/components/Member/MyinfoInfo.vue'
import MyinfoSns from '@/components/Member/MyinfoSns.vue'
import MyinfoWithdraw from '@/components/Member/MyinfoWithdraw.vue'

const member = [
  {
    path: '/member',
    name: 'member',
    component: Index,
    children: [
      {
        path: 'signin',
        name: 'signin',
        component: Signin
      },
      {
        path: 'findid',
        name: 'findid',
        component: FindId
      },
      {
        path: 'findpw',
        name: 'findpw',
        component: FindPw
      },
      {
        path: 'signup/:currentStep',
        name: 'signup',
        component: Signup,
        props: true
      },
      {
        path: 'myinfo',
        name: 'myinfo',
        component: Myinfo,
        children: [
          {
            path: 'sns',
            name: 'myinfoSns',
            component: MyinfoSns
          },
          {
            path: 'info',
            name: 'myinfoInfo',
            component: MyinfoInfo
          },
          {
            path: 'withdraw',
            name: 'myinfoWithdraw',
            component: MyinfoWithdraw
          }
        ]
      }
    ]
  }
]

export default member
