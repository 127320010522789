<template>
  <div>
    <div class="cert">
      <button type="button" class="phone" @click="cert('phone')">휴대폰 본인인증</button>
      <button type="button" class="ipin" @click="cert('ipin')">아이핀 본인인증</button>
    </div>
    <div class="certMessage">
      NICE평가정보에서 인증 받은 휴대폰 번호를 사용하고 있습니다.<br>본인인증 실패시 NICE평가정보 고객센터 1600-1522로 문의하세요.
    </div>
  </div>
</template>

<script>
let popup;
export default {
  name: 'MemberCert',
  props: {
    type: {
      type: String,
      default: 'signup'
    },
  },
  created() {
  },
  methods: {
    cert (certType) {
      let query = `?type=signup`;
      if(this.type === 'findId'){
        query = `?type=findid`;
      } else if(this.type === 'findPw'){
        query = `?type=findpw`;
      }

      const testUrl = 'https://api-jbhd.thesports.io'
      const liveUrl = 'https://api.jbfc.kr'

      if(certType === 'phone') {
        const url = `${liveUrl}/user/checkplus_main` + query
        window.onmessage = (e) => {
            if(e.origin === `${liveUrl}`) {
            const data = JSON.parse(e.data.replace(/&#34;/gm, '"'));
            if(data.err !== 'true') {
              this.$emit('complete', certType, data)
            }
          }

        }
        popup = window.open(url, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
        // document.location.href = 'http://localhost:4000/user/checkplus_main'
      } else if(certType === 'ipin') {
        const url = `${liveUrl}/user/ipin_main` + query;
        popup = window.open(url, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');

        window.onmessage = (e) => {
            if(e.origin === `${liveUrl}`) {
            const data = JSON.parse(e.data.replace(/&#34;/gm, '"'));
            if(data.err === 'true') {
              return false;
            }

            if (this.type === 'signup') {
              alert("아이핀 인증은 추후 회원 통합이 불가능합니다.")
            }
            this.$emit('complete', certType, data)
          }

        }
        // document.location.href = 'http://localhost:4000/user/ipin_main'
      }
      // alert('본인인증이 완료되었습니다.')
      // this.$emit('complete', certType)
    }
  }
}
</script>
<style lang="scss" scoped>
.cert {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.phone {
  margin-right: 70px;
}
.phone:before,
.ipin:before {
  content: "";
  display: block;
  width: 164px;
  height: 164px;
  margin-bottom: 20px;
  border-radius: 100%;
  background: $primary1 url('~@/assets/img/ico_cert_phone.png') 50% 50% / 44px auto no-repeat;
}
.ipin:before {
  background: $primary2 url('~@/assets/img/ico_cert_ipin.png') 50% 50% / 58px auto no-repeat;
}
@media screen and (max-width: 767px) {
  .phone {
    margin-right: 43px;
  }
  .phone:before,
  .ipin:before {
    background-size: auto 46px;
  }
}
</style>
