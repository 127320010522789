<template>
  <div class="form">
    <div class="formBox">
      <div class="formTitle">설문조사 참여 확인</div>
      <div class="formGroup">
        <label class="label">휴대폰번호</label>
        <div class="withBtn">
          <input type="tel" class="input" placeholder="휴대폰번호를 입력하세요" ref="phoneInput" :value="phone" @input="phoneFormatter" @keyup.enter.prevent="checkSurvey">
          <button type="button" class="btn whiteLightgrey" @click="checkSurvey($event)">설문조사 체크</button>
        </div>
        <p v-if="pass" class="confirmMessage">{{msg}}</p>
      </div>
      <div v-if="available === '01'" class="formBtns">
        <button type="button" class="btn mobileFull large primary1" @click="setAvailable">참여하기</button>
      </div>
      <div v-if="available === '02'" class="formBtns">
        <router-link type="button" class="btn mobileFull large" to="/mypage/activity/survey/1">설문조사 내역 확인하기</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from '@/api/index'
import axios from "axios"

export default {
  name: 'CheckSurvey',
  computed: {
    user() {
      return this.$store.getters['user']
    },
    surveyLogin () {
      return this.$store.state.surveyLogin
    }
  },
  created() {
    this.idx = this.$route.params.id
  },
  data() {
    return {
      phone: '',
      mobile: '',
      available: null,
      idx: '',
      msg: '',
      pass: false
    }
  },
  methods: {
    isEmpty (str) {
      return regExp.replaceAllEmpty(`${str}`).length === 0
    },
    focusRefs (ref) {
      setTimeout(() => {
        if (this.$refs[ref]) {
          this.$refs[ref].focus()
        }
      }, 100)
    },
    phoneFormatter (e) {
      const phoneNumber = e.target.value
      this.phone = regExp.phoneFormatter(phoneNumber)
    },
    checkSurvey () {
      const mobile = this.phone.replace(/-/g, '')
      const data = {name : this.user.name, number: mobile}
      if (!this.surveyLogin) {
        data.name = null; // 비회원 설문조사일 경우 이름삭제
      }
      http.post(`/fanzone/survey/${this.idx}/target`, data)
          .then(res => {
        if (this.isEmpty(this.phone)) {
          this.focusRefs('phoneInput')
          alert('휴대폰번호를 입력하세요')
          this.pass = false
          this.available = ''
        } else if (!regExp.expPhone.test(this.phone)) {
          alert('올바른 휴대폰번호 형식이 아닙니다')
          this.pass = false
          this.phone = ''
          this.available = ''
        } else {
          // 설문여부 조회
          /**
           * 조회 전: null
           * 조회 후 참여가능: 01
           * 조회 후 참여불가: 02 (이미 참여함)
           */
          if (res.data.success) {
            this.pass = true
            this.available = '01'
            this.msg = res.data.message
          } else {
            this.pass = true
            this.available = '02'
            this.msg = res.data.message
          }
        }
      })
    },
    setAvailable () {
      this.$emit('setAvailable', true)
      this.$emit('setMobile', this.phone.replace(/-/g, ''))
    },
  },
}
</script>

