<template>
  <div class="mediaView fanzoneEventView">
    <div class="mediaTitle eventTitle">
      <div class="container">
        <span class="status" :data-status="eventData.dataStatus">{{ eventData.eventState }}</span>
        <p class="subject">{{ eventData.event_name }}</p>
        <p class="date">{{ eventData.setStartDate }} ~ {{ eventData.setEndDate }}</p>
      </div>
    </div>
    <div class="container">
      <!-- 이벤트 본문 -->
      <div class="mediaContent eventContent">
        <div class="article">
          <div class="photo" v-if="eventData.guide_pic !== ''">
            <img :src="eventData.guide_pic" alt="">
          </div>
          <div class="context" v-html="eventData.guide_contents">
          </div>
        </div>
        <!-- 신청폼 -->
        <div class="form" v-if="this.setEventState === '01'">
          <div class="formBox">
            <div class="formTitle">신청내용</div>
            <!-- 이름 -->
            <div class="formGroup">
              <label class="label">신청자 이름</label>
              <input type="text" class="input" :value="this.user.name" disabled>
            </div>
            <!-- //이름 -->
            <!-- 연락처 -->
            <div class="formGroup">
              <label class="label">신청자 연락처</label>
              <input type="text" class="input" :value="this.userMobile" disabled>
            </div>
            <!-- //연락처 -->
            <!--추가입력 pic1-->
            <div class="formGroup" v-for="(item, num) in eventData.arr" :key="num">
              <label class="label">{{item.name}}</label>
              <!-- 체크박스인 경우 : C -->
              <div v-if="item.st === 'C'"
                  v-for="(checkbox, index) in item.arr"
                  :key="index"
                  class="checkbox checkbox formRow">
                <input
                    :name="`checkbox_${num}_${index}`"
                    :id="`checkbox_${num}_${index}`"
                    :value="checkbox"
                    type="checkbox"
                    @change="getCheckedValues($event, item, num, index)"
                >
                <label :for="`checkbox_${num}_${index}`">
                  {{checkbox}}
                </label>
                <div v-if="etcSwitch[num] && checkbox === '기타'" :class="['inputValue', { 'hidden': !item.showInputValue }]">
                  <input
                      type="text"
                      class="input"
                      v-model="item.inputValue"
                      maxlength="300"
                      placeholder="입력하세요">
                </div>
              </div>
              <!-- 체크박스인 경우 : C -->
              <!-- 첨부파일 : F-->
              <div  v-if="item.st === 'F'" :class="uploadData.length !== 0 ? 'withBtn w140' : ''">
                <div class="upload">
                  <div class="file" v-for="({id, file}) in uploadData" :key="id">
                    <div class="fileBox">
                      <div class="fileNameBox"><span>{{ file.name }}</span></div>
                      <div class="fileBtnBox"><span><button type="button" class="btnDel" @click="deleteFile(id)"></button></span></div>
                    </div>
                    </div>
                </div>
                <label for="file">
                  <div class="btn primary2 click">첨부파일 등록하기</div>
                </label>
                <input type="file" @change="uploadFile" ref="upload" name="file" id="file"
                       accept="image/png, image/jpeg, image/jpg" style="display: none">
              </div>
              <!-- 첨부파일 : F-->
              <!-- 라디오인 경우 : M -->
              <div  v-if="item.st === 'M'"
                  v-for="(radio, index) in item.arr"
                  :key="index"
                  class="checkbox radio formRow">
                <input
                    :name="`radio_${num}`"
                    :id="`radio_${num}_${index}`"
                    type="radio"
                    :value="radio"
                    @change="getCheckedRadioValues(num, radio)"
                   v-model="item.value">
                <label :for="`radio_${num}_${index}`">
                  {{radio}}
                </label>
                <div v-if="isInput(radio)"
                     :class="['inputValue', { hidden: !radioEtcSwitch[num] }]">
                  <input
                      type="text"
                      class="input"
                      v-model="item.inputValue"
                      maxlength="300"
                      placeholder="입력하세요">
                </div>
              </div>
              <!-- 라디오인 경우 : M -->
              <!-- 라디오인 경우 : N -->
              <div  v-if="item.st === 'N'"
                    v-for="(radio, index) in item.arr"
                    :key="index"
                    class="checkbox radio formRow">
                <input
                    :name="`radio_${num}`"
                    :id="`radio_${num}_${index}`"
                    type="radio"
                    :value="radio"
                    @change="getCheckedRadioValues(num, radio)"
                    v-model="item.value">
                <label :for="`radio_${num}_${index}`">
                  {{radio}}
                </label>
              </div>
              <!-- 라디오인 경우 : N -->
              <!-- 단답형인 경우 : T -->
              <input v-if="item.st === 'T'" class="input" :placeholder="'입력하세요'" maxlength="100" @input="item.value=$event.target.value"/>
            </div>
            <!-- 단답형인 경우 : T -->
            <div class="formGroup" v-if="form.season_state === 'y'">
              <label class="label">시즌권 선택</label>
              <select class="input" v-model="form.seasonNum">
                <option value="">선택하세요</option>
                <option v-for="(num, i) in season"
                        :key="i"
                        :value="num.number">
                  {{ num.product_name }} ({{ num.number }})
                </option>
              </select>
            </div>
            <!-- //시즌권 -->
            <!--우편DIV-->
            <div v-if="form.addr_state === 'y'">
              <!-- 우편번호 -->
              <div class="formGroup">
                <label class="label">경품 수령지 입력</label>
                <div class="withBtn w140">
                  <input type="email" class="input" placeholder="우편번호를 검색하세요" :value="form.address_num" disabled>
                  <button type="button" class="btn primary2" @click="findZipcode">우편번호 검색</button>
                </div>
                <div class="zipcodeWrap" ref="embed"></div>
              </div>
              <!-- //우편번호 -->
              <!-- 상세주소 -->
              <div class="formGroup">
                <label class="label">주소</label>
                <input type="text" class="input" :value="form.address" disabled>
              </div>
              <div class="formGroup">
                <label class="label">상세주소</label>
                <input type="text" class="input" placeholder="상세주소를 입력하세요" :value="form.address_under">
              </div>
              <!-- //상세주소 -->
            </div>
            <!--//우편DIV-->
            <!-- 개인정보 동의 -->
            <div class="formGroup noTable">
              <label class="label">개인정보 수집 및 이용에 관한 동의사항</label>
              <div class="context" v-html="terms"></div>
              <div class="checkAgree">
                <div class="checkbox radio">
                  <input
                      v-model="agree"
                      type="radio"
                      name="agree_sms"
                      id="agree_sms_y"
                      value="y">
                  <label for="agree_sms_y">동의</label>
                </div>
                <div class="checkbox radio">
                  <input
                      v-model="agree"
                      type="radio"
                      name="agree_sms"
                      id="agree_sms_n"
                      value="n">
                  <label for="agree_sms_n">미동의</label>
                </div>
              </div>
            </div>
            <!-- //개인정보 동의 -->
            <div class="formBtns">
              <button type="button" :disabled="isSuccess" class="btn mobileFull large primary1" @click="postEvent">신청하기</button>
            </div>
          </div>
        </div>
        <!-- //신청폼 -->
        <div class="form" v-if="this.setEventState === '02'">
          <div class="noData">본 이벤트는 신청 전입니다. 이벤트 시작 시간에 새로고침 해주시기 바랍니다.</div>
        </div>
        <div class="form" v-if="this.setEventState === '03'">
          <div class="noData">본 이벤트는 마감되었습니다.</div>
        </div>
      </div>
      <div class="mediaBtns">
        <router-link to="/fanzone/event" class="btn auto">목록으로</router-link>
      </div>
      <!-- //이벤트 본문 -->
    </div>
  </div>
</template>

<script>
import http from '@/api/index'
import mypageApi from '@/api/mypageApi'
import dayjs from "dayjs"
import {stripTag} from '@/library/stripHtml'
import axios from "axios"
import store from "@/store"
import termsLib from "@/library/terms"
import router from "@/router"

export default {
  name: 'FanzoneEventView',
  created() {
    this.user = this.$store.getters['user']
    // console.log(this.user)
    // if (this.user.name === null || this.user.name === '') {
    //   const redirectUrl = router.currentRoute.path;
    //   store.commit('logout');
    //   alert('로그인이 필요한 서비스입니다.');
    //   return router.push({name: 'signin', query: {redirectUrl: redirectUrl}});
    // }
    http.get('/user/detail')
        .then(res => {
          this.userMobile = res.data.data.user.phone
        })
    mypageApi.get('/seasonpass/?range=thisyear')
        .then(res => {
          this.season = res.data.data.list
        })

    this.init()

    this.terms = termsLib.eventTerms // 시즌권자
  },
  computed: {
    stripTag() {
      return stripTag
    },
  },
  data() {
    return {
      radioEtcSwitch: {
        0: false,
        1: false,
        2: false
      },
      etcSwitch : [false, false, false],
      isSuccess: false,
      setEventState: '',
      eventData: [],
      user: [],
      userMobile: '',
      season: [],
      upload_state: {
        pic1: '',
        pic2: '',
        pic3: ''
      },
      form: {
        upload_state: '',
        pic1_state: '',
        add_info1: '',
         pic2_state: '',
        add_info2: '',
        pic3_state: '',
        add_info3: '',
        season_state: '',
        seasonNum: '',
        addr_state: '',
        address_num: '',
        address: '',
        address_under: '',
      },
      uploadData: [],
      chkArr : [],
      id: 0,
      agree: '',
      terms: '',
    }
  },
  methods: {
    async init() {
      axios({
        methods: 'get',
        url: `https://api.jbfc.kr/fanzone/event/temp/${this.$route.params.id}`, // 라이브
        // url: `https://api-jbhd.thesports.io/fanzone/event/${this.$route.params.id}`, // 개발
        headers: {
          'x-access-token': store.state.token.access,
          "content-type": "application/json"
        }
      }).then(data => {
        if (data.data.data.err === true) {
          alert(`${data.data.data.msg}`)
          return this.$router.push('/fanzone/event')
        }
        this.eventData = data.data.data.event
        const nowTime = dayjs()
        if (this.eventData.length !== 0) {
          this.terms = this.eventData.data_terms_content;
          if(!this.eventData.data_terms_content) {
            this.terms = termsLib.eventTerms
          }
          this.eventData.setStartDate = this.eventData.start_apply ? dayjs(this.eventData.start_apply).format('YYYY년 MM월 DD일 HH:mm') : '';
          this.eventData.setEndDate = this.eventData.end_apply ? dayjs(this.eventData.end_apply).format('YYYY년 MM월 DD일 HH:mm') : '';
          this.form.addr_state = this.eventData.user_addr === 'n' ? 'n' : 'y';
          this.form.season_state = this.eventData.season_check === "y" ? 'y' : 'n';
          this.form.pic1_state = !this.eventData.pic1_st ? 'n' : 'y';
          this.form.pic2_state = !this.eventData.pic2_st ? 'n' : 'y';
          this.form.pic3_state = !this.eventData.pic3_st ? 'n' : 'y';
          this.upload_state.pic1 = this.eventData.pic1_st !== 'F' ? 'n' : 'y';
          this.upload_state.pic2 = this.eventData.pic2_st !== 'F' ? 'n' : 'y';
          this.upload_state.pic3 = this.eventData.pic3_st !== 'F' ? 'n' : 'y';

          this.eventData.arr = []
          const addEventPic = (picName, picValue, picState, num) => {
            if (picState !== null) {
              const arr = this.getItem(picValue, picState)
              this.eventData.arr.push({ name: picName, arr, st: picState , value : picState=== 'C' ? [] : '', inputValue : ''})
            }
          }
          addEventPic(this.eventData.pic1_name, this.eventData.pic1_value, this.eventData.pic1_st,1)
          addEventPic(this.eventData.pic2_name, this.eventData.pic2_value, this.eventData.pic2_st,2)
          addEventPic(this.eventData.pic3_name, this.eventData.pic3_value, this.eventData.pic3_st,3)

          const start_apply = dayjs(this.eventData.start_apply)
          const end_apply = dayjs(this.eventData.end_apply)
          if (nowTime.isBefore(start_apply) === true) {
            this.eventData.eventState = '예정'
            this.eventData.dataStatus = '02'
            this.setEventState = '01'
          } else if (nowTime.isAfter(end_apply) === true) {
            this.eventData.eventState = '종료'
            this.eventData.dataStatus = '03'
            this.setEventState = '01'
          } else{
            this.eventData.eventState = "진행중"
            this.eventData.dataStatus = '01'
            this.setEventState = '01'
          }
        }
      })
          .catch(err => {
            if (err.response.status === 401 && err.response.data.message === 'non-existent member') {
              console.log(err)
              alert('존재하지 않는 회원입니다.');
              return router.push('/');
            }
            if (err.response.status === 400 || err.response.data === undefined) {
              console.log(err)
              alert('존재하지 않는 이벤트입니다.');
              return this.$router.push({name: 'fanzoneEvent'})
            }
          })
    },
    findZipcode() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          this.form.address_num = data.zonecode
          this.form.address = data.roadAddress
        }
      }).embed(this.$refs.embed);
    },
    uploadFile(e) {
      let i = this.id
      const obj = {id: i}
      const maxSize = 3 * 1024 * 1024 // 3MB
      if (e.target.files[0].size > maxSize) {
        return alert('3MB 이내의 JPG, JPEG, PNG 파일만 등록이 가능합니다.')
      }
      if (this.uploadData.length > 2) {
        return alert('첨부파일은 최대 3장까지 등록 가능합니다.')
      }
      else {
        obj['file'] = e.target.files[0]
      }
      this.uploadData.push(obj)
      this.id++
      alert('정상으로 등록되었습니다.')
    },
    deleteFile(id) {
      this.uploadData = this.uploadData.filter(row => row.id !== id);
      this.$refs.upload.value = ''
    },
    getItem (string, type) {
      if (type === 'P') {
        return
      }
      if(string !== null && string.indexOf('#') !== -1) {
        if (type === 'N') {
          let count = 6
          let obj = string.split('#').slice(1).map(str => str.trim())
          obj.map((el, i) => {
            count--
            obj[i] = `${el} (${count}점)`
          })
          return obj
        } else {
          return string.split('#').slice(1).map(str => str.trim())
        }
      } else {
        return string
      }
    },
    postEvent() {
      const nowTime = dayjs()
      const end_apply = dayjs(this.eventData.end_apply)
      if(nowTime.isAfter(end_apply) === true) {
        alert('본 이벤트는 마감되었습니다.')
        return this.$router.push('/fanzone/event')
      }

      if (this.agree !== 'y') {
        return alert('해당 이벤트는 개인정보 수집 및 이용에 관한 동의사항은 필수로 동의하셔야 참여가 가능합니다.')
      }

      if (this.form.upload_state === 'y' && this.uploadData.length === 0) {
        alert('일부 항목이 누락되었습니다.')
        return
      }

      if (this.form.season_state === 'y' && this.form.seasonNum === "") {
        alert('일부 항목이 누락되었습니다.')
        return
      }

      if (this.form.addr_state === 'y' && this.form.address_under === "") {
        alert('일부 항목이 누락되었습니다.')
        return
      }
      for (let i = 0; i < 3; i++) {
        if (!this.eventData.arr[i]) {
          continue; // 해당 인덱스의 요소가 없으면 건너뛰고 다음 인덱스 확인
        }

        if (this.eventData.arr[i].st !== 'F' && this.form[`pic${i + 1}_state`] === 'y' && this.eventData.arr[i].value.length === 0) {
          alert('일부 항목이 누락되었습니다.')
          return
        }

        if (this.eventData.arr[i].st === 'F' && this.uploadData.length === 0) {
          alert('일부 항목이 누락되었습니다.')
          return
        }

        if (this.eventData.arr[i].st === 'M' && this.eventData.arr[i].value === '기타' && this.eventData.arr[i].inputValue.length === 0) {
          alert('일부 항목이 누락되었습니다.')
          return
        }

        if (this.eventData.arr[i].st === 'C' && this.isInput(this.eventData.arr[i].value) && this.eventData.arr[i].inputValue.length === 0) {
          alert('일부 항목이 누락되었습니다.')
          return
        }
      }


      this.eventData.arr.filter(el => {
        el.setValue = el.value
        if (el.st === 'C') {
          const arr = el.value
          if (el.inputValue.length > 0 && el.value.indexOf('기타') !== -1) {
            arr.push(el.inputValue)
          }
          el.setValue = `#${arr.join('#')}`
        }
        if (el.st === 'M' && el.value === '기타') {
          if (el.inputValue.length === 0) {
            return
          }
          else {
            el.setValue = `기타(${el.inputValue})`
          }
        }
        if (el.st === 'N') {
          el.setValue = el.value.match(/\((.*?)\)/)[1]
        }
      })
      const id = this.user.id
      const formData = new FormData()
      formData.append('user_id', id)
      formData.append('apply_name', this.user.name)
      formData.append('apply_mobile', this.userMobile)
      formData.append('add_info1', this.form.add_info1 = this.eventData.arr[0] !== undefined  ? this.eventData.arr[0].setValue : '')
      formData.append('add_info2', this.form.add_info2 = this.eventData.arr[1] !== undefined ? this.eventData.arr[1].setValue : '')
      formData.append('add_info3', this.form.add_info3 = this.eventData.arr[2] !== undefined  ? this.eventData.arr[2].setValue : '')
      formData.append('season_num', this.form.seasonNum)
      formData.append('zipcode', this.form.address_num)
      formData.append('addr_1', this.form.address)
      formData.append('addr_2', this.form.address_under)
      formData.append('event_pic1', this.uploadData[0] ? this.uploadData[0].file : '')
      formData.append('event_pic2', this.uploadData[1] ? this.uploadData[1].file : '')
      formData.append('event_pic3', this.uploadData[2] ? this.uploadData[2].file : '')

      http.post(`/fanzone/event/temp/${this.$route.params.id}`, formData)
          .then(res => {
            if(res.data.success === true) {
              this.isSuccess = true // 버튼 비활성화
              alert(`${res.data.message}`)
              return this.$router.push({name: 'fanzoneEvent'})
            } else {
              this.isSuccess = true // 버튼 비활성화
              alert(`${res.data.message}`)
              return this.$router.push('/fanzone/event')
            }
          })
          .catch(err => console.log(err))
    },
    isInput (val) {
      return val.includes('기타')
    },
    getCheckedValues(e, item, num, index) { // 체크박스 체킹
      const checkboxId = `checkbox_${num}_${index}`
      const checkboxElement = document.getElementById(checkboxId)

      if (checkboxElement.checked) {
        item.value.push(checkboxElement.value)
      } else {
        const valueIndex = item.value.indexOf(checkboxElement.value)
        if (valueIndex !== -1) {
          item.value.splice(valueIndex, 1)
        }
      }
      this.$set(this.etcSwitch, num, item.arr.includes('기타'))

      if (checkboxElement.value === '기타') {
        item.showInputValue = checkboxElement.checked
      }
    },
    getCheckedRadioValues(num, value) { // 라디오박스 기타란 체킹
      if (value === '기타') {
        this.$set(this.radioEtcSwitch, num, true)
      } else {
        this.$set(this.radioEtcSwitch, num, false)
      }
    }
  },
}
</script>
<style>

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hidden {
  display: none;
}
.click {
  cursor: pointer;
}
._textarea {
  padding: 24px 32px;
  overflow: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  border: 1px solid #E5E5E5;
  background-color: #FFFFFF;
  resize: none;
}
.noData {
  padding: 130px 0;
  text-align: center;
  color: #000;
  background-color: #F7F7F7;
}
.noData:before {
   content: "";
   display: block;
   height: 80px;
   margin-bottom: 24px;
   background: url('~@/assets/img/ico_warn_3.png') 50% 0 / contain no-repeat;
 }
.fileBox{
  display: flex;
  justify-content: center;
}
.fileNameBox{
  overflow: hidden;
  width: 70%;
  text-overflow: ellipsis;
  white-space: pre;
}
.fileBtnBox{
  width: 30%;
}
@media screen and (max-width: 767px) {
  .fileBtnBox {
    margin-top: 8px;
  }
}
</style>
