<template>
  <div>
    <div class="tabs">
      <div class="container">
        <button
          v-for="tab in tabs"
          :key="tab.id"
          :class="{active: tab.id === cate}"
          :data-tab="tab.id"
          type="button"
          class="tab"
          @click="setCategory(tab.id)">
          {{tab.name}}
        </button>
      </div>
    </div>
    <div class="container">
      <div class="faqList accordion">
        <div v-for="(item, i) in list" :key="i" class="faqItem accordionItem" :class="{open: item.open}">
          <div class="accordionHead" @click="item.open = !item.open">
            <button type="button" class="btnFold"></button>
            <div class="title">
              <span class="category">{{getCateName(item.cate)}}</span>
              <span :class="item.open ? 'bold' : ''" v-html="item.title"></span>
            </div>
          </div>
          <div class="accordionBody" v-html="item.content"></div>
        </div>
        <div class="noData" v-if="list.length === 0">데이터가 없습니다</div>
      </div>
    </div>

  </div>
</template>

<script>
import http from '@/api/index'

export default {
  name: 'FanzoneFaq',
  props: {
    cate: {
      type: String,
      default: 'all'
    }
  },
  computed: {
    list () {
      const cate = this.cate
      return this.faqData.filter(x => cate === 'all' ? x : x.cate === cate)
    }
  },
  created () {
    this.init()
    this.data()
  },
  // destroyed () {
  //   this.$router.push({
  //     params: {
  //       cate: ''
  //     }
  //   })
  // },
  watch: {
    $route (to, from) {
      this.init()
    }
  },
  data () {
    return {
      tabs: [
        {id: 'all', name: '전체'},
        {id: 'ticket', name: '티켓'},
        {id: 'season', name: '시즌권'},
        {id: 'stadium', name: '경기장'},
        {id: 'homepage', name: '웹사이트'},
        {id: 'app', name: '모바일앱'},
        {id: 'clubhouse', name: '클럽하우스'},
        {id: 'etc', name: '기타'}
      ],
      faqData:[],
    }
  },
  methods: {
    init () {
      if (!this.$route.params.cate) {
        this.setCategory('all')
      }
    },
    setCategory (cate) {
      this.$router.push({
        params: {
          cate: cate || 'all'
        }
      })
    },
    getCateName (cate) {
      return this.tabs.find(x => x.id === cate) ? this.tabs.find(x => x.id === cate).name : '-'
    },
    async data(){
      const fanzone = await http.get(`/fanzone/faq`)
      fanzone.data.data.map(row => {
        row.open = false
        switch (row.cate_gbn){
          case 'G':
            row.cate = 'stadium'
                break
          case 'C':
            row.cate = 'clubhouse'
                break
          case 'T':
            row.cate = 'ticket'
                break
          case 'E':
            row.cate = 'etc'
                break
          case 'H' :
              row.cate = 'homepage'
              break
          case 'A' :
            row.cate = 'app'
                break
          case 'S' :
            row.cate ='season'
        }
      })
      this.faqData = fanzone.data.data
    }
  }
}
</script>
<style>

</style>
