<template>
  <div class="memberBox">
    <div v-if="step !== '3'" class="topTitle">
      <p class="title">비밀번호 찾기</p>
      <p class="subTitle" v-if="step==='1'">전북현대모터스에프씨<br>웹사이트 비밀번호 찾기를 위해 아이디를 입력하세요.</p>
      <p class="subTitle" v-else>전북현대모터스에프씨<br>비밀번호 찾기를 위해 사용하신 본인인증을 하세요.</p>
    </div>
    <div v-else class="topTitle">
      <p class="title">비밀번호 변경</p>
      <p class="subTitle">전북현대모터스에프씨<br>변경할 비밀번호를 입력하세요.</p>
    </div>
    <div v-if="step === '1'">
      <div>
        <input type="text" class="input" placeholder="아이디를 입력하세요" v-model="inputId">
      </div>
      <div class="formBtns formBtns2">
        <button type="button" class="btn large full primary1" @click="findPw">비밀번호 찾기</button>
      </div>
    </div>
    <div v-if="step === '2'">
      <Cert
        :type="'findPw'"
        @complete="setCertResult" />
    </div>
    <div v-if="step === '3'">
      <div>
        <input type="password" class="input" placeholder="비밀번호를 입력하세요" v-model="pw1" @keyup="matchPassword">
        <p class="inputMessage"><span v-if="pw1_pass" class="confirmMessage"></span> 영어 대/소문자, 특수문자, 숫자를 조합한 9~20자리로 입력하세요</p>
      </div>
      <div class="formRow">
        <input type="password" class="input" placeholder="비밀번호를 재입력하세요" v-model="pw2" @keyup="matchPassword">
        <p v-if="pw2_pass" class="confirmMessage">비밀번호가 일치합니다</p>
    </div>
      <div class="formBtns formBtns2">
        <button type="button" class="btn large full primary1" @click="changePwd">비밀번호 변경</button>
      </div>
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import Cert from '@/components/Member/Cert'
import http from "@/api";
import router from "@/router"

export default {
  name: 'MemberFindPw',
  components: {
    Cert
  },
  data () {
    return {
      step: '1',
      inputId: '',
      loading: false,
      pw1: '',
      pw2: '',
      pw1_pass: false,
      pw2_pass: false,
      user: {},
      originEncodeData: ''
    }
  },
  created() {
    const userAgent = navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('naver')> -1){
      alert('크롬, 사파리 등 기본 브라우저를 사용해주세요')
      return this.$router.go(-1)
    }
  },
  mounted() {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')
  },
  methods: {
    findPw () {
      if(this.loading) return

      const inputId = regExp.replaceAllEmpty(this.inputId).length > 0
      if (inputId) {
        this.loading = true;
        // 아이디 유효한지 조회
        http.post('/user/checkId', {
          userId: this.inputId
        }).then(res => {
          if (res.data.data.exist) {
            this.step = '2'
          } else {
            alert('해당 아이디는 존재하지 않습니다.')
          }
        }).finally(() => {
          this.loading = false;
        })
      } else {
        alert('아이디를 입력하세요')
      }
    },
    setCertResult (type, info) {
      if(!info.userId || info.userId === 'false'){
        alert('가입한 내역이 없거나, 가입시 명의가 동일한지 확인하세요.');
        return;
      }

      this.user = info;
      this.originEncodeData = encodeURIComponent(info.originEncodeData)
      this.step = '3'
    },
    isEmpty (str) {
      return regExp.replaceAllEmpty(`${str}`).length === 0
    },
    matchPassword () {
      const pw1 = this.pw1
      const pw2 = this.pw2
      this.pw1_pass = !this.isEmpty(pw1) && regExp.expPassword.test(pw1)
      if (!this.isEmpty(pw1) && this.pw1_pass) {
        this.pw2_pass = pw1 === pw2
      }
    },
    changePwd () {
      if(this.loading) return

      if (!this.pw1_pass) {
        alert('비밀번호를 영어 대/소문자, 특수문자, 숫자를 조합한 9~20자리로 입력하세요')
        return
      } else if (!this.pw2_pass) {
        alert('비밀번호를 확인해주세요')
        return
      }

      this.loading = true;
      http.post(`/user/password?EncodeData=${this.originEncodeData}`, {
        userIdx: this.user.userIdx,
        password: this.pw1
      }).then(() => {
        alert('비밀번호 변경에 성공하였습니다.')
        this.$router.push('/member/signin')
      }).catch(() => {
        alert('비밀번호 변경에 실패하였습니다.');
      }).finally(() => {
        this.loading = false;
      })

    }
  },
  beforeDestroy () {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
  }
}
</script>

<style lang="scss" scoped>
.result {
  padding: 44px 20px;
  text-align: center;
  font-size: 14px;
  color: $grey3;
  background-color: #f5f5f5;
  .name {
    margin-bottom: 28px;
    color: #000;
  }
  .id {
    margin-bottom: 20px;
    font-size: 16px;
    color: #000;
  }
}
.inputMessage {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}
.confirmMessage {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #046A38;
}
.confirmMessage:before {
  content: "L";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 3px;
  text-align: center;
  vertical-align: middle;
  line-height: 16px;
  font-family: "arial";
  font-size: 10px;
  font-weight: 0;
  color: #FFFFFF;
  border-radius: 100%;
  background-color: #046A38;
  transform: scaleX(-1) rotate(-35deg);
}
</style>
