<template>
  <div>
    <div class="sectionTitle line">안내</div>
    <div class="" v-if="!state">내용</div>
    <div class="indexBox waitingSeason" style="margin-top: 80px;" v-if="state">
      <div class="container">
        <img src="@/assets/img/ticket/skybox/skybox_240614.jpg" alt=""/>
      </div>
    </div>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 30px;" v-else>
      <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
export default {
  name: 'TicketSkyboxInfo',
  created () {

  },
  data () {
    return {
      state: true,
    }
  },
  methods : {
  },
}
</script>
