<template>
  <div>
    <div class="topTitle">
      <div class="title">전북현대<br>회원가입 본인인증</div>
      <div class="subTitle">전북현대모터스에프씨 웹사이트 가입을 위해서 본인인증 절차가 필요합니다.<br>아래 본인인증 서비스 중 하나를 선택하세요.</div>
    </div>
    <Cert
      @complete="nextStep" />
    <!--<div class="certMessage">
      NICE평가정보에서 인증 받은 휴대폰 번호를 사용하고 있습니다.<br>본인인증 실패시 NICE평가정보 고객센터 1600-1522로 문의하세요.
    </div>-->
  </div>
</template>

<script>
import Cert from '@/components/Member/Cert'

export default {
  name: 'SignupStep3',
  components: {
    Cert
  },
  mounted() {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')
  },
  created() {
    // console.log(this.$isMobile())
  },
  methods: {
    nextStep (certType, certInfo) {
      this.$emit('cert', certType, certInfo)
      this.$emit('nextStep', '4')
    }
  },
  beforeDestroy() {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
  }
}
</script>

<style lang="scss" scoped>
.certMessage {
  margin-top: 220px;
  margin-bottom: 35px;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
}
</style>
