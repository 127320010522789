<template>
  <!-- 메가메뉴 -->
  <div v-if="menu" class="megaMenu">
    <button type="button" class="btnClose" @click="close"></button>
    <div class="container" ref="container">
      <div class="logo hiddenMobile">
        <router-link :to="{ name: 'main' }"><img src="@/assets/img/logo.png" alt="" /></router-link>
      </div>
      <!-- 모바일상단 -->
      <div class="displayMobile">
        <div class="memberBox">
          <div v-if="isLogin" class="inner">
            <div class="memberName">
              {{ user.name }}님<br />
              안녕하세요
            </div>
            <div class="icons">
              <router-link :to="{ name: 'mypage' }"><img src="@/assets/img/ico_mypage.png" alt=""></router-link>
              <router-link :to="{ name: 'main' }" ><img src="@/assets/img/ico_logout.png" alt="" @click="setLogout"></router-link>
            </div>
          </div>
          <div v-else class="inner">
            <router-link :to="{ name: 'signin' }" class="btn">로그인</router-link>
            <router-link :to="{ name: 'signup', params: { currentStep: '1' } }" class="btn yellow">통합 회원가입</router-link>
          </div>
        </div>
      </div>
      <!-- //모바일상단 -->
      <div class="menu">
        <router-link :to="{ name: 'main' }" class="displayMobile home">HOME</router-link>
        <!-- 메뉴 -->
        <ul class="mainMenuBox">
          <li v-for="main in menuList" :key="main.name" class="mainMenu"
            :class="{ isOpen: main.isOpen }">
            <div @click="setOpen(main.name, main)" class="mainMenuName cursorPointer">
              <a :ref="`title-${main.name}`">{{ main.title }}</a>
            </div>
            <ul v-if="main.children" class="subMenuBox">
              <li v-for="sub in main.children" :key="sub.name" class="subMenu">
                <!-- 외부링크 연결 -->
                <a v-if="sub.link" :href="sub.link" class="subMenuName">{{ sub.title }}</a>
                <!-- //외부링크 연결 -->
                <!-- 추후 주차장 안내 우선으로 들어가기 해지시 삭제 요망 -->
<!--                <router-link v-else-if="sub.title === '경기장안내'" :to="{name : 'ticketStadiumParking'}" class="subMenuName">경기장 안내</router-link>-->
                <!--// 추후 주차장 안내 우선으로 들어가기 해지시 삭제 요망 -->
                <router-link v-else :to="{ name: sub.name, ...sub.params && { params: sub.params } }" class="subMenuName">
                  {{ sub.title }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
        <!-- //메뉴 -->
        <!-- 슬라이더, 배너 -->
        <!--
        <div class="ad">
          <div class="slider gmarket">
            <vueper-slides v-if="slider" :autoplay="true" :duration="2500" :infinite="true" :arrows="false"
              :slideRatio="308 / 260" :touchable="false" :pause-on-hover="false" :pause-on-touch="false"
              class="no-shadow">
              <vueper-slide v-for="(item, index) in slider" :key="index">
                <template #content>
                  <a :href="item.link" :target="item.newWindow ? '_blank' : '_self'"
                    :class="{ imgOnly: !item.title && item.img }">
                    <div v-if="item.title" class="title">
                      {{ item.title }}
                    </div>
                    <img :src="item.img">
                  </a>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
          <div class="banner"><a href="http://jbfcmall.com/" target="_blank">
              <img src="@/assets/img/menu_banner.png" alt="">
            </a>
          </div>
        </div>
        -->
        <!-- //슬라이더, 배너 -->
      </div>
    </div>
  </div>
  <!-- //메가메뉴 -->
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'MegaMenu',
  props: [
    'menu'
  ],
  components: {
    VueperSlides,
    VueperSlide
  },
  computed: {
    isLogin() {
      return this.$store.getters['isLogin']
    },
    user() {
      return this.$store.getters['user']
    },
  },
  created() {
    this.init()
  },
  data() {
    return {
      slider: [
        { // CMS로 업로드 하는 경우 (타이틀, 링크 등 별도 관리)
          title: `2022
          SEASON UNIFORM
          COLLECTION`,
          img: require('@/assets/img/menu_slider_1.png'), // 적정 사이즈: 260*130 (이미지 자체 위 여백 10px정도)
          link: 'http://jbfcmall.com/',
          newWindow: true
        },
        { // 타이틀 없이 통이미지로 들어가는 경우
          img: require('@/assets/img/menu_slider_2.png'), // 260*308
          link: '/',
          newWindow: false
        }
      ],
      menuList: [],
      mdshopMenu: false,
      greenSchool: false,
      sponsorHouse: false,
    }
  },
  methods: {
    init() {
      if (this.menu) {
        this.menuList = this.menu.map(x => {
          return {
            ...x,
            isOpen: false
          }
        })
      }
    },
    setOpen(menuName, menu) {
      const windowInner = window.innerWidth
      const item = this.menuList.find(x => x.name === menuName)
      item.isOpen = !item.isOpen
      if ( windowInner > 767 ) {
        if (menu.children && menu.children[0].name === 'teamPlayer') {
          this.$router.push('/team/proteam/all')
        } else if (menu.children && menu.name === 'fanzone') {
          this.$router.push('/fanzone/event')
        } else if (menu.children && menu.children[0].name === 'mediaNotice') {
          this.$router.push('/media/all')
        } else if (menu.children && menu.name === 'greenschool') {
          window.location.href = 'https://greenschool.hyundai-motorsfc.com/'
        } else if (menu.children && menu.name === 'house') {
          window.location.href = 'https://house.hyundai-motorsfc.com/'
        } else if (menu.children && menu.name === 'mdshop') {
          window.location.href = 'https://shop.hyundai-motorsfc.com/'
        } else {
          this.$router.push({ name: menu.children? menu.children[0].name : menu.name })
        }
      }
      if (windowInner <= 767) {
        this.$nextTick(() => {
            const title = this.$refs[`title-${menuName}`][0]
          title.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },
    close() {
      this.$emit('close')
    },
    setLogout(){
      this.$store.commit('logout')
    },

  }
}
</script>
