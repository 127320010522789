import Index from '@/views/Match.vue'
import Schedule from '@/components/Match/Schedule.vue'
import ScheduleList from '@/components/Match/ScheduleList.vue'
import ScheduleResult from '@/components/Match/ScheduleResult.vue'
import K4 from '@/components/Match/K4.vue'
import K4List from '@/components/Match/K4List.vue'
import K4Result from '@/components/Match/K4Result.vue'
import U18 from '@/components/Match/U18.vue'
import U18List from '@/components/Match/U18List.vue'
import U18Result from '@/components/Match/U18Result.vue'
import U15 from '@/components/Match/U15.vue'
import U15List from '@/components/Match/U15List.vue'
import U15Result from '@/components/Match/U15Result.vue'
import Ranking from '@/components/Match/Ranking.vue'
import RankingKleague from '@/components/Match/RankingKleague.vue'
import RankingK4 from '@/components/Match/RankingK4.vue'
import RankingAcl from "@/components/Match/RankingAcl.vue"
import Record from '@/components/Match/Record.vue'
import RecordGoal from '@/components/Match/RecordGoal.vue'
import RecordAssist from '@/components/Match/RecordAssist.vue'
import RecordGame from '@/components/Match/RecordGame.vue'
// 경기결과 > 기록
import Game from '@/components/Match/Game.vue'
import GameSummary from '@/components/Match/GameSummary.vue'
import GameReview from '@/components/Match/GameReview.vue'
import GameRecord from '@/components/Match/GameRecord.vue'
import GamePhoto from '@/components/Match/GamePhoto.vue'
import GameHighlight from '@/components/Match/GameHighlight.vue'

const match = [
  {
    path: '/match',
    name: 'match',
    component: Index,
    redirect: {
      name: 'matchScheduleList'
    },
    children: [
      {
        path: 'schedule',
        name: 'matchSchedule',
        component: Schedule,
        redirect: {
          name: 'matchScheduleList',
        },
        children: [
          {
            path: 'list',
            name: 'matchScheduleList',
            component: ScheduleList,
            meta: {
              match: 'schedule',
              detail: 'list'
            }
          },
          {
            path: 'result',
            name: 'matchScheduleResult',
            component: ScheduleResult,
            meta: {
              match: 'schedule',
              detail: 'result'
            }
          }
        ]
      },
      {
        path: 'k4',
        name: 'matchK4',
        component: K4,
        redirect: {
          name: 'matchK4List'
        },
        children: [
          {
            path: 'list',
            name: 'matchK4List',
            component: K4List,
            meta: {
              match: 'k4',
              detail: 'list'
            },
          },
          {
            path: 'result',
            name: 'matchK4Result',
            component: K4Result,
            meta: {
              match: 'k4',
              detail: 'result'
            }
          }
        ]
      },
      {
        path: 'u18',
        name: 'matchU18',
        component: U18,
        redirect: {
          name: 'matchU18List'
        },
        children: [
          {
            path: 'list',
            name: 'matchU18List',
            component: U18List,
            meta: {
              match: 'u18',
              detail: 'list'
            }
          },
          {
            path: 'result',
            name: 'matchU18Result',
            component: U18Result,
            meta: {
              match: 'u18',
              detail: 'result'
            }
          }
        ]
      },
      {
        path: 'u15',
        name: 'matchU15',
        component: U15,
        redirect: {
          name: 'matchU15List'
        },
        children: [
          {
            path: 'list',
            name: 'matchU15List',
            component: U15List,
            meta: {
              match: 'u15',
              detail: 'list'
            }
          },
          {
            path: 'result',
            name: 'matchU15Result',
            component: U15Result,
            meta: {
              match: 'u15',
              detail: 'result'
            }
          }
        ]
      },
      {
        path: 'ranking',
        name: 'matchRanking',
        component: Ranking,
        redirect: {
          name: 'matchRankingKleague'
        },
        children: [
          {
            path: 'kleague',
            name: 'matchRankingKleague',
            component: RankingKleague,
            meta: {
              match: 'ranking',
              detail: 'kleague'
            }
          },
          {
            path: 'k4',
            name: 'matchRankingK4',
            component: RankingK4,
            meta: {
              match: 'ranking',
              detail: 'k4'
            },

          },
          {
            path: 'acl',
            name: 'matchAclRanking',
            component: RankingAcl,
            meta: {
              match: 'ranking',
              detail: 'acl'
            }
          },
        ]
      },
      {
        path: 'record',
        name: 'matchRecord',
        component: Record,
        redirect: {
          name: 'matchRecordGoal'
        },
        children: [
          {
            path: 'goal',
            name: 'matchRecordGoal',
            component: RecordGoal,
            meta: {
              match: 'record',
              detail: 'goal'
            }
          },
          {
            path: 'assist',
            name: 'matchRecordAssist',
            component: RecordAssist,
            meta: {
              match: 'record',
              detail: 'assist'
            }
          },
          {
            path: 'game',
            name: 'matchRecordGame',
            component: RecordGame,
            meta: {
              match: 'record',
              detail: 'game'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/match/game/:id',
    name: 'matchGame',
    component: Game,
    params: {
      id: 1
    },
    props: true,
    redirect: {
      name: 'matchGameSummary'
    },
    children: [
      {
        path: 'summary',
        name: 'matchGameSummary',
        component: GameSummary,
        meta: {
          detail: 'summary'
        }
      },
      {
        path: 'review',
        name: 'matchGameReview',
        component: GameReview,
        meta: {
          detail: 'review'
        }
      },
      {
        path: 'record',
        name: 'matchGameRecord',
        component: GameRecord,
        meta: {
          detail: 'record'
        }
      },
      {
        path: 'photo',
        name: 'matchGamePhoto',
        component: GamePhoto,
        meta: {
          detail: 'photo'
        }
      },
      {
        path: 'highlight',
        name: 'matchGameHighlight',
        component: GameHighlight,
        meta: {
          detail: 'highlight'
        }
      }
    ]
  }
]

export default match
