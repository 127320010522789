<template>
  <div class="fanzonePage">
    <Top />
    <router-view></router-view>
  </div>
</template>

<script>
import Top from '@/components/Fanzone/Top'

export default {
  name: 'FanzoneView',
  components: {
    Top
  }
}
</script>
