<template>
  <div class="pagination">
    <button type="button" class="go first" :disabled="disabledBtn.first" @click="goPage(1)">
      <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 9L1 5L5 1" stroke="#999999" stroke-linecap="square"/>
        <path d="M10 9L6 5L10 1" stroke="#999999" stroke-linecap="square"/>
      </svg>
    </button>
    <button type="button" class="go prev" :disabled="disabledBtn.prev" @click="goPage('prev')">
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 9L1 5L5 1" stroke="#999999" stroke-linecap="square"/>
      </svg>
    </button>
    <button
        v-for="num in range"
        :key="num"
        type="button"
        :class="{current: page === `${num}`}"
        @click="goPage(num)">
      {{num}}
    </button>
    <button type="button" class="go next" :disabled="disabledBtn.next" @click="goPage('next')">
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 9L5 5L1 1" stroke="#999999" stroke-linecap="square"/>
      </svg>
    </button>
    <button type="button" class="go last" :disabled="disabledBtn.last" @click="goPage('last')">
      <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 9L10 5L6 1" stroke="#999999" stroke-linecap="square"/>
        <path d="M1 9L5 5L1 1" stroke="#999999" stroke-linecap="square"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    perPage: {
      type: Number,
      default: 6 // 페이지당 보여지는 리스트 개수
    },
    pages: {
      type: Number,
      default: 1 // 전체 리스트 개수
    }
  },
  computed: {
    disabledBtn () {
      const pages = parseInt(this.pages) // 전체 리스트 개수
      const perPage = parseInt(this.perPage) // 페이지당 리스트 개수
      const pageAll = Math.ceil(pages / perPage) // 전체 페이지 개수
      const range = this.range // 현재 페이징
      const first = range.includes(1)
      const last = range.includes(pageAll)
      const prev = range.includes(1)
      const next = range.includes(pageAll)
      return {
        first: first,
        last: last,
        prev: prev,
        next: next
      }
    }
  },
  watch: {
    $route (to, from) {
      this.page = to.params.page
      this.setPage()
    },
    limit (limit) {
      this.setPage()
    },
    pages () {
      this.setPage()
      this.goPage(1)
    }
  },
  created () {
    this.onResize()
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
    this.setPage()
  },
  data () {
    let page = this.$route.params.page || '1'
    return {
      page,
      range: [],
      limit: 5
    }
  },
  methods: {
    onResize () {
      this.limit = window.innerWidth >= 768 ? 10 : 5 // 최대 페이징 개수 (1,2,3,...,10) pc: 1~10 / 모바일: 1~5
    },
    setPage () {
      const limit = this.limit
      const current = parseInt(this.page) - 1 // 현재페이지
      const pageStart = parseInt(current / limit) * limit // 페이지 시작 숫자(1, 11, 21...)
      const pages = parseInt(this.pages) // 전체 리스트 개수
      const perPage = parseInt(this.perPage)
      const range = getRange(limit, pageStart).filter(x => x <= Math.ceil(pages / perPage))
      /**
       * 1~10
       * 11~20
       * 21~30
       */
      function getRange (size, start = 1) {
        let result = []
        for (let i = 1; i <= size; i++) {
          result.push(start + i)
        }
        return result
      }
      this.range = range
    },
    goPage (go) {
      const current = parseInt(this.page) // 현재페이지
      const pages = parseInt(this.pages) // 전체 리스트 개수
      const perPage = parseInt(this.perPage) // 페이지당 리스트 개수
      const pageAll = Math.ceil(pages / perPage) // 전체 페이지 개수
      const limit = this.limit
      const range = this.range
      if (current !== go) {
        let page
        if (go === 'next') {
          page = range[0] + limit >= pageAll ? pageAll : range[0] + limit // 다음 10개 (1~10/11~20/21~30...), 모바일은 5개 (1~5/6~10...)
        } else if (go === 'prev') {
          page = range[0] - limit <= 0 ? 1 : range[0] - limit // 이전 10 or 5개
        } else if (go === 'last') {
          page = pageAll
        } else {
          page = go
        }
        this.$emit('change', page);
        this.$router.push({
          params: {
            page: `${page}`
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  button {
    min-width: 28px;
    height: 28px;
    margin: 0 2px;
    //padding: 2px 4px 0;
    font-size: 14px;
    color: $grey3;
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    &.current {
      color: $white;
      border-color: #495057;
      background-color: #495057;
    }
    &:not(.current):hover {
      color: $white;
      border-color: #495057;
      background-color: #495057;
      opacity: 0.5;
    }
    &.go {
      font-family: sans-serif;
      //font-size: 18px; //
      border-color: $lightgrey2;
      span {
        position: relative;
      }
    }
    &.prev {
      margin-right: 12px;
    }
    &.next {
      margin-left: 12px;
    }
    &[disabled],
    &[disabled]:hover {
      color: $grey4;
      border-color: $lightgrey3;
      background-color: $lightgrey3;
      opacity: 1;
      cursor: not-allowed;
    }
  }
}
</style>
