<template>
  <div>
    <div class="policyMessage">이 약관은 전기통신 사업법 및 동 법 시행령에 의하여 전북현대모터스에프씨 주식회사 (이하 "회사" 라 합니다.)가 제공하는 인터넷 홈페이지 서비스 (이하 "서비스" 라 합니다.)의 이용조건 및 절차에 관한 사항, 회사와 이용자의 권리와 의무 및 책임사항을 규정함을 목적으로 합니다.</div>
    <div v-if="anchors" class="policyAnchor">
      <div v-for="(a, i) in anchors"
        :key="i"
        class="anchor">
        <a href="javascript:void(0)" @click="goAnchor(a.anchor)">
          <i>{{a.id}}</i>
          {{a.name}}
        </a>
      </div>
    </div>
    <div ref="service">
      <p class="policyIndex" data-index="1" data-label="목적">제 1조 (목적)</p>
      <div class="policyContent">이 약관은 전기통신 사업법 및 동 법 시행령에 의하여 전북현대모터스에프씨 주식회사 (이하 "회사" 라 합니다.)가 제공하는 인터넷 홈페이지 서비스 (이하 "서비스" 라 합니다.)의 이용조건 및 절차에 관한 사항, 회사와 이용자의 권리와 의무 및 책임사항을 규정함을 목적으로 합니다.</div>

      <p class="policyIndex" data-index="2" data-label="약관의 효력과 개정">제 2조 (약관의 효력과 개정)</p>
      <div class="policyContent">이 약관은 전기통신사업법 제 31 조, 동 법 시행규칙 제 21조의 2에 따라 공시절차를 거친 후 홈페이지를 통하여 이를 공지하거나 전자우편 기타의 방법으로 이용자에게 통지함으로써 효력을 발생합니다.<br>
      회사는 본 약관을 사전 고지 없이 개정할 수 있으며, 개정된 약관은 제9조에 정한 방법으로 공지합니다. 회원은 개정된 약관에 동의하지 아니하는 경우 본인의 회원등록을 취소(회원탈퇴)할 수 있으며, 계속 사용의 경우는 약관 개정에 대한 동의로 간주됩니다. 개정된 약관은 공지와 동시에 그 효력이 발생됩니다.</div>

      <p class="policyIndex" data-index="3" data-label="약관이외의 준칙">제 3조 (약관이외의 준칙)</p>
      <div class="policyContent">이 약관에 명시되어 있지 않은 사항은 전기통신 기본법, 전기통신 사업법, 기타 관련법령의 규정에 따릅니다.</div>

      <p class="policyIndex" data-index="4" data-label="용어의 정의">제 4조 (용어의 정의)</p>
      <div class="policyContent">이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br>
      회원 : 서비스에 개인정보를 제공하여 회원등록을 한 자로서, 서비스의 정보를 지속적으로 제공받으며, 이용할 수 있는 자를 말합니다.<br>
      이용자 : 본 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.<br>
      아이디 (ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합을 말합니다.<br>
      비밀번호 : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 문자와 숫자의 조합을 말합니다.<br>
      전자우편 (E-mail) : 인터넷을 통한 우편입니다.<br>
      해지 : 회사 또는 회원이 서비스 이용 이후 그 이용계약을 종료 시키는 의사표시를 말합니다.<br>
      홈페이지 : 회사가 이용자에게 서비스를 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 이용자가 열람 및 이용할 수 있도록 설정한 가상의 서비스 공간을 말합니다.<br>
        전북현대포인트 : 적립 및 이용과 관련한 사항의 규정을 목적으로 합니다. (이하 ‘포인트’라고 합니다)</div>

      <p class="policyIndex" data-index="5" data-label="서비스의 제공 및 변경">제 5조 (서비스의 제공 및 변경)</p>
      <div class="policyContent">회사가 제공하는 서비스는 다음과 같습니다.<br>
      1) 회사에 대한 홍보 내용<br>
      2) 회사가 판매하는 제품 안내<br>
      3) 기타 회사가 제공하는 각종 정보<br>
      4) 고객 상담 서비스<br>
      5) 회원 이용 서비스<br>
      회사는 필요한 경우 서비스의 내용을 추가 또는 변경하여 제공할 수 있습니다.</div>

      <p class="policyIndex" data-index="6" data-label="서비스의 중단">제 6조 (서비스의 중단)</p>
      <div class="policyContent">회사는 컴퓨터 등 정보통신설비의 보수점검/교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.<br>
      제 1항에 의한 서비스 중단의 경우에는 제 9조에 정한 방법으로 이용자에게 통지합니다.<br>
      회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상하지 아니합니다. 단, 회사에 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.</div>

      <p class="policyIndex" data-index="7" data-label="회원가입">제 7조 (회원가입)</p>
      <div class="policyContent">이용자는 회사가 정한 가입양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.<br>
      회사는 제 1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.<br>
      1) 이름이 실명이 아닌 경우<br>
      2) 등록 내용에 허위, 기재누락, 오기가 있는 경우<br>
      3) 타인의 명의를 사용하여 신청한 경우<br>
      4) 가입신청자가 이 약관 제 8조 3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우(단, 제 8조 3항에 의한 회원자격 상실 후 3년이 경과한 자로서 회사의 회원 재가입 승낙을 얻은 경우는 예외로 합니다.)<br>
      5) 기타 회원으로 회사 소정의 이용신청요건을 충족하지 못하는 경우<br><br>
      회원가입계약의 성립시기는 회사의 승낙이 이용자에게 도달한 시점으로 합니다.<br>
      회원은 제 10조 1항에 의한 등록사항에 변경이 있는 경우 회원정보변경 항목을 통해 직접 변경사항을 수정, 등록하여야 합니다.</div>

      <p class="policyIndex" data-index="8" data-label="회원탈퇴 및 자격 상실 등">제 8조 (회원탈퇴 및 자격 상실 등)</p>
      <div class="policyContent">회원은 언제든지 회원의 탈퇴를 홈페이지에 요청할 수 있으며, 홈페이지는 즉시 이에 응합니다.<br>
      회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.<br>
      1) 가입 신청 시에 허위 내용을 등록한 경우<br>
      2) 타인의 서비스 이용을 방해하거나 그 정보를 도용하는 등 서비스 운영질서를 위협하는 경우<br>
      3) 서비스를 이용하여 법령과 이 약관이 금지하거나, 공서양속에 반하는 행위를 하는 경우<br>
      4) 제 17조 에 명기된 회원의 의무사항을 준수하지 못할 경우<br>
        5) 회원은 마지막 로그인 5년 초과한 경우<br>
        (다만, 포인트 미 소유자에 한하며 사전 30일전 고지 이후 회원 탈퇴 및 자격 상실)<br>
      회사가 회원자격을 제한/정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실 시킬 수 있습니다.<br>
      회사가 회원자격을 상실 시키는 경우 회원에게 이를 통지하고 탈퇴를 처리합니다. 이 경우 회원에게 이를 통지하고, 탈퇴 전에 소명할 기회를 부여합니다.</div>

      <p class="policyIndex" data-index="9" data-label="이용자에 대한 통지">제 9조 (이용자에 대한 통지)</p>
      <div class="policyContent">회사가 이용자에 대한 통지를 하는 경우, 이용자가 서비스에 제출한 전자우편 주소로 할 수 있습니다.<br>
      회사가 불특정 다수 이용자에 대한 통지의 경우 1주일 이상 서비스 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다.</div>

      <p class="policyIndex" data-index="10" data-label="개인 정보 보호">제 10조 (개인 정보 보호)</p>
      <div class="policyContent">회원 및 이용자의 개인정보 보호에 관한 사항은 회사 공식 홈페이지에 공개된 개인정보처리방침에 따릅니다.</div>

      <p class="policyIndex" data-index="11" data-label="회사의 의무">제 11조 (회사의 의무)</p>
      <div class="policyContent">회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다하여야만 합니다.<br>
      회사는 서비스에 관련된 설비를 항상 운용할 수 있는 상태로 유지/보수하고, 장애가 발생하는 경우 지체 없이 이를 수리/복구할 수 있도록 최선의 노력을 다하여야 합니다.<br>
      회사는 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의 개인정보보호를 위한 보안시스템을 갖추어야 합니다.<br>
      회사는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</div>

      <p class="policyIndex" data-index="12" data-label="회원의 ID 및 비밀번호에 대한 의무">제 12조 (회원의 ID 및 비밀번호에 대한 의무)</p>
      <div class="policyContent">회원에게 부여된 아이디(ID)와 비밀번호의 관리책임은 회원에게 있으며 관리 소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.<br>
      회원이 자신의 ID 및 비밀번호를 도난 당하거나 제 3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.</div>

      <p class="policyIndex" data-index="13" data-label="포인트의 적립, 사용 및 관리">제 13조 (포인트의 적립, 사용 및 관리)</p>
      <div class="policyContent">
          (1) 전북현대는 회원의 구매활동, 이벤트 참여 등에 따라 회원에게 일정한 포인트를 부여할 수 있습니다.<br />
          (2) 회원은 포인트를 전북현대의 홈경기 입장권 구매 페이지(티켓링크 시설 페이지) 또는 MD상품 구매 페이지(초록이네)에서 티켓 또는 상품 구매 시 결제 수단으로 사용할 수 있으며, 전북현대는 포인트의 적립기준, 사용방법, 사용조건, 사용기간 및 제한에 대한 사항을 공식 웹사이트에 별도로 게시하거나 통지합니다.<br />
          (3) 포인트의 적립기준, 사용방법, 사용조건, 사용기간 및 제한에 관한 사항은 전북현대의 정책에 따라 달라질 수 있습니다.<br />
          (4) 포인트는 현금으로 환급될 수 없습니다.<br />
          (5) 회원은 포인트를 제3자에게 또는 다른 아이디로 양도할 수 없으며, 유상으로 거래하거나 현금으로 전환할 수 없습니다.<br />
          (6) 전북현대는 회원이 전북현대가 승인하지 않은 방법 또는 허위 정보 제공 등의 부정한 방법으로 포인트를 획득하거나 부정한 목적이나 용도로 포인트를 사용하는 경우, 포인트의 사용을 제한하거나 포인트를 사용한 구매 신청을 취소, 또는 회원 자격을 정지할 수 있습니다.<br />
          (7) 회원 탈퇴 시 미사용한 포인트는 즉시 소멸되며, 탈퇴 후 재가입하더라도 소멸된 포인트는 복구되지 아니합니다.<br />
          (8) 회원이 구매 또는 이벤트 등으로 포인트를 적립받았으나 해당 적립 원인이 취소(반품 등)되는 경우 해당 포인트를 반환해야 하고, 적립받은 포인트를 이미 사용하였다면 포인트 상당 금액을 전북현대에 반환하여야 합니다.<br />
          (9) 포인트의 유효기간은 고지된 바에 따라 특정되며, 유효기간 내에 사용되지 않은 포인트는 해당 유효기간 만료 시 즉시 소멸됩니다.
      </div>

      <p class="policyIndex" data-index="14" data-label="포인트 사용의 금지 행위">제 14조 (포인트 사용의 금지 행위)</p>
      <div class="policyContent">
        회원은 아래와 같은 행위를 하여서는 아니됩니다.<br />
        (1) 전북현대를 이용하여 타 판매자와의 직거래를 유도, 시도하거나 직접 직거래를 하는 행위<br />
        (2) 고의적으로 전북현대의 판매 활동을 방해하는 행위<br />
        (3) 티켓, 상품 등을 재판매 목적으로 구매하여 제3자에게 판매하는 행위<br />
        (4) 전북현대의 동의 없이 영리를 목적으로 전북현대 상품을 이용하는 행위<br />
        (5) 가격 비교, 비방 또는 욕설 등을 온라인 상에 게시하여 전북현대의 판매 활동을 방해하는 행위<br />
        (6) 각종 이벤트 악용, 상품후기 조작, 허위 등 부정한 목적으로 이루어진 행위<br />
        (7) 그 밖에 위 각 호의 행위에 준하는 일체의 거래 부정 행위
      </div>


      <p class="policyIndex" data-index="15" data-label="금지 행위 위반에 따른 포인트 조치">제 15조 (금지 행위 위반에 따른 포인트 조치)</p>
      <div class="policyContent">
        회원이 제14조 각호의 행위를 한 경우 전북현대는 아래 각호의 조치를 취할 수 있습니다.<br />
        (1) 금지행위와 관련된 상품 결제 취소<br />
        (2) 포인트, 쿠폰 등의 지급 보류 또는 환수<br />
        (3) 회원 자격의 제한(이용제한, 포인트 적립 제한, 회원 자격 정지)<br />
        (4) 회원 자격의 상실
      </div>

      <p class="policyIndex" data-index="16" data-label="포인트의 유효기간">제 16조 (포인트의 유효기간)</p>
      <div class="policyContent">
        (1) 구 멤버십 제도로 적립된 포인트 : 적립 년도로부터 5년 (2027년 12월 31일 이후 소멸)<br />
        (2) 전북현대 티켓 및 상품 구매 또는 경기 및 이벤트 참여 등에 따라 부여된 신규 포인트 : 적립 시점으로부터 1년 이후 소멸</p>
      </div>

      <p class="policyIndex" data-index="17" data-label="회원의 의무">제 17조 (회원의 의무)</p>
      <div class="policyContent">회원은 관계법령, 본 약관의 규정, 이용안내 및 주의사항 등 회사가 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.<br>
      회원은 회사의 사전승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.<br>
      회원은 서비스를 이용하여 얻은 정보를 회사의 사전승낙 없이 복사, 복제, 변경, 번역, 출판/방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.<br>
      회원은 자기 신상정보의 변경사항 발생시 즉각 변경하여야 합니다. 회원정보를 수정하지 않아 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.<br>
      회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하지 않아야 하며, 다음 행위를 함으로 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.<br>
      1) 다른 회원의 아이디(ID)를 부정하게 사용하는 행위<br>
      2) 다른 회원의 E-mail 주소를 취득하여 스팸메일을 발송하는 행위<br>
      3) 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위<br>
      4) 선량한 풍속, 기타 사회질서를 해하는 행위<br>
      5) 회사 및 타인의 명예를 훼손하거나 모욕하는 행위<br>
      6) 회사 및 타인의 지적재산권 등의 권리를 침해하는 행위<br>
      7) 해킹행위 또는 컴퓨터 바이러스의 유포행위<br>
      8) 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위<br>
      9) 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위<br>
      10) 회사가 제공하는 서비스의 내용을 변경하는 행위<br>
      11) 기타 관계법령에 위배되는 행위</div>

      <p class="policyIndex" data-index="18" data-label="게시물 삭제">제 18조 (게시물 삭제)</p>
      <div class="policyContent">회사는 이용자가 게시하거나 등록하는 서비스내의 게시물이 제 17조의 규정에 위반되거나, 다음 각 호에 해당한다고 판단되는 경우 사전통지 없이 게시물을 삭제할 수 있습니다.<br>
      1) 다른 이용자 또는 제 3자를 비방하거나 중상모략으로 명예를 손상시키는 내용<br>
      2) 공공질서 또는 미풍양속에 위반되는 내용<br>
      3) 범죄적 행위에 결부된다고 인정되는 내용<br>
      4) 제 3자의 저작권 등 기타 권리를 침해하는 내용<br>
      5) 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 내용<br>
      6) 근거나 확인절차 없이 회사를 비난하거나 유언비어를 유포한 내용<br>
      7) 기타 관계법령에 의거하여 위반된다고 판단되는 내용<br><br>
      회사는 이용자가 게시하거나 등록하는 서비스내의 게시물이 제 17조의 규정에 위반되거나 동 조 제1항 각 호에 해당한다고 판단되는 정보를 링크하고 있을 경우 사전통지 없이 게시물을 삭제할 수 있습니다.</div>

      <p class="policyIndex" data-index="19" data-label="게시물에 대한 권리 / 의무">제 19조 (게시물에 대한 권리 / 의무)</p>
      <div class="policyContent">게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 이용자에게 있습니다.</div>

      <p class="policyIndex" data-index="20" data-label="연결 '홈페이지'와 피연결 '홈페이지'간의 관계">제 20조 (연결 "홈페이지"와 피연결 "홈페이지"간의 관계)</p>
      <div class="policyContent">상위 "홈페이지"와 하위 "홈페이지"가 하이퍼 링크(예:하이퍼 링크의 대상에는 문자, 그림 및 동화상 등이 포함됨) 방식 등으로 연결된 경우, 전자를 연결 "홈페이지"라고 하고 후자를 피연결 "홈페이지(웹사이트)"라고 합니다.<br>
      연결 "홈페이지"는 피연결 "홈페이지"가 독자적으로 제공하는 재화• 용역에 의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않습니다.</div>

      <p class="policyIndex" data-index="21" data-label="저작권의 귀속 및 이용제한">제 21조 (저작권의 귀속 및 이용제한)</p>
      <div class="policyContent">회사가 작성한 저작물에 대한 저작권 및 기타 지적재산권은 회사에 귀속합니다.<br>
      이용자는 서비스를 이용함으로써 얻은 정보를 회사의 사전승낙 없이 복제, 송신, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.</div>

      <p class="policyIndex" data-index="22" data-label="양도금지">제 22조 (양도금지)</p>
      <div class="policyContent">회원이 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</div>

      <p class="policyIndex" data-index="23" data-label="손해배상">제 23조 (손해배상)</p>
      <div class="policyContent">회사는 무료로 제공되는 서비스와 관련하여 이용자에게 어떠한 손해가 발생하더라도 동 손해가 회사의 중대한 과실에 의한 경우를 제외하고 이에 대하여 책임을 부여하지 아니합니다.</div>

      <p class="policyIndex" data-index="24" data-label="면책 / 배상">제 24조 (면책 / 배상)</p>
      <div class="policyContent">회사는 이용자가 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에 관하여는 어떠한 책임을 부담하지 아니하고, 이용자는 자신의 책임아래 서비스를 이용하며, 서비스를 이용하여 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사선택, 기타 서비스 이용과 관련하여 어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 이용자에게 있습니다.<br>
      회사는 제 17조의 규정에 위반하여 이용자간 또는 이용자와 제 3자간에 서비스를 매개로 한 물품거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 이용자가 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.<br>
      이용자가 제 17조, 기타 이 약관의 규정을 위반함으로 인하여 회사가 이용자 또는 제 3자에 대하여 책임을 부담하게 되고, 이로써 회사에게 손해가 발생하게 되는 경우, 이 약관을 위반한 이용자는 회사에게 발생하는 모든 손해를 배상하여야 하며, 동 손해로부터 회사를 면책시켜야 합니다.</div>

      <p class="policyIndex" data-index="25" data-label="분쟁의 해결">제 25조 (분쟁의 해결)</p>
      <div class="policyContent">회사와 이용자는 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.<br>
      제 1항의 규정에도 불구하고, 동 분쟁으로 인하여 소송이 제기될 경우 동 소송은 서울지방법원을 관할로 합니다.<br>
      동 소송에는 대한민국 법을 적용합니다.</div>

      <p class="policyIndex" data-index="26" data-label="기타">제 26조 (기타)</p>
      <div class="policyContent">이 약관에 명시되지 아니한 사항의 처리를 위하여 이용자는 전북현대모터스축구단.(전화번호 : 063-273-1763~5)를 이용합니다.<br><br>
      부칙<br>
      이 약관은 2024년 7월 24일부터 시행합니다.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PolicyService',
  mounted () {
    this.init()
  },
  data () {
    return {
      anchors: []
    }
  },
  methods: {
    init () {
      const el = this.$refs.service
      if (this.$route.hash) {
        this.$router.replace({ hash: '' })
      }
      // data-index 속성 검사하여 북마크 생성
      if (el && el.querySelectorAll('[data-index]') && el.querySelectorAll('[data-index]').length > 0) {
        el.querySelectorAll('[data-index]').forEach((x, i) => {
          this.anchors.push({
            id: x.dataset.index,
            name: x.dataset.label || x.textContent,
            anchor: `anchor_${i}_${x.dataset.index}`
          })
          x.setAttribute('id', `anchor_${i}_${x.dataset.index}`)
        })
      }
    },
    goAnchor (pos) { // 앵커 타겟이 헤더에 묻혀 헤더높이 + 일정수치만큼 스크롤 내림
      // setTimeout(() => {
      //   const scrollY = window.scrollY
      //   const header = document.querySelector(window.innerWidth >= 768 ? '.header' : '.gnbBox')
      //   const height = header.clientHeight + 40
      const headerHeight = window.innerWidth >= 768 ? 80 : 60
      const plusHeight = window.innerWidth >= 768 ? 280 : 130
      const position = document.getElementById(pos).offsetTop + plusHeight + headerHeight;
      window.scrollTo(0, position)
      // }, 700)
    }
  }
}
</script>
