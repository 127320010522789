<template>
  <div class="myinfoBox">
    <div class="topTitle">
      <div class="title">SNS연동</div>
      <div class="subTitle">전북현대모터스에프씨 웹사이트 회원제 사용 편의를 위해서 <span class="pcBreak">SNS 서비스와 연동과 연동해제를 제공합니다.</span></div>
    </div>
    <div class="interlockSns">
      <button
        :id="s.tag_id"
        v-for="s in sns"
        :key="s.id"
        :class="s.id"
        @click="snsLogin(s.id, s.interlock)"
        type="button"
        class="btn whiteLightgrey full">
        {{s.name}} 아이디 {{s.interlock ? '해제' : '연동'}}
      </button>
      <div id="naver_id_login" style="display:none;"></div>
      <div id="appleid-signin" data-color="black" data-border="true" data-type="sign in"></div>
    </div>
  </div>


</template>

<script>

import http from '@/api/index'
import axios from 'axios'

export default {
  name: 'MyinfoSns',
  computed: {
  },
  created() {
    //alert('SNS로그인 기능은 1월 2주차부터 사용이 가능합니다');

  },
  mounted () {
    // SNS 연동여부 확인
    http.get('/mypage/sns').then(res=>{
      const sns = res.data.data;
        sns.forEach(s=>{
          this.sns.find(sns=>sns.id===s).interlock = true;
        })
        // Naver Check
        this.naver_id_login = new window.naver_id_login(process.env.VUE_APP_NAVER_CLIENT_ID, `${window.location.origin}/member/myinfo/sns`);
        this.naverTokenCheck();
        let state = this.naver_id_login.getUniqState();
        this.naver_id_login.setButton("white", 1, 40);
        this.naver_id_login.setState(state);
        this.naver_id_login.init_naver_id_login();
        this.naver_login_link = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.VUE_APP_NAVER_CLIENT_ID}&redirect_uri=${window.location.origin}/member/myinfo/sns&state=${state}`;
    }).catch(err=>{
      console.log(err);
    })

  },
  data () {
    return {
      sns: [
        { id: 'kakao', name: '카카오', interlock: false, tag_id: 'kakao_id_login'},
        { id: 'naver', name: '네이버', interlock: false, tag_id: 'naveridlogin' },
        // { id: 'facebook', name: '페이스북', interlock: false , tag_id: 'facebook_id_login'},
        { id: 'apple', name: '애플', interlock: false, tag_id: 'appleid-signin' }
        // { id: 'google', name: '구글', interlock: false }
      ],
      naver_id_login: '',
      naver_login_link: '',
      loading: false
    }
  },
  methods: {
    naverTokenCheck() {
      if(this.naver_id_login.getAccessToken() !== undefined) {
        //const accessToken = this.naver_id_login.getAccessToken();
        let body = {
          code: this.naver_id_login.getAccessToken()
        };
        http.post(`/user/naver`, body).then((res) => {
          this.sns[1].interlock = true;
          let query = Object.assign({}, this.$route.query);
          delete query.access_token;
          this.$router.replace({ query });
          alert("연동에 성공했습니다.");
        }).catch((err) => {
          if(err.response.data.message === 'Invalid Token') {
            return alert("네이버 연동에 실패했습니다.");
          }
          alert(err.response.data.message);
          let query = Object.assign({}, this.$route.query);
          delete query.access_token;
          this.$router.replace({ query });
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
    snsLogin(type) {
      if(this.loading) return;
      this.loading = true;

      if (type === "kakao") {
        this.kakaoLogin();
      } else if (type === "naver") {
        this.naverLogin();
      } else if (type === "facebook") {
        alert("페이스북 연동은 기본 브라우저에서만 가능합니다.");
        this.facebookLogin();
      } else if (type === "apple") {
        this.appleLogin();
      } else if (type === "google") {
        this.googleLogin();
      }
    },
    deleteSns (sns) {
      if (confirm('연동을 해제하시겠습니까?')) {
        http.delete(`/user/${sns}`).then(res => {
          this.sns.forEach(s => {
            if (s.id === sns) s.interlock = false;
          })

          alert('연동 해제가 완료되었습니다.');

        }).catch(err=>{
          console.log(err);
        }).finally(()=>{
          this.loading = false;
        })
      } else {
        this.loading = false;
      }
    },
    naverLogin () {
      if(this.sns[1].interlock) {
        // 해제
        this.deleteSns('naver');
      } else {
        // 연동
        document.querySelector('#naver_id_login>a').click();
      }
    },
    facebookLogin() {
      if (this.sns[2].interlock) {
        // 해제
        this.deleteSns('facebook');
      } else {
        // 연동
        window.FB.login((response) => {
          if (response.status === 'connected') {
            let body = {
              code: response.authResponse.accessToken
            }
            http.post(`/user/facebook`, body).then((res) => {
              this.sns[2].interlock = true;
              alert('연동에 성공했습니다.');
            }).catch((err) => {
              if(err.response.data.message === 'Invalid Token') {
                return alert("페이스북 연동에 실패했습니다.");
              }
              alert(err.response.data.message);
            }).finally(() => {
              this.loading = false;
            });

          } else {
            this.loading = false;
            alert('페이스북 연동에 실패했습니다.');
          }
        }, {scope: 'email'});
      }
    },
    appleLogin() {

      if(this.sns[3].interlock) {
        // 해제
        this.deleteSns('apple');
      } else {
        // 연동
        window.onmessage = (e) => {
          if(e.origin === process.env.VUE_APP_API_SERVER_URI) {
            if (e.data.hasOwnProperty('id_token')) {
              let body = {
                code: e.data.id_token
              }
              http.post('/user/apple', body).then(res=>{
                this.sns[3].interlock = true;
                alert('연동에 성공했습니다.');
              }).catch(err=>{
                if(err.response.data.message === 'Invalid Token') {
                  return alert("애플 연동에 실패했습니다.");
                }
                alert(err.response.data.message);
              }).finally(()=>{
                this.loading = false;
              })
            } else {
              this.loading = false;
              alert('애플 연동에 실패했습니다.');
            }
          } else {
            this.loading = false;
            alert('애플 연동에 실패했습니다.');
          }
        }

        window.open(process.env.VUE_APP_APPLE_LOGIN_URI, "apple", "width=500, height=500");
      }

    },
    googleLogin() {

    },
    kakaoLogin() {
      if(this.sns[0].interlock) {
        // 해제
        this.deleteSns('kakao');
      } else {
        // 연동
        window.Kakao.Auth.login({
          success: (authObj) => {
            let body = {
              code: authObj.access_token
            };
            http.post(`/user/kakao`, body).then((res) => {
              this.sns[0].interlock = true;
              alert("연동에 성공했습니다.");
            }).catch((err) => {
              if(err.response.data.message === 'Invalid Token') {
                return alert("카카오 연동에 실패했습니다.");
              }
              alert(err.response.data.message);
            }).finally(() => {
              this.loading = false;
            });
          },
          fail: (err) => {
            this.loading = false;
            alert('카카오 연동에 실패했습니다.');
          }
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
