<template>
  <div class="introSection section4">
    <div class="emblemBox box1" :style="{height: `${sectionHeight}px`}">
      <div class="sectionName">JEONBUK HYUNDAI<br>EMBLEM</div>
      <div class="sectionTitle">
        <div v-if="mainTitle[lang]" class="mainTitle" v-html="mainTitle[lang]"></div>
        <div v-if="subTitle[lang]" class="subTitle" v-html="subTitle[lang]"></div>
        <div v-if="desc[lang]" class="desc" v-html="desc[lang]"></div>
      </div>
    </div>
    <div class="emblemBox box2" :style="{height: `${sectionHeight}px`}">
      <div class="emblemInner">
        <div
          v-for="(item, index) in emblem1"
          :key="index"
          class="emblemItem">
          <div class="emblemContent imgOnly" :style="{color: item.text, backgroundColor: item.bg}">
            <div class="img"><img :src="require(`@/assets/img/club/${item.img}.png`)" alt=""></div>
          </div>
          {{item[lang]}}
        </div>
      </div>
    </div>
    <div class="emblemBox box3" :style="{height: `${sectionHeight}px`}">
      <div class="emblemInner">
        <div
          v-for="(item, index) in emblem2"
          :key="index"
          class="emblemItem">
          <div class="emblemContent" :style="{color: item.text, backgroundColor: item.bg}">
            <div class="color" :style="{backgroundColor: getRgb(item.r, item.g, item.b)}">
              {{item.initial}}
            </div> 
            <div class="colorName" :style="{color: getRgb(item.r, item.g, item.b)}">
              {{item.color}}
            </div>
            <div class="colorCode">
              <div class="label">CMYK</div>
              <span class="codes">C <strong>{{item.c}}</strong></span>
              <span class="codes">M <strong>{{item.m}}</strong></span>
              <span class="codes">Y <strong>{{item.y}}</strong></span>
              <span class="codes">K <strong>{{item.k}}</strong></span>
            </div>
            <div class="colorCode">
              <div class="label">RGB</div>
              <span class="codes">R <strong>{{item.r}}</strong></span>
              <span class="codes">G <strong>{{item.g}}</strong></span>
              <span class="codes">B <strong>{{item.b}}</strong></span>
            </div>
          </div>
          {{item[lang]}}
        </div>
      </div>
    </div>
    <div class="emblemBox box4" :style="{height: `${sectionHeight}px`}">
      <div class="emblemInner">
        <div
            v-for="(item, index) in emblem_addColor"
            :key="index"
            class="emblemItem">
          <div class="emblemContent" :style="{color: item.text, backgroundColor: item.bg}">
            <div class="color" :style="{backgroundColor: getRgb(item.r, item.g, item.b)}">
              {{item.initial}}
            </div>
            <div class="colorName" :style="{color: getRgb(item.r, item.g, item.b)}">
              {{item.color}}
            </div>
            <div class="colorCode">
              <div class="label">CMYK</div>
              <span class="codes">C <strong>{{item.c}}</strong></span>
              <span class="codes">M <strong>{{item.m}}</strong></span>
              <span class="codes">Y <strong>{{item.y}}</strong></span>
              <span class="codes">K <strong>{{item.k}}</strong></span>
            </div>
            <div class="colorCode">
              <div class="label">RGB</div>
              <span class="codes">R <strong>{{item.r}}</strong></span>
              <span class="codes">G <strong>{{item.g}}</strong></span>
              <span class="codes">B <strong>{{item.b}}</strong></span>
            </div>
          </div>
          {{item[lang]}}
        </div>
        <div
          v-for="(item, index) in emblem3"
          :key="index"
          class="emblemItem">
          <a v-if="item.link" :href="item.link" :download="item.download"></a>
          <div class="emblemContent" :style="{color: item.text, backgroundColor: item.bg}">
            <div class="img"><img :src="require(`@/assets/img/club/${item.img}.png`)" alt=""></div>
            <div class="desc" v-html="item.desc"></div>
          </div>
          {{item[lang]}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ClubEmblem',
  props: [
    'active',
    'height',
    'lang'
  ],
  computed: {
    isActive () {
      return this.active && this.active.index === 4
    },
    sectionHeight () {
      const height = this.height && this.height.minHeight ? parseInt(this.height.minHeight.replace(/[^0-9]/g, '')) : 860
      return height
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onScroll()
    this.onResize()
  },
  data () {
    return {
      parallaxWidth: false,
      mainTitle: {
        ko: '<span>JEONBUK HYUNDAI</span> EMBLEM',
        en: '<span>JEONBUK HYUNDAI</span> EMBLEM'
      },
      subTitle: {
        ko: '전북현대 엠블럼'
      },
      desc: {
        ko: `
        <span class="pcBreak">봉황을 메인으로 두고 기존 비파형 동검 형태를 방패형으로 간결하게 표현했고</span>
            구단명(JEONBUK)을 크게 강조해 연고 의식을 높였습니다.<br/>
            엠블럼 하단의 V자는 구단과 팬이 하나 되어 승리를 이루자는 의미를 표현하였습니다.`
      },
      emblem1: [
        { ko: '엠블럼(별포함)', en: 'EMBLEM(STAR)', img: 'img_emblem_1', bg: '#fff', text: '#000' },
        { ko: '엠블럼', en: 'EMBLEM', img: 'img_emblem_2', bg: '#fff', text: '#000' },
        { ko: '엠블럼 1도', en: 'EMBLEM(SOLID COLOR)', img: 'img_emblem_3', bg: '#fff', text: '#000' }
      ],
      emblem2: [
        { ko: 'COLOR (JEONBUK GREEN)', en: 'COLOR (JEONBUK GREEN)', initial: 'G', color: 'JEONBUK GREEN', c: '91%', m: '57%', y: '84%', k: '27%', r: '0', g: '82', b: '61', bg: '#fff', text: '#000' },
        { ko: 'COLOR (YELLOW GREEN)', en: 'COLOR (YELLOW GREEN)', initial: 'G', color: 'YELLOW GREEN', c: '34%', m: '0%', y: '80%', k: '0%',r: '183', g: '247', b: '0', bg: '#fff', text: '#000' },
        { ko: 'COLOR (DARK GREEN)', en: 'COLOR (DARK GREEN)', initial: 'G', color: 'DARK GREEN', c: '94%', m: '71%', y: '84%', k: '56%',r: '0', g: '46', b: '28', bg: '#fff', text: '#000' },
      ],
      emblem3: [
        { ko: '엠블럼 다운로드', en: 'EMBLEM DOWNLOAD', img: 'download_emblem', desc: 'JEONBUK HYUNDAI<br><strong>EMBLEM DOWNLOAD</strong>', bg: '#000', text: '#fff', link: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/MotorsEmblem_20240808.zip', download: true }
      ],
      emblem_addColor: [
        { ko: 'COLOR (YELLOW)', en: 'COLOR (YELLOW)', initial: 'Y', color: 'YELLOW', c: '5%', m: '22%', y: '89%', k: '0%',r: '255', g: '210', b: '0', bg: '#fff', text: '#000' }
      ]
    }
  },
  methods: {
    getRgb (r, g, b) {
      return `rgb(${r}, ${g}, ${b})`
    },
    onScroll () {
      const isActive = this.isActive
      const section = document.querySelector('.section4')
      const scrollY = window.scrollY
      function getRange (scroll, position) {
        const section = position ? position.find(x => {
          return scroll >= x.top && scroll <= x.bottom
        }) : null
        return section ? {
          index: section.section,
          scrollPercent: ((scroll - section.top) / section.height * 100).toFixed(2) // 현재 섹션에서 스크롤 위치가 몇%인지
        } : null
      }
      if (section && isActive) {
        const scrollDiff = scrollY - section.offsetTop
        const box = section.querySelectorAll('.emblemBox')
        const position = box ? [...box].map((x, i) => {
          return {
            section: i,
            height: x.clientHeight,
            top: x.offsetTop,
            bottom: x.offsetTop + x.clientHeight - 1
          }
        }).filter((x, i) => i !== 0) : null
        const currentSection = getRange(scrollDiff, position)
        const currentBox = box && currentSection ? box[currentSection.index] : null
        const currentInner = currentBox && currentBox.querySelector('.emblemInner') ? currentBox.querySelector('.emblemInner') : null
        const nextBox = currentBox ? box[currentSection.index + 1] : null
        const nextInner = nextBox && nextBox.querySelector('.emblemInner') ?  nextBox.querySelector('.emblemInner') : null
        if (this.parallaxWidth) {
          if (currentBox && currentInner) {
            const boxPos = currentInner.offsetTop - scrollDiff > 0 ? 0 : (currentInner.offsetTop - scrollDiff) * -1
            if (boxPos <= currentInner.clientHeight) {
              currentInner.style.transform = `translateY(${boxPos}px)`
            }
            if (nextBox && nextInner) {
              const nextPos = nextInner.offsetTop - scrollDiff + currentInner.clientHeight > 0
                ? 0
                : (nextInner.offsetTop - scrollDiff + currentInner.clientHeight) * -1
              nextInner.style.transform = `translateY(${nextPos}px)`
            }
          }
        } else {
          section.querySelector('.emblemInner').style.transform = ''
        }
      }
      
    },
    onResize () {
      // resize
      this.parallaxWidth = window.innerWidth >= 1600
    }
  }
}
</script>
