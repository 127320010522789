<template>
  <div class="mediaView gameReview">
    <div class="innerBox">
      <div class="innerTitle">경기리뷰</div>
      <div class="noData" v-if="reviewData.length === 0">데이터가 없습니다</div>
      <div class="reviewBox" v-else>
        <div class="mediaTitle">
          <div class="subject">{{reviewData.Title}}</div>
          <div class="summary">{{reviewData.TopContent}}</div>
          <div class="about">
            <div class="info">
              <span class="date">{{reviewData.set_RegiDate}}</span>
              <span class="view">{{reviewData.Hit}}</span>
            </div>
            <!-- 공유 -->
            <div class="share">
              <div class="shareBtn link" @click="clipboard">
                <span v-if="copyLink" class="message">주소가 복사되었습니다.</span>
              </div>
              <a href="#" class="shareBtn facebook" @click="shareFacebook"></a>
              <span class="shareBtn kakaotalk" @click="shareKakao"></span>
            </div>
            <!-- //공유 -->
          </div>
        </div>
        <div class="mediaContent"  :class="isOver100000">
          <!--
          <div class="img" v-if="reviewData.TopImage"><img :src="reviewData.TopImage" alt="" @error="replaceImg"></div>
          <div class="img" v-else><img src="../../assets/img/bg_media_404.png"></div>
          -->
          <div class="article" v-html="reviewData.Content"></div>
          <div class="tags pretendard" v-if="reviewData.tag.length!==0">
            <a href="#" class="tag" v-for="(tag, num) in reviewData.tag" :key="num+'k'">{{tag.name}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import bgImg from "@/assets/img/bg_media_404.png"
import { sharePath } from '@/library/sharePath'

export default {
  name: 'MatchGameReview',
  created() {
    this.init()
  },
  props: {
    game_date: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      copyLink: false,
      reviewData :[],
      isOver100000: ''
    }
  },
  methods: {
    clipboard() {
      const url = window.location.href
      this.copyLink = true
      window.navigator.clipboard.writeText(url)
      setTimeout(() => {
        this.copyLink = false
      }, 1000)
    },
    replaceImg(e) {
      e.target.src = bgImg
    },
    async init(){
      const match = this.$store.state.matchData
      if (match.content.review) {
        this.reviewData = match.content.review;
        if (this.reviewData.seq >= 100000) {
          this.isOver100000 = 'over100000'
        }
        this.reviewData.set_RegiDate = dayjs(this.reviewData.RegiDate).format('YYYY.MM.DD');
      }
    },
    shareFacebook() {
      // window.open('http://www.facebook.com/sharer.php?u=www.naver.com');
      //추후 주소 필요
      window.open(`http://www.facebook.com/sharer.php?u=${sharePath.host}/match/game/${this.$route.params.id}/review`);
    },
    shareKakao () {
      const url = `${sharePath.host}/match/game/${this.$route.params.id}/review`
      //추후 주소 필요
      window.Kakao.Link.sendDefault({
            objectType: 'feed',
            content: {
              title: this.reviewData.Title,
              description: this.reviewData.TopContent,
              imageUrl: this.reviewData.TopImage,
              link: {
                mobileWebUrl: url,
                webUrl: url,
              },
            },
            buttons: [
              {
                title: '웹으로 보기',
                link: {
                  mobileWebUrl: url,
                  webUrl: url,
                },
              },
            ],
            installTalk: true,
          }
      )
    }
  }
}
</script>
<style>
.over100000 p {
  margin-bottom: 1em;
}
</style>
