<template>
  <div>
    <div class="sectionTitle line">경기장 안전 가이드라인</div>
    <div class="indexBox waitingSeason" style="margin-top: 80px;">
      <div class="container">
        <img src="@/assets/img/ticket/guide_2024.jpg" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TicketStadiumGuidelines',
  data () {
    return {
      state: true, // 티켓 임시페이지 사용시 false
    }
  },
}
</script>


