export default {
    name: '2023년 02월 21일 이전',
    html: `<div>
    <p class="policyIndex">제1조 총칙</p>
    <div class="policyContent">전북현대모터스FC(이하 '회사'라고 합니다)는 ｢개인정보 보호법｣ 제30조에 따라 고객님의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</div>

    <p class="policyIndex" data-index="1" data-label="개인정보의 처리 목적, 항목 및 보유기간">제2조 개인정보의 처리 목적, 항목 및 보유기간</p>
    <div>1) 회사는 전북현대 웹사이트(www.hyundai-motorsfc.com)를 운영하며 다음의 목적을 위하여 아래와 같이 개인정보 항목을 처리하고 있습니다.<br>
        법령에 따른 개인정보 보유 및 이용기간 또는 고객님으로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용기간 내에서 개인정보를 처리, 보유합니다. 처리하고 있는 개인정보는 다음 목적 이외의 용도 로는 이용되지 않으며, 이용 목적, 항목이 변경되는 경우에는 ｢개인정보 보호법｣ 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</div>
    <div class="policyTable">
        <table>
            <thead>
            <tr>
                <th>서비스구분</th>
                <th>수집, 이용 목적</th>
                <th>수집항목</th>
                <th>보유기간</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>웹사이트 및 모바일 애플리케이션 회원제 운영</td>
                <td>
                    - 웹사이트 및 모바일 애플리케이션 회원 관리 및 서비스 제공 : 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 확인, 회원자격 유지∙관리, 콘텐츠 등 이용약관에 따른 서비스 제공<br>
                    - 웹사이트 및 모바일 애플리케이션 회원제 서비스 제공 : 회원제 서비스 제공, 신청형 서비스 제공, 참여형 서비스 제공, 맞춤 서비스 제공, 리워드(포인트) 제공<br>
                    - 마케팅 및 광고 활용, 제공 : 신규 서비스 개발, 맞춤 서비스 제공, 광고성 정보 제공 및 이벤트 참여 기회 제공, 서비스의 유효성 확인, 회원의 서비스 이용에 대한 통계 목적으로 개인정보를 처리<br>
                    - 구단 소식 및 경기 정보 안내, 이벤트, 홍보, 프로모션 정보 등 전자적 전송매체(SMS, 이메일 등 포함)를 이용한 광고성 정보 전송
                </td>
                <td>
                    (필수) 본인인증을 통한 “이름, 휴대폰번호, 생년월일, 성별, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI)”, 아이디, 휴대폰번호(아이핀을 통한 본인인증 시 별도 입력), 이메일주소, 우편번호, 좋아하는 선수<br><br>
                    (선택) 광고성 메시지 수신 및 야간 광고성 수신 동의 여부, 직업, 결혼여부, 전주공장 사원번호
                </td>
                <td>웹사이트 및 모바일 애플리케이션 회원 탈퇴시까지</td>
            </tr>
            <tr>
                <td>문자 안내 서비스 제공</td>
                <td>구단 소식 및 경기 정보, 이벤트 정보 등 문자(SMS, LMS) 안내 서비스 제공</td>
                <td>(필수) 이름, 휴대폰번호</td>
                <td>문자 안내 서비스 신청 철회시까지(문자 안내 서비스 신청은 문자 안내 서비스 신청 페이지 하단 및 회사가 전송한 문자 등에 기재된 수신 거부 연락처를 통하여 언제든지 철회하실 수 있습니다)</td>
            </tr>
            <tr>
                <td>그린스쿨 유소년 축구교실</td>
                <td>- 그린스쿨 회원 관리 및 서비스 제공 : 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 확인, 회원자격 유지•관리, 콘텐츠 등 이용약관에 따른 서비스 제공, 서비스 부정이용 방지, 그린스쿨 유소년 축구교실 운영, 교육비 납부 및 환불절차 진행, 복수 자녀 수강시 가입비 면제 혜택 제공, 공지 및 고지사항 전달</td>
                <td>(필수) 이름, 아이디, 휴대폰번호, 이메일주소, 교육비 입금자명, 생년월일, 본인인증결과값(DI), 자녀명, 연락처, 생년월일, 학교, 학년, 나이, 신장, 체중, 사이즈</td>
                <td>그린스쿨 회원 탈퇴시까지<br>
                    ※ 단, 아동(자녀)이 유소년 축구교실에 참여하여 교육비 수납이 완료 된 경우, 해당 클래스 수강이 완료 된 날로부터 5년까지
                </td>
            </tr>
            <tr>
                <td>티켓 예매 서비스 제공</td>
                <td>
                    - 전북현대 홈 경기 티켓 서비스 제공 : 티켓 예매 및 조회, 취소, 환불, 민원을 위해 처리<br>
                    - 시즌권, 멤버십 서비스 제공 : 시즌권, 멤버십 서비스 제공을 위해 개인정보를 처리<br>
                    - 마케팅 및 광고 활용, 제공 : 신규 서비스 개발, 맞춤 서비스 제공, 광고성 정보 제공 및 이벤트 참여 기회 제공, 서비스의 유효성 확인, 회원의 서비스 이용에 대한 통계 목적으로 개인정보를 처리
                </td>
                <td>(필수) 아이디, 이름, 생년월일, 휴대폰번호, 이메일주소(입력 시) 및 티켓 구매 정보 일체</td>
                <td>티켓 예매하여 입금이 완료 된 경우, 해당 시점부터 5년까지</td>
            </tr>
            <tr>
                <td>MD쇼핑몰 서비스 제공</td>
                <td>
                    - MD쇼핑몰 서비스 제공 및 발권 서비스 제공 : MD상품 구매 확인 및 배송, 환불, 교환, 민원을 위해 처리<br>
                    - 마케팅 및 광고 활용, 제공 : 신규 서비스 개발, 맞춤 서비스 제공, 광고성 정보 제공 및 이벤트 참여 기회 제공, 서비스의 유효성 확인, 회원의 서비스 이용에 대한 통계 목적으로 개인정보를 처리
                </td>
                <td>아이디, 이름, 주문자 정보(이름, 우편번호, 주소, 휴대폰번호, 이메일주소), 수령인 정보(이름, 우편번호, 주소, 휴대폰번호, 이메일주소), 주문∙결제 승인정보 및 환불 정보(금융사, 계좌번호, 예금주명)</td>
                <td>MD상품 구매하여 입금이 완료 된 경우, 해당 시점부터 5년까지</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="policyContent">2) 단, 상법 등 관련법령의 규정에 의하여 보존할 의무가 있는 경우 회사는 고객의 개인정보를 보관합니다.<br>
        이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 다음과 같습니다.<br>
        ① 회사의 상업장부와 영업에 관한 중요서류 및 전표 등에 관련된 정보 : 10년 - 중요서류 / 5년 - 전표(상법)<br>
        ② 모든 거래에 관한 장부 및 증빙서류와 관련된 정보 : 5년 (국세기본법, 법인세법)<br>
        ③ 계약 또는 청약철회 등에 관한 기록, 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)<br>
        ④ 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래 등에서의 소비자보호에 관한 법률)<br>
        ⑤ 장부와 교부한 세금계산서 또는 영수증 : 5년 (부가가치세법)<br>
        ⑥ 「통신비밀보호법」에 따른 서비스이용기록, 접속로그, 접속IP정보 : 3개월<br>
        ※ '개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다. 단, 아동(자녀)이 유소년 축구교실에 참여 중인 그린스쿨 회원 및 해당 아동의 개인정보는 예외로 합니다.
    </div>

    <p class="policyIndex" data-index="2" data-label="처리하는 개인정보의 수집 방법">제3조 처리하는 개인정보의 수집 방법</p>
    <div class="policyContent">
        1. 회사는 고객의 편의를 위해 회사의 패밀리사이트인 초록이네, 그린스쿨, 후원의집 사이트의 통합회원제도(Single Sing On, SSO)를 운영하고 있습니다. 이를 위해 본 회사가 수집한 개인정보는 아래의 내용에 따라 각 패밀리사이트에 제공하게 됩니다. 따라서 고객님이 전북현대 웹사이트 회원이시며 동시에 회사가 운영하는 패밀리 사이트의 회원이 되십니다. 고객님의 편리한 이용을 위해 전북현대 웹사이트에 로그인(ID와 비밀번호로 접속)하신 경우 별도의 로그인 절차없이 패밀리 사이트를 이용하실 수 있습니다.<br>
        2. ?<br>
        3. 전북현대 웹사이트 회원가입 시 정보주체의 동의를 통한 수집<br>
        4. 신청형·참여형 이벤트 참여 시 정보주체의 동의를 통한 수집<br>
        5. 제3자(티켓판매 대행사)와의 서비스 연계를 통한 개인정보 수집<br>
        - 제공업체 : 엔에이치엔링크 주식회사<br>
        - 제공받는 항목 : 이름, 생년월일, 휴대폰번호, 이메일주소(입력시) 및 티켓 구매 정보 일체<br>
        - 제공받는 목적 : 티켓구매 빈도·선호좌석에 따른 맞춤서비스(쿠폰 발급) 제공, 구매금액에 따른 리워드(포인트) 제공<br>
        6. 로그 분석 프로그램을 통한 수집
    </div>

    <p class="policyIndex" data-index="3" data-label="개인정보의 파기절차 및 파기방법">제4조 개인정보의 파기절차 및 파기방법</p>
    <div class="policyContent">
        1) 회사는 다른 법률에 따라 개인정보를 보존하여야 하는 경우가 아닌 한, 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br><br>
        2) 고객님으로부터 동의받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 의한 근거 및 내부 방침 및 정보보호 사유(보유 및 이용기간 참조)에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여(종이의 경우 별도의 서류함) 보존합니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는 목적 이외의 다른 목적으로 이용되지 않습니다.<br>
        ① 법령 근거 : 전자상거래 등에서의 소비자보호에 관한 법률(5년)<br>
        ② 보존하는 개인정보 항목 : 이름, 아이디, 연락처, 결제 상품 정보 및 결제 정보 일체<br><br>
        3) 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br>
        ① 파기절차<br>
        회사는 파기 사유가 발생한 개인정보를 선정하고, 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br>
        ② 파기방법<br>
        회사는 전자적 파일 형태로 기록・저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록・저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
    </div>

    <p class="policyIndex" data-index="4" data-label="미용자의 개인정보 파기 등에 관한 조치">제5조 미용자의 개인정보 파기 등에 관한 조치</p>
    <div class="policyContent">
        1) 회사는 1년간 서비스를 이용하지 않은 이용자는 휴면계정으로 전환하고, 개인정보를 별도로 분리하여 보관합니다. 분리 보관된 개인정보는 1년간 보관 후 지체없이 파기합니다.<br><br>
        2) 회사는 휴면전환 30일 전까지 휴면예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, 문자 등 이용자에게 통지 가능한 방법으로 알리고 있습니다.<br><br>
        3) 휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전 서비스 로그인을 하시면 됩니다. 또한, 휴면계정으로 전환되었더라도 로그인을 하는 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.
    </div>

    <p class="policyIndex" data-index="5" data-label="개인정보 처리의 위탁">제6조 개인정보 처리의 위탁</p>
    <div class="policyContent">
        1) 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br><br>
        2) 회사는 위탁계약 체결 시 ｢개인정보 보호법｣ 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br><br>
        3) 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
    </div>
    <div class="policyTable">
        <table>
            <thead>
            <tr>
                <th>수탁사</th>
                <th>위탁 업무 내용</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>더스포츠커뮤니케이션</td>
                <td>- 웹사이트 및 모바일 애플리케이션 서비스 관리, 유지보수<br>
                    - 데이터 기반 마케팅 계획 수립 대행 및 운영<br>
                    - 그린스쿨 유소년 축구교실 신청, 관리 시스템 운영
                </td>
            </tr>
            <tr>
                <td>에스아이엘</td>
                <td>MD쇼핑몰 서비스 운영 및 관리, 배송 대행</td>
            </tr>
            <tr>
                <td>엔에이치엔커머스 주식회사</td>
                <td>MD쇼핑몰 서비스 시스템 관리</td>
            </tr>
            <tr>
                <td>NHN한국사이버결제 주식회사</td>
                <td>그린스쿨 가입비 및 수강료 결제</td>
            </tr>
            </tr>
        </tbody>
    </table>
</div>

<p class="policyIndex" data-index="6" data-label="개인정보의 제3자 제공">제7조 개인정보의 제3자 제공</p>
<div class="policyContent">
    1) 회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 ｢개인정보 보호법｣ 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br><br>
    2) 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
</div>
<div class="policyTable">
    <table>
        <thead>
        <tr>
            <th>구분</th>
            <th>제공 목적</th>
            <th>제공하는 개인정보 항목</th>
            <th>개인정보 보유 및 이용기간</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>엔에이치엔링크 주식회사</td>
            <td>전북현대 홈 경기 입장권, 시즌권, 멤버십 예매 및 조회, 취소 시스템 운영</td>
            <td>회원번호, 아이디, 이름, 휴대폰번호, 생년월일, 이메일주소, 성별, 회원등급</td>
            <td>회원 탈퇴시까지</td>
        </tr>
        </tbody>
    </table>
</div>

<p class="policyIndex" data-index="7" data-label="개인정보의 안정성 확보조치">제8조 개인정보의 안정성 확보조치</p>
<div class="policyContent">
    회사는 고객의 개인정보를 처리함에 있어 개인정보의 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 아래와 같은 기술적/관리적 대책을 적용하고 있습니다.<br><br>
    <p class="strong">1) 기술적 조치</p>
    ① 고객정보의 암호화<br>
    고유식별정보, 카드번호 등 법령에서 암호화를 요구하고 있는 개인정보를 저장하는 경우, DB내에 암호화 저장되어 외부 침입에 의해 유출되더라도 고객의 개인정보를 활용할 수 없도록 하고 있습니다.<br>
    ② 통신구간 암호화<br>
    웹사이트, 모바일 애플리케이션을 통한 회원가입 및 로그인 등 고객정보를 입력하여 전달하는 구간에 대해 SSL 등 암호화 통신을 통하여 고객의 정보가 안전하게 전송되도록 조치하고 있습니다.<br>
    ③ 보안솔루션의 설치<br>
    서비스 제공 및 고객의 정보를 안전하게 관리하기 위하여 개인정보처리시스템에 대해 백신프로그램의 설치, 주기적인 업데이트 및 정기점검을 수행하고 있으며, DB 접근제어 솔루션 및 필요시 화면캡처방지 솔루션 등을 적용하고 있습니다. 또한 해킹 등의 외부침입을 대비하여 침입차단/탐지시스템을 설치하고 통합보안관제센터를 통해 해킹 및 외부침입에 대비하여 계속적으로 모니터링을 수행하고 있습니다.<br><br>
    <p class="strong">2) 관리적 조치</p>
    ① 개인정보관리체계 수립<br>
    개인정보를 안전하게 관리하기 위하여 회사 내부적으로 개인정보 관리체계를 수립하여 운영하고 있습니다.<br>
    ② 개인정보보호 위원회 운영<br>
    회사의 개인정보보호를 위한 위원회를 구성하여 연 1회 이상 위원회 회의를 개최하고 개인정보관리체계의 운영 및 개인정보보호 이슈 등의 사항에 대하여 개선하고 바로잡기 위한 노력을 기울이고 있습니다.<br>
    ③ 개인정보 취급자 관리<br>
    고객의 개인정보를 처리하는 개인정보취급자를 대상으로 개인정보보호 서약서를 제출 받고, 연 1회 이상의 개인정보보호 교육을 수행하여 고객정보의 중요성과 안전하게 관리하도록 하고 있습니다. 또한 개인정보처리자의 권한 관리를 통하여 불필요한 고객의 개인정보에 대한 접근과 노출을 최소화하고 있습니다.
</div>

<p class="policyIndex" data-index="8" data-label="개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항">제9조 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항</p>
<div class="policyContent">
    1) 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.<br><br>
    2) 쿠키는 웹사이트를 운영하는데 이용되는 서버(https)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.<br>
    ① 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. 고객은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 고객은 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br>
    ② 쿠키의 설치∙운영 및 거부: 쿠키 설정을 거부하는 방법으로는 고객이 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br>
    ※ 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의도구 > 인터넷옵션 > 개인정보 > 고급 > 설정방법 선택<br>
    ③ 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.
</div>

<p class="policyIndex" data-index="9" data-label="고객, 법정대리인의 권리와 의무 및 그 행사방법">제10조 고객, 법정대리인의 권리와 의무 및 그 행사방법</p>
<div class="policyContent">
    1) 고객 또는 법정대리인은 회사에 대하여 언제든지 개인정보 수집 · 이용 · 제공 등의 동의를 철회(가입해지)할 수 있으며 개인정보 열람, 정정, 삭제, 처리정지 요구 등의 권리를 행사할 수 있습니다.<br><br>
    2) 고객 또는 법정대리인은 위와 같은 권리 행사를 온라인에서는 회사 웹사이트 및 모바일 애플리케이션에 접속하여 본인 확인 절차를 거친 후 개인정보관리 메뉴에서 하실 수 있고, 서면, 전화 또는 이메일 등을 통하여 고객센터 또는 회사 개인정보보호 책임자 및 담당자에게 연락하는 방법으로 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.<br><br>
    3) 제1, 2항에 따른 권리 행사는 고객님의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br><br>
    4) 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조제2항에 의하여 고객님의 권리가 제한될 수 있습니다.<br><br>
    5) 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br><br>
    6) 회사는 정보주체 권리에 따른 열람의 요구, 정정, 삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br><br>
    7) 고객이 개인정보 오류정정을 요구하신 경우, 회사는 오류정정을 완료하기 전까지 당해 개인정보를 이용 · 제공하지 않으며, 이미 제3자에게 당해 개인정보를 제공한 경우에는 제3자에게 지체없이 통지하여 오류 정정이 이루어지도록 하고 있습니다.<br><br>
    8) 고객 또는 법정 대리인이 동의철회(가입해지)한 경우, 회사는 지체없이 파기하는 것을 원칙으로 하나 관계법령에서 의무적으로 보유하도록 한 경우에는 개인정보 처리방침 '개인정보의 보유 및 이용기간'에 따라 처리하고, 반드시 필요한 경우에만 열람 또는 이용이 가능하도록 조치하고 있습니다.
</div>

<p class="policyIndex" data-index="10" data-label="고객의 권익침해에 대한 구제방법">제11조 고객의 권익침해에 대한 구제방법</p>
<div class="policyContent">
    고객께서는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.<br>
    아래의 기관은 회사와 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.<br><br>
    ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)<br>
    - 소관업무 : 개인정보 침해사실 신고, 상담 신청<br>
    - 홈페이지 : privacy.kisa.or.kr<br>
    - 전화 : (국번없이) 118<br>
    - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층<br><br>
    ▶ 개인정보 분쟁조정위원회<br>
    - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)<br>
    - 홈페이지 : www.kopico.go.kr<br>
    - 전화 : (국번없이) 1833-6972<br>
    - 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 12층<br><br>
    ▶ 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br><br>
    ▶ 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
</div>

<p class="policyIndex" data-index="11" data-label="개인정보 보호책임자 및 담당자, 업무처리 부서">제12조 개인정보 보호책임자 및 담당자, 업무처리 부서</p>
<div class="policyContent">
    1) 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 고객의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br><br>

    &gt;개인정보 보호책임자&lt;<br>
    - 성명: 허병길<br>
    - 소속 및 직책: 대표이사<br>
    - 연락처: (063)273-1763<br>
    - 이메일: jeonbuk@hyundai-motorsfc.com<br><br>

    &gt;개인정보 보호담당자&lt;<br>
    - 성명: 장은서<br>
    - 소속 및 직책: 경영기획팀 / 책임매니저<br>
    - 연락처: (063)273-1763<br>
    - 이메일: jeshi@hyundai-motorsfc.com<br><br>
    2) 고객께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.<br>
    회사는 고객님의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.<br><br>
    ※ 다만 개인정보 보호 관련 문의, 불만 및 피해관련 내용 이외의 내용으로 발송하시는 이메일은 답변 및 처리가 어려우며, 담당자의 동의 없이 발송하는 영리목적의 광고성 이메일에 대해서는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제50조부터 제50조의8의 규정에 따라 관계기관에 신고 등의 조치가 이루어질 수 있습니다.
</div>

<p class="policyIndex" data-index="12" data-label="광고성 정보 전송 관련 안내">제13조 광고성 정보 전송 관련 안내</p>
<div class="policyContent">
    1) 회사는 고객의 사전 동의 없이 영리목적의 광고성 정보를 전송하지 않습니다.<br><br>
    2) 회사는 신차 출시 안내 또는 이벤트 안내 등 마케팅 및 홍보 등 영리 목적의 광고성 정보를 전송하는 경우에는, 광고성 정보 전송에 대한 사전 동의를 득한 고객에 한하여 발송하고 있습니다.<br><br>
    3) 회사는 전자적 전송 매체를 이용하여 광고성 정보 전송하는 경우 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제50조부터 제50조의8의 규정에 따라 다음과 같이 조치 후 전송하고 있습니다.<br>
    - 전자적 전송 수단 : 휴대전화 문자메시지, 전자우편, 팩스, 그 밖의 전자적 전송매체<br>
    ① 제목이 시작되는 부분에 (광고) 표시<br>
    ② 전송자의 명칭 및 연락처<br>
    ③ 무료 수신 거부 가능 번호 표기<br><br>
    4) 회사는 고객님이 광고성 정보 수신 동의를 한 날로부터 매 2년 마다 고객의 광고성 정보 수신 동의 사실을 고객에게 확인합니다. 고객님께서 수신 동의 여부 안내를 받은 후 아무런 의사표시를 하지 않으신 경우 수신 동의 의사가 그대로 유지됩니다.<br><br>
    5) 회사는 오후 9시부터 그 다음 날 오전 8시까지의 시간에 전자적 전송매체를 이용한 광고성 정보를 전송하지 않습니다.<br>
    다만, 광고성 정보 수신 동의를 동의한 회원 중 오후 9시 이후에는 야간 광고성 정보 수신 동의를 완료한 회원에 한하여 경기 소식을 받아보실 수 있습니다.<br><br>
    6) 다만 아래의 경우에 전자적 전송매체로 발송하는 내용은 광고성 정보의 예외로 봅니다.<br>
    - 회사와 고객간 체결된 계약이행 등과 관련한 정보<br>
    - 고객님께서 요청하신 신청, 정보에 대한 안내, 답변(예 : 이벤트 신청, Q&A 등)<br><br>
    ※ 광고성 정보 수신에 대한 동의 철회 방법은 다음과 같습니다.<br><br>
    웹사이트, 모바일 애플리케이션 내 마이페이지에서 광고성 정보 수신동의 여부를 거부로 변경 혹은 회사가 전송 한 SMS, LMS 등에 기재된 무료 수신 거부 전화번호를 통한 자동 거부
</div>

<p class="policyIndex" data-index="13" data-label="개인정보 처리방침의 변경에 관한 사항">제14조 개인정보 처리방침의 변경에 관한 사항</p>
<div class="policyContent">
    회사는 본 개인정보처리방침을 변경하는 경우 그 이유 및 변경내용을 홈페이지 첫 화면의 공지사항란 또는 별도의 창을 통하는 등의 방법으로 사전에 공지한 후 변경 및 적용하고 있습니다.<br><br>
    법령/정책 또는 보안기술의 변경에 따라 내용의 변경(추가, 삭제 및 수정 등)이 있을 시에는 변경되는 개인정보 처리방침을 시행하기 최소 5일 전에 웹사이트를 통해 변경사유 및 내용 등을 공지하도록 하겠습니다.<br><br>
    본 방침은 2023년 2월 21일부터 시행됩니다.
</div>
</div>
`}
