<template>
  <div class="introSection section3">
    <div class="video" :style="{height: `${sectionHeight}px`}">
      <video autoplay playsinline muted loop preload>
        <source :src="this.video" type="video/mp4" />
      </video>
    </div>
    <div class="stadiumBox" :style="{height: `${sectionHeight}px`}">
      <div class="sectionName">HOME<br>STADIUM</div>
      <div class="sectionTitle">
        <div v-if="mainTitle[lang]" class="mainTitle" v-html="mainTitle[lang]"></div>
        <div v-if="subTitle[lang]" class="subTitle" v-html="subTitle[lang]"></div>
        <div v-if="seat[lang]" class="thirdTitle" v-html="seat[lang]"></div>
        <div v-if="desc[lang]" class="desc" v-html="desc[lang]"></div>
      </div>
    </div>
    <div class="imgBox">
      <div class="imgList" :style="{maxWidth: `${imgWidth}px`}">
        <div
          v-for="img in imgLength"
          :key="img"
          class="img">
          <img :src="require(`@/assets/img/club/img_stadium_${img}.png`)" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgPath from '@/library/imgPath'

export default {
  name: 'ClubStadium',
  props: [
    'active',
    'height',
    'lang'
  ],
  computed: {
    isActive () {
      return this.active && this.active.index === 3
    },
    sectionHeight () {
      const height = this.height && this.height.minHeight ? parseInt(this.height.minHeight.replace(/[^0-9]/g, '')) : 860
      return height
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onScroll()
    this.onResize()
    this.video = imgPath.setVideo('bg_club_stadium_ver1')
  },
  data () {
    return {
      parallaxWidth: false,
      imgWidth: 905,
      imgLength: 4,
      mainTitle: {
        ko: 'JEONJU WORLDCUP STADIUM',
        en: 'JEONJU WORLDCUP STADIUM'
      },
      seat: {
        ko: '(34,276석)',
        en: '(34,276 seat)',
      },
      subTitle: {
        ko: '전주월드컵경기장'
      },
      desc: {
        ko: `
        축구팬과 네티즌이 선정한 한국에서 가장 아름다운 경기장으로 
        <span class="pcBreak">우리 민족의 전통적 요소인 유교적 은율과 기풍 그리고 예향의 멋이 어우러져 </span>
        21세기를 향한 전주의 넉넉한 기상을 표현하였습니다.`
      },
      video : ''
    }
  },
  methods: {
    onScroll () {
      const isActive = this.isActive
      const section = document.querySelector('.section3')
      const scrollY = window.scrollY - section.offsetTop
      const video = section.querySelector('.video')
      if (section && isActive) {
        const sectionTitle = section.querySelector('.sectionTitle')
        const imgBox = section.querySelector('.imgBox')
        const imgList = imgBox.querySelector('.imgList')
        const titlePos = sectionTitle.offsetTop - scrollY > 0 ? 0 : (sectionTitle.offsetTop - scrollY) * -1
        const imgPos = imgBox.offsetTop
        if (this.parallaxWidth) {
          sectionTitle.style.transform = `translateY(${titlePos}px)`
          if (imgPos - scrollY <= sectionTitle.clientHeight) {
            const translateY = `${(imgPos - scrollY > sectionTitle.clientHeight ? 0 : (imgPos - scrollY - sectionTitle.clientHeight) * -1)}px`
            const translateX = `${imgPos - scrollY > sectionTitle.clientHeight ? 0 : imgPos - scrollY - sectionTitle.clientHeight}px`
            imgBox.style.transform = `translateY(${translateY})`
            imgList.style.transform = `translateX(${translateX})`
          } else {
            imgBox.style.transform = ''
            imgList.style.transform = ''
          }
        } else {
          sectionTitle.style.transform = ''
          imgBox.style.transform = ''
          imgList.style.transform = ''
        }
      }
      if (section && video) {
        video.style.transform = `translateY(${scrollY < 0 ? 0 : scrollY}px)`
      }
    },
    onResize () {
      // resize
      const section = document.querySelector('.section3')
      const imgBox = section ? section.querySelector('.imgBox') : null
      const video = section ? section.querySelector('.video') : null
      if (section && imgBox) {
        imgBox.style.height = `${this.imgWidth * (this.imgLength + 2)}px`
      }
      if (section && video) {
        video.style.height = `${window.innerHeight}px`
      }
      this.parallaxWidth = window.innerWidth >= 1600
    }
  }
}
</script>
