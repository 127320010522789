<template>
  <div class="introSection section3">
    <div class="emblemBox">
      <div class="emblemTitle">
        <strong>엠블럼<br>히스토리</strong>
        <p>전북현대모터스의 창단 엠블럼부터 <span class="pcBreak">30주년 기념, 현재 엠블럼까지의</span> 역사를 소개합니다.</p>
        <div class="swiper-button-prev emblemSwiperPrev" slot="button-prev"></div>
        <div class="swiper-button-next emblemSwiperNext" slot="button-next"></div>
      </div>
      <swiper class="swiper emblemSwiper"
        ref="emblemSwiper"
        :options="swiperOption">
        <swiper-slide
          v-for="(item, index) in emblem"
          :key="index"
          class="swiperItem">
          <div v-if="item === 'blank'"></div>
          <div v-else>
            <div class="emblem"><img :src="require(`@/assets/img/history/${item.img}.png`)" /></div>
            <div class="year">{{item.year}}</div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'HistoryEmblem',
  props: [
    'active'
  ],
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    isActive () {
      return this.active && this.active.index === 3
    }
  },
  data () {
    return {
      emblem: [
        { year: '1995~1996', img: 'emblem_1995' },
        { year: '1997~1998', img: 'emblem_1997' },
        { year: '1999~2000.05', img: 'emblem_1999' },
        { year: '2000.06~2005', img: 'emblem_2000' },
        { year: '2006~2012', img: 'emblem_2006' },
        { year: '2014', img: 'emblem_2014' },
        { year: '2013~2017', img: 'emblem_2013' },
        { year: '2018~', img: 'emblem_2018' },
        { year: '2024', img: 'emblem_2024' },
        'blank'
      ],
      swiperOption: {
        loop: false,
        slidesPerView: window.innerWidth >= 768 ? 2.8 : 1.2,
        spaceBetween: 0,
        navigation: {
          nextEl: '.emblemSwiperNext',
          prevEl: '.emblemSwiperPrev'
        }
      }
    }
  },
  methods: {
  }
}
</script>
