import Ethics from '@/components/Etc/Ethics.vue'
import Jbfcmall from '@/components/Etc/Jbfcmall.vue'
import PresentOfSmartTk from '@/components/Etc/PresentOfSmartTk'
import WebviewBridge from "@/components/Etc/WebviewBridge.vue";
//import LoginBridge from "@/components/Etc/LoginBridge.vue";
import Alert from "@/components/Etc/Alert.vue"
import ErrorPage from "@/components/Etc/Error.vue"
import ServerErrorPage from "@/components/Etc/ServerError.vue"
import Quiz from "@/components/Etc/Quiz.vue"
import Roulette from "@/components/Etc/Roulette.vue"

const etc = [
  {
    path: '/ethics',
    name: 'ethics',
    component: Ethics
  },
  {
    path: '/jbfcmall',
    name: 'jbfcmall',
    component: Jbfcmall
  },
  {
    path: '/giftTicket',
    name: 'PresentOfSmartTk',
    component: PresentOfSmartTk,
    meta: {
      role: 'view',
      noSubTop: true // 서브상단 없는 경우
    },
  },
  {
    path: '/bridge',
    name: 'bridge',
    component: WebviewBridge
  },
  {
    path: '/alert',
    name: 'alert',
    component: Alert
  },
  {
    path: '/err',
    name: 'errorPage',
    component: ErrorPage
  },
  {
    path: '/serverErr',
    name: 'serverErrorPage',
    component: ServerErrorPage
  },
  {
    path: '/quiz',
    name: 'quiz',
    component: Quiz
  },
  {
    path: '/roulette',
    name: 'roulette',
    component: Roulette
  }
]

export default etc
