<template>
  <div>
    <div class="listInfo">
      <div class="title hiddenMobile">설문조사 내역</div>
      <div class="sort">
        <div class="filter">
          <!-- filter -->
          <div class="filterItem shortcut">
            <button
              v-for="s in shortcuts"
              :key="s.id"
              type="button"
              @click="setTerm(s.id)"
              :class="{current: s.id === shortcut}">
              <span>{{s.name}}</span>
            </button>
          </div>
          <!-- //filter -->
          <!-- datepicker -->
          <div class="filterItem datePicker hiddenMobile">
            <date-picker v-model="term.from" valueType="format"></date-picker> ~
            <date-picker v-model="term.to" valueType="format"></date-picker>
            <button type="button" class="btn whiteLightgrey2 auto btnSearch" @click="search">검색</button>
          </div>
          <!-- //datepicker -->
        </div>
      </div>
    </div>
    <div class="dataTable">
      <div>
        <table v-if="showData">
          <thead>
            <tr>
              <th>설문조사 제목</th>
              <th>설문조사 일시</th>
              <th>참여 일시</th>
              <th>기능</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(el, i) in surveyList" :key="i">
              <td>{{el.subject}}</td>
              <td class="term">{{el.setStartDate}} ~ {{el.setEndDate}}</td>
              <td class="date">{{el.finSurveyDate}}</td>
              <td class="btns">
                <button type="button" class="btn w100" @click="goSurvey(el.idx)" :disabled="el.setStateMsg !== '참여하기'">{{el.setStateMsg}}</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="noData">설문조사 내역이 없습니다</div>
        <Pagination
            v-show="showData"
            :perPage="6"
            :pages="pages"
            @change="getData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Pagination from '@/components/Util/Pagination'
import http from '@/api/mypageApi'

export default {
  name: 'MypageActivitySurvey',
  components: {
    DatePicker,
    Pagination
  },
  computed: {
    showData () {
      return Object.keys(this.surveyList).length
    },
  },
  watch: {
  },
  created() {
    this.pages = 0;
    const termFrom = dayjs().startOf('year').format()
    const termTo = dayjs().format()
    this.term = {
      from: termFrom,
      to: termTo
    }

    let page = this.$route.params.page || '1';
    this.getData(page)
  },
  data () {
    return {
      shortcut: 'thisYear',
      shortcuts: [
        { id: 'thisMonth', name: '이번달' },
        { id: 'lastMonth', name: '지난달' },
        { id: 'thisYear', name: '올해' },
        { id: 'lastYear', name: '지난해' }
      ],
      term: {
        from: '',
        to: ''
      },
      surveyList: [],
    }
  },
  methods: {
    getData (page) {
      const today = dayjs()
      /**
       * 1. 참여일시 내려달라고 하기
       * 2. 시작전/후/참여안함 설문조사 나누기
       * */
      let range = `${dayjs(this.term.from).format('YYYY-MM-DD')},${dayjs(this.term.to).format('YYYY-MM-DD')}`
      let query = `?&page=${page}&range=${range}&limit=6`
      http.get(`/survey${query}`)
          .then(res => {
            const surveyList = res.data.data.list
            this.pages = res.data.data.total
            if (surveyList.length > 0) {
              surveyList.map(row => {
                row.setStartDate = dayjs(row.start_date).format('YYYY.MM.DD HH:mm')
                if (row.end_date === null) {
                  row.setEndDate = ''
                }
                else if (row.end_date === '0000-00-00 00:00:00') {
                  row.setEndDate = ''
                  row.end_date = '2099-12-31 00:00:00'
                }
                else {
                  row.setEndDate = dayjs(row.end_date).format('YYYY.MM.DD HH:mm')
                }
                row.finSurveyDate = row.apply_end_date !== null ? dayjs(row.apply_end_date).format('YYYY.MM.DD HH:mm') : '-'
                if (row.state !== 'C' && today.isAfter(row.end_date)) {
                  row.setStateMsg = '참여불가'
                } else if (row.state === 'C') {
                  row.setStateMsg = '참여완료'
                } else {
                  row.setStateMsg = '참여하기'
                }
              })
              return this.surveyList = surveyList
            } else {
              return this.surveyList = surveyList
            }
          }).catch(err => console.log(err))
    },
    setTerm (shortcut) {
      const term = this.term
      switch (shortcut) {
        case 'thisMonth':
          term.from = dayjs().startOf('month').format()
          term.to = dayjs().format()
          break
        case 'lastMonth':
          term.from = dayjs().add(-1, 'month').startOf('month').format()
          term.to = dayjs().add(-1, 'month').endOf('month').format()
          break
        case 'thisYear':
          term.from = dayjs().startOf('year').format()
          term.to = dayjs().format()
          break
        case 'lastYear':
          term.from = dayjs().add(-1, 'year').startOf('year').format()
          term.to = dayjs().add(-1, 'year').endOf('year').format()
          break
      }
      this.shortcut = shortcut
      if (this.$route.params.page !== '1') {
        this.$router.push({params: {page: `1`}})
      }
      this.getData(1);
    },
    search () {
      if(!this.term.from || !this.term.to) {
        alert('날짜를 확인해주세요');
        return false;
      }
      this.getData(1);
    },
    goSurvey (idx) {
      this.$router.push({name: 'surveyView', params: {id: idx}})
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  tbody {
    .term {
      color: $grey3;
    }
    .date {
      &:before {
        content: "참여: ";
      }
    }
  }
}
</style>
