<template>
  <div class="mainApp">
    <div class="sectionTitle">
      전북현대 모바일 애플리케이션의<br />다양한 콘텐츠 및 서비스를 만나보세요.
    </div>
    <div class="desc">
    </div>
    <div class="links">
      <a href="https://apple.co/3HVkjc3" target="_blank"><img src="@/assets/img/main_application_appstore.png" alt=""></a>
      <a href="https://bit.ly/3nQtcvC" target="_blank"><img src="@/assets/img/main_application_googleplay.png" alt=""></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainApplication',
  created () {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
