<template>
  <div class="fanzoneEvent">
    <div class="container">
      <div class="noData" v-if="eventData.length === 0">등록된 이벤트가 없습니다</div>
      <div class="eventList" v-else>
        <div class="eventItem" v-for="(data, i) in eventData" :key="i">
          <router-link :to="{ name: 'fanzoneEventView', params: {id: data.idx} }">
            <div>
              <div class="img" v-if="data.thumnail_pic">
                <img :src="data.thumnail_pic" alt="" @error="replaceImg">
              </div>
              <div class="img" v-else><img src="@/assets/img/bg_event_404_wide.png"></div>
              <span class="status" :data-status=data.dataStatus>{{ data.eventState }}</span>
              <p class="subject">{{ data.event_name }}</p>
              <p class="date">{{ data.setStart_apply }} ~ {{ data.setEnd_apply }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api/index'
import dayjs from "dayjs"
import bgImg from "@/assets/img/bg_event_404_wide.png"

export default {
  name: 'FanzoneEvent',
  created() {
    this.init()
  },
  data() {
    return {
      eventData: [],
    }
  },
  methods: {
    async init() {
      const nowTime = dayjs()
      const event = await http.get('/fanzone/event')
      event.data.data.list.map(row => {
        row.setStart_apply = row.start_apply ? dayjs(row.start_apply).format('YYYY년 MM월 DD일 HH:mm') : ''
        row.setEnd_apply = row.end_apply ? dayjs(row.end_apply).format('YYYY년 MM월 DD일 HH:mm') : ''
          row.startDay = dayjs(row.start_apply)
          row.endDay = dayjs(row.end_apply)
          if (nowTime.isBefore(row.startDay) === true) {
            row.eventState = '예정'
            row.dataStatus = '02'
          } else if (nowTime.isAfter(row.endDay) === true) {
            row.eventState = '종료'
            row.dataStatus = '03'
          } else {
            row.eventState = "진행중"
            row.dataStatus = '01'
          }
      })
      this.eventData = event.data.data.list
    },
    replaceImg(e) {
      e.target.src = bgImg
    },
  },
}
</script>
